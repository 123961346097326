import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GiCheckMark } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { Box, Grid, Typography } from "@mui/material";
import ThirdBox from "../Questions/ThirdBox";
import bottomRight from "../../assets/bg/bottomRight.png";
import topLeft from "../../assets/bg/topLeft.png";
import topRight from "../../assets/bg/topRight.png";
import bottomLeft from "../../assets/bg/bottomLeft.png";
import CenterLogo from "../../assets/Images/centerLogo.png";
import Modal from "@mui/material/Modal";
import Logo from "../../assets/Images/Logo.png";
import DndBox from "./DndBox";
function ShortTestFeedBack() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [sequenceData, setSequenceData] = useState([]);
  const [qDetails, setQDetails] = useState([]);
  const [qType, setQType] = useState("");
  const [totalMarks, setTotalMarks] = useState("");
  const [gainMark, setGainMarks] = useState("");

  // third Type Question
  const [studentimage, setStudentImage] = useState("");
  const [correctImage, setCorrectImage] = useState("");
  const [studentData, setStudentData] = useState([]);
  const [correctData, setCorrectData] = useState([]);
  const [studentChoose, setStudentChoose] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [optionModel, setOptionModel] = useState(false);

  const styles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    border: "0",
    outline: "0",
    bgcolor: "#fff",
    borderRadius: "10px",
    p: 3,
    paddingBottom: 5,
  };

  const handleClosePopup = () => setOpenModel(false);

  useEffect(() => {
    setSequenceData(state.sequenceData);
    setQDetails(state?.response?.data);
    setQType(state?.response?.questionType);
    setTotalMarks(state?.response?.totalAnswer);
    setGainMarks(state?.response?.correctAnswer);
    if (state?.response?.questionType == "3") {
      correctDataHandler(state?.response.data);
      studentDataHandler(state?.response.data);
      setStudentImage(state?.response.data[0].image);
      setCorrectImage(state?.response.data[0].image);
    }
  }, [state]);

  const nextHandler = () => {
    if (state.attemptType === 1) {
      if (state?.response.passingStatus === "Pass") {
        setStudentChoose(true);
        setOpenModel(true);
      } else {
        if (state.response.passingStatus === "Failed") {
          navigate(`/preview`, {
            state: {
              ppt: sequenceData && sequenceData[0]?.ppt,
              audio: sequenceData && sequenceData[0].audio,
              classId: localStorage.getItem("classId"),
              sessionId: localStorage.getItem("sessionId"),
              courseId: localStorage.getItem("courseId"),
            },
          });
        }
      }
    } else {
      navigate("/questions", {
        state: {
          courseId: localStorage.getItem("courseId"),
          sessionId: localStorage.getItem("sessionId"),
          classID: localStorage.getItem("classId"),
          sameQuestion: false,
        },
      });
      // navigate(`/preview`, {
      //   state: {
      //     ppt: sequenceData && sequenceData[0]?.ppt,
      //     audio: sequenceData && sequenceData[0].audio,
      //     classId: localStorage.getItem("classId"),
      //     sessionId: localStorage.getItem("sessionId"),
      //     courseId: localStorage.getItem("courseId"),
      //   },
      // });
    }
  };

  const correctDataHandler = (data) => {
    const newQuestions = data.map((res) => ({
      correctAnswer: res.correctAnswer,
      questionID: res.questionID,
      optionID: res.id,
      studentAns: "",
      Uid: res.Uid,
      top: res.top,
      left: res.left,
      right: res.right,
      bottom: res.bottom,
    }));
    setCorrectData(newQuestions);
  };

  const studentDataHandler = (data) => {
    const newQuestions = data.map((res) => ({
      correctAnswer: "",
      questionID: res.questionID,
      optionID: res.id,
      // need to chnage here student Ans
      studentAns: res.answer,
      Uid: res.Uid,
      top: res.top,
      left: res.left,
      right: res.right,
      bottom: res.bottom,
    }));
    setStudentData(newQuestions);
  };

  return (
    <>
      <div className="relative h-screen w-full ">
        <div className="absolute top-0 left-0 z-10">
          <img
            src={topLeft}
            alt=""
            className="w-[80px] sm:w-[110px] md:w-[140px] lg:w-[180px]"
          />
        </div>
        <div className="absolute top-0 right-10 z-10">
          <img
            src={topRight}
            alt=""
            className="w-[50px] sm:w-[80px] md:w-[120px] lg:w-[150px]"
          />
        </div>
        <div className="absolute bottom-0 left-4 z-10">
          <img
            src={bottomLeft}
            alt=""
            className="w-[50px] sm:w-[80px] md:w-[120px] lg:w-[150px]"
          />
        </div>
        <div className="absolute bottom-0 right-0 z-10">
          <img
            src={bottomRight}
            alt=""
            className="w-[80px] sm:w-[110px] md:w-[140px] lg:w-[180px]"
          />
        </div>
        <div className="relative z-50">
          <div className="flex justify-center items-start h-full flex-col p-3 gap-5 max-w-[1200px] w-full m-auto">
            <div>
              <img
                src={CenterLogo}
                alt="Logo"
                className="w-[100px] sm:w-[130px] md:w-[160px] lg:w-[200px]"
              />
            </div>

            <div className="w-full">
              {qType && (qType == "1" || qType == "2") && (
                <>
                  <div className="table-responsive">
                    <table
                      className="table table-bordered result_table"
                      border={2}
                    >
                      <thead>
                        <tr>
                          {/* <th scope="col"></th> */}
                          <th
                            scope="col"
                            style={{
                              background: "#F5B816",
                              textAlign: "center",
                            }}
                          >
                            Question
                          </th>

                          <th
                            scope="col"
                            style={{
                              background: "#F5B816",
                              textAlign: "center",
                            }}
                          >
                            Correct Answer
                          </th>
                          <th
                            scope="col"
                            style={{
                              background: "#F5B816",
                              textAlign: "center",
                            }}
                          >
                            Your Answer
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {qDetails?.map((res, i) => (
                          <tr key={res.question}>
                            {/* <td style={{ width: "50px" }} scope="row">
                              {i + 1}
                            </td> */}
                            <td
                              style={{ textAlign: "center" }}
                              // className={
                              //   res.answer === res.correctAnswer
                              // ? "!bg-[#32AD95]"
                              // : "!bg-[#DF1E50]"
                              // }
                            >
                              {res.questionText}
                            </td>

                            {res.answer && (
                              <td
                                style={{ width: "300px", textAlign: "center" }}
                                className={
                                  res.answer === res.correctAnswer
                                    ? "bg-[#32AD95]"
                                    : "bg-[#DF1E50]"
                                }
                              >
                                {qType === 1
                                  ? res.correctAnswer === "1"
                                    ? "true"
                                    : "false"
                                  : res.correctAnswer}
                              </td>
                            )}
                            <td
                              style={{ width: "300px", textAlign: "center" }}
                              className={
                                res.answer === res.correctAnswer
                                  ? "!bg-[#32AD95]"
                                  : "!bg-[#DF1E50]"
                              }
                            >
                              {qType === 1 ? (
                                res.answer === res.correctAnswer ? (
                                  <div>
                                    {res.correctAnswer == "1" ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        true
                                        {/* <GiCheckMark
                                          style={{
                                            background: "#fff",
                                            color: "#32AD95",
                                            padding: "5px",
                                            fontSize: "35px",
                                          }}
                                        /> */}
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        false
                                        {/* <GiCheckMark
                                          style={{
                                            background: "#fff",
                                            color: "#32AD95",
                                            padding: "5px",
                                            fontSize: "35px",
                                          }}
                                        /> */}
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div>
                                    {res.answer == "1" ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        true{" "}
                                        {/* <AiOutlineClose
                                          style={{
                                            background: "#fff",
                                            color: "#DF1E50",
                                            padding: "5px",
                                            fontSize: "35px",
                                          }}
                                        /> */}
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        false{" "}
                                        {/* <AiOutlineClose
                                          style={{
                                            background: "#fff",
                                            color: "#DF1E50",
                                            padding: "5px",
                                            fontSize: "35px",
                                          }}
                                        /> */}
                                      </div>
                                    )}
                                  </div>
                                )
                              ) : res.answer === res.correctAnswer ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  {res.answer}{" "}
                                  {/* <GiCheckMark
                                    style={{
                                      background: "#fff",
                                      color: "#32AD95",
                                      padding: "5px",
                                      fontSize: "35px",
                                    }}
                                  /> */}
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  {res.answer}{" "}
                                  {/* <AiOutlineClose
                                    style={{
                                      background: "#fff",
                                      color: "#DF1E50 !important",
                                      padding: "5px",
                                      fontSize: "35px",
                                    }}
                                  /> */}
                                </div>
                              )}
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan={2} style={{ textAlign: "end" }}>
                            Total
                          </td>
                          <td
                            style={{ textAlign: "center" }}
                          >{`${gainMark} / ${totalMarks}`}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </>
              )}

              {qType && qType == "3" && (
                <div className="w-full flex student-row-wise flex-col justify-center items-start gap-5">
                  <Box sx={{  width: "500px" }}>
                    <Typography
                      fontSize={"20px"}
                      fontWeight={700}
                      marginBottom={2}
                      borderBottom={2}
                    >
                      Student Answer
                    </Typography>
                    <Box
                      // sx={{
                      //   display: "flex",
                      //   flexDirection: "column",
                      //   position: "relative",
                      // }}
                      ref={null}
                    >
                      <div className="flex justify-center relative items-start ">
                        <img
                          className="image_question"
                          src={studentimage}
                          alt="Student Answer"
                        />
                        {studentData?.map((res) => (
                          <div className="w-[156px] absolute top-0 right-0">
                            <DndBox
                              key={res}
                              id={res}
                              value={res.correctAnswer}
                              {...studentData[res]}
                              onlyView={false}
                            />
                          </div>
                        ))}
                      </div>
                    </Box>
                  </Box>
                  <Box sx={{  width: "500px" }}>
                    <Typography
                      fontSize={"20px"}
                      fontWeight={700}
                      marginBottom={2}
                      borderBottom={2}
                    >
                      Correct Answer
                    </Typography>
                    <Box
                    // sx={{
                    //   display: "flex",
                    //   flexDirection: "column",
                    //   position: "relative",
                    // }}
                    >
                      <div className="flex justify-center relative items-start ">
                        <img
                          className="image_question"
                          src={correctImage}
                          alt="Correct Answer"
                        />
                        <div>
                          {correctData?.map((res, index) => (
                            <div className="w-[156px] absolute top-0 right-0">
                              <DndBox
                                id={res}
                                value={res.correctAnswer}
                                {...correctData[res]}
                                onlyView={true}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </Box>
                  </Box>
                </div>
              )}

              <div style={{ textAlign: "end" }}>
                <button
                  style={{
                    textAlign: "center",
                    margin: "50px 0",
                    padding: "5px 20px",
                    backgroundColor: "rgb(255,117,31)",
                    color: "white",
                    borderRadius: "4px",
                  }}
                  onClick={() => {
                    nextHandler();
                    // navigate(`/preview`, {
                    //   state: {
                    //     ppt: sequenceData && sequenceData[0]?.ppt,
                    //     audio: sequenceData && sequenceData[0].audio,
                    //     classId: localStorage.getItem("classId"),
                    //     sessionId: localStorage.getItem("sessionId"),
                    //     courseId: localStorage.getItem("courseId"),
                    //   },
                    // });
                  }}
                >
                  Next
                </button>
              </div>
            </div>

            {/* <div className="bg-[#DF1E50] sm:!px-3 px-2 sm:!py-2 py-1 rounded-lg">
              <a href="mailto:naomi.wineman.18@ucl.ac.uk" className="text-white text-xs sm:text-sm md:text-base">
                naomi.wineman.18@ucl.ac.uk
              </a>
            </div> */}
          </div>
        </div>
      </div>
      {studentChoose && (
        <Modal
          className="confirm_modal"
          keepMounted
          open={openModel}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
          disableBackdropClick={true}
          md
        >
          <Box sx={styles}>
            <Grid container width={"100%"}>
              <Grid item xs={12}>
                <Typography
                  className="modal_head"
                  fontSize={"30px"}
                  fontWeight={"700"}
                  sx={{ borderBottom: "2px solid var(--primary)" }}
                >
                  Are you happy to continue?
                </Typography>
              </Grid>
              <Grid item>
                <Grid container padding={"50px 0 0"}>
                  <Grid item xs={12} marginBottom={"50px"}>
                    <ul>
                      <li>
                        <Typography
                          fontSize={"25px"}
                          className="modal_text"
                          fontWeight={"500"}
                        >
                          You got all the answers right. Well done.
                        </Typography>
                        <Typography
                          fontSize={"25px"}
                          className="modal_text"
                          fontWeight={"500"}
                        >
                          If you are ready to look at some other words, click{" "}
                          <span
                            onClick={() => {
                              handleClosePopup();
                              navigate("/questions", {
                                state: {
                                  passingStatus: state.response.passingStatus,
                                  totalData: state && state,
                                },
                              });
                            }}
                            style={{
                              color: "var(--primary)",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            {" "}
                            here{" "}
                          </span>
                        </Typography>
                      </li>
                    </ul>
                  </Grid>
                  <Grid item xs={12}>
                    <ul>
                      <li>
                        <Typography
                          fontSize={"25px"}
                          className="modal_text"
                          fontWeight={"500"}
                        >
                          If you want to go over the words again,
                        </Typography>
                        <Typography
                          fontSize={"25px"}
                          className="modal_text"
                          fontWeight={"500"}
                        >
                          click{" "}
                          <span
                            onClick={() => {
                              navigate("/preview", {
                                state: {
                                  ppt: sequenceData && sequenceData[0]?.ppt,
                                  sameQuestion: true,
                                  audio: sequenceData && sequenceData[0].audio,
                                  classId: localStorage.getItem("classId"),
                                  sessionId: localStorage.getItem("sessionId"),
                                  courseId: localStorage.getItem("courseId"),
                                },
                              });
                              handleClosePopup();
                            }}
                            style={{
                              color: "var(--primary)",
                              textDecoration: "underline",
                              cursor: "pointer",

                            }}
                          >
                            {" "}
                            here
                          </span>
                        </Typography>
                      </li>
                    </ul>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} display={"flex"} gap={"20px"}>
                <Typography
                  className="modal_head"
                  width={"100%"}
                  fontSize={"30px"}
                  fontWeight={"700"}
                  sx={{ borderBottom: "2px solid var(--primary)" }}
                ></Typography>
                <img src={Logo} width={"100px"} />
              </Grid>
            </Grid>
          </Box>
        </Modal>
      )}
    </>
  );
}

export default ShortTestFeedBack;
