import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Card,
  Chip,
  Grid,
  Modal,
  Paper,
  Tooltip,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,

} from "@mui/material";
import Typography from "@mui/material/Typography";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "./AddStudent.css";
import { useLocation, useNavigate } from "react-router";
import moment from "moment";
import { styled } from "@mui/system";
import { Form, useForm } from "../../../utils/useForm";
import { Space } from "antd";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Controls from "../../../components/controls/Controls";
import EmailIcon from "@mui/icons-material/Email";
import { RiDeleteBinLine, RiEditLine } from "react-icons/ri";
import { ToastContainer, toast } from 'react-toastify';

import PhoneIcon from "@mui/icons-material/Phone";
import { useProSidebar } from "react-pro-sidebar";
import {
  AddressDefault,
  DeleteAddress,
  DeleteStudent,
  getStudentDetails,
  updateAddress,
} from "../../../state/actions/Admin/studentAction";
import { useSnackbar } from "notistack";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday"

const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));
const CssTextField = styled(TextField)(() => ({
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "yellow",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "black",
    },
    "&:hover fieldset": {
      borderColor: "black",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#000",
    fontSize: 13,
    border: 0,
    fontWeight: 600,
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "25px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    color: "rgb(17, 24, 39)",
    border: 0,
    paddingLeft: "25px",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
}));
const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "0",
  outline: "0",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: 3,
};

const StyledIcon = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
}));


const initialValues = {
  address: "",
};

const DetailsStudent = () => {
  const [studentList, setStudentList] = useState([]);
  const [addressName, setAddressName] = useState([]);

  const [studentResults, setStudentResult] = useState([]);

  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [openAddressModel, setOpenAddressModel] = useState(false);
  const [openDeleteAddressModel, setOpenDeleteAddressModel] = useState(false);
  const [deleteAddressId, setDeleteAddressId] = useState();
  const [AddressId, setAddressId] = useState();
  const { collapsed, broken } = useProSidebar();
  const [openAddressDefaultModel, setOpenAddressDefaultModel] = useState(false);
  const [planHistoryList, setplanHistoryList] = useState([]);
  const [AddVehicle, setAddVehicle] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [stateData, setStateData] = useState([]);


  //delete the user
  const handleCloseDelete = () => setOpenDeleteModel(false);
  const handleDeleteModel = () => setOpenDeleteModel(true);

  //delete the Address

  const handleCloseDeleteAddress = () => setOpenDeleteAddressModel(false);
  const handleDeleteAddressModel = () => setOpenDeleteAddressModel(true);

  //change default
  const handleCloseAddressDefualt = () => setOpenAddressDefaultModel(false);
  const handleAddressDefaultModel = () => setOpenAddressDefaultModel(true);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { state } = useLocation();
  const id = state.user;

  //get details

  const getDetails = async () => {
    const res = await getStudentDetails(id);
    if (res && res.status) {
      setStudentList(res?.data);
      setplanHistoryList(res.data.planHistory);
      setStudentResult(res.data.studentsTestAttempt)
    }
  };
  useEffect(() => {
    getDetails(id);
  }, [id]);


  //device status
  const deviceStatus = (deviceStatus) => {
    const statusColors = {
      1: {
        key: "Yes",
        color: "black",
      },
      0: {
        key: "No",
        color: "black",
      },
    };

    return (
      <StyledChip
        style={{
          height: "30px",
          fontSize: "15px",
        }}
        sx={statusColors[deviceStatus]}
        label={statusColors[deviceStatus] ? statusColors[deviceStatus].key : ""}
      />
    );
  };


  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("address" in fieldValues)
      temp.address = fieldValues.address ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, } =
    useForm(initialValues, true, validate);

  const orderStatus = (orderStatus) => {
    const statusColors = {
      0: {
        key: "Pending",
        backgroundColor: "#ede7dd",
        color: "#fc7e00",
      },
      1: {
        key: "Order Pickup Schedule",
        backgroundColor: "green",
        color: "#ede7dd",
      },
      2: {
        key: "Pickup Package",
        backgroundColor: "green",
        color: "#ede7dd",
      },
      3: {
        key: "Package in Transit",
        backgroundColor: "green",
        color: "#ede7dd",
      },
      4: {
        key: "Delivered",
        backgroundColor: "green",
        color: "#ede7dd",
      },
      5: {
        key: "Cancelled",
        backgroundColor: "#FDE3E1",
        color: "#ff0000",
      },
    };

    return (
      <StyledChip
        style={{
          height: "30px",
          fontSize: "15px",
        }}
        sx={statusColors[orderStatus]}
        label={statusColors[orderStatus] ? statusColors[orderStatus].key : ""}
      />
    );
  };

  //address
  useEffect(() => {
    if (
      addressName.id != "" &&
      addressName.id != null &&
      addressName.id != undefined
    ) {
      setValues({
        ...values,
        address: addressName.address,
        id: addressName?.id,
      });
    }
  }, [addressName]);

  //change default

  const handleDefault = async (e) => {
    e.preventDefault();

    const res = await AddressDefault(AddressId, id);

    if (res && res.status === true) {
      handleCloseAddressDefualt();
      getDetails();
      toast.success(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  //delete address
  const handleDelete = async () => {
    const res = await DeleteAddress(deleteAddressId, id);
    if (res && res.status === true) {
      handleCloseDeleteAddress();
      getDetails();
      toast.success(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  //delete the details

  const handleDeleteDetails = async () => {
    const res = await DeleteStudent(id);
    if (res && res.status === true) {
      handleCloseDelete();
      navigate("/student");
      toast.success(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };




  const genderStatus = (genderStatus) => {
    const statusColors = {
      1: {
        key: "Male",
        color: "black",
        backgroundColor: "white"
      },
      2: {
        key: "Female",
        color: "black",
        backgroundColor: "white"

      },


    };

    return (
      <StyledChip
        style={{
          margin: "0",
          fontFamily: "Arial",
          fontWeight: 400,
          fontSize: "1rem",
          lineHeight: "1.5",
          letterSpacing: "0.00938em",
          textAlign: "left",
          color: "black",
          marginRight: "19px"
        }}
        sx={statusColors[genderStatus]}
        label={statusColors[genderStatus] ? statusColors[genderStatus].key : ""}
      />
    );
  };

  return (
    <>
      <Box
        sx={{
          px: 3,
          paddingBottom: "3px",
          backgroundColor: "rgb(241, 245, 249)",
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography
            sx={{
              paddingTop: "25px",
              paddingBottom: "15px",
              fontSize: "15px",
              fontWeight: "600",
              color: "#000",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/student");
            }}
          >
            <KeyboardBackspaceIcon
              sx={{ verticalAlign: "top", marginRight: "5px" }}
            />
            Student
          </Typography>
          <div>
            <Controls.Button
              text="Back"
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                borderRadius: "50px",
                border: "0",
                color: "white",
                ":hover": { bgcolor: "black", border: "0" },
                backgroundColor: "black",
                textTransform: "none",
                marginRight: "10px",
              }}
              variant="outlined"
              onClick={() => {
                navigate("/student");
              }}
            />

            <Controls.Button
              text="Delete"
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: "#fff",
                backgroundColor: "Red",
                ":hover": { bgcolor: "Red" },
                textTransform: "none",
                borderRadius: "50px",
                boxShadow: 0,
                marginLeft: "7px",
              }}
              onClick={() => {
                handleDeleteModel();
              }}
            />
          </div>
        </Box>
      </Box>

      <Box
        sx={{
          marginTop: "15px",
          marginLeft: { xs: "20px", md: "25px", lg: "25px" },
          marginRight: { xs: "15px", md: "25px", lg: "25px" },
        }}
      >
        <Box
          sx={{
            maxWidth: {
              md: "800px",
              lg: "100%",
            },
            marginLeft: "-5px",
          }}
        >
          <Box>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={{ xs: 2, md: 3 }}>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                  <Card
                    sx={{
                      paddingTop: "24px",
                      textAlign: "center",
                      color: "black",
                      paddingBottom: "15px",
                    }}
                  >
                    <StyledIcon
                      sx={{
                        color: "black",
                        backgroundImage: "white",
                      }}
                    >
                      <Avatar
                        sx={{ width: "90px", height: "90px" }}
                        src={studentList?.profile}
                        alt="profilepic"
                      />
                    </StyledIcon>

                    <Typography
                      sx={{
                        fontSize: { xs: '15px' },
                        marginTop: "25px",
                        fontWeight: "600",
                        textTransform: "uppercase",
                      }}
                    >
                      {studentList?.firstName} {studentList?.lastName}
                    </Typography>

                    <Typography
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ py: 0.5, marginLeft: "-22px" }}
                    >
                      <EmailIcon
                        sx={{ width: "15px", height: "15px", px: 1 }}
                      />
                      {studentList?.email}
                    </Typography>

                  </Card>


                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={9}>
                  <Paper sx={{ marginTop: "0px", height: "100%" }}>
                    <Box>
                      <Typography
                        sx={{
                          color: "black",
                          fontSize: "20px",
                          padding: "12px",
                          fontWeight: "bold",
                          marginLeft: "19px"
                        }}
                      >
                        Student Details
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        maxWidth: {
                          lg: "1250px",
                        },
                        marginTop: "-27px",
                        marginLeft: "19px",
                        marginRight: "19px"
                      }}
                    >
                      <Box
                        sx={{
                          marginTop: "20px",
                          marginBottom: "20px",
                        }}
                      >
                        <Box sx={{ flexGrow: 1, marginTop: "15px" }}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              {/* <Item> */}
                              <Grid
                                container
                                spacing={2}
                                sx={{ boxShadow: "none", px: 1, marginTop: "-10px" }}
                              >
                                <Grid xs={4} md={6} sx={{ paddingTop: "12px", px: 2 }}>
                                  <Typography textAlign="left"  fontWeight={'700'} sx={{  fontSize: { xs: '12px', sm: '16px' } }}>
                                    Class
                                  </Typography>
                                </Grid>
                                <Grid xs={8} md={6} sx={{ paddingTop: "12px" }}>
                                  <Typography textAlign="left"  fontWeight={'500'} sx={{  fontSize: { xs: '12px', sm: '16px' }}}>
                                    {studentList?.className}

                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid
                                container
                                spacing={2}
                                sx={{ boxShadow: "none", px: 1, marginTop: "-10px" }}
                              >
                                <Grid xs={4} md={6} sx={{ paddingTop: "12px", px: 2, }}>
                                  <Typography textAlign="left"  fontWeight={'700'} sx={{  fontSize: { xs: '12px', sm: '16px' } }}>
                                    Full Name
                                  </Typography>
                                </Grid>
                                <Grid xs={8} md={6} sx={{ paddingTop: "12px" }}>
                                  <Typography textAlign="left"  fontWeight={'500'} sx={{  fontSize: { xs: '12px', sm: '16px' }}}>
                                    {studentList?.firstName} {studentList?.lastName}

                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid container spacing={2} sx={{ boxShadow: "none", px: 1, marginTop: "-10px" }}>
                                <Grid xs={4} md={6} sx={{ paddingTop: "12px", px: 2 }}>
                                  <Typography textAlign="left"  fontWeight={'700'} sx={{  fontSize: { xs: '12px', sm: '16px' } }}>
                                    Email
                                  </Typography>
                                </Grid>
                                <Grid xs={8} md={6} sx={{ paddingTop: "12px" }}>
                                  <Typography textAlign="left"  fontWeight={'500'} sx={{  fontSize: { xs: '12px', sm: '16px' }}}>
                                    {studentList?.email}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>

      <Paper
        sx={{  maxWidth: {
          md: "100%",
          lg: "1300px",
          marginLeft:'auto',
          marginRight:'auto',
        }, marginTop: { xs: "20px", sm: "15px", md: "15px", lg: "25px" } }}
      >
        <Box>
          <Typography
            sx={{
              color: "black",
              fontSize: "20px",
              m: 1,
              padding: "12px",
              fontWeight: "bold",
            }}
          >
            Total ShortTest Attempt Details
          </Typography>
        </Box>
        <Box
          sx={{
            maxWidth: {
              lg: "1250px",
            },
            marginTop: "-27px",
          }}
        >
          <Box
            sx={{
              marginTop: "40px",
              marginBottom: "20px",
              borderTopRightRadius: "20px",
              borderTopLeftRadius: "20px",
            }}
          >
            <TableContainer
              component={Paper}
              sx={{ boxShadow: 0, paddingLeft: "0!important" }}
            >
              <Table
                aria-label="simple table"
                sx={{ paddingLeft: "0!important", minWidth: 1140 }}
              >
                <TableHead sx={{ paddingLeft: "0!important" }}>
                  <TableRow
                    sx={{
                      backgroundColor: "#f6f9fb",
                      paddingLeft: "0!important",
                    }}
                  >
                    <TableCell
                      sx={{
                        color: "#000",
                        fontSize: "14px",
                        fontWeight: "600",
                        textTransform: "none",
                        borderColor: "rgba(107, 114, 128, .4)",
                      }}
                    >
                      Course Name
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#000",
                        fontSize: "14px",
                        fontWeight: "600",
                        textTransform: "none",
                        borderColor: "rgba(107, 114, 128, .4)",
                      }}
                    >
                      Session Name
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#000",
                        fontSize: "14px",
                        fontWeight: "600",
                        textTransform: "none",
                        borderColor: "rgba(107, 114, 128, .4)",
                      }}
                    >
                      Gain Marks/ Total Marks 
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#000",
                        fontSize: "14px",
                        fontWeight: "600",
                        textTransform: "none",
                        borderColor: "rgba(107, 114, 128, .4)",
                      }}
                    >
                      No: of ppt packages opened
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    backgroundColor: "#fff",
                    paddingLeft: "0!important",
                  }}
                >

                  {studentResults?.map((row) => {
                    return (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "400",
                            textTransform: "none",
                          }}
                        >
                          {row?.courseName || "-"}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "400",
                            textTransform: "none",
                          }}
                        >
                          {row?.sessionName || "-"}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "400",
                            textTransform: "none",
                          }}
                        >
                          {`${row.gainMarks} / ${row.totalMark}`}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "400",
                            textTransform: "none",
                          }}
                        >
                          {row.count ? row.count : '-'}
                          {/* {row?.totalCount === 0 ? "0" :row?.totalCount|| "-"} */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {studentResults && studentResults.length === 0 ? (
                <div
                  className="noDataFound"
                  style={{
                    display: "flex",
                  }}
                >
                  {" "}
                  No Data Found
                </div>
              ) : null}
            </TableContainer>
          </Box>
        </Box>
      </Paper>

      <Modal
        keepMounted
        open={openDeleteModel}
        onClose={handleCloseDelete}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: {
              xs: "50%",
              sm: broken ? "50%" : collapsed ? "50%" : "65%",
              md: collapsed ? "50%" : "60%",
              lg: collapsed ? "50%" : "55%",
            },
            transform: "translate(-50%, -50%)",
            width: { xs: "320px", sm: "400px" },
            border: "0",
            outline: "0",
            bgcolor: "#fff",
            borderRadius: "10px",
            p: 3,
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#000",
              marginBottom: "15px",
            }}
          >
            Delete Record
          </Typography>
          <label className="fontLight">
            Are you sure to want to delete this record?
          </label>
          <Grid container style={{ marginTop: "2rem" }}>
            <Grid item xs={9} className="text-end">
              <Controls.Button
                style={{
                  backgroundColor: "#fff",
                  marginRight: "10px",
                  color: "#000",
                }}
                text="Cancel"
                onClick={handleCloseDelete}
              />
            </Grid>
            <Grid item xs={3} className="text-end">
              <Controls.Button
                style={{ marginRight: "7px" }}
                text="Delete"
                onClick={() => handleDeleteDetails(id)}
                type="submit"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={openDeleteAddressModel}
        onClose={handleCloseDeleteAddress}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: {
              xs: "50%",
              sm: broken ? "50%" : collapsed ? "50%" : "65%",
              md: collapsed ? "50%" : "60%",
              lg: collapsed ? "50%" : "55%",
            },
            transform: "translate(-50%, -50%)",
            width: { xs: "320px", sm: "400px" },
            border: "0",
            outline: "0",
            bgcolor: "#fff",
            borderRadius: "10px",
            p: 3,
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#000",
              marginBottom: "15px",
            }}
          >
            Delete Record
          </Typography>
          <label className="fontLight">
            Are you sure to want to delete this record?
          </label>
          <Grid container style={{ marginTop: "2rem" }}>
            <Grid item xs={9} className="text-end">
              <Controls.Button
                style={{
                  backgroundColor: "#fff",
                  marginRight: "10px",
                  color: "#000",
                }}
                text="Cancel"
                onClick={handleCloseDeleteAddress}
              />
            </Grid>
            <Grid item xs={3} className="text-end">
              <Controls.Button
                style={{ marginRight: "7px" }}
                text="Delete"
                onClick={() => handleDelete(deleteAddressId)}
                type="submit"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={openAddressDefaultModel}
        onClose={handleCloseAddressDefualt}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: {
              xs: "50%",
              sm: broken ? "50%" : collapsed ? "50%" : "65%",
              md: collapsed ? "50%" : "60%",
              lg: collapsed ? "50%" : "55%",
            },
            transform: "translate(-50%, -50%)",
            width: { xs: "320px", sm: "400px" },
            border: "0",
            outline: "0",
            bgcolor: "#fff",
            borderRadius: "10px",
            p: 3,
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#000",
              marginBottom: "15px",
            }}
          >
            Default Address
          </Typography>
          <label className="fontLight">
            Are you sure to want change default address?
          </label>
          <Grid container style={{ marginTop: "2rem" }}>
            <Grid item xs={9} className="text-end">
              <Controls.Button
                style={{
                  backgroundColor: "#fff",
                  marginRight: "10px",
                  color: "#000",
                }}
                text="Cancel"
                onClick={handleCloseAddressDefualt}
              />
            </Grid>
            <Grid item xs={3} className="text-end">
              <Controls.Button
                style={{ marginRight: "7px" }}
                text="Change"
                onClick={handleDefault}
                type="submit"
              />
            </Grid>
          </Grid>

        </Box>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />
    </>
  );
};

export default DetailsStudent;
