import axiosRequest from "../axios/axios";

export const trailAnsHandler = async (data) =>{
    // payload 0 is no 1 is yes 
    try{
        const response = await axiosRequest.post(`/StudentTrial`,data);
        return response.data;
    }catch(error){
        return error;
    }
};