import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
} from "@mui/material";
import { Grid, Box, Typography, IconButton } from "@mui/material";
import { RiDeleteBinLine, RiEditLine } from "react-icons/ri";
import camera from "../../../assets/camera.svg";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import { Space } from "antd";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import { Form, useForm } from "../../../utils/useForm";
import Controls from "../../../components/controls/Controls";
import {
  AddTeachers,
  updateTeachers,
  getClassDropdownList,
} from "../../../state/actions/Admin/teacherAction";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import dayjs from "dayjs";
import { useProSidebar } from "react-pro-sidebar";
import "./teacher.css";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
const StyledImageUploadWrapper = styled(Box)(() => ({
  marginTop: "16px",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  height: "206px",
  borderRadius: "10px",
  textAlign: "center",
}));
const StyledIconWrapper = styled(Box)(() => ({
  margin: "20px auto",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",

  img: {
    marginTop: "30px",
  },
}));
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

const StyledInput = styled("input")({
  display: "none",
});

const StyledRequired = styled("span")({
  color: "red",
  marginLeft: "2px",
  fontSize: "20px",
});
const StyledRemoveButton = styled(Box)({
  color: "red",
  display: "inline",
  "&:hover": {
    cursor: "pointer",
  },
});

const initialValues = {
  classID: "",
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  showPassword: "",
  gender: "",
  profile: "",
};

const Gender = [
  { id: 1, name: "Male" },
  { id: 2, name: "Female" },
];
const AddTeacher = (props) => {
  const [isEdit, setIsEdit] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [image, setImage] = useState("");
  const [teacherImage, setteacherImage] = useState({});
  const [error, setError] = useState("");
  const [errorDate, setErrorDate] = useState("");
  const [rowsData, setRowsData] = useState([]);
  const [errorEndDate, setErrorEndDate] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [classDropdown, setClassDropdown] = useState([]);

  const ClassDropdownList = async () => {
    const res = await getClassDropdownList();
    if (res.status === true) {
      setClassDropdown(res.data);
    } else {
      setClassDropdown([]);
    }
  };

  const { enqueueSnackbar } = useSnackbar();
  const { collapsed } = useProSidebar();
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("firstName" in fieldValues) {
      if (fieldValues.firstName !== "" && fieldValues.firstName !== undefined) {
        if (!/^[a-zA-Z ]*$/.test(fieldValues.firstName)) {
          temp.firstName = "Invalid First Name";
        } else {
          temp.firstName = "";
        }
      } else {
        temp.firstName = "This field is required.";
      }
    }
    if ("lastName" in fieldValues) {
      if (fieldValues.lastName !== "" && fieldValues.lastName !== undefined) {
        if (!/^[a-zA-Z ]*$/.test(fieldValues.lastName)) {
          temp.lastName = "Invalid Last Name";
        } else {
          temp.lastName = "";
        }
      } else {
        temp.lastName = "This field is required.";
      }
    }

    if ("password" in fieldValues) {
      temp.password = fieldValues.password ? "" : "This field is required.";
    }
    if ("email" in fieldValues) {
      if (fieldValues.email !== "" && fieldValues.email !== undefined) {
        const emailRegex =
          /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.(com|org|gov|edu|net|mil|in)$/;
        const isEmailValid = emailRegex.test(fieldValues.email);
        if (!isEmailValid) {
          temp.email = "Invalid email";
        } else {
          temp.email = "";
        }
      } else {
        temp.email = "This field is required.";
      }
    }
    if ("gender" in fieldValues) {
      temp.gender = fieldValues.gender ? "" : "This field is required.";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const { state } = useLocation();

  const navigate = useNavigate();

  const data = rowsData?.map((row) => row);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const formData = new FormData();
      formData.append("firstName", values.firstName);
      formData.append("lastName", values.lastName);
      formData.append("email", values.email);
      formData.append("password", values.password);
      formData.append("showPassword", values.showPassword);
      if (teacherImage && teacherImage.name) {
        formData.append("profile", values.vehicleIcon);
      }
      formData.append("classID", "");
      formData.append("gender", values.gender);

      if (isEdit) {
        const res = await updateTeachers(state?.teacher?.id, formData);
        if (res && res.status === true) {
          close();
          navigate("/teacher");

          toast.success(res.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error(res.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }

      if (!isEdit) {
        if (teacherImage && teacherImage.name) {
          const res = await AddTeachers(formData);
          if (res && res.status === true) {
            navigate("/teacher");
            close();
            toast.success(res.message, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            toast.error(res.message, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        } else {
          toast.error("Please Selecte Image", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
    }
  };

  const resetImage = () => {
    setImage("");
  };
  const reset = () => {
    resetImage();
    resetForm();
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: "#000",
      fontSize: 13,
      border: 0,
      fontWeight: 600,
      paddingTop: "15px",
      paddingBottom: "15px",
      paddingLeft: "25px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 13,
      color: "rgb(17, 24, 39)",
      border: 0,
      paddingLeft: "25px",

      paddingTop: "10px",
      paddingBottom: "10px",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&": {
      backgroundColor: "#fff",
      ":hover": { backgroundColor: "rgb(248 248 248)!important" },

      "&:nth-of-type(odd)": {
        backgroundColor: "rgb(250 250 250)!important",
      },
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const close = () => {
    // handleClose();
  };

  const [confirmDialog, setConfirmDialog] = useState(false);
  const removeImage = () => {
    resetImage();
    setValues((previousValues) => {
      var newValues = { ...previousValues };
      delete newValues["bannerImage"];
      return newValues;
    });
    setteacherImage({});
  };

  const maxSize = 2048;

  const validateImageSize = (vehicleIcons) => {
    if (vehicleIcons) {
      const fsize = vehicleIcons.size / 1024;
      if (fsize > maxSize) {
        return false;
      } else {
        return true;
      }
    }
  };

  async function handleImageItem(e) {
    const selected = e.target.files[0];
    if (validateImageSize(e.target.files[0])) {
      const ALLOWED_TYPES = ["image/png", "image/jpg", "image/jpeg"];
      if (selected && ALLOWED_TYPES.includes(selected.type)) {
        const base64String = await getBase64(selected);
        setImage(base64String);
        setteacherImage(selected);
        setValues({ ...values, vehicleIcon: selected });
      } else {
      }
    } else {
      setteacherImage({});
      toast.error("Please Upload 5MB Image", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };
  const color = "#c44242 !important";

  useEffect(() => {
    ClassDropdownList();
    if (state?.teacher) {
      setIsEdit(true);
      setValues({
        ...values,
        firstName: state?.teacher.firstName,
        lastName: state?.teacher.lastName,
        email: state?.teacher.email,
        password: state?.teacher.showPassword,
        showPassword: state?.teacher.showPassword,
        profile: state?.teacher.profile,
        classID: state?.teacher.classID,
        gender: state?.teacher.gender,
      });
      setImage(state?.teacher?.profile);
    } else {
      resetForm();
    }
  }, [state?.teacher]);

  return (
    <>
      <Typography
        sx={{
          marginLeft: "20px",
          paddingTop: "25px",
          paddingBottom: "0px",
          fontSize: "20px",
          fontWeight: "600",
          color: "#000",
          cursor: "pointer",
        }}
        onClick={() => {
          navigate("/teacher");
        }}
      >
        <KeyboardBackspaceIcon
          sx={{ verticalAlign: "top", marginRight: "5px", marginTop: "3px" }}
        />
        Teacher
      </Typography>
      <Form onSubmit={handleSubmit}>
        <Paper
          sx={{
            marginTop: { xs: "20px", sm: "15px", md: "15px", lg: "25px" },
            marginLeft: "19px",
            marginRight: "19px",
          }}
        >
          <Box>
            <Typography
              sx={{
                color: "black",
                fontSize: "20px",
                m: 1,
                padding: "12px",
                fontWeight: "bold",
              }}
            >
              Personal Information
            </Typography>
          </Box>
          <DialogContent>
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}
              spacing={2}
            >
              <Grid item md={3} sm={4} xs={12}>
                <StyledImageUploadWrapper>
                  <label htmlFor="image-upload">
                    <StyledInput
                      accept="image/*"
                      id="image-upload"
                      type="file"
                      onChange={handleImageItem}
                      onClick={(event) => {
                        event.currentTarget.value = null;
                      }}
                    />
                    <StyledIconWrapper
                      sx={
                        image == ""
                          ? {}
                          : {
                              backgroundImage: `url(${image})`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                              margin: "15px 0",
                            }
                      }
                    >
                      {image === "" && (
                        <img
                          src={camera}
                          alt="Camera"
                          style={{ marginLeft: "27px" }}
                        />
                      )}
                    </StyledIconWrapper>
                  </label>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "black",
                    }}
                  >
                    {isEdit ? "Edit Profile Image" : "Add Profile Image"}
                  </Typography>
                  <Typography
                    sx={{
                      color: "rgba(15, 15, 15, 0.5)",
                      mx: 2,
                      fontSize: "12px",
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, max size of 5 MB
                  </Typography>

                  {image != "" && (
                    <StyledRemoveButton
                      onClick={(e) => {
                        e.preventDefault();
                        removeImage();
                      }}
                    >
                      Remove
                    </StyledRemoveButton>
                  )}
                </StyledImageUploadWrapper>
              </Grid>
              <Grid item md={9} sm={8} xs={12}>
                <Grid container>
                  <Grid item sx={{ m: 1 }} md={5} xs={12}>
                    <Controls.Input
                      text="Add New"
                      variant="outlined"
                      color="primary"
                      label="First Name"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleInputChange}
                      error={errors.firstName}
                      helperText={errors.firstName}
                      inputProps={{ maxLength: 15 }}
                    />
                  </Grid>
                  <Grid item sx={{ m: 1 }} md={5} xs={12}>
                    <Controls.Input
                      text="Add New"
                      variant="outlined"
                      color="primary"
                      label="Last Name"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleInputChange}
                      error={errors.lastName}
                      helperText={errors.lastName}
                      inputProps={{ maxLength: 15 }}
                    />
                  </Grid>
                  <Grid item sx={{ m: 1 }} md={5} xs={12}>
                    <Controls.Input
                      text="Add New"
                      variant="outlined"
                      color="primary"
                      label="Email"
                      name="email"
                      value={values.email}
                      onChange={handleInputChange}
                      error={errors.email}
                      helperText={errors.email}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item sx={{ m: 1 }} md={5} xs={12}>
                    <FormControl
                      sx={{ marginTop: "10px", width: "100%" }}
                      variant="outlined"
                    >
                      <InputLabel
                        htmlFor="outlined-adornment-password"
                        sx={{ color: "#000", bgcolor: "#fff", lineHeight: "1" }}
                      >
                        Password
                      </InputLabel>
                      <OutlinedInput
                        sx={{
                          "& .MuiInputBase-input": {
                            padding: "10px 14px 11px !important",
                          },

                          "& .MuiOutlinedInput-notchedOutline": {
                            paddingTop: "10px!important",
                            borderColor: "rgba(107, 114, 128, .5)",
                            borderRadius: "7px!important",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "black",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "blue",
                          },
                          paddingTop: "0px",
                          color: "#000",
                        }}
                        id="outlined-adornment-password"
                        name="password"
                        value={values.password}
                        onChange={handleInputChange}
                        type={showPassword ? "text" : "password"}
                        inputProps={{ maxLength: 12 }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              sx={{ color: "#000" }}
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {Boolean(errors.password) ? (
                        <p
                          style={{
                            color: "rgb(255 0 0)",
                            marginTop: "4px",
                            marginBottom: "4px",
                            fontSize: "14px",
                          }}
                        >
                          {errors.password}
                        </p>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item sx={{ m: 1 }} md={5} xs={12}>
                    <Controls.SelectBox
                      name="gender"
                      label="Gender"
                      value={values.gender}
                      onChange={handleInputChange}
                      options={Gender}
                      className="input select_box"
                    />
                    {Boolean(errors.gender) ? (
                      <p
                        style={{
                          color: "rgb(255 0 0)",
                          marginTop: "4px",
                          marginBottom: "4px",
                          fontSize: "14px",
                        }}
                      >
                        {errors.gender}
                      </p>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="flex-end">
                <Controls.Button
                  style={{
                    marginBottom: "10px",
                    backgroundColor: "#e5e7eb",
                    color: "#000",
                    marginRight: "10px",
                  }}
                  text="Cancel"
                  onClick={() => {
                    navigate("/teacher");
                    resetForm();
                  }}
                />
                <Controls.Button
                  style={{ marginBottom: "10px" }}
                  text={isEdit ? "save" : "Submit"}
                  onClick={handleSubmit}
                  type="submit"
                />
              </Grid>
            </Grid>
          </DialogContent>
        </Paper>
      </Form>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </>
  );
};

export default AddTeacher;
