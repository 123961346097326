import { Box } from "@mui/system";
import React from "react";
import SessionList from "./SessionList";

const Session = () => {
    return (
        <Box>
            <SessionList />
        </Box>
    );
};

export default Session;