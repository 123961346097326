import { useEffect, useState } from "react";
import AboutMeImg from "../../assets/Images/aboutMe.png";
import MissionImg from "../../assets/Images/centerLogo.png";
import CommonModal from "@/components/common/Modal";
function AboutMe() {
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
  });
  window.scrollTo(0, 0);
  
  return (
    <>
      <div className="container-div">
        <div className="h-auto">
          <div className="flex justify-between px-[6vw] lg:px-8 xl:px-10 max-[1200px]:flex-col max-[1200px]:items-center">
            <div className="flex flex-col mt-32 gap-y-16 max-[1200px]:items-center max-[1100px]:gap-y-8 max-[1200px]:mt-4">
              <div className="flex flex-col">
                <div className="primary-font leading-[58.7px] max-[1200px]:mr-0 mr-[30%]  max-[1350px]:mr-[42%]">
                  About Me
                </div>
                <hr className="w-[90px] max-[1350px]:w-[70px] max-[1150px]:w-[60px] max-[1150px]:mt-2 bg-[--primary] pt-[5px] mt-3 " />
              </div>
              <div className="default leading-10 font-medium  max-[480px]:leading-7">
                I taught Chemistry at Kingsbury High School from 1984 until
                2018. I am now studying for a PhD at University College London.
                These resources are part of my research project.
              </div>
            </div>
            <img
              src={AboutMeImg}
              alt="aboutUs"
              className="w-full max-w-[600px] xl:max-w-full max-[600px]:w-[400px] h-auto max-[480px]:w-[280px] max-[400px]:w-[250px]"
            />
          </div>
        </div>
      </div>
      <div className="container-div-white">
        <div className="h-auto my-[80px] max-[600px]:my-5">
          <div className="flex justify-between px-[6vw] gap-x-10  lg:px-8 xl:px-10 max-[1200px]:flex-col-reverse  max-[1200px]:items-center">
            <img
              src={MissionImg}
              alt="aboutUs"
              className="w-[40%] max-w-[600px] xl:max-w-full max-[600px]:w-[400px] h-auto max-[480px]:w-[280px max-[400px]:w-[250px]"
            />
            <div className="flex flex-col gap-y-16 w-[50%] max-[1200px]:w-full max-[1200px]:items-center max-[1200px]:mb-[50px] max-[1100px]:gap-y-8 max-[1200px]:mt-4">
              <div className="flex flex-col font-Nunito">
                <div className="text-[50px] font-archia text-[#231F20] font-medium leading-[58.7px] max-[1200px]:mr-0 mr-[30%] max-[1350px]:text-[40px] max-[1350px]:mr-[42%] max-[1150px]:text-[32px]">
                  My Aim
                </div>
                <hr className="w-[90px] max-[1350px]:w-[70px] max-[1150px]:w-[60px] max-[1150px]:mt-2 bg-[--primary] pt-[5px] mt-3 " />
              </div>
              <div className="default leading-10 font-medium max-[480px]:leading-7">
                I think that sometimes students find science difficult because
                of the words we use in school. Science lessons have lots of new
                words. They sometimes use words differently from the way we
                normally use them. I want to give online help with words used in
                science lessons and exams. I want to see if this helps to
                improve marks in tests.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutMe;
