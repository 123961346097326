import * as React from "react";
import { Box } from "@mui/material";
export default function SelectBox(props) {
    const {
        name,
        value,
        onChange,
        maxRows = 0,
        options,
        disabled,
        ClassName,
        error,
        label,
        style,
        ...other
    } = props;

    return (
        <Box sx={{
            minWidth: 160, marginTop: "7px", height: '50px !important',
        }}>
            <select
                name={name}
                id="demo-select-small"
                value={value}
                label={label}
                disabled={disabled}
                onChange={onChange}
                style={{
                    width: '100%',
                    height: '40px',
                    fontSize: '15px',
                    borderRadius: '5px',
                    backgroundColor: 'white',
                    paddingLeft: '10px',
                    borderColor: "#B5B8Bf"
                }}
                {...other}
                {...(error && { error: true, helperText: error })}
            >

                <option value="" style={{ color: "#B5B8Bf !important" }}>None</option>
                {options.map((item) => {
                    return (
                        <option key={item.id} value={item.id} >
                            {item.name || item.title || item.className || item.courseName || item.sessionName ||item.middleName}
                        </option>
                    );
                })}

            </select>
        </Box >
    );
}
