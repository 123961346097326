import React, { useEffect, useState } from "react";
import {
  Box,
  Chip,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useLocation, useNavigate } from "react-router";
import { styled } from "@mui/system";
import { useForm } from "../../../utils/useForm";
import Controls from "../../../components/controls/Controls";
import { useProSidebar } from "react-pro-sidebar";
import {
  DeleteQuestions,
  getQuestionDetails,
} from "../../../state/actions/Admin/questionAction";
import { useSnackbar } from "notistack";
import { ToastContainer, toast } from "react-toastify";
import ThirdQuestionDetails from "@/pages/Questions/ThirdQuestionDetails";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const initialValues = {
  address: "",
};

const DetailsUser = () => {
  const [QuestionData, setQuestionData] = useState([]);
  const [imageQuestionData, setImageQuestionData] = useState([]);
  const [image, setImage] = useState("");
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const { collapsed, broken } = useProSidebar();
  const [noDataFound,setNoDataFound] = useState(false);

  //delete the user
  const handleCloseDelete = () => setOpenDeleteModel(false);
  const handleDeleteModel = () => setOpenDeleteModel(true);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { state } = useLocation();
  const id = state.question;

  //get details

  const getDetails = async () => {
    const res = await getQuestionDetails(id);
    if (res && res.status) {
      if (res.data && res.data.questionType === 3) {
        setImage(res.data.image);
        setImageQuestionData(res.data.QuestionData);
      } else {
        setQuestionData(res.data.QuestionData);
      }
    }
  };

  useEffect(() => {
    getDetails(id);
  }, [id]);

  useEffect(()=>{
    noDatahandler();
  })


  const noDatahandler = () =>{
    if(QuestionData.length === 0 && imageQuestionData.length === 0){
      setNoDataFound(true);
    }else{
      setNoDataFound(false);
    }
  }

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("address" in fieldValues)
      temp.address = fieldValues.address ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const orderStatus = (orderStatus) => {
    const statusColors = {
      0: {
        key: "Pending",
        backgroundColor: "#ede7dd",
        color: "#fc7e00",
      },
      1: {
        key: "Order Pickup Schedule",
        backgroundColor: "green",
        color: "#ede7dd",
      },
      2: {
        key: "Pickup Package",
        backgroundColor: "green",
        color: "#ede7dd",
      },
      3: {
        key: "Package in Transit",
        backgroundColor: "green",
        color: "#ede7dd",
      },
      4: {
        key: "Delivered",
        backgroundColor: "green",
        color: "#ede7dd",
      },
      5: {
        key: "Cancelled",
        backgroundColor: "#FDE3E1",
        color: "#ff0000",
      },
    };

    return (
      <StyledChip
        style={{
          height: "30px",
          fontSize: "15px",
        }}
        sx={statusColors[orderStatus]}
        label={statusColors[orderStatus] ? statusColors[orderStatus].key : ""}
      />
    );
  };

  //delete the details

  const handleDeleteDetails = async () => {
    const res = await DeleteQuestions(id);
    if (res && res.status === true) {
      handleCloseDelete();
      navigate("/question");
      toast.success(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          px: 3,
          paddingBottom: "3px",
          backgroundColor: "rgb(241, 245, 249)",
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography
            sx={{
              paddingTop: "25px",
              paddingBottom: "15px",
              fontSize: "15px",
              fontWeight: "600",
              color: "#000",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/question");
            }}
          >
            <KeyboardBackspaceIcon
              sx={{ verticalAlign: "top", marginRight: "5px" }}
            />
            Question
          </Typography>
          <div>
            <Controls.Button
              text="Back"
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                borderRadius: "50px",
                border: "0",
                color: "white",
                ":hover": { bgcolor: "black", border: "0" },
                backgroundColor: "black",
                textTransform: "none",
                marginRight: "10px",
              }}
              variant="outlined"
              onClick={() => {
                navigate("/question");
              }}
            />

            <Controls.Button
              text="Delete"
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: "#fff",
                backgroundColor: "Red",
                ":hover": { bgcolor: "Red" },
                textTransform: "none",
                borderRadius: "50px",
                boxShadow: 0,
                marginLeft: "7px",
              }}
              onClick={() => {
                handleDeleteModel();
              }}
            />
          </div>
        </Box>
      </Box>

      <Box
        sx={{
          marginTop: "15px",
          marginLeft: { xs: "20px", md: "25px", lg: "25px" },
          marginRight: { xs: "15px", md: "25px", lg: "25px" },
        }}
      >
        <Box
          sx={{
            maxWidth: {
              md: "800px",
              lg: "100%",
            },
            marginLeft: "-5px",
          }}
        >
          <Box>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={{ xs: 2, md: 3 }}>
                <Grid item xs={12} sm={12} md={12}>
                  <Paper sx={{ marginTop: "0px" }}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      padding="10px 20px"
                    >
                      <Typography
                        sx={{
                          color: "black",
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                      >
                        Question Details
                      </Typography>
                      <Controls.Button
                        text="View All"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "blue",
                          backgroundColor: "#fff",
                          ":hover": { bgcolor: "#F1F5F9" },
                          textTransform: "none",
                          borderRadius: "15px",
                          border: 1,
                          borderColor: "#d3d3d3",
                          boxShadow: 0,
                          height: "40px",
                        }}
                        onClick={() => {
                          navigate("/question", {
                            state: id,
                          });
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        maxWidth: {
                          lg: "1250px",
                        },
                        marginTop: "-27px",
                      }}
                    >
                      <Box
                        sx={{
                          marginTop: "40px",
                          marginBottom: "20px",
                          borderTopRightRadius: "20px",
                          borderTopLeftRadius: "20px",
                          border: 1,
                          borderColor: "#eaeaea",
                        }}
                      >
                        <TableContainer
                          component={Paper}
                          sx={{ boxShadow: 0, paddingLeft: "0!important" }}
                        >
                          <Table
                            aria-label="simple table"
                            sx={{ paddingLeft: "0!important", minWidth: 1198 }}
                          >
                            {QuestionData.length > 0 && (
                              <>
                                <TableHead sx={{ paddingLeft: "0!important" }}>
                                  <TableRow
                                    sx={{
                                      backgroundColor: "#f6f9fb",
                                      paddingLeft: "0!important",
                                    }}
                                  >
                                    <TableCell
                                      sx={{
                                        color: "#000",
                                        fontSize: "14px",
                                        fontWeight: "600",
                                        textTransform: "none",
                                        borderColor: "rgba(107, 114, 128, .4)",
                                      }}
                                    >
                                      Question Text
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#000",
                                        fontSize: "14px",
                                        fontWeight: "600",
                                        textTransform: "none",
                                        borderColor: "rgba(107, 114, 128, .4)",
                                      }}
                                    >
                                      Correct Answer
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody
                                  sx={{
                                    backgroundColor: "#fff",
                                    paddingLeft: "0!important",
                                  }}
                                >
                                  {QuestionData?.map((row) => {
                                    return (
                                      <TableRow
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell
                                          sx={{
                                            color: "#000",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            textTransform: "none",
                                          }}
                                        >
                                          {row?.questionText}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            color: "#000",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            textTransform: "none",
                                          }}
                                        >
                                          {row?.correctAnswer}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </TableBody>
                              </>
                            )}
                            {
                              imageQuestionData.length > 0 && (
                                <>
                                  {/* third type of question */} 
                                  <DndProvider backend={HTML5Backend}>
                                      <ThirdQuestionDetails imageData={image} data={imageQuestionData}/>
                                  </DndProvider>
                                </>
                              )
                            }
                          </Table>
                          {noDataFound && (
                            <div
                              className="noDataFound"
                              style={{
                                display: "flex",
                              }}
                            >
                              {" "}
                              No Data Found
                            </div>
                          )}
                        </TableContainer>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal
        keepMounted
        open={openDeleteModel}
        onClose={handleCloseDelete}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: {
              xs: "50%",
              sm: broken ? "50%" : collapsed ? "50%" : "65%",
              md: collapsed ? "50%" : "60%",
              lg: collapsed ? "50%" : "55%",
            },
            transform: "translate(-50%, -50%)",
            width: { xs: "320px", sm: "400px" },
            border: "0",
            outline: "0",
            bgcolor: "#fff",
            borderRadius: "10px",
            p: 3,
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#000",
              marginBottom: "15px",
            }}
          >
            Delete Record
          </Typography>
          <label className="fontLight">
            Are you sure to want to delete this record?
          </label>
          <Grid
            container
            style={{ marginTop: "2rem" }}
            justifyContent={"flex-end"}
          >
            <Grid item className="text-end">
              <Controls.Button
                style={{
                  backgroundColor: "#fff",
                  marginRight: "10px",
                  color: "#000",
                }}
                text="Cancel"
                onClick={handleCloseDelete}
              />
            </Grid>
            <Grid item className="text-end">
              <Controls.Button
                style={{ marginRight: "7px" }}
                text="Delete"
                onClick={() => handleDeleteDetails(id)}
                type="submit"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default DetailsUser;
