import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../../theme";
import {
  useTheme,
  Box,
  IconButton,
  Typography,
  Avatar,
  Tooltip,
  Grid,
  Modal,
  InputAdornment,
} from "@mui/material";

import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { useProSidebar } from "react-pro-sidebar";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LogoutIcon from "@mui/icons-material/Logout";
import { chnagePassword, logoutApi } from "../../../state/actions/authActions";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { ChangePpHandler, getNotificationLists } from "../../../state/actions/Admin/classAction";
import moment from "moment";
import Controls from "../../../components/controls/Controls";
import KeyIcon from "@mui/icons-material/Key";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import { useForm } from "../../../utils/useForm";
import { useSnackbar } from "notistack";
import { ToastContainer, toast } from "react-toastify";
import User from "../../../assets/Images/user.jpg";
import camera from "../../../assets/camera.svg";

const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", sm: "400px" },
  border: "0",
  outline: "0",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: 3,
};

const initialValues = {
  newPassword: "",
  currentPassword: "",
  confirmPassword: "",
};

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const { toggleSidebar, broken, rtl } = useProSidebar();
  const dispatch = useDispatch();
  const userAdmin = useSelector((store) => store?.auth?.user);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElNoti, setAnchorElNoti] = useState(null);
  const [notification, setNotification] = useState([]);
  const handleCloseDelete = () => setOpenDeleteModel(false);
  const handleDeleteModel = () => setOpenDeleteModel(true);
  const handleClosePpModel = () => setOpenPpModel(false);
  const handleppModel = () => setOpenPpModel(true);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [openPpModel, setOpenPpModel] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [imageToDisplay,setImageToDisplay] = useState(userAdmin.profile);

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const [image, setImage] = useState(userAdmin.profile);
  const [imageApi,setImageApi] = useState("");

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("currentPassword" in fieldValues)
      temp.currentPassword = fieldValues.currentPassword
        ? ""
        : "This field is required.";

    if ("newPassword" in fieldValues)
      temp.newPassword = fieldValues.newPassword
        ? ""
        : "This field is required.";

    if ("confirmPassword" in fieldValues)
      temp.confirmPassword = fieldValues.confirmPassword
        ? ""
        : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const StyledInput = styled("input")({
    display: "none",
  });

  const maxSize = 2048;

  const StyledIconWrapper = styled(Box)(() => ({
    margin: "20px auto",
    border: "1px solid rgba(15, 15, 15, 0.15)",
    width: "80px",
    height: "80px",
    borderRadius: "10px",
    backgroundColor: "rgba(15, 15, 15, 0.05)",

    img: {
      marginTop: "30px",
    },
  }));

  const validateImageSize = (vehicleIcons) => {
    if (vehicleIcons) {
      const fsize = vehicleIcons.size / 1024;
      if (fsize > maxSize) {
        return false;
      } else {
        return true;
      }
    }
  };

  const profilePicturrHandler = async () =>{
    const formData = new FormData();
    formData.append("profile", imageApi);

    if(image !== "" && imageApi !== ""){
      const response = await ChangePpHandler(userAdmin.id,formData);
      if(response.status === true){
        setImageToDisplay(image);
        handleClosePpModel();
      }else{
        toast.error(response.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }else{
      toast.error("Please Upload profile Picture", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }


  }

  const StyledRemoveButton = styled(Box)({
    color: "red",
    display: "inline",
    "&:hover": {
      cursor: "pointer",
    },
  });

  async function handleImageItem(e) {
    if (validateImageSize(e.target.files[0])) {
      const selected = e.target.files[0];
      const ALLOWED_TYPES = ["image/png", "image/jpg", "image/jpeg"];
      if (selected && ALLOWED_TYPES.includes(selected.type)) {
        const base64String = await getBase64(selected);
        setImage(base64String);
        setImageApi(selected);
      } else {
      }
    } else {
      setImage("");
      setImageApi("");
      toast.error("Please Upload 5MB Image", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const StyledImageUploadWrapper = styled(Box)(() => ({
    marginTop: "16px",
    border: "1px solid rgba(15, 15, 15, 0.15)",
    height: "220px",
    borderRadius: "10px",
    textAlign: "center",
  }));

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const Id = useSelector((state) => state?.auth?.user?.userdata?.id);

  const open = Boolean(anchorEl);
  const openNoti = Boolean(anchorElNoti);
  const handleClickUser = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickNoti = (event) => {
    setAnchorElNoti(event.currentTarget);
    notificationListing();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseNoti = () => {
    setAnchorElNoti(null);
  };

  const removeImage = () => {
    setImage("");
  };

  const notificationListing = async () => {
    const res = await getNotificationLists();
    if (res.status === true) {
      setNotification(res.data);
    } else {
      setNotification([]);
    }
  };

  useEffect(() => {
    let isInitialized = false;

    if (userAdmin?.userdata?.id) {
      const initializeOneSignal = async () => {
        if (isInitialized) {
          return;
        }

        const OneSignal = window.OneSignal || [];
        OneSignal.push(() => {
          OneSignal.init({
            appId: "53a31726-969d-4ce0-9f4e-37c3b8fd2e00",
            safari_web_id:
              "web.onesignal.auto.37e682f0-44ba-408e-8045-bbc89f9e01bb",
            notifyButton: {
              enable: true,
            },
            subdomainName: "jyffit",
          }).then(() => {
            OneSignal.isPushNotificationsEnabled(function (isEnabled) {
              if (isEnabled) {
                OneSignal.getUserId(function (userId) {
                  setPlayerId(userId, userAdmin?.userdata?.id);
                });
              } else {
                console.log("Push notifications are not enabled yet.");
              }
            });
          });
        });
        isInitialized = true;
      };

      initializeOneSignal();
    }
  }, [userAdmin?.userdata?.id]);

  const setPlayerId = async (userId, id) => {};

  //password
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //new password
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);

  const handleMouseDownNewPassword = (event) => {
    event.preventDefault();
  };

  //Confirm password
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const body = {
      currentPassword: values.currentPassword,
      newPassword: values.newPassword,
      confirmPassword: values.confirmPassword,
      id: userAdmin.id,
    };
    if (validate()) {
      const res = await chnagePassword(body);
      if (res && res.status === true) {
        handleCloseDelete();
        resetForm();
        toast.success(res.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(res.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  const logoutAdmin = () => {
    dispatch(logoutApi());
    navigate("/adminlogin");
  };

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      px={2}
      py={0.7}
      sx={{
        backgroundColor: "#fff",
        boxShadow: "2",
        alignItems: "center",
        position: "sticky",
        top: "0",
        zIndex: "999",
      }}
    >
      {broken && !rtl && (
        <IconButton sx={{ margin: "0 6 0 2" }} onClick={() => toggleSidebar()}>
          <MenuOutlinedIcon
            sx={{
              color: "#000",
            }}
          />
        </IconButton>
      )}
      <Box
        display="flex"
        sx={{
          display: {
            xs: "none",
            md: "block",
            lg: "block",
          },
        }}
      ></Box>
      <Box display="flex">
        {/* <IconButton
          style={{
            color: colors.grey[500],
          }}
          id="basic-button-noti"
          aria-controls={open ? "notification" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickNoti}
        >
          <Badge color="secondary" variant="dot">
            <NotificationsOutlinedIcon
              style={{
                fontSize: "25px",
              }}
            />
          </Badge>
        </IconButton> */}

        <Box>
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClickUser}
          >
            <Box
              display="flex"
              sx={{ marginLeft: "10px", alignItems: "center" }}
            >
              <Box textAlign="right" sx={{ marginRight: "10px" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#000",
                    marginBottom: "0px",
                    fontWeight: "600",
                    textTransform: "capitalize",
                  }}
                >
                  {userAdmin?.name || userAdmin.firstName}   
                </Typography>
                {/* <Typography
                  color={colors.grey[600]}
                  fontWeight="500"
                  sx={{
                    fontSize: "12px",
                    marginBottom: "0px",
                    textTransform: "capitalize",
                  }}
                >
                  Admin
                </Typography> */}
              </Box>
              <Box>
                <img
                  className="avater-image"
                  alt="profile user"
                  width="40px"
                  height="40px"
                  src={imageToDisplay ? imageToDisplay : User}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
            </Box>
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{
              "& .MuiPaper-root": {
                backgroundColor: "#fff",
              },
              marginLeft: "5px",
            }}
          >
            <MenuItem
              sx={{ color: "#000", fontSize: "14px", fontWeight: "400" }}
              onClick={(e) => {
                resetForm();
                handleDeleteModel();
              }}
            >
              <KeyIcon
                sx={{
                  color: "rgb(107, 114, 128)",
                  fontSize: "25px",
                  marginRight: "7px",
                }}
              />
              Change Password
            </MenuItem>
            <MenuItem
              sx={{ color: "#000", fontSize: "14px", fontWeight: "400" }}
              onClick={(e) => {
                resetForm();
                handleppModel();
              }}
            >
              <PermIdentityIcon
                sx={{
                  color: "rgb(107, 114, 128)",
                  fontSize: "25px",
                  marginRight: "7px",
                }}
              />
              Change profile Picture
            </MenuItem>
            <MenuItem
              sx={{ color: "#000", fontSize: "14px", fontWeight: "400" }}
              onClick={() => logoutAdmin()}
            >
              <LogoutIcon
                sx={{
                  color: "rgb(107, 114, 128)",
                  fontSize: "25px",
                  marginRight: "7px",
                }}
              />
              Logout
            </MenuItem>
          </Menu>
          <Menu
            id="notification"
            anchorEl={anchorElNoti}
            open={openNoti}
            onClose={handleCloseNoti}
            MenuListProps={{
              "aria-labelledby": "basic-button-noti",
            }}
            sx={{
              "& .MuiPaper-root": {
                backgroundColor: "#fff",
              },
              height: "500px",
            }}
          >
            {notification?.map((row) => {
              return (
                <>
                  <MenuItem
                    onClick={handleClose}
                    sx={{
                      color: "#000",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <Avatar
                        aria-label="recipe"
                        sx={{
                          marginLeft: " 16px",
                          marginTop: "16px",
                        }}
                        src={row?.notificationIcon}
                      ></Avatar>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: "20px",
                          marginTop: "15px",
                        }}
                      >
                        <Box sx={{ flex: "1 0 auto" }}>
                          <Typography
                            component="div"
                            variant="h5"
                            sx={{ color: "black" }}
                          >
                            <Tooltip title={row?.title}>
                              <Button sx={{ padding: "2px" }}>
                                {row?.title?.length < 30
                                  ? row?.title
                                  : row?.title.slice(0, 30) + "..."}
                              </Button>
                            </Tooltip>
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            color="text.secondary"
                            component="div"
                            sx={{ color: "black" }}
                          >
                            <Tooltip title={row?.description}>
                              <Button sx={{ padding: "2px" }}>
                                {row?.description.slice(0, 21) + "..."}
                              </Button>
                            </Tooltip>
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            color="text.secondary"
                            component="div"
                            sx={{ color: "black" }}
                          >
                            {moment(row.createdAt).format("MM-DD-YYYY LT")}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </MenuItem>
                </>
              );
            })}
          </Menu>
        </Box>
      </Box>

      <Modal
        keepMounted
        open={openDeleteModel}
        onClose={handleCloseDelete}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={styles}>
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#000",
              marginBottom: "15px",
            }}
          >
            Change Password
          </Typography>
          <form onSubmit={handleSubmit}>
            <Box>
              <Box sx={{ marginTop: "30px" }}>
                <FormControl
                  sx={{ marginTop: "10px", width: "100%" }}
                  variant="outlined"
                >
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    sx={{ color: "#000", bgcolor: "#fff", lineHeight: "1" }}
                  >
                    Old Password
                  </InputLabel>
                  <OutlinedInput
                    sx={{
                      "& .MuiInputBase-input": {
                        padding: "12px 14px !important",
                      },

                      "& .MuiOutlinedInput-notchedOutline": {
                        paddingTop: "10px!important",
                        borderColor: "rgba(107, 114, 128, .5)",

                        borderRadius: "7px!important",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "blue",
                      },
                      paddingTop: "0px",
                      color: "#000",
                    }}
                    id="outlined-adornment-password"
                    name="currentPassword"
                    value={values.currentPassword}
                    onChange={handleInputChange}
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          sx={{ color: "#000" }}
                          aria-label="toggle password visib`ility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {Boolean(errors.currentPassword) ? (
                    <p
                      style={{
                        color: "rgb(255 0 0)",
                        marginTop: "4px",
                        marginBottom: "4px",
                        fontSize: "14px",
                      }}
                    >
                      {errors.currentPassword}
                    </p>
                  ) : (
                    ""
                  )}
                </FormControl>

                <FormControl
                  sx={{ marginTop: "10px", width: "100%" }}
                  variant="outlined"
                >
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    sx={{ color: "#000", bgcolor: "#fff", lineHeight: "1" }}
                  >
                    New Password
                  </InputLabel>
                  <OutlinedInput
                    sx={{
                      "& .MuiInputBase-input": {
                        padding: "12px 14px !important",
                      },

                      "& .MuiOutlinedInput-notchedOutline": {
                        paddingTop: "10px!important",
                        borderColor: "rgba(107, 114, 128, .5)",

                        borderRadius: "7px!important",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "blue",
                      },
                      paddingTop: "0px",
                      color: "#000",
                    }}
                    id="outlined-adornment-password"
                    name="newPassword"
                    value={values.newPassword}
                    onChange={handleInputChange}
                    type={showNewPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          sx={{ color: "#000" }}
                          aria-label="toggle password visibility"
                          onClick={handleClickShowNewPassword}
                          onMouseDown={handleMouseDownNewPassword}
                          edge="end"
                        >
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {Boolean(errors.newPassword) ? (
                    <p
                      style={{
                        color: "rgb(255 0 0)",
                        marginTop: "4px",
                        marginBottom: "4px",
                        fontSize: "14px",
                      }}
                    >
                      {errors.newPassword}
                    </p>
                  ) : (
                    ""
                  )}
                </FormControl>
                <FormControl
                  sx={{ marginTop: "10px", width: "100%" }}
                  variant="outlined"
                >
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    sx={{ color: "#000", bgcolor: "#fff", lineHeight: "1" }}
                  >
                    Confirm Password
                  </InputLabel>
                  <OutlinedInput
                    sx={{
                      "& .MuiInputBase-input": {
                        padding: "12px 14px !important",
                      },

                      "& .MuiOutlinedInput-notchedOutline": {
                        paddingTop: "10px!important",
                        borderColor: "rgba(107, 114, 128, .5)",

                        borderRadius: "7px!important",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "blue",
                      },
                      paddingTop: "0px",
                      color: "#000",
                    }}
                    id="outlined-adornment-password"
                    name="confirmPassword"
                    value={values.confirmPassword}
                    onChange={handleInputChange}
                    type={showConfirmPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          sx={{ color: "#000" }}
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {Boolean(errors.confirmPassword) ? (
                    <p
                      style={{
                        color: "rgb(255 0 0)",
                        marginTop: "4px",
                        marginBottom: "4px",
                        fontSize: "14px",
                      }}
                    >
                      {errors.confirmPassword}
                    </p>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Box>
              <Grid container style={{ marginTop: "2rem" }}>
                <Grid item xs={9} className="text-end">
                  <Controls.Button
                    sx={{
                      backgroundColor: "#fff",
                      ":hover": { color: "#fff" },
                      marginRight: { xs: "10px", sm: "0px" },
                      color: "#000",
                    }}
                    text="Cancel"
                    onClick={handleCloseDelete}
                  />
                </Grid>
                <Grid item xs={3} className="text-end">
                  <Controls.Button
                    sx={{ marginRight: "7px" }}
                    text="Save"
                    type="submit"
                  />
                </Grid>
              </Grid>
            </Box>
          </form>
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={openPpModel}
        onClose={handleClosePpModel}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={styles}>
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#000",
              marginBottom: "15px",
            }}
          >
            Change Profile Picture
          </Typography>
          <StyledImageUploadWrapper>
            <label htmlFor="image-upload">
              <StyledInput
                accept="image/*"
                id="image-upload"
                type="file"
                onChange={handleImageItem}
                onClick={(event) => {
                  event.currentTarget.value = null;
                }}
              />
              <StyledIconWrapper
                sx={
                  image == ""
                    ? {}
                    : {
                        backgroundImage: `url(${image})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        margin: "15px 0",
                      }
                }
              >
                {image === "" && (
                  <img
                    src={camera}
                    alt="Camera"
                    style={{ marginLeft: "27px" }}
                  />
                )}
              </StyledIconWrapper>
            </label>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "14px",
                color: "black",
              }}
            >
              {"Add Profile Image"}
            </Typography>
            <Typography
              sx={{
                color: "rgba(15, 15, 15, 0.5)",
                mx: 2,
                fontSize: "12px",
              }}
            >
              Allowed *.jpeg, *.jpg, *.png, max size of 5 MB
            </Typography>

            {image != "" && (
              <StyledRemoveButton
                onClick={(e) => {
                  e.preventDefault();
                  removeImage();
                }}
              >
                Remove
              </StyledRemoveButton>
            )}
          </StyledImageUploadWrapper>
          <Button variant="contained" onClick={()=>profilePicturrHandler()}> Submit </Button>
        </Box>
      </Modal>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        className="toaster"
      />
      <ToastContainer />
    </Box>
  );
};

export default Topbar;
