import { QUESTION_HANDLER } from "../action-types/authActionTypes";

const initialState = {
  answer: [],
};

export default function queAnsReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case QUESTION_HANDLER:
      return {
        ...state,
        answer:payload
      };
    default:
      return state;
  }
}
