import { QUESTION_HANDLER } from "@/state/action-types/authActionTypes";
import axiosRequest from "../../axios/axios";

//get questions List
export const getQuestionsList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`questionsList` + queryString);
    return response.data;
  } catch (error) {
    return error;
  }
};

//add questions
export const AddQuestions = async (payload) => {
  try {
    const response = await axiosRequest.post(`questionsAdd`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// 3rd Type questions
export const AddthirdQuestion = async (payload) => {
  try {
    const response = await axiosRequest.post(`addQuestion`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// 3rd Type Question Edit
export const updateThirdQuestion = async (id, payload) => {
  try {
    const response = await axiosRequest.put(`updateQuestion/${id}`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//questions Delete
export const DeleteQuestions = async (id) => {
  try {
    const response = await axiosRequest.delete(`questionsDelete/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Question create as publish

export const PublishQuestions = async (id) => {
  try {
    const response = await axiosRequest.put(`questionsDraft/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//questions Status Update

export const StatusUpdate = async (id) => {
  try {
    const response = await axiosRequest.put(`questionsStatusUpdate/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//questions update

export const updateQuestions = async (id, payload) => {
  try {
    const response = await axiosRequest.patch(`questionsUpdate/${id}`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getCourseDropdownList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`/getcourselist`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getSessioDropdownList = async (id) => {
  try {
    const response = await axiosRequest.get(`/getsession/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getQuestionDetails = async (id) => {
  try {
    const response = await axiosRequest.get(`/questionsDetails/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

// EDIT FINAL API
export const editApiHandler = async (id, payload) => {
  try {
    const response = await axiosRequest.patch(
      `/questionsUpdate/${id}`,
      payload
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteSlideByPptId = async (id) => {
  try {
    const response = await axiosRequest.delete(`/slideDelete/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateStartPptHandler = async (id, payload) => {
  try {
    const response = await axiosRequest.put(`/updateStartPpt/${id}`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

// To Delete start Ppt while Edit
export const DeleteStartPptHandler = async (id) => {
  try {
    const response = await axiosRequest.put(`/startPptDelete/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

// To Delete start Audio while Edit
export const DeleteAudioHandler = async (id) => {
  try {
    const response = await axiosRequest.put(`/audioFileDelete/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

// To Delete AudioFile By ShortTest
export const deleteAudiobyShortTest = async (id) => {
  try {
    const response = await axiosRequest.put(`/slideAudioFileDelete/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

// To Delete PptFile by ShortTest
export const deletePptByShortTest = async (id) => {
  try {
    const response = await axiosRequest.put(`/slidePptDelete/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

// To duplicate Session
export const duplicateSessionHandler = async (id) => {
  try {
    const response = await axiosRequest.get(`/SessionRevise/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

// TO HANDLE ANSWER
export const ansHandler = (data) => (dispatch) => {
  dispatch({ type: QUESTION_HANDLER, payload: data });
};
