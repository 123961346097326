import React, { useEffect, useState } from "react";
import { Avatar, Chip, MenuItem, useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../../theme";
import { InputBase } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Typography from "@mui/material/Typography";
import {
  Box,
  Divider,
  Grid,
  Tooltip,
  TextField,
  Pagination,
} from "@mui/material";
import { Drawer, FormLabel } from "@mui/material";
import { useProSidebar } from "react-pro-sidebar";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Button from "@mui/material/Button";
import Controls from "../../../components/controls/Controls";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { RiDeleteBinLine, RiEditLine } from "react-icons/ri";
import Modal from "@mui/material/Modal";
import { Space } from "antd";
import { useLocation, useNavigate } from "react-router";
import {
  DeleteStudent,
  StatusUpdate,
  getStudentList
} from "../../../state/actions/Admin/studentAction";
import { useForm } from "../../../utils/useForm";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Select from "@mui/material/Select";
import { useSnackbar } from "notistack";
import { ToastContainer, toast } from 'react-toastify';
import {
  getClassDropdownList,
} from "../../../state/actions/Admin/courseAction";
import { GrView } from "react-icons/gr";


const initialValues = {
  name: "",
  email: "",
  mobile: "",
};

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  marginTop: "20px",
  marginBottom: "5px",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#000",
    fontSize: 13,
    border: 0,
    fontWeight: 600,
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "25px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    color: "rgb(17, 24, 39)",
    border: 0,
    paddingLeft: "25px",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    backgroundColor: "#fff",
    ":hover": { backgroundColor: "rgb(248 248 248)!important" },
  },

  "&:nth-of-type(odd)": {
    backgroundColor: "rgb(250 250 250)!important",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: '90%', sm: '400px' },
  border: "0",
  outline: "0",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: 3,
};

const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const WhiteBorderTextField = styled(TextField)`
  & .MuiInputBase-input {
    color: black;
  }
  & .MuiTextField-root {
    padding-top: 20px;
  }
  & .MuiInputLabel-root.Mui-error {
    color: #fc9494;
  }
  & .MuiOutlinedInput-input {
    padding: 10px 14px !important;
    border-radius: 7px;
  }
  & .MuiInputLabel-root {
    line-height: 2.7;
    overflow: visible;
  }
  & .MuiInputLabel-root {
    color: rgb(92 91 91 / 70%) !important;
    font-weight: 500 !important;
    font-size: 14px;
    height: 20px;
  }
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: rgba(107, 114, 128, 0.5);
  }
  & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #000;
  }
  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    color: #4f46e5 !important;
    border-color: #4f46e5;
  }

  & .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #fc9494 !important;
  }
  & .MuiSvgIcon-root {
    color: black;
  }
`;

export default function BasicTabs() {
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [studentList, setStudentList] = useState([]);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(1);

  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [switchId, setSwitchId] = useState(null);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [status, setStatus] = useState();
  const [openSwitchModel, setOpenSwitchModel] = useState(false);
  const [search, setSearch] = useState("");
  const [fieldName, setFieldName] = useState("");
  const [sortFlag, setSortFlag] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [filter, setFilter] = useState(false);
  const [id, setId] = useState("");
  const [checked, setChecked] = useState(true);
  const [flagName, setflagName] = useState(false);

  const [lastName, setlastName] = useState(false);
  const [flagEmail, setflagEmail] = useState(false);
  const [flagPhone, setflagPhone] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { collapsed, broken } = useProSidebar();
  const [isEdit, setIsEdit] = useState(false);
  const [AddVehicle, setAddVehicle] = useState(false);
  const refreshScreen = () => {
    setRefresh(!refresh);
  };

  const { state } = useLocation();

  const RecordPerPage = [
    { id: "10", title: "10" },
    { id: "20", title: "20" },
  ];

  const handleSwitchModel = () => setOpenSwitchModel(true);
  const handleCloseSwitch = () => setOpenSwitchModel(false);
  const handleCloseDelete = () => setOpenDeleteModel(false);
  const handleDeleteModel = () => setOpenDeleteModel(true);

  const userLists = [
    { id: "1", title: "Active" },
    { id: "0", title: "Inactive" },
  ];

  const genderLists = [
    { id: "1", title: "Male" },
    { id: "2", title: "Female" },
  ];

  const [classDropdown, setClassDropdown] = useState([]);


  const ClassDropdownList = async () => {

    const res = await getClassDropdownList();
    if (res.status === true) {
      setClassDropdown(res.data);
    } else {
      setClassDropdown([]);
    }
  };
  useEffect(() => {
    ClassDropdownList();
  }, []);

  const handleChangePage = (e, value) => {
    if (fieldName == "firstName") {
      getstudentList(value, fieldName, flagName);
    } else if (fieldName == "lastName") {
      getstudentList(value, fieldName, lastName);
    }
    else {
      getstudentList(value, "", "");
    }
    setPage(value);
  };

  const handleChangeRow = (event) => {
    if (fieldName == "firstName") {
      getstudentList(page, fieldName, flagName, event);
    } else if (fieldName == "lastName") {
      getstudentList(page, fieldName, lastName, event);
    }
    else {
      getstudentList(page, "", "", event);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFilterData(!filterData);
  };
  const openVehicleModal = () => {
    setAddVehicle(true);
  };
  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const navigate = useNavigate();

  //User List
  useEffect(() => {
    getstudentList(page, "", "");
  }, [refresh, filterData, search]);

  const getstudentList
    = async (page, name = "", flag, limit) => {
      const body = {
        search: search,
      };
      setOpenBreakdrop(true);
      let string = "";
      let i = 0;
      for (let [key, value] of Object.entries(values)) {
        string += `&${key}=${value}`;
        i++;
      }
      let recordLimit = limit ? limit : pageLimit;
      let queryString = `?page=${page}&search=${search}${string}&limit=${recordLimit}&sortby=${name}&sortFlag=${flag}`;

      const res = await getStudentList(queryString, body);
      if (res.status === true) {
        setStudentList(res.data);
        setTotalRecords(res.total);
        setPageLimit(recordLimit);
        setTotalPages(Math.ceil(res.total / recordLimit));
        setSortFlag(!sortFlag);
        setOpenBreakdrop(false);
      } else {
        setStudentList([]);
        setOpenBreakdrop(false);
        setTotalRecords(0);
        setTotalPages(0);
      }
      setFilter(false);
    };

  //status Change
  const handleChangeStatus = async () => {
    setOpenBreakdrop(true);
    setOpenBreakdrop(!openBackdrop);

    const res = await StatusUpdate(switchId);

    if (res && res.status === true) {
      if (fieldName == "firstName") {
        getstudentList(page, fieldName, flagName);
      } else if (fieldName == "lastName") {
        getstudentList(page, fieldName, lastName);
      }
      else {
        getstudentList(page, "", "");
      }
      handleCloseSwitch();
      setOpenBreakdrop(false);
      toast.success(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      setOpenBreakdrop(!openBackdrop);
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  //delete
  const handleDelete = async () => {
    const res = await DeleteStudent(id);
    setOpenBreakdrop(true);
    if (res && res.status === true) {
      if (fieldName == "firstName") {
        getstudentList(page, fieldName, flagName);
      } else if (fieldName == "lastName") {
        getstudentList(page, fieldName, lastName);
      }
      else {
        getstudentList(page, "", "");
      }
      handleCloseDelete();
      toast.success(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      setOpenBreakdrop(!openBackdrop);

      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  //sorting
  const dataShorting = (key) => {
    if (key === "firstName") {
      setflagName(!flagName);
      getstudentList(page, key, !flagName);
    } else {
      setflagName(false);
      setSortFlag(false);
    }
    if (key === "lastName") {
      setlastName(!lastName);
      getstudentList(page, key, !lastName);
    } else {
      setlastName(false);
      setSortFlag(false);
    }
    setFieldName(key);

  };


  //isVerify document
  const IsVerify = (IsVerify) => {
    const statusColors = {
      0: {
        key: "No",
        backgroundColor: "#ede7dd",
        color: "#fc7e00",
      },
      1: {
        key: "Yes",
        backgroundColor: "green",
        color: "#ede7dd",
      },
    };

    return (
      <StyledChip
        style={{
          height: "30px",
          fontSize: "15px",
        }}
        sx={statusColors[IsVerify]}
        label={statusColors[IsVerify] ? statusColors[IsVerify].key : ""}
      />
    );
  };

  //pro status
  const proStatus = (proStatus) => {
    const statusColors = {
      1: {
        key: "Yes",
        color: "green",
      },
      0: {
        key: "No",
        color: "red",
      },
    };

    return (
      <StyledChip
        style={{
          height: "30px",
          fontSize: "15px",
        }}
        sx={statusColors[proStatus]}
        label={statusColors[proStatus] ? statusColors[proStatus].key : ""}
      />
    );
  };

  return (
    <>
      <Box sx={{ backgroundColor: "rgb(241, 245, 249)" }}>
        <Box sx={{ padding: { xs: '0 10px', md: '0 15px' }, backgroundColor: "rgb(241, 245, 249)" }}>
          <Grid
            container
            sx={{
              padding: { xs: '20px 0', md: '40px 0' },
              alignItems: "center",
            }}
          >
            <Grid item sm={4} xs={12}>
              <Typography variant="h2" sx={{ fontWeight: "bold", fontSize: { xs: '25px', sm: '32px' } }}>
                Student   List
              </Typography>
            </Grid>
            <Grid item sm={8} xs={12}>
              <Box
                sx={{
                  display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
                  marginTop: { xs: "10px", sm: "0", md: "0", lg: "0" },
                }}
                justifyContent="flex-end"
              >
                <Box
                  border={1}
                  borderColor="rgba(0, 0, 0, .1)"
                  alignItems="center"
                  display="flex"
                  backgroundColor="#ffffffa3"
                  p={0.4}
                  borderRadius={10}
                >
                  <SearchIcon
                    style={{
                      color: colors.grey[300],
                    }}
                    sx={{ ml: 1, fontSize: "24px" }}
                  />
                  <InputBase
                    name="search"
                    sx={{ ml: 1, flex: 1, color: "#000", fontSize: "16px" }}
                    placeholder="Search"
                    onChange={(e) => {
                      setTimeout(() => {
                        setSearch(e.target.value);
                      }, 800);
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  sx={{ marginTop: { xs: "10px", sm: "0", md: "0", lg: "0" } }}
                >

                  <Controls.Button
                    startIcon={<AddIcon />}
                    text="Add Student"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      borderRadius: "50px",
                      border: "0",
                      color: "#fff",
                      ":hover": { bgcolor: "rgb(79, 70, 229)", border: "0" },
                      backgroundColor: "rgb(79, 70, 229)",
                      marginLeft: {
                        xs: "0px",
                        sm: "15px",
                        md: "15px",
                        lg: "15px",
                      },
                      textTransform: "none",
                    }}
                    onClick={() => {
                      navigate("/addStudent")
                    }}
                    variant="outlined"
                  />

                  <Button
                    variant="outlined"
                    startIcon={<FilterAltIcon />}
                    onClick={openFilterDrawer}
                    sx={{
                      marginLeft: "15px",
                      borderRadius: "50px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                      color: "#fff",
                      ":hover": { bgcolor: "rgb(30, 41, 59)" },
                      background: "rgb(30, 41, 59)",
                      fontSize: "16px",
                      fontWeight: "500",
                      textTransform: "none",
                    }}
                  >
                    Filter
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            width: "98%",
            backgroundColor: "rgb(241, 245, 249)",
            margin: "auto",
          }}
        >
          <Box
            sx={{
              border: 1,
              borderColor: "#eaeaea",
              maxHeight: "485px",
              overflow: "scroll",
            }}
          >
            <TableContainer component={Paper}>
              <Table
                aria-label="customized table"
                sx={{ width: { md: "100%", lg: "100%", xl: "100%" } }}
              >
                <TableHead>
                  <TableRow hover>
                    <StyledTableCell>Profile</StyledTableCell>
                    <StyledTableCell onClick={() => dataShorting("firstName")}>
                      First Name
                      {flagName ? (
                        <ArrowUpwardIcon
                          sx={{
                            color: "rgba(107, 114, 128, .5)",
                            fontSize: "17px",
                            marginLeft: "3px",
                          }}
                        />
                      ) : (
                        <ArrowDownwardIcon
                          sx={{
                            color: "rgba(107, 114, 128, .5)",
                            fontSize: "17px",
                            marginLeft: "3px",
                          }}
                        />
                      )}
                    </StyledTableCell>
                    <StyledTableCell onClick={() => dataShorting("lastName")}>
                      Last Name
                      {lastName ? (
                        <ArrowUpwardIcon
                          sx={{
                            color: "rgba(107, 114, 128, .5)",
                            fontSize: "17px",
                            marginLeft: "3px",
                          }}
                        />
                      ) : (
                        <ArrowDownwardIcon
                          sx={{
                            color: "rgba(107, 114, 128, .5)",
                            fontSize: "17px",
                            marginLeft: "3px",
                          }}
                        />
                      )}
                    </StyledTableCell>
                    <StyledTableCell>Email</StyledTableCell>
                    <StyledTableCell>Class</StyledTableCell>
                    <StyledTableCell>
                      <Button
                        variant="text"
                        sx={{
                          ":hover": { bgcolor: "transparent" },
                          paddingLeft: "0",
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "capitalize",
                          justifyContent: "left",
                        }}
                      >
                        Status
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell>Action</StyledTableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {studentList?.map((row) => {
                    return (
                      <StyledTableRow
                        hover
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate("/detailsStudent", {
                            state: {
                              user: row?.id,

                            },
                          });
                        }}
                      >
                        <StyledTableCell>
                          <Avatar
                            component="span"
                            variant="rounded"
                            alt="profile_image"
                            src={row?.profile}
                          />
                        </StyledTableCell>
                        <StyledTableCell>{row.firstName || "-"}</StyledTableCell>
                        <StyledTableCell>{row.lastName || "-"}</StyledTableCell>
                        <StyledTableCell>{row.email || "-"}</StyledTableCell>
                        <StyledTableCell>
                          {row.className || "-"}
                        </StyledTableCell>
                        <StyledTableCell onClick={(e) => e.stopPropagation()}>
                          <Controls.Switch
                            checked={row.isActive == 1 ? true : false}
                            onChange={(e) => {
                              handleSwitchModel();
                              setStatus(
                                e.target.checked == true ? "Active" : "InActive"
                              );
                              setSwitchId(row.id);
                              setChecked(e.target.checked);
                            }}
                            name="checkedB"
                            color="primary"
                            sx={{ marginLeft: "40px", px: 0 }}
                          />
                        </StyledTableCell>

                        <StyledTableCell
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <Space size="middle">
                          <button
                            className="action-button view"
                            onClick={() => {
                              navigate("/detailsStudent", {
                                state: {
                                  user: row?.id,
                                },
                              });
                            }}
                          >
                            <GrView />
                          </button>
                            <Tooltip
                              placement="bottom"
                              className="cursor-pointer"
                              title="Edit Student"
                            >
                              <button
                                className="action-button edit "
                                onClick={() => {
                                  navigate("/addStudent", {
                                    state: {
                                      user: row,
                                      isEdit: true
                                    },
                                  });
                                  setIsEdit(true);
                                }}
                              >
                                <RiEditLine />
                              </button>
                            </Tooltip>

                            <Tooltip placement="bottom" title="Delete User">
                              <button
                                className="action-button delete-btn"
                                onClick={() => {
                                  setId(row.id);
                                  handleDeleteModel();
                                }}
                              >
                                <RiDeleteBinLine />
                              </button>
                            </Tooltip>
                          </Space>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {studentList && studentList.length === 0 ? (
                <div
                  className="noDataFound"
                  style={{
                    display: "flex",
                  }}
                >
                  {" "}
                  No Data Found
                </div>
              ) : null}
            </TableContainer>
          </Box>
          <Box
            sx={{
              display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
              marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
              marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
              mt: 2,
              mb: 2,
            }}
          >
            <Box
              sx={{
                fontSize: "12px",
                marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
                gap: 2,
              }}
              display="flex"
              alignItems="center"
            >
              <Box>
                {" "} 
                Showing {" "}
                {studentList && studentList.length}  {" "}
                {/* {page * pageLimit > totalRecords
                  ? totalRecords
                  : page * pageLimit}{" "} */}
                of {totalRecords} Results
              </Box>
              <Box display="flex" sx={{ gap: 1 }} alignItems="center">
                <Typography fontSize={'12px'}>Records per page</Typography>
                <Select
                  className="recordLimit"
                  sx={{
                    color: "#000",
                    height: "30px",
                    fontSize: "12px",
                    borderRadius: "5px",
                    border: 1,
                    borderColor: "#000",
                    ".MuiSvgIcon-root ": {
                      fill: "black !important",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                  }}
                  id="limites"
                  name="limites"
                  value={pageLimit}
                  onChange={(e) => {
                    handleChangeRow(e.target.value);
                  }}
                >
                  {RecordPerPage.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name || item.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
            </Box>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              variant="outlined"
              shape="rounded"
              sx={{
                "& .MuiButtonBase-root ": {
                  color: "#000 !important",
                },
                "& .Mui-selected ": {
                  background: "#4b4b4e70 !important",
                },
                "& .Mui-disabled ": {
                  background: "#6c696966 !important",
                  color: "#000000 !important",
                  opacity: 1,
                },
              }}
            />
          </Box>
        </Box>
      </Box>

      <Modal
        keepMounted
        open={openSwitchModel}
        onClose={handleCloseSwitch}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={styles}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#000",
              marginBottom: "15px",
            }}
          >
            Status Change
          </Typography>
          <label className="fontLight">
            Are you sure change status to {status} ?
          </label>
          <Grid container style={{ marginTop: "2rem" }}>
            <Grid item className="text-end">
              <Controls.Button
                style={{
                  backgroundColor: "#fff",
                  color: "#000",
                  marginRight: '10px'
                }}
                text="Cancel"
                onClick={handleCloseSwitch}
              />
            </Grid>
            <Grid item className="text-end">
              <Controls.Button
                style={{ marginRight: "7px" }}
                text="Change"
                onClick={() => handleChangeStatus(switchId)}
                type="submit"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={openDeleteModel}
        onClose={handleCloseDelete}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={styles}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#000",
              marginBottom: "15px",
            }}
          >
            Delete Record
          </Typography>
          <label className="fontLight">
            Are you sure to want to delete this record?
          </label>
          <Grid container style={{ marginTop: "2rem" }}>
            <Grid item className="text-end">
              <Controls.Button
                style={{
                  backgroundColor: "#fff",
                  color: "#000",
                  marginRight: "10px",
                }}
                text="Cancel"
                onClick={handleCloseDelete}
              />
            </Grid>
            <Grid item className="text-end">
              <Controls.Button
                style={{ marginRight: "7px" }}
                text="Delete"
                onClick={() => handleDelete(deleteId)}
                type="submit"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
        <form
          onSubmit={handleSubmit}
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            backgroundColor: "#fff",
          }}
        >
          <Box
            sx={{
              Width: { xs: "100%", sm: "300px", md: "300px", lg: "300px" },
              padding: "10px 20px",
            }}
          >
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                sx={{ fontWeight: "bold", color: "#000", fontSize: "30px" }}
              >
                Filter
              </Typography>

              <AiOutlineCloseCircle
                className="close-button"
                onClick={closeFilterDrawer1}
              />
            </Stack>

            <Box
              sx={{
                marginTop: "5px",
              }}
            >
              <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
              <StyledFormLabel htmlFor="name">First Name</StyledFormLabel>
              <WhiteBorderTextField
                className="input"
                fullWidth
                id="firstName"
                name="firstName"
                value={values.firstName}
                onChange={handleInputChange}
                sx={{ mt: 0 }}
              />

              <StyledFormLabel htmlFor="name">Last Name</StyledFormLabel>
              <WhiteBorderTextField
                className="input"
                fullWidth
                id="lastName"
                name="lastName"
                value={values.lastName}
                onChange={handleInputChange}
                sx={{ mt: 0 }}
              />
              <StyledFormLabel htmlFor="name">Email</StyledFormLabel>
              <WhiteBorderTextField
                className="input"
                fullWidth
                id="email"
                name="email"
                value={values.email}
                onChange={handleInputChange}
                sx={{ mt: 0 }}
              />
              <StyledFormLabel htmlFor="name">Class </StyledFormLabel>
              <Controls.SelectBox
                className="input select_box"
                fullWidth
                id="classID"
                name="classID"
                value={values.classID}
                onChange={handleInputChange}
                options={classDropdown}
              />
            </Box>

            <Box>
              <StyledFormLabel htmlFor="hsnCode">Status</StyledFormLabel>
              <Controls.SelectBox
                className="input select_box"
                fullWidth
                id="isActive"
                name="isActive"
                value={values.isActive}
                onChange={handleInputChange}
                options={userLists}
              />
            </Box>
          </Box>
          <Stack sx={{ margin: "5px 20px 20px 20px" }} flexDirection="row">
            <button className="cancel-filter" onClick={closeFilterDrawer}>
              Reset
            </button>
            <button
              className="order-filter-button"
              type="submit"
              style={{
                cursor: "pointer",
              }}
            >
              Filter
            </button>
          </Stack>
        </form>
      </Drawer>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </>
  );
}
