import Dashboard from "../../pages/Admin/Dashboard/Dashboard";
import Student from "../../pages/Admin/Student/Student";
import Teacher from "../../pages/Admin/Teacher/Teacher";
import Course from "../../pages/Admin/Course/Course";
import { Routes, Route } from "react-router-dom";
import DetailsStudent from "../../pages/Admin/Student/DetailsStudent";
import TeacherReqDetails from "../../pages/Admin/Teacher/TeacherReqDetails";
import Class from "../../pages/Admin/Class/Class";
import Session from "../../pages/Admin/Session/Session";
import Question from "../../pages/Admin/Question/Question";
import Questionadd from "../../pages/Admin/Question/Questionadd";
import AdminLogin from "../../pages/Admin/Login/Login";
import AddTeacher from "@/pages/Admin/Teacher/AddTeacher";
import TeacherDetail from "@/pages/Admin/Teacher/TeacherDetails";
import AddStudent from "@/pages/Admin/Student/StudentAdd";
import Result from "@/pages/Admin/Result/Result";
import QuestionDetails from "@/pages/Admin/Question/QuestionDetails";
import DynamicAddQuestion from "@/pages/Admin/Question/DynamicAddQuestion";
import SlideManagement from "@/pages/Admin/Question/SlideManagement";
import SlideDetails from "@/pages/Admin/Question/SlideDetails";
import SlidePhase from "@/pages/Admin/Question/SlideManagement/SlidePhase";
import SlidePhaseEdit from "@/pages/Admin/Question/SlideManagement/SlidePhaseEdit";
import DraftQuestion from "@/pages/Admin/Question/DraftQuestion";

function Main() {
  return (
    <div>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/teacher" element={<Teacher />} />
        <Route path="/teacherDocument" element={<teacherDocument />} />
        <Route path="/teacherReqDetails" element={<TeacherReqDetails />} />
        <Route path="/student" element={<Student />} />
        <Route path="/detailsStudent" element={<DetailsStudent />} />
        <Route path="/course" element={<Course />} />
        <Route path="/AddTeacher" element={<AddTeacher />} />
        <Route path="/addStudent" element={<AddStudent />} />
        <Route path="/teacherdetail" element={<TeacherDetail />} />
        <Route path="/class" element={<Class />} />
        <Route path="/session" element={<Session />} />
        <Route path="/draft-question" element={<DraftQuestion />} />
        <Route path="/question" element={<Question />} />
        <Route path="/addquestion" element={<Questionadd />} />
        <Route path="/addQuestions" element={<DynamicAddQuestion />} />
        <Route path="/questionView" element={<SlidePhaseEdit />} />
        <Route path="/addMultiQuestion" element={<SlidePhase />} />
        <Route path="/adminlogin" element={<AdminLogin />} />
        <Route path="/results" element={<Result />} />
        <Route path="/questiondetails" element={<QuestionDetails />} />
        <Route path="/slideDetails" element={<SlideDetails />} />
      </Routes>
    </div>
  );
}

export default Main;
