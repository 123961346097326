import React from "react";
import { useState, useEffect, useCallback } from "react";
import update from "immutability-helper";
import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  tableCellClasses,
  Tooltip,
  Grid,
} from "@mui/material";
import { Space } from "antd";
import { RiDeleteBinLine } from "react-icons/ri";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { v4 as uuidv4 } from "uuid";
import AddIcon from "@mui/icons-material/Add";
import Controls from "../../../../components/controls/Controls";
import { useForm, Form } from "../../../../utils/useForm";
import { useDrop } from "react-dnd";
import { snapToGrid as doSnapToGrid } from "../../../../components/CustomDragLayout/snapToGrid";
import { TextBox } from "./TextBox";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#000",
    fontSize: 13,
    border: 0,
    fontWeight: 600,
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "25px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    color: "rgb(17, 24, 39)",
    border: 0,
    paddingLeft: "25px",

    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    backgroundColor: "#fff",
    ":hover": { backgroundColor: "rgb(248 248 248)!important" },

    "&:nth-of-type(odd)": {
      backgroundColor: "rgb(250 250 250)!important",
    },
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const initialValues = {
  classID: "",
  sessionID: "",
  courseID: "",
  questionType: "",
  marks: "",
};

function ImageQuestion(props) {
  const uniqueId = uuidv4();
  const [rowsTermsData, setRowsTermsData] = useState([]);
  const [image, setImage] = useState("");

  useEffect(() => {
    if (props.OutData) {
      editDataHandler();
    };
  }, [props.OutData]);

  const editDataHandler = () => {
    if (props.edit) {
      const editData = props?.OutData?.map((res) => ({
        Uid: res.Uid,
        correctAnswer: res.correctAnswer,
        beid: res.id,
        Image: res.image,
        imageId: res.imageId,
        left: res.left,
        optionType: res.optionType,
        pptId: res.pptID,
        questionId: res.questionID,
        questionText: res.questionText,
        questionType: res.questionType,
        studentAns: res.studentAns,
        top: res.top,
      }));
      setBoxes(editData);
    } else {
      const newEditData = props?.OutData?.map((res) => ({
        correctAnswer: res.correctAnswer,
        studentAns: res.studentAns,
        Uid: res.Uid,
        top: res.top,
        left: res.left,
      }));
      setBoxes(newEditData);
    }
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("classID" in fieldValues) {
      temp.classID = fieldValues.classID ? "" : "This field is required.";
    }
    if ("sessionID" in fieldValues) {
      temp.sessionID = fieldValues.sessionID ? "" : "This field is required.";
    }

    if ("courseID" in fieldValues) {
      temp.courseID = fieldValues.courseID ? "" : "This field is required.";
    }

    if ("questionType" in fieldValues) {
      temp.questionType = fieldValues.questionType
        ? ""
        : "This field is required.";
    }
    if ("marks" in fieldValues) {
      temp.marks = fieldValues.marks ? "" : "This field is required.";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  function snapToGrid(x, y) {
    const snappedX = Math.round(x / 32) * 32;
    const snappedY = Math.round(y / 32) * 32;
    return [snappedX, snappedY];
  }

  const [boxes, setBoxes] = useState([]);

  const moveBox = useCallback(
    (id, left, top) => {
      setBoxes(
        update(boxes, {
          [id]: {
            $merge: { left, top },
          },
        })
      );

    },
    [boxes]
  );


  const ItemTypes = {
    BOX: "box",
  };


  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.BOX,
      drop(item, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset();
        let left = Math.round(parseInt(item.left) + delta.x);
        let top = Math.round(parseInt(item.top || "20") + delta.y);
        if (snapToGrid) {
          [left, top] = doSnapToGrid(left, top);
        }
        moveBox(item.id, left, top, item.correctAnswer);
        return undefined;
      },
    }),
    [moveBox]
  );

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const addTableRowsTerms = () => {
    if (boxes.length <= 9) {
      const rowsInput = {
        correctAnswer: "",
        studentAns: "",
        Uid: uniqueId,
        top: 20,
        left: 20,
      };
      setBoxes([...boxes, rowsInput]);
      props.data([...boxes, rowsInput]);
      props.indexHandler(props.slideIndex, [...boxes, rowsInput], props.option);
    }
  };

  const handleChangesTerms = (index, e) => {
    const { name, value } = e.target;
    const rowsInput = [...boxes];
    rowsInput[index][name] = value;
    props.indexHandler(props.slideIndex, rowsInput, props.option);
    setBoxes(rowsInput);
  };

  const deleteTableRowsTerms = (index) => {
    const rows = [...rowsTermsData];
    rows.splice(index);
    props.indexHandler(props.slideIndex, rows, props.option);
  };

  const DragDataHandler = (data) => {
    setBoxes(data);
    props.data(data);
    props.indexHandler(props.slideIndex, data, props.option);
  };
  useEffect(() => {
    props.data(boxes);
    props.indexHandler(props.slideIndex, boxes, props.option);
  }, [boxes, props]);

  useEffect(() => {
    setImage(props.Image);
  }, [props])

  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", position: 'relative', padding: '50px 0', background: '#fff' }}
      ref={drop}
    >
      <Grid container justifyContent={"center"}>
        <Grid item>
          <div className="overflow-hidden">
          {
            image.length > 0 ?  
            <img
              src={image}
              className="image_question"
            /> : ""
          }
          </div>
        </Grid>
        <Grid item>
          <Table className="table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Correct Answer</StyledTableCell>
                <StyledTableCell sx={{ width: "10px" }}>
                  {
                    !props.View &&
                    <Button
                      variant="contained"
                      size="large"
                      style={{
                        backgroundColor: "blue",
                        borderRadius: "10px",
                        width: "0%",
                      }}
                      startIcon={<AddIcon sx={{ marginLeft: "9px" }} />}
                      fullWidth
                      onClick={addTableRowsTerms}
                    />
                  }
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowsTermsData?.map((data, index) => {
                const { questionText, correctAnswer } = data;
                return (
                  <StyledTableRow key={index} sx={{ cursor: "pointer" }}>
                    <StyledTableCell component="th" scope="row">
                      <Controls.Input
                        className='image_input'
                        text="Add New"
                        variant="outlined"
                        color="primary"
                        label="correct Answer"
                        value={correctAnswer}
                        onChange={(e) => {
                          handleChangesTerms(index, e);
                          handleInputChange(e);
                        }}
                        name="correctAnswer"
                        inputProps={{
                          maxLength: 255,
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Space size="middle">
                        <Tooltip placement="bottom" title="Delete ">
                          <button
                            className="action-button delete-btn"
                            onClick={() => deleteTableRowsTerms(index)}
                            style={{ marginLeft: "15px" }}
                          >
                            <RiDeleteBinLine />
                          </button>
                        </Tooltip>
                      </Space>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
          {Object.keys(boxes).map((res, index) => {
            return (
              <TextBox
                key={`res_${index}`}
                id={res}
                data={boxes}
                view={props.View}
                dataHandler={DragDataHandler}
                {...boxes[res]}
              />
            );
          })}
        </Grid>
      </Grid>
    </Box>
  );
}

export default ImageQuestion;
