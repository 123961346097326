import React, { useState, useEffect } from "react";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import "./uploadAudio.css";
import Slider from "@mui/material/Slider";
import { Stack } from "@mui/material";

function UploadAudio({ audioFile, keyName }) {
  const [buttonName, setButtonName] = useState("Play");
  const [audioElement, setAudioElement] = useState(null);
  const [audioLoaded, setAudioLoaded] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    if (audioElement) {
      audioElement.pause();
      audioElement.currentTime = 0;
      setButtonName("Play");
    }
    if (audioFile) {
      const newAudioElement = new Audio(audioFile);
      newAudioElement.onloadedmetadata = () => {
        setAudioElement(newAudioElement);
        setAudioLoaded(true);
        setDuration(newAudioElement.duration);
      };
      newAudioElement.ontimeupdate = () => {
        setCurrentTime(newAudioElement.currentTime);
      };
      newAudioElement.onended = () => {
        setButtonName("Play");
      };

      // Cleanup function for this useEffect
      return () => {
        newAudioElement.pause();
        newAudioElement.currentTime = 0;
        setAudioElement(null);
      };
    }
  }, [audioFile]);

  useEffect(() => {
    // Add event listener for beforeunload
    const beforeUnloadHandler = (e) => {
      if (audioElement) {
        audioElement.pause();
        audioElement.currentTime = 0;
        setAudioElement(null);
      }
    };

    window.addEventListener("beforeunload", beforeUnloadHandler);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    };
  }, [audioElement]);

  const handleClick = () => {
    if (buttonName === "Play") {
      if (audioLoaded && audioElement) {
        audioElement.play();
        setButtonName("Pause");
      }
    } else {
      if (audioElement) {
        audioElement.pause();
        setButtonName("Play");
      }
    }
  };

  const handleSliderChange = (event, newValue) => {
    if (audioElement) {
      audioElement.currentTime = newValue;
      setCurrentTime(newValue);
    }
  };

  return (
    <div key={keyName} className="audio-info">
      <Stack flexDirection={'row'} gap={'20px'} padding={'0 10px 0 5px'}>
        <button onClick={handleClick}>
          {buttonName === "Play" ? <PlayArrowIcon /> : <PauseIcon />}
        </button>

        {audioLoaded && (
          <Slider
            key={`${keyName}`}
            value={currentTime}
            min={0}
            max={duration}
            step={0.1}
            onChange={handleSliderChange}
          />
        )}
      </Stack>
    </div>
  );
}

export default UploadAudio;
