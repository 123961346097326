import axiosRequest from "../../axios/axios";

//get questions List
export const getResultList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`resultList` + queryString);
    return response.data;
  } catch (error) {
    return error;
  }
};

//questions Delete
export const DeleteQuestions = async (id) => {
    try {
      const response = await axiosRequest.delete(`questionsDelete/${id}`);
      return response.data;
    } catch (error) {
      return error;
    }
  };
  
  //questions Status Update
  
export const StatusUpdate = async (id) => {
    try {
      const response = await axiosRequest.put(`questionsStatusUpdate/${id}`);
      return response.data;
    } catch (error) {
      return error;
    }
};

export const RighWrongAnsHandler = async (id) =>{
  try {
    const response = await axiosRequest.get(`getStudentFinalResultlist/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
}

export const CreateResult = async (payload) => {
  try {
    const response = await axiosRequest.post(`createFinalResult`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
}


export const FinalResultHandler = async (id) =>{
  try {
    const response = await axiosRequest.get(`getFinalResultMarks/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
}