import { COURSE_DATA } from "../action-types/commanAction";
import axiosRequest from "../axios/axios";

//get Course List
export const getStudentCourse = async (studentId) => {
  try {
    const response = await axiosRequest.get(`/getStudentCourse/${studentId}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

// get Session List by classId and courseId 

export const getSessionListByClassIdAndCourseId = async(classId, courseId) =>{
  try {
    const response = await axiosRequest.get(`/getStudentSession/${courseId}/${classId}`);
    return response.data;
  } catch (error) {
    return error;
  }
};


export const courseDataHandler = (data) => (dispatch) => {
  dispatch({ type: COURSE_DATA, payload: data })
}