import { Box } from "@mui/system";
import React from "react";
import ResultList from "./ResultList";

const Result = () => {
    return (
        <Box>
            <ResultList />
        </Box>
    );
};

export default Result;