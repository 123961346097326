import axiosRequest from "../../axios/axios";

//get setting List
export const getClassLists = async (queryString) => {
  try {
    const response = await axiosRequest.get(`classList` + queryString);
    return response.data;
  } catch (error) {
    return error;
  }
};

//add setting

export const addClass = async (payload) => {
  try {
    const response = await axiosRequest.post(`ClassAdd`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//setting Delete
export const DeleteClass = async (id) => {
  try {
    const response = await axiosRequest.delete(`classDelete/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//setting Status Update

export const StatusUpdate = async (id) => {
  try {
    const response = await axiosRequest.put(`classStatusUpdate/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//setting update

export const updateClass = async (id, payload) => {
  try {
    const response = await axiosRequest.patch(`classUpdate/${id}`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//get notification list
//get setting List
export const getNotificationLists = async () => {
  try {
    const response = await axiosRequest.get(`pushNotificationList`);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Change Profile Picture 
export const ChangePpHandler = async (id, payload) => {
  try {
    const response = await axiosRequest.patch(`profileUpdate/${id}`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
}

//classDropdownWihtoutCourse
export const getClassDropdownList = async () => {
  try {
    const response = await axiosRequest.get(`classDropdownWihtoutCourse`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//classcourseDropdown
export const getClassCourseDropdownList = async () => {
  try {
    const response = await axiosRequest.get(`classcourseDropdown`);
    return response.data;
  } catch (error) {
    return error;
  }
};


//clone class
export const cloneClass = async (data) => {
  try {
    const response = await axiosRequest.post(`cloneClass`, data);
    return response.data;
  } catch (error) {
    return error;
  }
}
