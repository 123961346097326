import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { DragDropContainer, DropTarget } from "react-drag-drop-container";
import {
  getQuestionsData,
  getQuestionsDataFinalTest,
  pptCountHandler,
} from "../../state/actions/questionActions";
import { ansHandler } from "@/state/actions/Admin/questionAction";
import { ToastContainer, toast } from "react-toastify";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import ThirdQue from "./ThirdQue";
import { Typography } from "@mui/material";
import { submitTest } from "../../state/actions/examActions";
import { CreateResult } from "../../state/actions/Admin/resultAction";
import MultiBackend, {
  TouchTransition,
  MouseTransition,
} from "react-dnd-multi-backend";
import Modal from "@mui/material/Modal";
import { Grid, Box } from "@mui/material";
import PptView from "../Ppt/PptView";
import Logo from "../../assets/Images/Logo.png";

const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "0",
  outline: "0",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: 3,
  paddingBottom: 5,
};

const CustomHTML5toTouch = {
  backends: [
    {
      backend: HTML5Backend,
      transition: MouseTransition,
    },
    {
      backend: TouchBackend,
      options: { enableMouseEvents: true },
      transition: TouchTransition,
      skipDispatchOnTransition: true,
    },
  ],
};
function Exam() {
  const LinesOfline = Array(20).fill("Line");
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [questionData, setQuestionData] = useState([]);
  const [sequence, setSequence] = useState(0);
  const [sequenceData, setSequenceData] = useState([]);
  const [question1Html, setQuestion1Html] = useState([]);
  const [thirdQuestion, setThirdQuestion] = useState([]);
  const [currentSection, setCurrentSection] = useState("1_0");
  const [nextButton, setNextButton] = useState(true);
  const [visible, setVisible] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [secondAnswer, setSecondAnswer] = useState("");
  const [reduxDataBefore, setReduxBefore] = useState("");
  const [classId, setClassId] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [courseId, setCourseId] = useState("");
  const [studentId, setStudentId] = useState();
  const [attemptType, setAttemptType] = useState("1");
  const [answerData, setAnswerData] = useState([]);
  const [openModel, setOpenModel] = useState(false);
  const [isFinalTest, setIsFinalTest] = useState(false);
  const [courseName, setCourseName] = useState("");
  const [sessionName, setSessionName] = useState("");
  const [sequenceNo, setSequenceNo] = useState(0);
  const [startPptFlag, setStartPptFlag] = useState(false);
  const [startPpt, setStartPpt] = useState("");
  const [startAudio, setStartAudio] = useState("");
  const [startAudioFlag, setAudioFlag] = useState(false);
  const [dataNotFound, setDataNotFound] = useState([]);

  const getQuestions = async (data) => {
    let payload = {};
    if (data) {
      payload = {
        classID: data?.classId || localStorage.getItem("classId"),
        flag: state.sameQuestion ? state.sameQuestion : false,
        courseID: data?.courseId || localStorage.getItem("courseId"),
        sessionID: data?.sessionId || localStorage.getItem("sessionId"),
      };
    } else {
      payload = {
        classID: classId || localStorage.getItem("classId"),
        flag: state.sameQuestion ? state.sameQuestion : false,
        courseID: courseId || localStorage.getItem("courseId"),
        sessionID: sessionId || localStorage.getItem("sessionId"),
      };
    }
    const stId =
      state.studentId || studentId || localStorage.getItem("studentId");
    const res = await getQuestionsData(stId, payload);
    if (res && res.status === true) {
      localStorage.setItem("pptQuestionId", res.data.id);
      setQuestionData(res?.data);
      setSequenceData(res?.data?.pptdata);
      setAttemptType(
        res &&
        res?.data &&
        res?.data?.pptdata &&
        res?.data?.pptdata[0] &&
        res?.data?.pptdata[0].attampt
      );
      setSequence(
        res &&
        res?.data &&
        res?.data?.pptdata &&
        res?.data?.pptdata[0] &&
        res?.data?.pptdata[0].sequenceId
      );
      setSequenceNo(
        res &&
        res?.data &&
        res?.data?.pptdata &&
        res?.data?.pptdata[0] &&
        res?.data?.pptdata[0].Sequence
      );
      let flag = localStorage.getItem("pptCondition");
      let audioFlag = localStorage.getItem("audioCondition");

      if (audioFlag == "true") {
        if (res.data.audioFile) {
          setAudioFlag(true);
          setStartAudio(res.data.audioFile);
          localStorage.setItem("audioCondition", "false");
        } else {
          setAudioFlag(false);
        }
      }

      if (flag == "true") {
        if (res.data.startPpt) {
          setStartPptFlag(true);
          setStartPpt(res.data.startPpt);
          localStorage.setItem("pptCondition", "false");
        } else {
          setStartPptFlag(false);
        }
      }
      setVisible(false);
      setState1({
        answer: [
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
        ],

        draggedOption: 0,
        targetLine: 0,
        line: LinesOfline,
        birds: [1],
        optionDraggable: [""],
        linesDraggable: ["True"],
      });
      setAnswerData([]);
      setCourseName(res?.courseName);
      setSessionName(res?.sessionName);
    } else {
      if (res.isCompleted) {
        navigate(`/finalResult`, {
          state: {
            id: res.id,
          },
        });
      } else {
        getQuestionsFinalTest();
      }
    }
  };

  const handleClosePopup = () => setOpenModel(false);
  const totalData = useSelector((data) => data.questionAnswer);

  const thirdAnsToApiHandler = (data) => {
    setAnswerData([]);
    const thirdvalid = data.some((obj) => obj.studentAns == "");
    if (!thirdvalid) {
      setVisible(true);
    }
    data.forEach((element) => {
      const singleData = {
        questionID: element.questionID,
        optionID: element.optionID,
        answer: element.studentAns,
        type: element.type,
      };
      setAnswerData((prevState) => [...prevState, singleData]);
    });
  };

  const getQuestionsFinalTest = async (data) => {
    setVisible(false);
    const payload = {
      classID: classId || localStorage.getItem("classId"),
      courseID: courseId || localStorage.getItem("courseId"),
      flag: false,
      sessionID: sessionId || localStorage.getItem("sessionId"),
    };

    const res = await getQuestionsDataFinalTest(payload);
    if (res && res.status === true) {
      setState1({
        answer: [
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
        ],

        draggedOption: 0,
        targetLine: 0,
        line: LinesOfline,
        birds: [1],
        optionDraggable: [""],
        linesDraggable: ["True"],
      });
      setAnswerData([]);
      setQuestionData(res?.data);
      if (res?.data?.pptdata.length) {
        setSequenceData(res?.data?.pptdata);
      } else {
        navigate(`/dataNotFound`);
        setDataNotFound(true);
      }
      setIsFinalTest(true);
      setAttemptType(
        res &&
        res?.data &&
        res?.data?.pptdata &&
        res?.data?.pptdata[0] &&
        res?.data?.pptdata[0].attampt
      );
      setSequence(
        res &&
        res?.data &&
        res?.data?.pptdata &&
        res?.data?.pptdata[0] &&
        res?.data?.pptdata[0].sequenceId
      );
    }
  };

  useEffect(() => {
    setReduxBefore(totalData);
  }, [question1Html]);

  const resetAllHandler = () => {
    setVisible(false);
    setState1({
      answer: [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
      ],

      draggedOption: 0,
      targetLine: 0,
      line: LinesOfline,
      birds: [1],
      optionDraggable: [""],
      linesDraggable: ["True"],
    });
    setAnswerData([]);

    dispatch(ansHandler([...reduxDataBefore.answer]));
  };

  const resetOneHandler = (index, id) => {
    setVisible(false);
    setState1((prevState) => {
      const newState = { ...prevState };
      newState.answer[index] = undefined;
      newState.line[index] = "Line";
      newState.optionDraggable[index] = "";
      newState.linesDraggable[newState.linesDraggable] = "True";
      newState.birds[newState.draggedOption] = 1;
      newState.targetLine = parseInt(newState.targetLine) + 1;
      newState.draggedOption = parseInt(newState.draggedOption) + 1;
      return newState;
    });
    setAnswerData((prevAnswerData) =>
      prevAnswerData.filter((element) => element.optionID !== id)
    );
    dispatch(
      ansHandler(
        [...totalData.answer].filter((element) => element.optionID !== id)
      )
    );
  };

  const questionDataHandler = () => {
    const sections = Object.keys(sequenceData && sequenceData[0]);
    const currentIndex = sections.indexOf(currentSection);
    if (currentIndex < sections.length - 1) {
      setCurrentSection(sections[currentIndex + 1]);
      displayQuestions(sections[currentIndex + 1]);
      if (currentIndex < sections.length - 2) {
        setNextButton(true);
      } else {
        setNextButton(false);
      }
    } else {
      console.log("No more sections available.");
    }
  };

  function displayQuestions(section) {
    const questions = sequenceData && sequenceData;
    setThirdQuestion(questions);
    setQuestion1Html(questions);
  }

  function shuffleArray(array) {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }
    return shuffledArray;
  }

  useEffect(() => {
    if (state) {
      setClassId(state.classId || localStorage.getItem("classId"));
      setSessionId(state.sessionId || localStorage.getItem("sessionId"));
      setCourseId(state.courseId || localStorage.getItem("courseId"));
      setStudentId(state.studentId || localStorage.getItem("studentId"));
      getQuestions(state);
    }
  }, [state]);

  useEffect(() => {
    if (
      sequenceData &&
      sequenceData[0] &&
      sequenceData[0]?.questionType == "3"
    ) {
      questionDataHandler();
    }
  }, [sequenceData]);

  const windowHeight = window.innerHeight;

  const [state1, setState1] = useState({
    answer: [undefined, undefined, undefined, undefined, undefined, undefined],
    draggedOption: 0,
    targetLine: 0,
    line: LinesOfline,
    birds: [1],
    optionDraggable: [""],
    linesDraggable: ["True"],
  });

  const drag = (event) => {
    setState1((prevState) => ({
      ...prevState,
      draggedOption: event.optionID,
    }));
  };
  const reduxData = useSelector((data) => data);
  const response1 = useSelector((data) => data.questionAnswer);

  const questionAnsHandler = (ans, data, index, qId, oId, type) => {
    const singleData = {
      questionID: qId,
      optionID: oId,
      answer: ans,
      type: type,
    };

    setSequenceData((prevState) => {
      const newState = [...prevState];
      newState[0].optionData[index].answers = ans;
      return newState;
    });
    setAnswerData((prevAnswerData) =>
      prevAnswerData.filter((element) => element.optionID !== oId)
    );

    setAnswerData((prevState) => [...prevState, singleData]);

    if (
      [...answerData, singleData].length === sequenceData[0]?.optionData?.length
    ) {
      setVisible(true);
    }
  };

  const answerTwoHandler = () => {
    const answer1 = [];

    const finalData =
      sequenceData &&
      sequenceData[0] &&
      sequenceData[0]?.optionData?.map((res) => {
        answer1.push({ name: res.correctAnswer, id: res.id });
      });

    setSecondAnswer(shuffleArray(answer1));
  };

  console.log("secondAnswer", sequenceData[0]?.optionData);

  useEffect(() => {
    answerTwoHandler();
  }, [sequenceData]);

  console.log("stateeeeee1", state1);

  const drop = (event) => {
    const singleDragDropAns = {
      questionID: event.dropData.qId,
      optionID: event.dropData.oId,
      answer: event.dragData.ansText,
    };
    console.log("response1.answer", response1.answer, singleDragDropAns);

    const { idLine, draggedOption } = event.dropData;
    dispatch(ansHandler([...response1.answer, singleDragDropAns]));
    if (state1.answer[idLine] == undefined) {
      setState1((prevState) => {
        const newState = { ...prevState };
        newState.targetLine = event.dropData.idLine;
        console.log("event.dropData.idLine", event.dropData.idLine);
        newState.optionDraggable[event.dropData.idLine] =
          event.dragData.ansText;
        if (newState.line[idLine]) {
          newState.birds[newState.draggedOption] = 0.2;
          newState.answer[idLine] = event.dragData.ansText;
          newState.line[newState.targetLine] = newState.draggedOption;

          newState.linesDraggable[newState.targetLine] = "1";
        }

        const response = newState.line.filter((res) => {
          return res !== "Line";
        });
        if (response.length === sequenceData[0]?.optionData?.length) {
          setVisible(true);
        }
        return newState;
      });
      setAnswerData((prevState) => [...prevState, singleDragDropAns]);
    }
  };

  const submitTests = async (e) => {
    const payload = {
      ansdata: answerData,
      questionID:
        sequenceData && sequenceData[0] && sequenceData[0]?.questionID,
      questionType:
        sequenceData && sequenceData[0] && sequenceData[0]?.questionType,
      attempt: attemptType,
      sequenceId: sequence,
      studentId: studentId,
    };

    e.preventDefault();
    if (visible) {
      const res = await submitTest(payload);
      if (res && res.status === true) {
        setAnswerData([]);
        navigate("/feedBack", {
          state: {
            sequenceData: sequenceData,
            response: res,
            attemptType: attemptType,
          },
        });
      } else {
        getQuestions();
        setAttemptType("1");
      }
    }
  };

  const submitFinalTest = async (e) => {
    const uniqueEntries = new Map();

    const duplicateDataHandler = answerData.filter((entry) => {
      const key = `${entry.questionID}-${entry.optionID}`;
      if (!uniqueEntries.has(key)) {
        uniqueEntries.set(key, true);
        return true;
      }
      return false;
    });
    const payload = {
      ansdata: duplicateDataHandler,
      classID: classId || localStorage.getItem("classId"),
      courseID: courseId || localStorage.getItem("courseId"),
      sessionID: sessionId || localStorage.getItem("sessionId"),
      studentID: studentId || localStorage.getItem("studentId"),
      questionID:
        sequenceData && sequenceData[0] && sequenceData[0]?.questionID,
      questionType:
        sequenceData && sequenceData[0] && sequenceData[0]?.questionType,
    };
    e.preventDefault();
    if (visible) {
      const res = await CreateResult(payload);
      if (res && res.status === true) {
        navigate("/finalRightAns", {
          state: {
            id: res?.id,
          },
        });
      }
    }
  };

  const startNexthandler = async () => {
    setStartPptFlag(false);
    setAudioFlag(false);
    const payload = {
      questionId: localStorage.getItem("pptQuestionId"),
      studentId: localStorage.getItem("studentId"),
    };
    const data = await pptCountHandler(payload);
  };

  return (
    <>
      {startPptFlag || startAudioFlag ? (
        <>
          <PptView
            Link={startPpt}
            audioFile={startAudio}
            next={startNexthandler}
          />
        </>
      ) : (
        <>
          {questionData ? (
            <>
              <div className="h-screen flex flex-col p-14 max-[750px]:p-9 max-[450px]:px-5">
                <div className="flex flex-col mb-4">
                  <span
                    className="text-[35px] font-semibold max-[750px]:text-[28px] max-[600px]:text-[22px]"
                    style={{ fontFamily: "Archia" }}
                  >
                    {courseName}
                  </span>
                  <div className="flex justify-between max-[420px]:flex-col max-[420px]:gap-y-2">
                    <div className="text-[25px] font-normal max-[750px]:text-[20px]  max-[600px]:text-[15px]">
                      {sessionName}
                    </div>
                  </div>
                  <div className="flex justify-between max-[420px]:flex-col max-[420px]:gap-y-2">
                    <div className="text-[25px] font-normal max-[750px]:text-[20px]  max-[600px]:text-[15px]">
                      {isFinalTest && "Final Test"}
                      {attemptType == "1"
                        ? `Test ${sequenceNo} First Attempt`
                        : attemptType == "2"
                          ? `Test ${sequenceNo} Second Attempt`
                          : ""}
                    </div>
                  </div>
                </div>

                {/* <> */}
                {sequenceData &&
                  sequenceData[0] &&
                  sequenceData[0].questionType == "1" ? (
                  <>
                    {sequenceData &&
                      sequenceData[0]?.optionData?.map((data1, index) => {
                        return (
                          <>
                            <div
                              key={`${index}_question`}
                              className="rounded-[30px] mb-4 w-[100%] flex px-[59px] max-[1050px]:px-[40px] max-[830px]:px-[20px] gap-2 max-[992px]: border-[3px] justify-between py-6 border-[##D3D3D3] max-[1000px]:flex-col max-[600px]:py-3 max-[600px]:px-5 items-center"
                            >
                              <div className="default font-medium max-[992px]:text-[22px] max-[600px]:text-[15px] max-[750px]:text-[20px] pr-3">
                                {index + 1}. {data1.questionText}
                              </div>
                              <div className="gap-x-3 flex items-center text-[22px] font-semibold max-[992px]:text-[17px] max-[750px]:text-[14px] max-[600px]:text-[12px] max-[450px]:text-[10px] justify-center">
                                <span
                                  onClick={() =>
                                    questionAnsHandler(
                                      "1",
                                      data1,
                                      index,
                                      data1.questionID,
                                      data1.id,
                                      "1"
                                    )
                                  }
                                  className={`${data1 && data1?.answers == "1"
                                    ? "activeButton"
                                    : "inActiveButton"
                                    } cursor-pointer font-archia rounded-full  py-[6px] px-8 `}
                                >
                                  True
                                </span>
                                <span
                                  onClick={() => {
                                    questionAnsHandler(
                                      "2",
                                      data1,
                                      index,
                                      data1.questionID,
                                      data1.id,
                                      "1"
                                    );
                                  }}
                                  className={`${data1 && data1?.answers == "2"
                                    ? "activeButton"
                                    : "inActiveButton"
                                    } cursor-pointer font-archia rounded-full  py-[6px] px-8 `}
                                >
                                  False
                                </span>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </>
                ) : sequenceData &&
                  sequenceData[0] &&
                  sequenceData[0].questionType == "2" ? (
                  <>
                    <div className="rounded-[30px] w-full max-[992px]:my-1 flex flex-col max-[992px]:h-auto justify-between px-[59px] max-[1050px]:px-[40px] max-[830px]:px-[20px] border-[3px] py-6 border-[##D3D3D3]">
                      {sequenceData &&
                        sequenceData[0]?.optionData?.map((data1, index1) => {
                          return (
                            <>
                              <div
                                key={`${index1}_Liner`}
                                className="flex justify-between max-[992px]:flex-col"
                              >
                                <div className="default flex-shrink-0 whitespace-normal my-2 font-medium max-[1150px]:text-[22px] max-[750px]:text-[20px] max-[600px]:text-[15px] pr-3">
                                  {index1 + 1}. {data1.questionText}
                                </div>
                                <div className="flex items-center gap-1">
                                  <div>
                                    <DropTarget
                                      targetKey="line"
                                      dropData={{
                                        idLine: index1,
                                        qId: data1.questionID,
                                        oId: data1.id,
                                      }}
                                    >
                                      <div
                                        ids={index1}
                                        className="flex-shrink-0 primary-default my-2 max-[992px]:my-2 max-[992px]:justify-center flex bg-[--primaryLight] border-2 rounded-full px-8 py-2 max-[1000px]:w-[30%] max-[700px]:w-[40%] max-[600px]:w-[70%]"
                                      >
                                        {state1.answer[index1] == undefined &&
                                          "Drop Here"}
                                        {state1.answer[index1]}
                                      </div>
                                    </DropTarget>
                                  </div>
                                  {state1.answer[index1] && (<div>
                                    <Typography
                                      sx={{
                                        fontSize: "12px",
                                        color: "var(--primary)",
                                        background: "#f5e7de",
                                        padding: "2px 10px",
                                        borderRadius: "3px",
                                        cursor: "pointer",
                                        width: "fit-content",
                                        fontWeight: "600",
                                      }}
                                      onClick={() =>
                                        resetOneHandler(index1, data1.id)
                                      }
                                    >
                                      {" "}
                                      Reset{" "}
                                    </Typography>
                                  </div>)}


                                </div>
                              </div>
                            </>
                          );
                        })}
                      <div className="flex mx-auto gap-x-3 gap-y-5 flex-wrap mt-3">
                        {secondAnswer?.map((data1, index1) => {
                          return (
                            <>
                              <DragDropContainer
                                targetKey="line"
                                onDragStart={drag}
                                onDrop={drop}
                                dragData={{
                                  optionID: data1.id,
                                  ansText: data1.name,
                                }}
                                noDragging={
                                  state1.optionDraggable?.filter(
                                    (res) => res == data1.name
                                  ).length > 0
                                }
                              >
                                <span
                                  style={{
                                    opacity:
                                      state1.optionDraggable.filter(
                                        (res) => res == data1.name
                                      ).length > 0
                                        ? 0.5
                                        : 1,
                                  }}
                                  className="cursor-grabbing bg-[--primary] rounded-full px-8 py-2 min-[1150px]:px-10 max-[750px]:px-6 text-white max-[480px]:px-4 max-[480px]:py-2"
                                >
                                  {data1.name}
                                </span>
                              </DragDropContainer>
                            </>
                          );
                        })}
                        <br />
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: "var(--primary)",
                            background: "#f5e7de",
                            padding: "2px 10px",
                            borderRadius: "3px",
                            cursor: "pointer",
                            width: "fit-content",
                            fontWeight: "600",

                          }}
                          onClick={() => resetAllHandler()}
                        >
                          {" "}
                          Reset all{" "}
                        </Typography>
                      </div>
                    </div>
                  </>
                ) : sequenceData &&
                  sequenceData[0] &&
                  sequenceData[0].questionType == "3" ? (
                  <>
                    <DndProvider
                      backend={MultiBackend}
                      options={CustomHTML5toTouch}
                    >
                      <ThirdQue
                        totalData={thirdQuestion}
                        returnData={thirdAnsToApiHandler}
                        resetHandler={() => setVisible(false)}
                        attemptType={attemptType}
                      />
                    </DndProvider>
                  </>
                ) : (
                  <h3> Data is not available please try later !</h3>
                )}

                {Boolean(nextButton) ? (
                  <div
                    onClick={(e) => {
                      if (isFinalTest) {
                        submitFinalTest(e);
                      } else {
                        submitTests(e);
                      }
                    }}
                    className="cursor-pointer ml-auto py-4 "
                  >
                    <ToastContainer
                      position="top-right"
                      autoClose={2000}
                      limit={1}
                      hideProgressBar={false}
                      newestOnTop={true}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="light"
                    />
                    <ToastContainer />
                    <span
                      className={`${visible ? "activeButton" : "inActiveButton"
                        } cursor-pointer font-archia rounded-full px-8 py-2 `}
                    >
                      Next
                    </span>
                  </div>
                ) : (
                  <div
                    onClick={(e) => {
                      if (isFinalTest) {
                        submitFinalTest(e);
                      } else {
                        submitTests(e);
                      }
                    }}
                    className="cursor-pointer ml-auto my-4"
                  >
                    <span
                      className={`${visible ? "activeButton" : "inActiveButton"
                        } cursor-pointer font-archia rounded-full px-8 py-2 `}
                    >
                      Submit
                    </span>
                  </div>
                )}
              </div>
            </>
          ) : (
            ""
          )}

          {openModel && (
            <Modal
              className="confirm_modal"
              keepMounted
              open={openModel}
              aria-labelledby="keep-mounted-modal-title"
              aria-describedby="keep-mounted-modal-description"
              disableBackdropClick={true}
              md
            >
              <Box sx={styles}>
                <Grid container width={"100%"}>
                  <Grid item xs={12}>
                    <Typography
                      className="modal_head"
                      fontSize={"30px"}
                      fontWeight={"700"}
                      sx={{ borderBottom: "2px solid var(--primary)" }}
                    >
                      Are you happy to continue?
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid container padding={"50px 0 0"}>
                      <Grid item xs={12} marginBottom={"50px"}>
                        <ul>
                          <li>
                            <Typography
                              fontSize={"25px"}
                              className="modal_text"
                              fontWeight={"500"}
                            >
                              You got all the answers right. Well done.
                            </Typography>
                            <Typography
                              fontSize={"25px"}
                              className="modal_text"
                              fontWeight={"500"}
                            >
                              If you are ready to look at some other words,
                              click{" "}
                              <span
                                onClick={() => {
                                  handleClosePopup();
                                  getQuestions();
                                  setAttemptType("1");
                                }}
                                style={{
                                  color: "var(--primary)",
                                  textDecoration: "underline",
                                }}
                              >
                                {" "}
                                here{" "}
                              </span>
                            </Typography>
                          </li>
                        </ul>
                      </Grid>
                      <Grid item xs={12}>
                        <ul>
                          <li>
                            <Typography
                              fontSize={"25px"}
                              className="modal_text"
                              fontWeight={"500"}
                            >
                              If you want to go over the words again,
                            </Typography>
                            <Typography
                              fontSize={"25px"}
                              className="modal_text"
                              fontWeight={"500"}
                            >
                              click{" "}
                              <span
                                onClick={() => {
                                  navigate("/preview", {
                                    state: {
                                      ppt: sequenceData && sequenceData[0]?.ppt,
                                      audio:
                                        sequenceData && sequenceData[0].audio,
                                      classId: classId,
                                      sessionId: sessionId,
                                      courseId: courseId,
                                    },
                                  });
                                  handleClosePopup();
                                }}
                                style={{
                                  color: "var(--primary)",
                                  textDecoration: "underline",
                                }}
                              >
                                {" "}
                                here
                              </span>
                            </Typography>
                          </li>
                        </ul>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} display={"flex"} gap={"20px"}>
                    <Typography
                      className="modal_head"
                      width={"100%"}
                      fontSize={"30px"}
                      fontWeight={"700"}
                      sx={{ borderBottom: "2px solid var(--primary)" }}
                    ></Typography>
                    <img src={Logo} width={"100px"} />
                  </Grid>
                </Grid>
              </Box>
            </Modal>
          )}
        </>
      )}
    </>
  );
}

export default Exam;
