import React, { useEffect, useState } from "react";
import ThirdAns from "./ThirdAns";
import ThirdBox from "./ThirdBox";
import { styled } from "@mui/system";
import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  tableCellClasses,
  Grid,
} from "@mui/material";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    backgroundColor: "#fff",
    ":hover": { backgroundColor: "rgb(248 248 248)!important" },

    "&:nth-of-type(odd)": {
      backgroundColor: "rgb(250 250 250)!important",
    },
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#000",
    fontSize: 13,
    border: 0,
    fontWeight: 600,
    paddingTop: "15px",
    paddingBottom: "15px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    color: "rgb(17, 24, 39)",
    border: 0,
    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));

function ThirdQuestionDetails({ imageData, data }) {
  const [answer, setAnswer] = useState([]);
  const [fakeData, setFakeData] = useState([]);



  let ansData = [];

  const answerDataHandler = () => {
    data?.map((result) => {
      ansData.push({ ans: result.correctAnswer, disable: true });
    });
    setAnswer(ansData);
  };


  const fakeDataHandler = () => {
    const newQuestions = data.map((res) => ({
      correctAnswer: res.correctAnswer,
      questionID: res.questionID,
      optionID: res.id,
      studentAns: "",
      Uid: res.Uid,
      top: res.top,
      left: res.left,
    }));
    setFakeData(newQuestions);
  }


  useEffect(() => {
    answerDataHandler();
    fakeDataHandler();
  }, []);


  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", position: "relative" }}
      ref={null}
      height={"500px"}
    >
      <Grid container justifyContent={"center"}>
        <Grid item>
          <img
            className="image_question"
            src={imageData}
          />
        </Grid>
        <Grid item width={"200px"}>
          <Table className="table">
            <TableHead>
              <TableRow>
                <Grid
                  container
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Grid item>
                    <h2> Answer-1111 </h2>
                  </Grid>
                  {/* <Grid item>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "var(--primary)",
                        background: "#f5e7de",
                        padding: "2px 10px",
                        borderRadius: "3px",
                      }}
                      onClick={() => resetAllHandler()}
                    >
                      {" "}
                      reset all{" "}
                    </Typography>
                  </Grid> */}
                </Grid>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell>
                  {answer?.map((answer, index) => (
                    <ThirdAns
                      key={index}
                      answer={answer.ans}
                      disable={answer.disable}
                    />
                  ))}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>

          {fakeData?.map((res) => {
            return (
              <ThirdBox
                key={res}
                id={res}
                value={res.correctAnswer}
                {...fakeData[res]}
                onlyView={true}
              />
            );
          })}
        </Grid>
      </Grid>
    </Box>
  );
}

export default ThirdQuestionDetails;
