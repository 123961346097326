import { useCallback, useState } from "react";
import update from "immutability-helper";
import { useDrop } from "react-dnd";
import { snapToGrid as doSnapToGrid } from "./snapToGrid";
import { DraggableBox } from "./DragableBox";

const styles = {
  width: 400,
  height: 400,
  border: "1px solid black",
  position: "relative",
};

export const ItemTypes = {
  BOX: "box",
};

export function snapToGrid(x, y) {
  const snappedX = Math.round(x / 32) * 32;
  const snappedY = Math.round(y / 32) * 32;
  return [snappedX, snappedY];
}

function Container() {
  const [boxes, setBoxes] = useState({
    a: { top: 20, left: 80, title: "question-1", Ans:"",Uid: 1 },
    b: { top: 180, left: 85, title: "question-2",Ans:"",Uid: 2 },
    c: { top: 50, left: 90, title: "question-3",Ans:"", Uid: 3 },
    d: { top: 200, left: 95, title: "question-4",Ans:"", Uid: 4 },
    e: { top: 250, left: 100, title: "question-5",Ans:"",Uid: 5 },
  });

  const moveBox = useCallback(
    (id, left, top) => {
      setBoxes(
        update(boxes, {
          [id]: {
            $merge: { left, top },
          },
        })
      );
    },
    [boxes]
  );
  
  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.BOX,
      drop(item, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset();
        let left = Math.round(item.left + delta.x);
        let top = Math.round(item.top + delta.y);
        if (snapToGrid) {
          [left, top] = doSnapToGrid(left, top);
        }
        moveBox(item.id, left, top);
        return undefined;
      },
    }),
    [moveBox]
  );
  
  return (
    <div ref={drop} style={styles}>
      {Object.keys(boxes).map((key) => (
        <DraggableBox key={key} id={key} {...boxes[key]} />
      ))}
    </div>
  );
}

export default Container;
