import { Box, Paper ,TextField} from '@mui/material';
import React from 'react';
import { useDrag, useDrop } from 'react-dnd';

const Answer = ({ answer, onDrop }) => {
    const [{ isDragging }, drag] = useDrag({
      type: 'ANSWER',
      item: { answer },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    });
  
    return (
      <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
        {answer}
      </div>
    );
};


const TextFieldWithDrop = ({ onDrop, value }) => {
    const [{ isOver }, drop] = useDrop({
      accept: 'ANSWER',
      drop: (item) => onDrop(item.answer),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    });
  
    return (
      <div ref={drop}>
        <TextField value={value} variant="outlined" fullWidth />
      </div>
    );
};
  


function SmallDND() {
    const [textFields,setTextFields] = React.useState([
        {id:1,value:""},
        {id:2,value:""}
    ]);
    const answers = ['answer-1','answer-2','answer-3'];

    const handleDrop = (answer,id) =>{
        const updatedTextFields = textFields.map((field)=>
            field.id === id ? {...field,value:answer} : field
        );
        setTextFields(updatedTextFields);
    }

  return (
    <div>       
        <Box display="flex" justifyContent="space-around" mt={2}>
            {
                answers.map((answer,index)=>(
                    <Paper key={index} style={{padding:10,margin:5}}>
                        <Answer answer={answer} onDrop={(ans)=>handleDrop(ans,index+1)}/>
                    </Paper>
                ))
            }
        </Box>
        <Box display="flex" justifyContent="space-around" mt={4}>
            {
                textFields.map((field)=>(
                    <Paper key={field.id} style={{ padding: 10, margin: 5 }}>
                        <TextFieldWithDrop onDrop={(ans) => handleDrop(ans, field.id)} value={field.value} />
                    </Paper>
                ))
            }
        </Box>
    </div>
  )
}

export default SmallDND
