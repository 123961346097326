import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import DataDisplay from "./DataDisplay";
import TopStudentList from "./TopStudentList";
import { topStudentListHandler } from "@/Api/AdminApi";

const Dashboard = () => {
  const [studentList, setStudentList] = useState([]);

  useEffect(() => {
    studentDataHandler();
  }, []);

  const studentDataHandler = async () => {
    const { data, success } = await topStudentListHandler();
    if (success) {
      setStudentList(data);
    } else {
      setStudentList([]);
    }
  };

  return (
    <>
      <Box
        sx={{
          px: 3,
          paddingBottom: "3px",
          backgroundColor: "rgb(241, 245, 249)",
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography
            sx={{
              paddingTop: "25px",
              paddingBottom: "15px",
              fontSize: "32px",
              fontWeight: "600",
              color: "#000",
              cursor: "pointer",
            }}
          >
            <h2>Dashboard</h2>
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: "15px",
          marginLeft: { xs: "15px", md: "25px", lg: "25px" },
          marginRight: { xs: "15px", md: "25px", lg: "25px" },
        }}
      >
        <Box>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box
                  sx={{
                    marginTop: { xs: "0px", sm: "20px", md: "20px", lg: "0px" },
                    marginBottom: "20px",
                  }}
                >
                  <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <DataDisplay
                      title={"Total Class"}
                      data={studentList.totalClass}
                    />
                    <DataDisplay
                      title={"Total Course"}
                      data={studentList.totalCourse}
                    />
                    <DataDisplay
                      title={"Total Session"}
                      data={studentList.totalSession}
                    />
                    <DataDisplay
                      title={"Total Student"}
                      data={studentList.totalStudent}
                    />
                    <DataDisplay
                      title={"Total Teacher"}
                      data={studentList.totalTeachers}
                    />
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <TopStudentList data={studentList?.studentDetails} />
      </Box>
    </>
  );
};

export default Dashboard;
