import { TextField } from "@mui/material";
import React from "react";
import { useDrag, useDrop } from "react-dnd";
import "./textfield.css";

export const ItemTypes = {
  BOX: "box",
};

function getStyles(left, top, isDragging, correctAnswer, right, bottom) {
  const isMobile = window.innerWidth <= 768;
  // const leftOffset = isMobile ? 50 : 170;
  const transform = `translate3d(${left}px, ${top}px ,0px)`;

  return {
    position: "absolute",
    transform,
    WebkitTransform: transform,
    opacity: isDragging ? 0 : 1,
    height: isDragging ? 0 : "",
  };
}

function ThirdBox(props) {
  const { id, correctAnswer, studentAns, left, top, data, dataHandler } =
    props.id;

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: ItemTypes.BOX,
      item: { id, left, top, correctAnswer },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [id, left, top, correctAnswer]
  );

  const [{ isOver }, drop] = useDrop({
    accept: "ANSWER",
    drop: (item) => props.onDrop(item.answer),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div className="static">
      <div
        className="top-[80px]"
        style={getStyles(left, top, isDragging, correctAnswer)}
        role="DraggableBox"
      >
        <TextField
          size="small"
          ref={drop}
          sx={{ width: "70%" }}
          value={props.onlyView ? correctAnswer : studentAns}
        />
      </div>
    </div>
  );
}

export default ThirdBox;
