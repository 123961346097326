import Controls from "@/components/controls/Controls";
import {
  getClassDropdownList,
  getCourseDropdownList,
} from "@/state/actions/Admin/courseAction";
import {
  getQuestionDetails,
  getSessioDropdownList,
} from "@/state/actions/Admin/questionAction";
import {
  Box,
  Button,
  Grid,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useLocation,useNavigate } from "react-router";
import FinalTestView from "./SlideManagement/FinalTestView";
import ShortTestView from "./SlideManagement/SlideTestView";
import UploadAudio from "../upload/UploadAudio";

function SlideDetails() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [className, setClassName] = useState("");
  const [courseName, setCourseName] = useState("");
  const [sessionName, setSessionName] = useState("");
  const [marks, setMarks] = useState("");
  const [startPpt, setStartPpt] = useState("");
  const [startAudio, setStartAudio] = useState("");
  const [classDropdown, setClassDropdown] = useState([]);
  const [CourseDropdown, setCourseDropdown] = useState([]);
  const [SessionDropdown, setSessionDropdown] = useState([]);
  const [slides, setSlides] = useState([]);
  const [thirdQuestionData, setThirdQuestionData] = useState([]);
  const [finalDataView, setFinalDataView] = useState([]);
  const id = state.question;

  const thirdQuestionHandler = (data) => {
    setThirdQuestionData(data);
  };

  const getDetails = async () => {
    const res = await getQuestionDetails(id);
    if (res && res.status) {
      if (res.data) {
        setClassName(res.data.classID);
        setCourseName(res.data.courseID);
        setSessionName(res.data.sessionID);
        setStartPpt(res.data.startPpt);
        setStartAudio(res.data.audioFile);
        // TO DO : Audio Setup
        setSlides(res.data.pptData);
        setFinalDataView(res.data.finalQuestion);
      } else {
        // here we have to handle if data is going Blank
      }
    }
  };

  useEffect(() => {
    getDetails(id);
  }, [id]);

  const getCourseDetails = async () => {
    if (className) {
      const res = await getCourseDropdownList(className);
      setCourseDropdown(res.data);
    }
  };

  useEffect(() => {
    if (className) {
      getCourseDetails(className);
    }
  }, [className]);

  const getSessionDetail = async () => {
    if (courseName) {
      const res = await getSessioDropdownList(courseName);
      setSessionDropdown(res.data);
    }
  };

  useEffect(() => {
    if (courseName) {
      getSessionDetail(courseName);
    }
  }, [courseName]);

  const ClassDropdownList = async () => {
    const res = await getClassDropdownList();
    if (res.status === true) {
      setClassDropdown(res.data);
    } else {
      setClassDropdown([]);
    }
  };

  const backDataHandler = () => {};

  useEffect(() => {
    ClassDropdownList();
  }, []);

  return (
    <Box>
      <Box
        sx={{
          px: 3,
          paddingBottom: "3px",
          backgroundColor: "rgb(241, 245, 249)",
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography
            sx={{
              paddingTop: "25px",
              paddingBottom: "15px",
              fontSize: "15px",
              fontWeight: "600",
              color: "#000",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/question");
            }}
          >
            <KeyboardBackspaceIcon
              sx={{ verticalAlign: "top", marginRight: "5px" }}
            />
            Questions List
          </Typography>
        </Box>
      </Box>
      <Box
        className="b1"
        sx={{
          backgroundColor: "white",
        }}
      >
        <Grid item md={12} justifyContent="center" sm={12} xs={12} container>
          <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
            <Controls.SelectBox
              className="input"
              label="Select Class"
              fullWidth
              disabled
              id="classID"
              name="classID"
              value={className}
              options={classDropdown}
            />
          </Grid>{" "}
          <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
            <Controls.SelectBox
              className="input"
              label="Select Course"
              fullWidth
              id="courseID"
              disabled
              name="courseID"
              value={courseName}
              options={CourseDropdown}
            />
          </Grid>
          <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
            <Controls.SelectBox
              className="input"
              label="Select Session"
              fullWidth
              disabled
              id="sessionID"
              name="sessionID"
              value={sessionName}
              options={SessionDropdown}
            />
          </Grid>{" "}
          <Grid item sx={{ m: 1 }} md={5.5} xs={12}></Grid>{" "}
          <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
            <Button variant="contained" component="label">
              starting ppt
              <input hidden />
            </Button>
            {startPpt && <h5 style={{ marginTop: "10px",overflowWrap: 'anywhere'}}> {startPpt} </h5>}
          </Grid>
          <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
            <Button variant="contained" component="label">
              Audio File
              <input hidden />
            </Button>
            {
              startAudio &&   <h5 style={{ marginTop: "10px",overflowWrap: 'anywhere'}}>{startAudio}</h5>
            }
            {startAudio && (
              <UploadAudio audioFile={startAudio} keyName={`${startAudio}`} />
            )}
          </Grid>
        </Grid>
      </Box>
      <Box
        className="b1"
        sx={{
          backgroundColor: "white",
          marginTop: "20px",
          padding: "20px !important",
        }}
      >
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid item>
            <Typography variant="h6">Slides</Typography>
          </Grid>
        </Grid>

        {/* multiple slides start Here  */}
        {slides?.map((res, index) => {
          return (
            <ShortTestView
              slideI={index}
              sTData={res}
              slides={slides}
              backData={backDataHandler}
            />
          );
        })}
      </Box>

      <FinalTestView incomeData={finalDataView} />
    </Box>
  );
}

export default SlideDetails;
