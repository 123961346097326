import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  DialogContent,
  Paper,
} from "@mui/material";
import { Grid, Box, Typography, IconButton } from "@mui/material";
import camera from "../../../assets/camera.svg";
import { styled } from "@mui/material/styles";
import dayjs from 'dayjs';
import { useSnackbar } from "notistack";
import { Form, useForm } from "../../../utils/useForm";
import Controls from "../../../components/controls/Controls";
import { getClassDropdownList, addStudent, updateStudent } from "../../../state/actions/Admin/studentAction";
import {
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useProSidebar } from "react-pro-sidebar";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import "./AddStudent.css";
import { ToastContainer, toast } from 'react-toastify';
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
const StyledImageUploadWrapper = styled(Box)(() => ({
  marginTop: "16px",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  height: "220px",
  borderRadius: "10px",
  textAlign: "center",
}));
const StyledIconWrapper = styled(Box)(() => ({
  margin: "20px auto",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",

  img: {
    marginTop: "30px",
  },
}));
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

const StyledInput = styled("input")({
  display: "none",
});

const StyledRequired = styled("span")({
  color: "red",
  marginLeft: "2px",
  fontSize: "20px",
});
const StyledRemoveButton = styled(Box)({
  color: "red",
  display: "inline",
  "&:hover": {
    cursor: "pointer",
  },
});

const initialValues = {

  firstName: "",
  lastName: "",
  email: "",
  classID: "",
  gender: "",
  password: "",

};

const AddStudent = (props) => {
  const { studentData } = props;

  const [studentImage, setstudentImage] = useState({});
  const [image, setImage] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [userId, setUserId] = useState("");
  const [imageRemove,setRemoveImage] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [showPassword, setShowPassword] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { collapsed } = useProSidebar();
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const options = { month: 'M', day: 'D', year: 'numeric' };
  const currentDate = new Date().toLocaleDateString(options);


  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("firstName" in fieldValues) {
      if (fieldValues.firstName !== "" && fieldValues.firstName !== undefined) {
        if (!/^[a-zA-Z ]*$/.test(fieldValues.firstName)) {
          temp.firstName = "Invalid First Name";
        } else {
          temp.firstName = "";
        }
      } else {
        temp.firstName = "This field is required.";
      }
    }
    if ("lastName" in fieldValues) {
      if (fieldValues.lastName !== "" && fieldValues.lastName !== undefined) {
        if (!/^[a-zA-Z ]*$/.test(fieldValues.lastName)) {
          temp.lastName = "Invalid Last Name";
        } else {
          temp.lastName = "";
        }
      } else {
        temp.lastName = "This field is required.";
      }
    }
    if ("classID" in fieldValues)
      temp.classID = fieldValues.classID
        ? ""
        : "This field is required.";

    if ("email" in fieldValues) {
      if (fieldValues.email !== "" && fieldValues.email !== undefined) {
        const emailRegex =
          /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.(com|org|gov|edu|net|mil|in)$/;
        const isEmailValid = emailRegex.test(fieldValues.email);
        if (!isEmailValid) {
          temp.email = "Invalid email";
        } else {
          temp.email = "";
        }
      } else {
        temp.email = "This field is required.";
      }
    }
    if ("password" in fieldValues) {
      temp.password = fieldValues.password ? "" : "This field is required.";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const formData = new FormData();

      formData.append("classID", values.classID);
      formData.append("firstName", values.firstName);
      formData.append("lastName", values.lastName);
      formData.append("email", values.email);
      formData.append("password", values.password);
      formData.append("showPassword", values.password);
      if (studentImage && studentImage.name) {
        formData.append("profile", values.profile);
      }
      if (imageRemove === true) {
        formData.append("profile", null);
      }
      if (isEdit) {
        // if (image) {
          const res = await updateStudent(userId, formData);
          if (res && res.status === true) {
            toast.success(res.message, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setTimeout(() => {
              navigate("/student");
            }, 1000);
  
          } else {
            toast.error(res.message, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        // }
      }
      if (!isEdit) {
          const res = await addStudent(formData);
          if (res && res.status === true) {
            toast.success(res.message, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setTimeout(() => {
              navigate("/student");
            }, 2000)
          } else {
            toast.error(res.message, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
       
      }
    }
  }

  const [classDropdown, setClassDropdown] = useState([]);

  const ClassDropdownList = async () => {

    const res = await getClassDropdownList();
    if (res.status === true) {
      setClassDropdown(res.data);
    } else {
      setClassDropdown([]);
    }
  };




  const gender = [
    { id: 1, name: "Male" },
    { id: 2, name: "Female" },
  ];
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  useEffect(() => {
    ClassDropdownList();
    if (state && state?.isEdit == true) {
      setIsEdit(true);
      setUserId(state?.user?.id);
      setValues({
        ...values,
        firstName: state?.user.firstName,
        lastName: state?.user.lastName,
        email: state?.user.email,
        classID: state?.user.classID,
        password: state?.user.showPassword,
        profile: state?.user.profile
      })
      setImage(state?.user?.profile);

    } else { resetForm(); }
  }, [state?.user]);

  const data = values?.useForIds?.map((row) => row?.id);

  //image


  const resetImage = () => {
    setImage("");
  };
  const reset = () => {
    resetImage();
    resetForm();
  };

  const removeImage = () => {
    resetImage();
    setValues((previousValues) => {
      var newValues = { ...previousValues };
      delete newValues["profile"];
      console.log("newValues",newValues)

      return newValues;
    });
    setRemoveImage(true)
    setstudentImage({});
  };
  console.log("image",image);

  const maxSize = 2048;

  const validateImageSize = (vehicleIcons) => {
    if (vehicleIcons) {
      const fsize = vehicleIcons.size / 1024;
      if (fsize > maxSize) {
        return false;
      } else {
        return true;
      }
    }
  };

  async function handleImageItem(e) {
    if (validateImageSize(e.target.files[0])) {
      const selected = e.target.files[0];
      const ALLOWED_TYPES = ["image/png", "image/jpg", "image/jpeg"];
      if (selected && ALLOWED_TYPES.includes(selected.type)) {
        const base64String = await getBase64(selected);
        setImage(base64String);
        setstudentImage(selected);
        setValues({ ...values, profile: selected });
      } else {

      }
    } else {
      setstudentImage({});
      toast.error("Please Upload 5MB Image", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };
  const color = "#c44242 !important";




  return (
    <div>
      <>
        <Typography
          sx={{
            marginLeft: "20px",
            paddingTop: "25px",
            paddingBottom: "0px",
            fontSize: "20px",
            fontWeight: "600",
            color: "#000",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("/student");
          }}
        >
          <KeyboardBackspaceIcon
            sx={{ verticalAlign: "top", marginRight: "5px", marginTop: '3px' }}
          />
          Student
        </Typography>
        <div>
          <Form onSubmit={handleSubmit}>
            <Paper
              sx={{ marginTop: { xs: "20px", sm: "15px", md: "15px", lg: "25px" }, marginLeft: "19px", marginRight: "19px" }}
            >
              <Box>
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "20px",
                    m: 1,
                    padding: "12px",
                    fontWeight: "bold",
                  }}
                >
                  Personal Information
                </Typography>
              </Box>
              <DialogContent>
                <Grid container justifyContent={'space-between'} alignItems={'center'} spacing={2}>
                  <Grid item md={3} sm={4} xs={12}>
                    <StyledImageUploadWrapper>
                      <label htmlFor="image-upload">
                        <StyledInput
                          accept="image/*"
                          id="image-upload"
                          type="file"
                          onChange={handleImageItem}
                          onClick={(event) => {
                            event.currentTarget.value = null;
                          }}
                        />
                        <StyledIconWrapper
                          sx={
                            image == ""
                              ? {}
                              : {
                                backgroundImage: `url(${image})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                margin: '15px 0'
                              }
                          }
                        >
                          {image === "" && <img src={camera} alt="Camera" style={{ marginLeft: "27px" }} />}
                        </StyledIconWrapper>
                      </label>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "black",
                        }}
                      >
                        {isEdit ? "Edit Profile Image" : "Add Profile Image"}
                      </Typography>
                      <Typography
                        sx={{
                          color: "rgba(15, 15, 15, 0.5)",
                          mx: 2,
                          fontSize: "12px",
                        }}
                      >
                        Allowed *.jpeg, *.jpg, *.png, max size of 5 MB
                      </Typography>

                      {image != "" && (
                        <StyledRemoveButton
                          onClick={(e) => {
                            e.preventDefault();
                            removeImage();
                          }}
                        >
                          Remove
                        </StyledRemoveButton>
                      )}
                    </StyledImageUploadWrapper>
                  </Grid>
                  <Grid item md={9} sm={8} xs={12}>
                    <Grid container>
                      <Grid item sx={{ m: 1 }} md={5} xs={12}>
                        <Controls.Input
                          text="Add New"
                          variant="outlined"
                          color="primary"
                          label="First Name"
                          name="firstName"
                          value={values.firstName}
                          onChange={handleInputChange}
                          error={errors.firstName}
                          helperText={errors.firstName}
                          inputProps={{ maxLength: 15 }}
                        />
                      </Grid>
                      <Grid item sx={{ m: 1 }} md={5} xs={12}>
                        <Controls.Input
                          text="Add New"
                          variant="outlined"
                          color="primary"
                          label="Last Name"
                          name="lastName"
                          value={values.lastName}
                          onChange={handleInputChange}
                          error={errors.lastName}
                          helperText={errors.lastName}
                          inputProps={{ maxLength: 15 }}
                        />
                      </Grid>
                      <Grid item sx={{ m: 1 }} md={5} xs={12}>
                        <Controls.Input
                          text="Add New"
                          variant="outlined"
                          color="primary"
                          label="Email"
                          name="email"
                          value={values.email}
                          onChange={handleInputChange}
                          error={errors.email}
                          helperText={errors.email}
                          inputProps={{ maxLength: 50 }}
                        />
                      </Grid>
                      <Grid item sx={{ m: 1 }} md={5} xs={12}>
                        <FormControl
                          sx={{ marginTop: "10px", width: "100%" }}
                          variant="outlined"
                        >
                          <InputLabel
                            htmlFor="outlined-adornment-password"
                            sx={{ color: "#000", bgcolor: "#fff", lineHeight: "1" }}
                          >
                            Password
                          </InputLabel>
                          <OutlinedInput
                            sx={{
                              "& .MuiInputBase-input": {
                                padding: "10px 14px 11px !important",
                              },

                              "& .MuiOutlinedInput-notchedOutline": {
                                paddingTop: "10px!important",
                                borderColor: "rgba(107, 114, 128, .5)",
                                borderRadius: "7px!important",
                              },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "black",
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "blue",
                              },
                              paddingTop: "0px",
                              color: "#000",
                            }}
                            id="outlined-adornment-password"
                            name="password"
                            value={values.password}
                            onChange={handleInputChange}
                            type={showPassword ? "text" : "password"}
                            inputProps={{ maxLength: 12 }}

                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  sx={{ color: "#000" }}
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          {Boolean(errors.password) ? (
                            <p
                              style={{
                                color: "rgb(255 0 0)",
                                marginTop: "4px",
                                marginBottom: "4px",
                                fontSize: "14px",
                              }}
                            >
                              {errors.password}
                            </p>
                          ) : (
                            ""
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item sx={{ m: 1 }} md={5} xs={12}>
                        <Controls.SelectBox
                          name="classID"
                          label="Class"
                          value={values.classID}
                          onChange={handleInputChange}
                          options={classDropdown}
                          className="input select_box"
                        />
                        {Boolean(errors.classID) ? (
                          <p
                            style={{
                              color: "rgb(255 0 0)",
                              marginTop: "4px",
                              marginBottom: "4px",
                              fontSize: "14px",
                            }}
                          >
                            {errors.classID}
                          </p>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} display='flex' justifyContent='flex-end'>
                    <Controls.Button
                      style={{
                        marginBottom: "10px",
                        backgroundColor: "#e5e7eb",
                        color: "#000",
                        marginRight: "10px",
                      }}
                      text="Cancel"
                      onClick={() => {
                        navigate("/student");
                        resetForm();
                      }}
                    />
                    <Controls.Button
                      style={{ marginBottom: "10px" }}
                      text={isEdit ? 'save' : "Submit"}
                      onClick={handleSubmit}
                      type="submit"
                    />
                  </Grid>
                </Grid>
              </DialogContent>
            </Paper>
          </Form>

        </div>
      </>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </div >
  );
};

export default AddStudent;
