// PptxPreview.js
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import Controls from "@/components/controls/Controls";
import UploadAudio from "../Admin/upload/UploadAudio";
import { pptCountHandler } from "@/state/actions/questionActions";

const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "0",
  outline: "0",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: 3,
};
const PptxPreview = () => {
  const { state } = useLocation();

  const docs = [
    {
      uri: state.ppt,
    },
  ];
  const navigate = useNavigate();
  const pptxFileUrl =
    "https://wordgym.org/emsapi/uploads/question/questionppt_1691488773229.pptx"; // Replace with actual URL
  const [imageToDisplay, setImageToDisplay] = useState(
    "https://wordgym.org/emsapi/uploads/question/questionppt_1691488773229.pptx"
  );
  const [courseId, setCourseId] = useState("");
  const [open, setOpen] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [classID, setClassID] = useState("");

  useEffect(() => {
    if (state && state.ppt) {
      setImageToDisplay(state.ppt);
    }
  }, [state]);

  useEffect(() => {
    if (state && state.courseId) {
      setCourseId(state.courseId);
    }
    if (state && state.sessionId) {
      setSessionId(state.sessionId);
    }
    if (state && state.classID) {
      setClassID(state.classID);
    }
  }, [state]);

  const handelNext = async () => {
    navigate("/questions", {
      state: {
        courseId: courseId,
        sessionId: sessionId,
        classID: classID,
        sameQuestion: state?.sameQuestion && state?.sameQuestion,
      },
    });

    // ppt count Api Call 
    const payload = {
      questionId: localStorage.getItem("pptQuestionId"),
      studentId: localStorage.getItem("studentId")
    };
    const data = await pptCountHandler(payload);
  };

  return (
    <div>
      <div style={{ padding: "10px 3%" }}>
        {
          state.ppt && 
          <DocViewer
            documents={docs}
            pluginRenderers={DocViewerRenderers}
            style={{ height: "550px" }}
          />
        }

        {
          state.audio && <UploadAudio audioFile={state.audio} keyName={`${state.audio}`}/>
        }
        <div style={{ padding: "10px 0", textAlign: "end" }}>
          <Controls.Button
            style={{ backgroundColor: "var(--primary)", color: "#fff" }}
            text="Next"
            onClick={() => handelNext()}
          />
        </div>
      </div>
    </div>
  );
};

export default PptxPreview;
