import { applyMiddleware, compose, createStore } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import reducers from "./reducers/index";
import authReducer from "../state/reducers/authReducer";
import queAnsReducer from "./reducers/queAnsReducer";
import commanReducer from "./reducers/commanReducer";
const initialState = {};
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = configureStore({
  reducer: {
    auth: authReducer,
    questionAnswer: queAnsReducer,
    comman:commanReducer
  },
});
