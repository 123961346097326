import { Box, Stack, Typography, DialogContent, Divider } from '@mui/material'
import Logo from "../../../assets/Images/Logo.png";
import Controls from '../../controls/Controls';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { trailAnsHandler } from '@/state/actions/TrailAction';
import { EventEmitter } from '@/utils/helper';

const Slide1 = ({ handleClose, modalOpen }) => {
  const navigate = useNavigate();

  const isAuthenticated = useSelector((store) => store.auth.isAuthenticated);

  if(!isAuthenticated){
      navigate("/");
  };


  const userAdmin = useSelector((store) => store?.auth?.user);

  console.log("userAdmin",userAdmin);
  
  const agreeHandler = async () =>{
    // Yes => 1 
    console.log("called-->1");
    localStorage.setItem("trailStatus","AGREE");
    EventEmitter.dispatch('trailStatus',true);
    // payload 0 is no 1 is yes 
    const payload = {
      studentId : userAdmin.id,
      trial:1
    }
    // localStorage.removeItem("trailOpen");
    const response = await trailAnsHandler(payload);
    console.log("responssse",response);
    // handleClose();
  };

  const disAgreeHandler = () =>{
    // PENDING MEANS WE WILL ASK AGAIN FOR TRAIL
    localStorage.setItem("trailStatus","PENDING");
    EventEmitter.dispatch('trailStatus',true);
    // handleClose();
  };

  return (
    <>
      <DialogContent>
        <Box height={'200px'}>
          <Stack height={'100%'} justifyContent={'space-between'}>
            <Stack>
              <Typography fontSize={'20px'}>Did you agree to be part of this trial?</Typography>
              <Divider sx={{ borderColor: '#ff751f', borderWidth: '1px', opacity: '1', marginTop: '5px' }} />
            </Stack>
            <Stack flexDirection={'row'} gap={1} justifyContent={'center'} marginTop={3}>
              <Controls.Button
                style={{ backgroundColor: "#ff751f", color: "#fff" }}
                text="Yes"
                onClick={()=>agreeHandler()}
              />
               <Controls.Button
                style={{ backgroundColor: "#ff751f", color: "#fff" }}
                text="No"
                onClick={()=>disAgreeHandler()}
              />
            </Stack>
            <Stack flexDirection={'row'} gap={1} alignItems={'flex-end'}>
              <Divider sx={{ width: '390px', borderColor: '#ff751f', borderWidth: '1px', opacity: '1', marginTop: '5px' }} />
              <img
                src={Logo}
                alt="logo"
                className="w-[60px]"
              />
            </Stack>
          </Stack>
        </Box>
      </DialogContent>
    </>
  )
}

export default Slide1