import { Box, Stack, Typography, DialogContent, Divider } from "@mui/material";
import Logo from "../../../assets/Images/Logo.png";
import Controls from "@/components/controls/Controls";

const Slide3 = ({ handleClose, modalOpen }) => {

  const agreeHandler = () =>{
    localStorage.setItem("trailStatus","TRAILAGREE");
    handleClose();
  };
  return (
    <>
      <DialogContent>
        <Box>
          <Stack gap={3}>
            <Stack flexDirection={"row"} gap={10}>
              <Box
                sx={{
                  width: "300px",
                  padding: "12px",
                  background: "#ff751f",
                  borderRadius: "15px",
                }}
              >
                <Box
                  sx={{
                    width: "320px",
                    padding: "12px",
                    background: "#000",
                    color: "#fff",
                    borderRadius: "10px",
                  }}
                >
                  Each session has three sets of words. You get practice tests
                  on 3 or 4 words. If you make any mistakes, you will get help
                  correcting them.
                </Box>
              </Box>
              <Box>
                <img src={Logo} alt="logo" className="w-[80px]" />
              </Box>
            </Stack>
            <Stack alignItems={"center"}>
              <Box
                sx={{
                  width: "300px",
                  padding: "12px",
                  background: "#000",
                  color: "#fff",
                  borderRadius: "15px",
                }}
              >
                <Box
                  sx={{
                    width: "300px",
                    padding: "12px",
                    background: "#ff751f",
                    borderRadius: "10px",
                    position: "relative",
                    right: "30px",
                  }}
                >
                  The only score that the computer keeps is the score for the
                  final test.
                </Box>
              </Box>
            </Stack>
            <Stack
              flexDirection={"row"}
              gap={1}
              justifyContent={"end"}
              marginTop={3}
            >
              <Controls.Button
                style={{ backgroundColor: "#ff751f", color: "#fff" }}
                text="Done"
                onClick={() => agreeHandler()}
              />
            </Stack>
          </Stack>
        </Box>
      </DialogContent>
    </>
  );
};

export default Slide3;
