import Typography from "@mui/material/Typography";
import { Box, Card, CardContent, Grid } from "@mui/material";

function DataDisplay({ title, data }) {
  return (
    <Grid item xs={4}>
      <Card>
        <CardContent>
          <Typography sx={{ fontSize: 16, color: "black" }} gutterBottom>
            {title}
          </Typography>

          <Typography
            sx={{
              fontSize: 18,
              mb: 1.5,
              color: "black",
              fontWeight: "600",
            }}
          >
            {data}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default DataDisplay;
