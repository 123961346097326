import React, { useEffect, useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { RighWrongAnsHandler } from "@/state/actions/Admin/resultAction";
import ThirdBox from "../Questions/ThirdBox";
import { GiCheckMark } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import DndBox from "./DndBox";

const rows = [
  {
    studentAns: "1",
    question: "1",
    answer: "1",
  },
  {
    studentAns: "1",
    question: "1",
    answer: "1",
  },
  {
    studentAns: "1",
    question: "1",
    answer: "1",
  },
];

function StudentRightAns() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [qType, setQType] = useState();
  const [studentimage, setStudentImage] = useState("");
  const [correctImage, setCorrectImage] = useState("");
  const [studentData, setStudentData] = useState([]);
  const [correctData, setCorrectData] = useState([]);
  const [totaQuestion, setTotalQuestion] = useState("");
  const [gainMarks, setGainMarks] = useState("");
  const [qData, setQdata] = useState([]);
  const [id, setId] = useState();

  useEffect(() => {
    if (state && state.id) {
      QueAnsHandler(state.id);
      setId(state.id);
    }
  }, []);

  const studentDatahandler = (data) => {
    const newQuestions = data.map((res) => ({
      correctAnswer: "",
      questionID: res.questionID,
      optionID: res.id,
      studentAns: res.studentAns,
      Uid: res.Uid,
      top: res.top,
      left: res.left,
    }));
    setStudentData(newQuestions);
  };

  const correctDataHandler = (data) => {
    const newQuestions = data.map((res) => ({
      correctAnswer: res.correctAns,
      questionID: res.questionID,
      optionID: res.id,
      studentAns: "",
      Uid: res.Uid,
      top: res.top,
      left: res.left,
    }));
    setCorrectData(newQuestions);
  };

  const QueAnsHandler = async (id) => {
    const data = await RighWrongAnsHandler(id);
    setQType(data.questionType);
    if (data.questionType == "3") {
      setStudentImage(data.image);
      setCorrectImage(data.image);
      correctDataHandler(data.data);
      studentDatahandler(data.data);
    } else {
      setQdata(data.data);
      setTotalQuestion(data.totalMark);
      setGainMarks(data.gainMarks);
    }
  };

  return (
    <div style={{ padding: "50px 3%" }} className="min-h-[calc(100vh-200px)]">
      {qType && (qType == "1" || qType == "2") && (
        <>
          <div className="table-responsive">
            <table className="table table-bordered result_table" border={2}>
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Question</th>
                  <th scope="col">Correct Answer</th>
                  <th scope="col">Your Answer</th>
                </tr>
              </thead>
              <tbody>
                {qData.map((row, i) => (
                  <tr key={row.question}>
                    <td style={{ width: "50px" }} scope="row">
                      {i + 1}
                    </td>
                    <td
                    //   className={
                    //   row.studentAns === row.correctAns
                    //     ? "!bg-[#53B9AB]"
                    //     : "!bg-[#EC8E7B]"
                    // }
                    >
                      {row.optionData}
                    </td>

                    {
                      <td
                        style={{ width: "300px" }}
                        className={
                          row.studentAns === row.correctAns
                            ? "bg-[#53B9AB]"
                            : "bg-[#EC8E7B]"
                        }
                      >
                        {qType === 1
                          ? row.correctAns === "1"
                            ? "true"
                            : "false"
                          : row.correctAns}
                      </td>
                    }
                    <td
                      style={{ width: "300px" }}
                      className={
                        row.studentAns === row.correctAns
                          ? "!bg-[#53B9AB]"
                          : "!bg-[#EC8E7B]"
                      }
                    >
                      {qType === 1 ? (
                        row.studentAns === row.correctAns ? (
                          <div>
                            {row.correctAns === "1" ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                true
                                <GiCheckMark
                                  style={{
                                    background: "#fff",
                                    color: "#53B9AB",
                                    padding: "5px",
                                    fontSize: "35px",
                                  }}
                                />
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                false
                                <GiCheckMark
                                  style={{
                                    background: "#fff",
                                    color: "#53B9AB",
                                    padding: "5px",
                                    fontSize: "35px",
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>
                            {row.studentAns === "1" ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                true{" "}
                                <AiOutlineClose
                                  style={{
                                    background: "#fff",
                                    color: "#EC8E7B",
                                    padding: "5px",
                                    fontSize: "35px",
                                  }}
                                />
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                false{" "}
                                <AiOutlineClose
                                  style={{
                                    background: "#fff",
                                    color: "#EC8E7B",
                                    padding: "5px",
                                    fontSize: "35px",
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        )
                      ) : row.studentAns === row.correctAns ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {row.studentAns}{" "}
                          <GiCheckMark
                            style={{
                              background: "#fff",
                              color: "#53B9AB",
                              padding: "5px",
                              fontSize: "35px",
                            }}
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {row.studentAns}{" "}
                          <AiOutlineClose
                            style={{
                              background: "#fff",
                              color: "#EC8E7B !important",
                              padding: "5px",
                              fontSize: "35px",
                            }}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={3} style={{ textAlign: "end" }}>
                    Total
                  </td>
                  <td
                    style={{ textAlign: "center" }}
                  >{`${gainMarks} / ${totaQuestion}`}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}

      {qType && qType == "3" && (
        <div className="w-full flex flex-col justify-center items-start gap-5 overflow-x-auto student-row-wise">
          <Box sx={{ overflow: "auto", width: "500px" }}>
            <Typography
              fontSize={"20px"}
              fontWeight={700}
              marginBottom={2}
              borderBottom={2}
            >
              Student Answer{" "}
            </Typography>
            <Box
              // sx={{
              //   display: "flex",
              //   justifyContent: "center",
              //   position: "relative",
              // }}
              ref={null}
              // height={"500px"}
            >
              <div className="flex justify-center relative items-start overflow-auto">
                <Grid item>
                  <img className="image_question" src={studentimage} />
                </Grid>
                {studentData?.map((res) => {
                  return (
                    <div className="w-[150px] absolute top-0 right-0">
                      <DndBox
                        key={res}
                        id={res}
                        value={res.correctAnswer}
                        {...studentData[res]}
                        onlyView={false}
                      />
                    </div>
                  );
                })}
              </div>
            </Box>
          </Box>
          <Box sx={{ overflow: "auto", width: "500px" }}>
            <Typography
              fontSize={"20px"}
              fontWeight={700}
              marginBottom={2}
              borderBottom={2}
            >
              {" "}
              Correct Answer{" "}
            </Typography>
            <Box
              // sx={{
              //   display: "flex",
              //   justifyContent: "center",
              //   position: "relative",
              // }}
              ref={null}
              // height={"500px"}
            >
              <div className="flex justify-center relative items-start overflow-auto">
                <img className="image_question" src={correctImage} />
                {correctData?.map((res) => {
                  return (
                    <div className="w-[150px] absolute top-0 right-0">
                      <DndBox
                        key={res}
                        id={res}
                        value={res.correctAnswer}
                        {...correctData[res]}
                        onlyView={true}
                      />
                    </div>
                  );
                })}
              </div>
            </Box>
          </Box>
        </div>
      )}

      <div style={{ textAlign: "end" }}>
        <button
          style={{
            textAlign: "center",
            margin: "10px 0",
            padding: "5px 20px",
            backgroundColor: "rgb(255,117,31)",
            color: "white",
            borderRadius: "4px",
          }}
          onClick={() => {
            navigate(`/finalResult`, {
              state: {
                id: id,
              },
            });
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default StudentRightAns;
