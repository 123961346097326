import React, { useEffect, useState } from "react";
import bottomRight from "../../assets/bg/bottomRight.png";
import topLeft from "../../assets/bg/topLeft.png";
import topRight from "../../assets/bg/topRight.png";
import bottomLeft from "../../assets/bg/bottomLeft.png";
import CenterLogo from "../../assets/Images/centerLogo.png";
import { getStudentCourse } from "@/state/actions/courseActions";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { logoutApi } from "@/state/actions/authActions";
import { Box } from "@mui/material";

// const courseList = [
//   {
//     name: "Structure and bonding",
//   },
//   {
//     name: "Chemical calculations",
//   },
//   {
//     name: "Reactions of metals",
//   },
//   {
//     name: "Reactions of acids",
//   },
//   {
//     name: "Electrolysis",
//   },
//   {
//     name: "Energy changes",
//   },
// ];

const NewHome = () => {
  const [courseList, setCourseList] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [studentCourse, setStudentCourse] = useState([]);
  const [studentDetails, setStudentDetails] = useState({});
  const [studentFlag, setStudentFlag] = useState(false);

  const userAdmin = useSelector((store) => store?.auth?.user);

  useEffect(() => {
    console.log("userAdmin", userAdmin);
    setStudentDetails(userAdmin);
    localStorage.setItem("studentId", userAdmin?.id);
    if (userAdmin && userAdmin.roleID === 2) {
      setStudentDetails(userAdmin);
      setStudentFlag(true);
      localStorage.setItem("studentId", userAdmin?.id);
      getDetails(userAdmin?.id);
    }
  }, [userAdmin]);

  const logoutStudent = () => {
    dispatch(logoutApi());
    localStorage.removeItem("studentId");
    setStudentFlag(false);
    setStudentDetails({});
    setCourseList([]);
    localStorage.removeItem("trailOpen");
    localStorage.removeItem("trailStatus");
    localStorage.removeItem("authUser");
    navigate("/login");
    setShow(false);
  };

  const getDetails = async (studentID) => {
    const sID = studentID || userAdmin?.id || localStorage.getItem("studentId");
    const res = await getStudentCourse(sID);
    console.log("Response", res);
    if (res && res.status === true) {
      setStudentCourse(res?.data);
      setCourseList(res.data);
    } else {
      setStudentCourse([]);
      setCourseList([]);
    }
  };

  const getRandombgColorClass = () => {
    const bgColors = ["bg-[#32AD95]", "bg-[#DF1E50]", "bg-[#F5B816]"];
    const randomIndexbg = Math.floor(Math.random() * bgColors.length);
    return bgColors[randomIndexbg];
  };

  return (
    <div className="relative h-screen w-full ">
      <div className="absolute top-0 left-0 z-10">
        <img
          src={topLeft}
          alt=""
          className="w-[80px] sm:w-[110px] md:w-[140px] lg:w-[180px]"
        />
      </div>
      <div className="absolute top-0 right-10 z-10">
        <img
          src={topRight}
          alt=""
          className="w-[50px] sm:w-[80px] md:w-[120px] lg:w-[150px]"
        />
      </div>
      <div className="absolute bottom-0 left-4 z-10">
        <img
          src={bottomLeft}
          alt=""
          className="w-[50px] sm:w-[80px] md:w-[120px] lg:w-[150px]"
        />
      </div>
      <div className="absolute bottom-0 right-0 z-10">
        <img
          src={bottomRight}
          alt=""
          className="w-[80px] sm:w-[110px] md:w-[140px] lg:w-[180px]"
        />
      </div>
      <div className="relative z-50">
        {studentDetails && studentDetails.id ? (
          <div className="absolute top-3 right-3">
            <div
              className="bg-[--primary] text-white rounded-full sm:!px-7 px-4 sm:!py-4 py-2 sm:text-xl cursor-pointer"
              onClick={() => setShow(true)}
            >
              logout
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="flex justify-between items-center h-screen flex-col p-3 sm:!p-5">
          <div>
            <img
              src={CenterLogo}
              alt="Logo"
              className="w-[130px] sm:w-[180px] md:w-[240px] lg:w-[300px]"
            />
          </div>
          <div className="flex lg:!gap-3 sm:!gap-2 gap-1 flex-wrap max-w-[1200px] items-center justify-center">
            {!studentFlag && courseList.length === 0 ? (
              <div className="">
                <div
                  className="bg-[#32AD95] text-white rounded-3xl sm:!px-8 px-4 sm:!py-4 py-2 sm:text-[26px] cursor-pointer"
                  onClick={() => navigate(`/login`)}
                >
                  login
                </div>
              </div>
            ) : (
              courseList.map((data, i) => {
                //   const {name} = data;
                const bgcolorClass = getRandombgColorClass();
                return (
                  <div
                    key={i}
                    className={`text-white ${bgcolorClass} lg:!py-5 lg:!px-8 sm:!py-4 sm:!px-6 px-4 py-2 lg:text-2xl md:text-xl sm:text-lg text-base font-bold rounded-full flex justify-center items-center text-center cursor-pointer text-wrap`}
                    onClick={() =>
                      navigate(`/courses`, {
                        state: {
                          data: data,
                          courseId: data.id,
                          classId: data.classID,
                        },
                      })
                    }
                  >
                    <div className={``}>
                      {data.courseName ? data.courseName : "-"}
                    </div>
                  </div>
                );
              })
            )}
          </div>
          <div className="flex md:flex-row md:justify-around flex-col  w-full gap-3 justify-center mt-2">
            <a href="https://nettyfy.com/" target="_blank">
              <div className="bg-[#ff751f] sm:!px-3 px-2 sm:!py-2 py-1 rounded-lg h-fit">
                <span className="text-white text-xs sm:text-sm md:text-base">
                  Developed By Nettyfy Technologies
                </span>
              </div>
            </a>
            <div className="bg-[#DF1E50] sm:!px-3 px-2 sm:!py-2 py-1 rounded-lg h-fit">
              <a
                href="mailto:naomi.wineman.18@ucl.ac.uk"
                className="text-white text-xs sm:text-sm md:text-base"
              >
                naomi.wineman.18@ucl.ac.uk
              </a>
            </div>
            <div className="bg-[#ff751f] sm:!px-3 px-2 sm:!py-2 py-1 rounded-lg h-fit">
              <span className="text-white text-xs sm:text-sm md:text-base">
                Voiced by Shimron Palmer
              </span>
            </div>
          </div>
        </div>
      </div>
      <Modal
        size="md"
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby=""
        centered
      >
        <Modal.Header className="border-0 pb-0 font-semib-24" closeButton>
          Logout
        </Modal.Header>
        <Modal.Body>
          <p className="mb-0 font-semib-25 font-primary-light fw-medium">
            Are you sure you want to Logout?
          </p>
        </Modal.Body>
        <Modal.Footer className="border-0 pt-0">
          <Button
            className="bg-[--primaryLight] fw-medium m-2 text-[--primary] border-[--primary] hover:bg-[--primary] hover:border-none"
            onClick={() => setShow(false)}
          >
            Cancel
          </Button>
          <Button
            className="fw-medium m-2 bg-[--primaryLight] text-[--primary] border-[--primary] hover:bg-[--primary] hover:border-none"
            onClick={() => {
              logoutStudent();
            }}
          >
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default NewHome;
