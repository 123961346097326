import React, { useState } from "react";
import Logo from "../../assets/Images/Logo.png";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../state/store";
import { logoutApi } from "../../state/actions/authActions";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./Layout.css"
import { HiOutlineMenu } from 'react-icons/hi';
import { IoClose } from 'react-icons/io5';
import Offcanvas from 'react-bootstrap/Offcanvas';
import CommonModal from "../common/Modal";
const Header = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const isAuthenticated = useSelector((store) => store.auth.isAuthenticated);
  const isAuthenticatedUser = useSelector((store) => store.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname.substr(1);
  const [activeSpan, setActiveSpan] = useState(pathname);

  const handleSpanClick = (span) => {
    setActiveSpan(span);
    navigate(`/${span}`);
    // Perform any additional logic or navigation here
  };

  const logoutStudent = async () => {
    dispatch(logoutApi());
    localStorage.removeItem("trailOpen");
    localStorage.removeItem("trailStatus");
    localStorage.removeItem("authUser");
    navigate("/");
    setShow(false)
  };

  const [showDrawer, setShowDrawer] = useState(false);

  const handleClose = () => setShowDrawer(false);
  const handleShow = () => setShowDrawer(true);
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
  });
  return (
    <div className="flex bg-[--primaryLight] px-[86px] py-2 max-[1200px]:px-[65px] max-[800px]:px-5">
      <div className="w-[15%] max-[1380px]:w-[30%] max-[991px]:w-[45%]">
        <img
          src={Logo}
          onClick={() => handleSpanClick("home")}
          alt="logo"
          className="h-[92px] w-[139px] max-[992px]:h-auto max-[992px]:w-[80px] max-[400px]:w-[60px]"
        />
      </div>
      <div className="flex items-center justify-end font-medium text-[16px] font-nunito w-[85%]  max-[992px]:hidden">
        <span
          className={`${activeSpan === "home"
            ? "bg-[--primary] text-white border rounded-full "
            : ""
            } px-6 py-2 cursor-pointer`}
          onClick={() => handleSpanClick("home")}
        >
          Home
        </span>
        {Boolean(isAuthenticated) ? (
          <span
            className={`${activeSpan === "courses"
              ? "bg-[--primary] text-white border rounded-full "
              : ""
              } px-6 py-2 cursor-pointer`}
            onClick={() => handleSpanClick("courses")}
          >
            Courses
          </span>
        ) : (
          ""
        )}
        <span
          className={`${activeSpan === "about-me"
            ? "bg-[--primary] text-white border rounded-full "
            : ""
            } px-6 py-2 cursor-pointer`}
            onClick={() => handleSpanClick("about-me")}
          // onClick={() =>
          //   // setIsModalOpen({
          //   //   open: true,
          //   //   currentComponent: "slide1",
          //   // })
          // }
        >
          About Me
        </span>
        <CommonModal
          modalOpen={isModalOpen}
          handleClose={() =>
            setIsModalOpen({ open: false, currentComponent: "" })
          } />
        {
          Boolean(isAuthenticated) &&
          <span className="px-6 py-2 cursor-pointer">welcome, {isAuthenticatedUser.user.firstName || isAuthenticatedUser.user.name}</span>
        }

        {Boolean(isAuthenticated) ? (
          <span
            onClick={() => setShow(true)}
            className="border-[--primary] text-[--primary] border-2 rounded-full px-6 py-2 cursor-pointer"
          >
            Log out
          </span>
        ) : (
          <span
            className="border-[--primary] text-[--primary] border-2 rounded-full px-6 py-2 cursor-pointer"
            onClick={() => navigate("/login")}
          >
            Log In
          </span>
        )}

      </div>
      <div className="hidden max-[992px]:block w-[70%]">
        <div className="flex items-center justify-end h-full">
          <HiOutlineMenu className="text-4xl cursor-pointer max-[600px]:text-3xl" onClick={handleShow} />
          <Offcanvas show={showDrawer} onHide={handleClose} placement="end" className='w_250'>
            <Offcanvas.Header>
              <Offcanvas.Title className="text-end w-full">
                <IoClose className="text-4xl text-end cursor-pointer max-[600px]:text-3xl" onClick={handleClose} />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="flex items-start justify-end font-medium text-[16px] font-nunito flex-col gap-2">
                <span
                  className={`${location.pathname === "/"
                    ? "bg-[--primary] text-white border w-full"
                    : " w-full"
                    } p-2 rounded text-center cursor-pointer`}
                  onClick={() => [handleSpanClick("home"), setShowDrawer(false)]}
                >
                  Home
                </span>
                {Boolean(isAuthenticated) ? (
                  <span
                    className={`${location.pathname === "/courses"
                      ? "bg-[--primary] text-white border w-full"
                      : " w-full"
                      } p-2 rounded text-center cursor-pointer`}
                    onClick={() => [handleSpanClick("courses"), setShowDrawer(false)]}
                  >
                    Courses
                  </span>
                ) : (
                  ""
                )}
                <span
                  className={`${location.pathname === "/about-me"
                    ? "bg-[--primary] text-white border w-full"
                    : " w-full"
                    } p-2 rounded text-center cursor-pointer`}
                  onClick={() => [handleSpanClick("about-me"), setShowDrawer(false)]}
                >
                  About Me
                </span>

                {Boolean(isAuthenticated) ? (
                  <span
                    onClick={() => [setShow(true), setShowDrawer(false)]}
                    className="border-[--primary] text-[--primary] border-2 p-2 w-full rounded text-center cursor-pointer"
                  >
                    Log out
                  </span>
                ) : (
                  <span
                    className="border-[--primary] text-[--primary] border-2 p-2 w-full rounded text-center cursor-pointer"
                    onClick={() => [navigate("/login"), setShowDrawer(false)]}
                  >
                    Log In
                  </span>
                )}

              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      </div>

      <Modal
        size="md"
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby=""
        centered
      >
        <Modal.Header className="border-0 pb-0 font-semib-24" closeButton>
          Logout
        </Modal.Header>
        <Modal.Body>
          <p className="mb-0 font-semib-25 font-primary-light fw-medium">
            Are you sure you want to Logout?
          </p>
        </Modal.Body>
        <Modal.Footer className="border-0 pt-0">
          <Button
            className="bg-[--primaryLight] fw-medium m-2 text-[--primary] border-[--primary] hover:bg-[--primary] hover:border-none"
            onClick={() => setShow(false)}
          >
            Cancel
          </Button>
          <Button
            className="fw-medium m-2 bg-[--primaryLight] text-[--primary] border-[--primary] hover:bg-[--primary] hover:border-none"
            onClick={() => {
              logoutStudent();
            }}
          >
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Header;
