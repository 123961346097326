import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  tableCellClasses,
} from "@mui/material";
import { RiDeleteBinLine, RiEditLine } from "react-icons/ri";
import Typography from "@mui/material/Typography";
import { Space } from "antd";
import AddIcon from "@mui/icons-material/Add";
import Radio from "@mui/material/Radio";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import RadioGroup from "@mui/material/RadioGroup";
import ImageQuestion from "../ImageQuestion";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { styled } from "@mui/material/styles";
import Controls from "@/components/controls/Controls";
import camera from "../../../../assets/camera.svg";

function FinalTest(props) {
  const Answer = [
    { id: 1, name: "True" },
    { id: 2, name: "False" },
  ];
  const [finaTestData, setFinalTestData] = useState([]);
  const [trueFalseData, setTrueFalseData] = useState([]);
  const [thirdQuestionEdit, setThirdQuestionEdit] = useState([]);
  const [dragDropData, setDragDropData] = useState([]);
  const textFields = useRef([]);
  const [focusedIndex, setFocusedIndex] = useState();
  const [ddFocusedIndex, setFocusIndex] = useState();
  const [ddFocuesAns, setDdFocusesAns] = useState();

  // below useState and Funcation is  For True False
  const [trueFalseCursor, setTrueFalseCursor] = useState({});

  const handleTrueFalseCursor = (index, position) => {
    setTrueFalseCursor((prevState) => ({
      ...prevState,
      [index]: position,
    }));
  };

  // below useState and Funcation is  For Drag and Drop

  const [dragDropCursor, setDragDropCursor] = useState({});

  const handleDDCursor = (index, position) => {
    setDragDropCursor((prevState) => ({
      ...prevState,
      [index]: position,
    }));
  };

  // below useState and Funcation is  For Drag and Drop (Answer)

  const [dragDropACursor, setDragDropACursor] = useState({});

  const handleDDACursor = (index, position) => {
    setDragDropACursor((prevState) => ({
      ...prevState,
      [index]: position,
    }));
  };

  const handleFocusOut = () => {
    const lastFieldIndex = textFields.current.length - 1;
    setFocusedIndex(lastFieldIndex);
  };

  const handleDragFocusOut = () => {
    const lastFieldIndex = textFields.current.length - 1;
    setFocusedIndex(lastFieldIndex);
  };

  const handleAnsFocusOut = () => {
    const lastFieldIndex = textFields.current.length - 1;
    setDdFocusesAns(lastFieldIndex);
  };

  const resetImage = (index) => {
    const rowsData = [...finaTestData];
    rowsData[0]["image"] = "";
    rowsData[0]["imageApi"] = "";
    setFinalTestData(rowsData);
  };

  const removeImage = (index) => {
    resetImage(index);
  };

  const thirdQuestionHandler = (data) => {
    props.data(finaTestData);
  };

  const thirIndexHandler = (slideIndex, data) => {
    finaTestData[0]["questionSet"] = data;
    props.data(finaTestData);
  };

  const StyledRemoveButton = styled(Box)({
    color: "red",
    display: "inline",
    "&:hover": {
      cursor: "pointer",
    },
  });

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: "#000",
      fontSize: 13,
      border: 0,
      fontWeight: 600,
      paddingTop: "15px",
      paddingBottom: "15px",
      paddingLeft: "25px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 13,
      color: "rgb(17, 24, 39)",
      border: 0,
      paddingLeft: "25px",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
  }));

  const StyledInput = styled("input")({
    display: "none",
  });

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&": {
      backgroundColor: "#fff",
      ":hover": { backgroundColor: "rgb(248 248 248)!important" },

      "&:nth-of-type(odd)": {
        backgroundColor: "rgb(250 250 250)!important",
      },
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const StyledImageUploadWrapper = styled(Box)(() => ({
    marginTop: "16px",
    border: "1px solid rgba(15, 15, 15, 0.15)",
    height: "206px",
    borderRadius: "10px",
    textAlign: "center",
  }));

  const StyledIconWrapper = styled(Box)(() => ({
    margin: "20px auto",
    border: "1px solid rgba(15, 15, 15, 0.15)",
    width: "80px",
    height: "80px",
    borderRadius: "10px",
    backgroundColor: "rgba(15, 15, 15, 0.05)",
    img: {
      marginTop: "30px",
    },
  }));

  async function handleImageItem(index, e) {
    if (validateImageSize(e.target.files[0])) {
      const rowsData = [...finaTestData];
      const selected = e.target.files[0];
      const ALLOWED_TYPES = ["image/png", "image/jpg", "image/jpeg"];
      if (selected && ALLOWED_TYPES.includes(selected.type)) {
        const base64String = await getBase64(selected);
        rowsData[index]["image"] = base64String;
        rowsData[index]["imageApi"] = base64String;
        props.data(rowsData);
        setFinalTestData(rowsData);
      } else {
      }
    } else {
      // need to clear slie wise image
    }
  }

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const maxSize = 2048;

  const validateImageSize = (vehicleIcons) => {
    if (vehicleIcons) {
      const fsize = vehicleIcons.size / 1024;
      if (fsize > maxSize) {
        return false;
      } else {
        return true;
      }
    }
  };

  const deleteFinalHandler = () => {
    props.data([]);
    setFinalTestData([]);
    setTrueFalseData([]);
    setDragDropData([]);
  };

  const handleChanges = (index, e) => {
    const { name, value } = e.target;
    const rowsInput = [...trueFalseData];
    rowsInput[index][name] = value;
    setFocusedIndex(index);
    setTrueFalseData(rowsInput);
    finaTestData[0]["questionSet"] = rowsInput;
    props.data(finaTestData);
  };

  const addTableRows = (index) => {
    const typeOneData = {
      questionText: "",
      correctAnswer: "",
    };
    setTrueFalseData([...trueFalseData, typeOneData]);
    finaTestData[0]["questionSet"] = [...trueFalseData, typeOneData];
  };

  const addTableRowsTerms = (index, option) => {
    const rowsInput = {
      questionText: "",
      correctAnswer: "",
    };
    setDragDropData([...dragDropData, rowsInput]);
    finaTestData[0]["questionSet"] = [...dragDropData, rowsInput];
  };

  const handleChangesTerms = (index, e) => {
    const { name, value } = e.target;
    if (name == "questionText") {
      setFocusIndex(index);
      setDdFocusesAns(null);
    } else {
      setFocusIndex(null);
      setDdFocusesAns(index);
    }
    const rowsInput = [...dragDropData];
    rowsInput[index][name] = value;
    setDragDropData(rowsInput);
    finaTestData[0]["questionSet"] = rowsInput;
    props.data(finaTestData);
  };

  const deleteTableRowsTerms = (index) => {
    const rows = [...dragDropData];
    rows.splice(index, 1);
    finaTestData[0]["questionSet"] = rows;
    setDragDropData(rows);
  };

  const deleteTableRows = (index) => {
    const rows = [...trueFalseData];
    rows.splice(index, 1);
    finaTestData[0]["questionSet"] = rows;
    setTrueFalseData(rows);
  };

  const fTHandleClick = () => {
    if (finaTestData.length <= 1) {
      const FinalSlides = {
        questionType: "",
        questionSet: [],
        image: "",
        imageApi: "",
      };
      setFinalTestData([FinalSlides]);
    }
  };

  const QuestionTypeHandler = (index, value) => {
    const totalData = [...finaTestData];
    totalData[index]["questionType"] = value;
    setFinalTestData(totalData);
    finaTestData[0]["questionSet"] = [];
    setTrueFalseData([]);
    setDragDropData([]);
    finaTestData[0]["image"] = "";
    finaTestData[0]["imageApi"] = "";
  };

  return (
    <Box
      className="b1"
      sx={{
        backgroundColor: "white",
        marginTop: "20px",
        padding: "20px !important",
      }}
    >
      <Grid container justifyContent={"space-between"} alignItems={"center"}>
        <Grid item>
          <Typography variant="h6">Add Final Test</Typography>
        </Grid>
        {finaTestData.length == 0 && (
          <Grid item sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              size="large"
              style={{
                backgroundColor: "blue",
                borderRadius: "10px",
                width: "0%",
              }}
              startIcon={<AddIcon sx={{ marginLeft: "9px" }} />}
              fullWidth
              onClick={fTHandleClick}
            />
          </Grid>
        )}
      </Grid>
      {finaTestData?.map((res, index) => {
        return (
          <Box key={`finaTestData_${index}`}>
            <Box
              key={`slides1 ${index}`}
              sx={{
                padding: "10px !important",
                border: "1px solid #8d8c8c",
                marginTop: "10px",
                borderRadius: "10px",
              }}
            >
              <Grid container justifyContent={"space-between"}>
                <Grid item sx={{ m: 1 }} md={5.9} xs={12}>
                  <Box display="flex" alignItems="center">
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Question Type
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          value="1"
                          control={
                            <Radio
                              onChange={(e) =>
                                QuestionTypeHandler(index, e.target.value)
                              }
                            />
                          }
                          label="True / False"
                        />
                        <FormControlLabel
                          value="2"
                          control={
                            <Radio
                              onChange={(e) =>
                                QuestionTypeHandler(index, e.target.value)
                              }
                            />
                          }
                          label="Drag & Drop"
                        />
                        <FormControlLabel
                          value="3"
                          control={
                            <Radio
                              onChange={(e) =>
                                QuestionTypeHandler(index, e.target.value)
                              }
                            />
                          }
                          label="Image"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      margin: "10px 0 15px",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => deleteFinalHandler()}
                    >
                      delete
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />

              {res.questionType == "1" && (
                <Grid container>
                  <Grid item sx={{ m: 1 }} md={12} xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "400",
                        marginLeft: "28px",
                        fontSize: "20px",
                      }}
                    >
                      Question
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    md={12}
                    xs={12}
                    sx={{ m: 1, overflowY: "auto", maxHeight: "300px" }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "rgb(241, 245, 249)",
                        width: "98%",
                        margin: "auto",
                      }}
                    >
                      <Box
                        sx={{
                          border: 1,
                          borderColor: "#eaeaea",
                          marginBottom: "100px",
                        }}
                      >
                        <Table className="table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Question Text</StyledTableCell>
                              <StyledTableCell>Correct Answer</StyledTableCell>
                              <StyledTableCell sx={{ width: "10px" }}>
                                <Button
                                  variant="contained"
                                  size="large"
                                  style={{
                                    backgroundColor: "blue",
                                    borderRadius: "10px",
                                    width: "0%",
                                  }}
                                  startIcon={
                                    <AddIcon sx={{ marginLeft: "9px" }} />
                                  }
                                  fullWidth
                                  onClick={() => addTableRows(index)}
                                />
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {trueFalseData.map((data, index) => {
                              const { questionText, correctAnswer } = data;
                              return (
                                <StyledTableRow
                                  key={`trueFalse${index}`}
                                  sx={{ cursor: "pointer" }}
                                >
                                  <StyledTableCell component="th" scope="row">
                                    <TextField
                                      key={`trueFalse${index + 1}`}
                                      text="Add New"
                                      autoFocus={focusedIndex == index}
                                      index={index}
                                      variant="outlined"
                                      color="primary"
                                      fullWidth
                                      label="Question Text"
                                      // onBlur={() => handleFocusOut(index)}
                                      name="questionText"
                                      value={questionText}
                                      inputRef={(input) => {
                                        if (
                                          input &&
                                          trueFalseCursor[index] !== undefined
                                        ) {
                                          input.selectionStart =
                                            trueFalseCursor[index];
                                          input.selectionEnd =
                                            trueFalseCursor[index];
                                        }
                                      }}
                                      onChange={(e) => {
                                        handleChanges(index, e);
                                        handleTrueFalseCursor(
                                          index,
                                          e.target.selectionStart
                                        );
                                      }}
                                      inputProps={{
                                        maxLength: 255,
                                      }}
                                    />
                                  </StyledTableCell>

                                  <StyledTableCell component="th" scope="row">
                                    <Controls.SelectBox
                                      className="input"
                                      label="Select Answer"
                                      fullWidth
                                      id="correctAnswer"
                                      name="correctAnswer"
                                      value={correctAnswer}
                                      onChange={(e) => {
                                        handleChanges(index, e);
                                      }}
                                      options={Answer}
                                    />
                                  </StyledTableCell>

                                  <StyledTableCell
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <Space size="middle">
                                      <Tooltip
                                        placement="bottom"
                                        title="Delete "
                                      >
                                        <button
                                          className="action-button delete-btn"
                                          onClick={() => deleteTableRows(index)}
                                          style={{ marginLeft: "15px" }}
                                        >
                                          <RiDeleteBinLine />
                                        </button>
                                      </Tooltip>
                                    </Space>
                                  </StyledTableCell>
                                  {/* <Divider
                                    sx={{ bgcolor: "rgba(107, 114, 128, .4)" }}
                                  /> */}
                                </StyledTableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              )}
              <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />

              {res.questionType == "2" && (
                <Grid container>
                  <Grid item sx={{ m: 1 }} md={12} xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "400",
                        marginLeft: "28px",
                        fontSize: "20px",
                      }}
                    >
                      Question
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    sx={{ m: 1, overflowY: "auto", maxHeight: "300px" }}
                    md={12}
                    xs={12}
                  >
                    <Box
                      sx={{
                        backgroundColor: "rgb(241, 245, 249)",
                        width: "98%",
                        margin: "auto",
                      }}
                    >
                      <Box
                        sx={{
                          border: 1,
                          borderColor: "#eaeaea",
                          marginBottom: "100px",
                        }}
                      >
                        <Table className="table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Question Text</StyledTableCell>
                              <StyledTableCell>Correct Answer</StyledTableCell>
                              <StyledTableCell sx={{ width: "10px" }}>
                                <Button
                                  variant="contained"
                                  size="large"
                                  style={{
                                    backgroundColor: "blue",
                                    borderRadius: "10px",
                                    width: "0%",
                                  }}
                                  startIcon={
                                    <AddIcon sx={{ marginLeft: "9px" }} />
                                  }
                                  fullWidth
                                  onClick={() => addTableRowsTerms(index)}
                                />
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {/* rowsTermsData */}
                            {res.questionSet?.map((data, index) => {
                              const { questionText, correctAnswer } = data;

                              return (
                                <StyledTableRow
                                  key={`DragDrop${index}`}
                                  sx={{ cursor: "pointer" }}
                                >
                                  <StyledTableCell component="th" scope="row">
                                    <TextField
                                      text="Add New"
                                      variant="outlined"
                                      color="primary"
                                      key={`DragDrop${index}`}
                                      autoFocus={ddFocusedIndex == index}
                                      label="Question Text"
                                      fullWidth
                                      // onBlur={() => handleDragFocusOut(index)}
                                      value={questionText}
                                      inputRef={(input) => {
                                        if (
                                          input &&
                                          dragDropCursor[index] !== undefined
                                        ) {
                                          input.selectionStart =
                                            dragDropCursor[index];
                                          input.selectionEnd =
                                            dragDropCursor[index];
                                        }
                                      }}
                                      onChange={(e) => {
                                        handleChangesTerms(index, e);
                                        handleDDCursor(
                                          index,
                                          e.target.selectionStart
                                        );
                                      }}
                                      name="questionText"
                                      inputProps={{
                                        maxLength: 255,
                                      }}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell component="th" scope="row">
                                    <TextField
                                      text="Add New"
                                      variant="outlined"
                                      color="primary"
                                      label="correct Answer"
                                      fullWidth
                                      inputRef={(input) => {
                                        if (
                                          input &&
                                          dragDropACursor[index] !== undefined
                                        ) {
                                          input.selectionStart =
                                            dragDropACursor[index];
                                          input.selectionEnd =
                                            dragDropACursor[index];
                                        }
                                      }}
                                      value={correctAnswer}
                                      // onBlur={() => handleAnsFocusOut(index)}
                                      autoFocus={ddFocuesAns == index}
                                      onChange={(e) => {
                                        handleChangesTerms(index, e);
                                        // handleInputChange(e);
                                        handleDDACursor(
                                          index,
                                          e.target.selectionStart
                                        );
                                      }}
                                      name="correctAnswer"
                                      inputProps={{
                                        maxLength: 255,
                                      }}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <Space size="middle">
                                      <Tooltip
                                        placement="bottom"
                                        title="Delete "
                                      >
                                        <button
                                          className="action-button delete-btn"
                                          onClick={() =>
                                            deleteTableRowsTerms(index)
                                          }
                                          style={{ marginLeft: "15px" }}
                                        >
                                          <RiDeleteBinLine />
                                        </button>
                                      </Tooltip>
                                    </Space>
                                  </StyledTableCell>
                                </StyledTableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              )}
              <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
              {res.questionType == "3" && (
                <>
                  <Grid container>
                    <Grid item sx={{ m: 1 }} md={12} xs={12}>
                      <Typography
                        sx={{
                          fontWeight: "400",
                          marginLeft: "28px",
                          fontSize: "20px",
                        }}
                      >
                        Question Type Image
                      </Typography>
                      <Box>
                        <StyledImageUploadWrapper>
                          <label htmlFor="image-upload-Final">
                            <StyledInput
                              accept="image/*"
                              id="image-upload-Final"
                              type="file"
                              onChange={(e) => handleImageItem(index, e)}
                              onClick={(event) => {
                                event.currentTarget.value = null;
                              }}
                            />
                            <StyledIconWrapper
                              sx={
                                res.image == ""
                                  ? {
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }
                                  : {
                                      backgroundImage: `url(${res.image})`,
                                      // backgroundImage: `url(${slides[slideIndex].image})`,
                                      // backgroundImage: `url(${image})`,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "cover",
                                    }
                              }
                            >
                              {/* slides[slideIndex].image === "" && */}
                              {res.image === "" && (
                                <img
                                  src={camera}
                                  alt="Camera"
                                  style={{ margin: "0" }}
                                />
                              )}
                            </StyledIconWrapper>
                          </label>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              color: "black",
                            }}
                          >
                            Add Images For Question
                          </Typography>
                          <Typography
                            sx={{
                              color: "rgba(15, 15, 15, 0.5)",
                              mx: 2,
                              fontSize: "12px",
                            }}
                          >
                            Allowed *.jpeg, *.jpg, *.png, max size of 3.1 MB
                          </Typography>
                          {/* slides[slideIndex].image != "" &&  */}
                          {res.image != "" && (
                            <StyledRemoveButton
                              onClick={(e) => {
                                e.preventDefault();
                                removeImage(index);
                              }}
                            >
                              Remove
                            </StyledRemoveButton>
                          )}
                        </StyledImageUploadWrapper>
                      </Box>
                    </Grid>
                  </Grid>

                  <DndProvider backend={HTML5Backend}>
                    <ImageQuestion
                      data={thirdQuestionHandler}
                      //   slideIndex={slideIndex}
                      indexHandler={thirIndexHandler}
                      // Image={image}
                      Image={res.image && res.image}
                      // Image={slides[slideIndex].image && slides[slideIndex].image }
                      OutData={thirdQuestionEdit}
                    />
                  </DndProvider>
                </>
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}

export default FinalTest;
