import Buttons from '@/components/controls/Button';
import React from 'react';
import { Button } from 'react-bootstrap';
import {useNavigate} from "react-router-dom";


function DataNotFound() {
    const navigate = useNavigate();
    const homeBackHandler = ()=>{
        navigate(`/`);
    }
  return (
    <div className='data_not_found'>
        <h2> Data Not Found</h2>
        <button
          style={{
            textAlign: "center",
            margin: "10px 0",
            padding: "5px 20px",
            backgroundColor: "rgb(255,117,31)",
            color: "white",
            borderRadius:'4px'
          }}
          onClick={() => homeBackHandler()}
        >
          back To Home
        </button>
        {/* <Buttons onClick={()=>homeBackHandler()}> back </Buttons> */}
    </div>
  )
}

export default DataNotFound;
