import { Box } from "@mui/system";
import React from "react";
import QuestionList from "./QuestionList";

const Question = () => {
    return (
        <Box>
            <QuestionList />
        </Box>
    );
};

export default Question;