import axiosRequest from "../../axios/axios";

//get setting List
export const getTeachersList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`TeachersList` + queryString);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getClassDropdownList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`/getclassData`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//add setting

export const AddTeachers = async (payload) => {
  try {
    const response = await axiosRequest.post(`teachersAdd`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//setting Delete
export const DeleteTeachers = async (id) => {
  try {
    const response = await axiosRequest.delete(`teachersDelete/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//setting Status Update

export const StatusUpdate = async (id) => {
  try {
    const response = await axiosRequest.put(`teachersStatusupdate/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//setting update

export const updateTeachers = async (id, payload) => {
  try {
    const response = await axiosRequest.patch(`TeachersUpdate/${id}`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};


export const getTeachersDetails = async (id) => {
  try {
    const response = await axiosRequest.get(`/teachersDetailes/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};


export const updateAddress = async (id, ids, payload) => {
  try {
    const response = await axiosRequest.put(
      `UserAddressUpdate/${id}/${ids}`,
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//delete address
export const DeleteAddress = async (id, ids) => {
  try {
    const response = await axiosRequest.delete(
      `UserAddressDelete/${id}/${ids}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const AddressDefault = async (id, ids) => {
  try {
    const response = await axiosRequest.put(`UserAddressDefault/${id}/${ids}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

