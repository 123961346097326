import React, { useEffect, useReducer, useRef, useState } from "react";
import { Space } from "antd";
import { useForm, Form } from "@/utils/useForm";
import debounce from "lodash.debounce";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  tableCellClasses,
} from "@mui/material";
import Controls from "@/components/controls/Controls";
import Typography from "@mui/material/Typography";
import ImageQuestion from "../ImageQuestion";
import { styled } from "@mui/material/styles";
import camera from "../../../../assets/camera.svg";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ToastContainer, toast } from "react-toastify";
import { RiDeleteBinLine, RiEditLine } from "react-icons/ri";
import UploadAudio from "../../upload/UploadAudio";

function ShortTest({ slideI, sTData, slides, backData }) {
  const [thirdQuestionData, setThirdQuestionData] = useState([]);
  const [thirdQuestionEdit, setThirdQuestionEdit] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [shorTestData, setShortTestData] = useState(slides);
  const [tFo1Data, setTFO1eData] = useState([]);
  const [tFo2Data, setTFO2eData] = useState([]);
  const [dD1Data, setDD1Data] = useState([]);
  const [dD2Data, setDD2Data] = useState([]);
  const [i1, setI1] = useState("");
  const [iApi1, setApi1] = useState("");
  const [ppt, setPpt] = useState("");
  const [pptName, setPptName] = useState("");

  // below useState and Funcation is  For True False Attempt-1
  const [cursorPositions, setCursorPositions] = useState({});

  const handleCursorChange = (index, position) => {
    setCursorPositions((prevState) => ({
      ...prevState,
      [index]: position,
    }));
  };

  // below useState and Funcation For True False Attempt-2
  const [cursorPositions2, setCursorPositions2] = useState({});

  const handleCursorChange2 = (index, position) => {
    setCursorPositions2((prevState) => ({
      ...prevState,
      [index]: position,
    }));
  };

  // below useState and Funcation For Drag and Drop Attempt-1
  const [cPDD1, setCPDD1] = useState({});
  const handlecPDD1 = (index, position) => {
    setCPDD1((prevState) => ({
      ...prevState,
      [index]: position,
    }));
  };

  // below useState and Funcation For Drag and Drop Attempt-2
  const [cPDD2, setCPDD2] = useState({});
  const handlecPDD2 = (index, position) => {
    setCPDD2((prevState) => ({
      ...prevState,
      [index]: position,
    }));
  };

  // below useState and Funcation For Drag and Drop (Answer) Attempt-1
  const [cPDDA1, setCPDDA1] = useState({});
  const handlecPDDA1 = (index, position) => {
    setCPDDA1((prevState) => ({
      ...prevState,
      [index]: position,
    }));
  };

  // below useState and Funcation For Drag and Drop (Answer) Attempt-2
  const [cPDDA2, setCPDDA2] = useState({});
  const handlecPDDA2 = (index, position) => {
    setCPDDA2((prevState) => ({
      ...prevState,
      [index]: position,
    }));
  };


  const pptDeleteHandler = (index) => {
    const rowsInput = [...slides];
    shorTestData[index]["slidePpt"] = "";
    shorTestData[index]["slidePptName"] = "";
    setPpt("");
    setPptName("");
  };

  const audioDeleteHandler = (index) => {
    const rowsInput = [...slides];
    // const basedData = await getBase64(e.target.files[0]);
    rowsInput[index]["slideAudio"] = "";
    rowsInput[index]["slideAudioName"] = "";
    setShortTestData(rowsInput);
    setAudioFile("");
    setAudioFileName("");
  };

  // audio File
  const [audioFile, setAudioFile] = useState("");
  const [audioFileName, setAudioFileName] = useState("");

  const truFalse = useRef([]);
  const trueFalseAttempet2 = useRef([]);
  const dragAtt1 = useRef([]);
  const dragAtt2 = useRef([]);
  const dragAnsAtt1 = useRef([]);
  const dragAnsAtt2 = useRef([]);

  // Attempet -1
  const [t1index, setT1Index] = useState();
  const [d1Index, setD1Index] = useState();
  const [d1AIndex, setD1AIndex] = useState();

  // Attempet-2
  const [t2index, setT2Index] = useState();
  const [d2Index, setD2Index] = useState();
  const [d2AIndex, setD2AIndex] = useState();

  const trueFalseIndexHandler = () => {
    const lastFieldIndex = truFalse.current.length - 1;
    setT1Index(lastFieldIndex);
  };

  const trueFalseAtt2Handler = () => {
    const lastFieldIndex = trueFalseAttempet2.current.length - 1;
    setT2Index(lastFieldIndex);
  };

  const dragIndexHandler = () => {
    const lastFieldIndex = dragAtt1.current.length - 1;
    setD1Index(lastFieldIndex);
  };

  const dragIndexAtt2Handler = () => {
    const lastFieldIndex = dragAtt2.current.length - 1;
    setD2Index(lastFieldIndex);
  };

  const dragAnsIndexHandler = () => {
    const lastFieldIndex = dragAnsAtt1.current.length - 1;
    setD1AIndex(lastFieldIndex);
  };

  const dragAnsAtt2IndexHandler = () => {
    const lastFieldIndex = dragAnsAtt2.current.length - 1;
    setD2AIndex(lastFieldIndex);
  };

  const addTableRows = (index, option) => {
    const rowsInput = {
      questionText: "",
      correctAnswer: "",
    };
    if (option == "option1") {
      setTFO1eData([...tFo1Data, rowsInput]);
      shorTestData[index][option] = [...tFo1Data, rowsInput];
    } else {
      setTFO2eData([...tFo2Data, rowsInput]);
      shorTestData[index][option] = [...tFo2Data, rowsInput];
    }
  };

  // to upload File again Issue 
  const startPptRef = useRef(null);
  const startAudioRef = useRef(null);

  const handleChanges = (index, e, slideIndex, option) => {
    const { name, value } = e.target;
    if (option == "option1") {
      const rowsInput = [...tFo1Data];
      rowsInput[index][name] = value;
      setTFO1eData(rowsInput);
      setT1Index(index);
      setT2Index(null);
    } else {
      setT2Index(index);
      setT1Index(null);
      const rowsInput = [...tFo2Data];
      rowsInput[index][name] = value;
      setTFO2eData(rowsInput);
    }
  };

  const addTableRowsTerms = (index, option) => {
    const rowsInput = {
      questionText: "",
      correctAnswer: "",
    };
    if (option == "option1") {
      setDD1Data([...dD1Data, rowsInput]);
      shorTestData[index][option] = [...dD1Data, rowsInput];
    } else {
      setDD2Data([...dD2Data, rowsInput]);
      shorTestData[index][option] = [...dD2Data, rowsInput];
    }
  };

  const StyledInput = styled("input")({
    display: "none",
  });

  const handleChangesTerms = (index, e, slideIndex, option) => {
    const { name, value } = e.target;
    if (option == "option1") {
      const rowsInput = [...dD1Data];
      rowsInput[index][name] = value;
      setDD1Data(rowsInput);
      if (name == "questionText") {
        setD1Index(index);
        setD1AIndex(null);
      }
      if (name == "correctAnswer") {
        setD1AIndex(index);
        setD1Index(null);
      }
    } else {
      const rowsInput = [...dD2Data];
      rowsInput[index][name] = value;
      setDD2Data(rowsInput);
      if (name == "questionText") {
        setD2Index(index);
        setD2AIndex(null);
      }
      if (name == "correctAnswer") {
        setD2AIndex(index);
        setD2Index(null);
      }
    }
  };

  const Types = [
    { id: 1, title: "True / False" },
    { id: 2, title: "Drag & Drop" },
    { id: 3, title: "Image" },
  ];

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(true);

  const maxSize = 2048;

  const validateImageSize = (vehicleIcons) => {
    if (vehicleIcons) {
      const fsize = vehicleIcons.size / 1024;
      if (fsize > maxSize) {
        return false;
      } else {
        return true;
      }
    }
  };

  const handleImageItem = async (slideIndex, e, option) => {
    if (validateImageSize(e.target.files[0])) {
      const selected = e.target.files[0];
      const ALLOWED_TYPES = ["image/png", "image/jpg", "image/jpeg"];
      if (selected && ALLOWED_TYPES.includes(selected.type)) {
        const base64String = await getBase64(selected);
        const rowsInput = [...slides];
        rowsInput[slideIndex]["image1"] = base64String;
        rowsInput[slideIndex]["imageApi1"] = base64String;
        setShortTestData(rowsInput);
      }
    }
  };

  const handleImageTwoItem = async (slideIndex, e) => {
    if (validateImageSize(e.target.files[0])) {
      const selected = e.target.files[0];
      const ALLOWED_TYPES = ["image/png", "image/jpg", "image/jpeg"];
      if (selected && ALLOWED_TYPES.includes(selected.type)) {
        const base64String = await getBase64(selected);
        const rowsInput = [...slides];
        rowsInput[slideIndex]["image2"] = base64String;
        rowsInput[slideIndex]["imageApi2"] = base64String;
        setShortTestData(rowsInput);
      }
    }
  };

  const StyledImageUploadWrapper = styled(Box)(() => ({
    marginTop: "16px",
    border: "1px solid rgba(15, 15, 15, 0.15)",
    height: "206px",
    borderRadius: "10px",
    textAlign: "center",
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: "#000",
      fontSize: 13,
      border: 0,
      fontWeight: 600,
      paddingTop: "15px",
      paddingBottom: "15px",
      paddingLeft: "25px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 13,
      color: "rgb(17, 24, 39)",
      border: 0,
      paddingLeft: "25px",

      paddingTop: "10px",
      paddingBottom: "10px",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&": {
      backgroundColor: "#fff",
      ":hover": { backgroundColor: "rgb(248 248 248)!important" },

      "&:nth-of-type(odd)": {
        backgroundColor: "rgb(250 250 250)!important",
      },
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const deleteTableRowsTerms = (index, slideIndex, option) => {
    if (option == "option1") {
      const rows = [...dD1Data];
      rows.splice(index, 1);
      shorTestData[slideIndex][option] = rows;
      setDD1Data(rows);
    } else {
      const rows = [...dD2Data];
      rows.splice(index, 1);
      shorTestData[slideIndex][option] = rows;
      setDD2Data(rows);
    }
  };

  const deleteTableRows = (index, slideIndex, option) => {
    if (option == "option1") {
      const rows = [...tFo1Data];
      rows.splice(index, 1);
      shorTestData[slideIndex][option] = rows;
      setTFO1eData(rows);
    } else {
      const rows = [...tFo2Data];
      rows.splice(index, 1);
      shorTestData[slideIndex][option] = rows;
      setTFO2eData(rows);
    }
  };

  const thirIndexHandler = (slideIndex, data, option) => {
    if (option == "option1") {
      slides[slideIndex]["questionSet"] = data;
    } else {
      slides[slideIndex]["questionSet2"] = data;
    }
  };

  const removeImage = (index, option) => {
    if (option == "option1") {
      const shortestData = [...slides];
      shortestData[index]["image1"] = "";
      shorTestData[index]["imageApi1"] = "";
      setShortTestData(shortestData);
    } else {
      const shortestData = [...slides];
      shortestData[index]["image2"] = "";
      shorTestData[index]["imageApi2"] = "";
      setShortTestData(shortestData);
    }
  };

  const StyledRemoveButton = styled(Box)({
    color: "red",
    display: "inline",
    "&:hover": {
      cursor: "pointer",
    },
  });

  const validatePptSize = (ppt) => {
    const MAX_FILE_SIZE = 2 * 1024 * 1024;
    const allowedExtensions = [".ppt", ".pptx"];
    const selectedFile = ppt;
    const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
    if (selectedFile) {
      if (allowedExtensions.includes(`.${fileExtension}`)) {
        if (selectedFile.size <= MAX_FILE_SIZE) {
          return true;
        } else {
          toast.error("Please Upload Only PPT File Less Than 2 MB", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        toast.error("Please Upload Only PPT File", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const pptHandler = async (index, event) => {
    if (validatePptSize(event.target.files[0])) {
      const rowsInput = [...slides];
      const basedData = await getBase64(event.target.files[0]);
      shorTestData[index]["slidePpt"] = basedData;
      shorTestData[index]["slidePptName"] = event.target.files[0];
      setPpt(event.target.files[0]);
      setPptName(event.target.files[0].name);
      startPptRef.current.value = "";
    } else {
      setPpt("");
      setPptName("");
    }
  };

  const validAudioHandler = (audioFile) => {
    const MAX_FILE_SIZE = 2 * 1024 * 1024;
    const allowedExtensions = [".mp3", ".mp4", ".wav", ".ogg"];

    if (audioFile) {
      const fileExtension = audioFile.name.split(".").pop().toLowerCase();
      if (allowedExtensions.includes(`.${fileExtension}`)) {
        if (audioFile.size <= MAX_FILE_SIZE) {
          return true;
        } else {
          toast.error("Please Upload Only audio File Less Than 2 MB", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        toast.error("Please Upload Only Audio File ", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
    return false;
  };

  const audioUploadHandler = async (index, e) => {
    if (validAudioHandler(e.target.files[0])) {
      const rowsInput = [...slides];
      const basedData = await getBase64(e.target.files[0]);
      rowsInput[index]["slideAudio"] = basedData;
      rowsInput[index]["slideAudioName"] = e.target.files[0].name;
      setShortTestData(rowsInput);
      setAudioFile(basedData);
      startAudioRef.current.value = "";
    } else {
      setAudioFile("");
      setAudioFileName("");
    }
  };

  const thirdQuestionHandler = (data) => {
    // EDIT
  };

  const deleteSlideHandler = (index) => {
    const slideData = [...slides];
    slideData.splice(index, 1);
    setShortTestData(slideData);
    backData(slideData);
  };

  const StyledIconWrapper = styled(Box)(() => ({
    margin: "20px auto",
    border: "1px solid rgba(15, 15, 15, 0.15)",
    width: "80px",
    height: "80px",
    borderRadius: "10px",
    backgroundColor: "rgba(15, 15, 15, 0.05)",
    img: {
      marginTop: "30px",
    },
  }));

  const Answer = [
    { id: 1, name: "True" },
    { id: 2, name: "False" },
  ];

  const questionTypeHandler = (index, e) => {
    const { name, value } = e.target;
    const rowsInput = [...slides];
    rowsInput[index]["questionType"] = value;

    rowsInput[index]["option1"] = [];
    rowsInput[index]["option2"] = [];
    rowsInput[index]["image1"] = [];
    rowsInput[index]["image2"] = [];
    rowsInput[index]["imageApi1"] = [];
    rowsInput[index]["imageApi2"] = [];
    setTFO1eData([]);
    setTFO2eData([]);
    setDD1Data([]);
    setDD2Data([]);
    setShortTestData(rowsInput);
  };

  return (
    <Box
      key={`shortTest${slideI}`}
      sx={{
        padding: "10px !important",
        border: "1px solid #8d8c8c",
        marginTop: "12px",
        borderRadius: "10px",
        position: "relative"
      }}
    >
      {/* Question Type */}
      <div className="absolute top-[-12px] left-[40px] bg-white text-lg font-semibold px-[10px] w-fit">{`Unit ${slideI + 1}`}</div>
      <Grid container>

        <Grid item sx={{ m: 1 }} md={5.9} xs={12}>
          <Box display="flex" alignItems="center">
            <Controls.RadioGroup
              name="questionType"
              onChange={(e) => {
                questionTypeHandler(slideI, e);
              }}
              value={shorTestData[slideI]?.questionType}
              items={Types}
              isRowDir={true}
            />
            {Boolean(errors.questionType) ? (
              <p
                style={{
                  color: "rgb(255 0 0)",
                  marginTop: "4px",
                  marginBottom: "4px",
                  fontSize: "14px",
                }}
              >
                {errors.questionType}
              </p>
            ) : (
              ""
            )}
          </Box>
        </Grid>
        <Grid
          item
          md={5.9}
          xs={12}
          sx={{
            display: "flex",
            margin: "10px 0 15px",
            justifyContent: "flex-end",
          }}
        >

          <Button
            variant="contained"
            onClick={() => deleteSlideHandler(slideI)}
          >
            delete
          </Button>
        </Grid>
      </Grid>

      <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />

      {/* question type 1 */}

      {shorTestData[slideI]?.questionType == "1" && (
        <Grid item md={12} justifyContent="center" sm={12} xs={12} container>
          <Grid item sx={{ m: 1 }} md={12} xs={12}>
            <Typography
              sx={{
                fontWeight: "400",
                marginLeft: "28px",
                fontSize: "20px",
              }}
            >
              Attempt - 1
            </Typography>
          </Grid>

          <Grid item sx={{ m: 1 }} md={12} xs={12}>
            <Box
              sx={{
                backgroundColor: "rgb(241, 245, 249)",
                width: "98%",
                margin: "auto",
              }}
            >
              <Box
                sx={{
                  border: 1,
                  borderColor: "#eaeaea",
                }}
              >
                <Table className="table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Question Text</StyledTableCell>
                      <StyledTableCell>Correct Answer</StyledTableCell>
                      {/* {rowsData.length == 0 ? ( */}
                      <StyledTableCell sx={{ width: "10px" }}>
                        <Button
                          variant="contained"
                          size="large"
                          style={{
                            backgroundColor: "blue",
                            borderRadius: "10px",
                            width: "0%",
                          }}
                          startIcon={<AddIcon sx={{ marginLeft: "9px" }} />}
                          fullWidth
                          onClick={() => addTableRows(slideI, "option1")}
                        />
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {shorTestData[slideI].option1.map((data, index) => {
                      const { questionText, correctAnswer } = data;
                      return (
                        <StyledTableRow
                          key={`${data.questionText} - ${index}`}
                          sx={{ cursor: "pointer" }}
                        >
                          <StyledTableCell
                            key={`${data.questionText} - ${index} - ${data.correctAnswer}`}
                            component="th"
                            scope="row"
                          >
                            <TextField
                              fullWidth
                              key={`${index}-${questionText}`}
                              text="Add New"
                              variant="outlined"
                              color="primary"
                              inputRef={(input) => {
                                if (
                                  input &&
                                  cursorPositions[index] !== undefined
                                ) {
                                  input.selectionStart = cursorPositions[index];
                                  input.selectionEnd = cursorPositions[index];
                                }
                              }}
                              autoFocus={t1index == index}
                              label="Question Text"
                              name="questionText"
                              value={questionText}
                              onChange={(e) => {
                                handleChanges(index, e, slideI, "option1");
                                handleCursorChange(
                                  index,
                                  e.target.selectionStart
                                );
                              }}
                              inputProps={{
                                maxLength: 255,
                              }}
                            />
                          </StyledTableCell>

                          <StyledTableCell component="th" scope="row">
                            <Controls.SelectBox
                              className="input"
                              label="Select Answer"
                              fullWidth
                              id="correctAnswer"
                              name="correctAnswer"
                              value={correctAnswer}
                              onChange={(e) =>
                                handleChanges(index, e, slideI, "option1")
                              }
                              options={Answer}
                            />
                            {Boolean(errors.correctAnswer) ? (
                              <p
                                style={{
                                  color: "rgb(255 0 0)",
                                  marginTop: "4px",
                                  marginBottom: "4px",
                                  fontSize: "14px",
                                }}
                              >
                                {errors.correctAnswer}
                              </p>
                            ) : (
                              ""
                            )}
                          </StyledTableCell>

                          {/* {rowsData.length == 0 ? ( */}

                          <StyledTableCell
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Space size="middle">
                              <Tooltip placement="bottom" title="Delete ">
                                <button
                                  className="action-button delete-btn"
                                  onClick={() =>
                                    deleteTableRows(index, slideI, "option1")
                                  }
                                  style={{ marginLeft: "15px" }}
                                >
                                  <RiDeleteBinLine />
                                </button>
                              </Tooltip>
                            </Space>
                          </StyledTableCell>
                          {/* <Divider
                            sx={{ bgcolor: "rgba(107, 114, 128, .4)" }}
                          /> */}
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </Grid>

          <Grid item sx={{ m: 1 }} md={12} xs={12}>
            <Typography
              sx={{
                fontWeight: "400",
                marginLeft: "28px",
                fontSize: "20px",
              }}
            >
              Attempt - 2
            </Typography>
          </Grid>

          <Grid item sx={{ m: 1 }} md={12} xs={12}>
            <Box
              sx={{
                backgroundColor: "rgb(241, 245, 249)",
                width: "98%",
                margin: "auto",
              }}
            >
              <Box
                sx={{
                  border: 1,
                  borderColor: "#eaeaea",
                }}
              >
                <Table className="table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Question Text</StyledTableCell>
                      <StyledTableCell>Correct Answer</StyledTableCell>
                      {/* {rowsData.length == 0 ? ( */}
                      <StyledTableCell sx={{ width: "10px" }}>
                        <Button
                          variant="contained"
                          size="large"
                          style={{
                            backgroundColor: "blue",
                            borderRadius: "10px",
                            width: "0%",
                          }}
                          startIcon={<AddIcon sx={{ marginLeft: "9px" }} />}
                          fullWidth
                          onClick={() => addTableRows(slideI, "option2")}
                        />
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {shorTestData[slideI].option2.map((data, index) => {
                      const { questionText, correctAnswer } = data;
                      return (
                        <StyledTableRow key={index} sx={{ cursor: "pointer" }}>
                          <StyledTableCell component="th" scope="row">
                            <TextField
                              text="Add New"
                              key={`index-2${index}`}
                              variant="outlined"
                              color="primary"
                              fullWidth
                              inputRef={(input) => {
                                if (
                                  input &&
                                  cursorPositions2[index] !== undefined
                                ) {
                                  input.selectionStart =
                                    cursorPositions2[index];
                                  input.selectionEnd = cursorPositions2[index];
                                }
                              }}
                              label="Question Text"
                              name="questionText"
                              autoFocus={t2index == index}
                              value={questionText}
                              onChange={(e) => {
                                handleChanges(index, e, slideI, "option2");
                                handleCursorChange2(
                                  index,
                                  e.target.selectionStart
                                );
                              }}
                              inputProps={{
                                maxLength: 255,
                              }}
                            />
                          </StyledTableCell>

                          <StyledTableCell component="th" scope="row">
                            <Controls.SelectBox
                              className="input"
                              label="Select Answer"
                              fullWidth
                              id="correctAnswer"
                              name="correctAnswer"
                              value={correctAnswer}
                              onChange={(e) => {
                                handleChanges(index, e, slideI, "option2");
                                handleInputChange(e);
                              }}
                              options={Answer}
                            />
                            {Boolean(errors.correctAnswer) ? (
                              <p
                                style={{
                                  color: "rgb(255 0 0)",
                                  marginTop: "4px",
                                  marginBottom: "4px",
                                  fontSize: "14px",
                                }}
                              >
                                {errors.correctAnswer}
                              </p>
                            ) : (
                              ""
                            )}
                          </StyledTableCell>

                          {/* {rowsData.length == 0 ? ( */}

                          <StyledTableCell
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Space size="middle">
                              <Tooltip placement="bottom" title="Delete ">
                                <button
                                  className="action-button delete-btn"
                                  onClick={() =>
                                    deleteTableRows(index, slideI, "option2")
                                  }
                                  style={{ marginLeft: "15px" }}
                                >
                                  <RiDeleteBinLine />
                                </button>
                              </Tooltip>
                            </Space>
                          </StyledTableCell>
                          {/* <Divider
                            sx={{ bgcolor: "rgba(107, 114, 128, .4)" }}
                          /> */}
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
      <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />

      {/* question type 2 */}
      {shorTestData[slideI]?.questionType == "2" ? (
        <Grid item md={12} justifyContent="center" sm={12} xs={12} container>
          <Grid item sx={{ m: 1 }} md={12} xs={12}>
            <Typography
              sx={{
                fontWeight: "400",
                marginLeft: "28px",
                fontSize: "20px",
              }}
            >
              Attempt -1
            </Typography>
          </Grid>

          <Grid item sx={{ m: 1 }} md={12} xs={12}>
            <Box
              sx={{
                backgroundColor: "rgb(241, 245, 249)",
                width: "98%",
                margin: "auto",
              }}
            >
              <Box
                sx={{
                  border: 1,
                  borderColor: "#eaeaea",
                }}
              >
                <Table className="table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Question Text</StyledTableCell>
                      <StyledTableCell>Correct Answer</StyledTableCell>
                      <StyledTableCell sx={{ width: "10px" }}>
                        <Button
                          variant="contained"
                          size="large"
                          style={{
                            backgroundColor: "blue",
                            borderRadius: "10px",
                            width: "0%",
                          }}
                          startIcon={<AddIcon sx={{ marginLeft: "9px" }} />}
                          fullWidth
                          onClick={() => addTableRowsTerms(slideI, "option1")}
                        />
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* rowsTermsData */}
                    {sTData.option1?.map((data, index) => {
                      const { questionText, correctAnswer } = data;
                      return (
                        <StyledTableRow
                          key={`${index} ${questionText}`}
                          sx={{ cursor: "pointer" }}
                        >
                          <StyledTableCell component="th" scope="row">
                            <TextField
                              text="Add New"
                              variant="outlined"
                              color="primary"
                              fullWidth
                              inputRef={(input) => {
                                if (input && cPDD1[index] !== undefined) {
                                  input.selectionStart = cPDD1[index];
                                  input.selectionEnd = cPDD1[index];
                                }
                              }}
                              label="Question Text"
                              autoFocus={d1Index == index}
                              value={questionText}
                              onChange={(e) => {
                                handleChangesTerms(index, e, slideI, "option1");
                                handlecPDD1(index, e.target.selectionStart);
                              }}
                              name="questionText"
                              inputProps={{
                                maxLength: 255,
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            <TextField
                              text="Add New"
                              variant="outlined"
                              color="primary"
                              fullWidth
                              inputRef={(input1) => {
                                if (input1 && cPDDA1[index] !== undefined) {
                                  input1.selectionStart = cPDDA1[index];
                                  input1.selectionEnd = cPDDA1[index];
                                }
                              }}
                              label="correct Answer"
                              autoFocus={d1AIndex == index}
                              value={correctAnswer}
                              onChange={(e) => {
                                handleChangesTerms(index, e, slideI, "option1");
                                handlecPDDA1(index, e.target.selectionStart);
                              }}
                              name="correctAnswer"
                              inputProps={{
                                maxLength: 255,
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Space size="middle">
                              <Tooltip placement="bottom" title="Delete ">
                                <button
                                  className="action-button delete-btn"
                                  onClick={() =>
                                    deleteTableRowsTerms(
                                      index,
                                      slideI,
                                      "option1"
                                    )
                                  }
                                  style={{ marginLeft: "15px" }}
                                >
                                  <RiDeleteBinLine />
                                </button>
                              </Tooltip>
                            </Space>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </Grid>

          <Grid item sx={{ m: 1 }} md={12} xs={12}>
            <Typography
              sx={{
                fontWeight: "400",
                marginLeft: "28px",
                fontSize: "20px",
              }}
            >
              Attempt -2
            </Typography>
          </Grid>

          <Grid item sx={{ m: 1 }} md={12} xs={12}>
            <Box
              sx={{
                backgroundColor: "rgb(241, 245, 249)",
                width: "98%",
                margin: "auto",
              }}
            >
              <Box
                sx={{
                  border: 1,
                  borderColor: "#eaeaea",
                }}
              >
                <Table className="table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Question Text</StyledTableCell>
                      <StyledTableCell>Correct Answer</StyledTableCell>
                      <StyledTableCell sx={{ width: "10px" }}>
                        <Button
                          variant="contained"
                          size="large"
                          style={{
                            backgroundColor: "blue",
                            borderRadius: "10px",
                            width: "0%",
                          }}
                          startIcon={<AddIcon sx={{ marginLeft: "9px" }} />}
                          fullWidth
                          onClick={() => addTableRowsTerms(slideI, "option2")}
                        />
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* rowsTermsData */}
                    {sTData.option2.map((data, index) => {
                      const { questionText, correctAnswer } = data;
                      return (
                        <StyledTableRow
                          key={`${index} ${questionText}`}
                          sx={{ cursor: "pointer" }}
                        >
                          <StyledTableCell component="th" scope="row">
                            <TextField
                              text="Add New"
                              variant="outlined"
                              color="primary"
                              fullWidth
                              inputRef={(input) => {
                                if (input && cPDD2[index] !== undefined) {
                                  input.selectionStart = cPDD2[index];
                                  input.selectionEnd = cPDD2[index];
                                }
                              }}
                              label="Question Text"
                              autoFocus={d2Index == index}
                              value={questionText}
                              onChange={(e) => {
                                handleChangesTerms(index, e, slideI, "option2");
                                handlecPDD2(index, e.target.selectionStart);
                              }}
                              onBlur={() => dragIndexAtt2Handler()}
                              name="questionText"
                              inputProps={{
                                maxLength: 255,
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            <TextField
                              text="Add New"
                              variant="outlined"
                              color="primary"
                              fullWidth
                              inputRef={(input1) => {
                                if (input1 && cPDDA2[index] !== undefined) {
                                  input1.selectionStart = cPDDA2[index];
                                  input1.selectionEnd = cPDDA2[index];
                                }
                              }}
                              label="correct Answer"
                              autoFocus={d2AIndex == index}
                              value={correctAnswer}
                              onBlur={() => dragAnsAtt2IndexHandler()}
                              onChange={(e) => {
                                handleChangesTerms(index, e, slideI, "option2");
                                handlecPDDA2(index, e.target.selectionStart);
                              }}
                              name="correctAnswer"
                              inputProps={{
                                maxLength: 255,
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Space size="middle">
                              <Tooltip placement="bottom" title="Delete ">
                                <button
                                  className="action-button delete-btn"
                                  onClick={() =>
                                    deleteTableRowsTerms(
                                      index,
                                      slideI,
                                      "option2"
                                    )
                                  }
                                  style={{ marginLeft: "15px" }}
                                >
                                  <RiDeleteBinLine />
                                </button>
                              </Tooltip>
                            </Space>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </Grid>
        </Grid>
      ) : null}
      <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />

      {shorTestData[slideI]?.questionType == "3" ? (
        <>
          {/* Attempt - 1 */}
          <Grid
            item
            md={12}
            mt={2}
            justifyContent="center"
            sm={12}
            xs={12}
            container
          >
            <Grid item sx={{ m: 1 }} md={12} xs={12}>
              <Typography
                sx={{
                  fontWeight: "400",
                  marginLeft: "28px",
                  fontSize: "20px",
                }}
              >
                Attempt - 1
              </Typography>
              {errorMessage && (
                <h5 style={{ color: "red" }}> {errorMessage}</h5>
              )}
              <Box key={`Index-1`}>
                <StyledImageUploadWrapper>
                  <label htmlFor={`image-upload-Attempt-1_${slideI}`}>
                    <StyledInput
                      accept="image/*"
                      id={`image-upload-Attempt-1_${slideI}`}
                      type="file"
                      onChange={(e) => handleImageItem(slideI, e)}
                      onClick={(event) => {
                        event.currentTarget.value = null;
                      }}
                    />
                    <StyledIconWrapper
                      sx={
                        shorTestData[slideI].image1 == ""
                          ? {
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }
                          : {
                            backgroundImage: `url(${shorTestData[slideI].image1})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                          }
                      }
                    >
                      {shorTestData[slideI].image1 === "" && (
                        <img
                          src={camera}
                          alt="Camera"
                          style={{ margin: "0" }}
                        />
                      )}
                    </StyledIconWrapper>
                  </label>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "black",
                    }}
                  >
                    Add Images For Question
                  </Typography>
                  <Typography
                    sx={{
                      color: "rgba(15, 15, 15, 0.5)",
                      mx: 2,
                      fontSize: "12px",
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, max size of 3.1 MB
                  </Typography>
                  {/* slides[slideIndex].image != "" &&  */}
                  {shorTestData[slideI].image1 != "" && (
                    <StyledRemoveButton
                      onClick={(e) => {
                        e.preventDefault();
                        removeImage(slideI, "option1");
                      }}
                    >
                      Remove
                    </StyledRemoveButton>
                  )}
                </StyledImageUploadWrapper>
              </Box>
            </Grid>
          </Grid>
          <DndProvider backend={HTML5Backend}>
            <ImageQuestion
              data={thirdQuestionHandler}
              slideIndex={slideI}
              option="option1"
              indexHandler={thirIndexHandler}
              Image={shorTestData[slideI].image1 && shorTestData[slideI].image1}
              OutData={thirdQuestionEdit}
            />
          </DndProvider>
          <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />

          {/* Attempt - 2 */}

          <Grid item md={12} justifyContent="center" sm={12} xs={12} container>
            <Grid item sx={{ m: 1 }} md={12} xs={12}>
              <Typography
                sx={{
                  fontWeight: "400",
                  marginLeft: "28px",
                  fontSize: "20px",
                }}
              >
                Attempt - 2
              </Typography>
              {errorMessage && (
                <h5 style={{ color: "red" }}> {errorMessage}</h5>
              )}
              <Box>
                <StyledImageUploadWrapper>
                  <label htmlFor={`image-upload-Attempt-2_${slideI}`}>
                    <StyledInput
                      accept="image/*"
                      id={`image-upload-Attempt-2_${slideI}`}
                      type="file"
                      onChange={(e) => handleImageTwoItem(slideI, e)}
                      onClick={(event) => {
                        event.currentTarget.value = null;
                      }}
                    />
                    <StyledIconWrapper
                      sx={
                        shorTestData[slideI].image2 == ""
                          ? {
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }
                          : {
                            backgroundImage: `url(${shorTestData[slideI].image2})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                          }
                      }
                    >
                      {shorTestData[slideI].image2 === "" && (
                        <img
                          src={camera}
                          alt="Camera"
                          style={{ margin: "0" }}
                        />
                      )}
                    </StyledIconWrapper>
                  </label>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "black",
                    }}
                  >
                    Add Images For Question
                  </Typography>
                  <Typography
                    sx={{
                      color: "rgba(15, 15, 15, 0.5)",
                      mx: 2,
                      fontSize: "12px",
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, max size of 3.1 MB
                  </Typography>
                  {shorTestData[slideI].image2 != "" && (
                    <StyledRemoveButton
                      onClick={(e) => {
                        e.preventDefault();
                        removeImage(slideI, "option2");
                      }}
                    >
                      Remove
                    </StyledRemoveButton>
                  )}
                </StyledImageUploadWrapper>
              </Box>
            </Grid>
          </Grid>
          <DndProvider backend={HTML5Backend}>
            <ImageQuestion
              data={thirdQuestionHandler}
              slideIndex={slideI}
              option="option2"
              indexHandler={thirIndexHandler}
              Image={shorTestData[slideI].image2 && shorTestData[slideI].image2}
              OutData={thirdQuestionEdit}
            />
          </DndProvider>
          <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
        </>
      ) : null}

      <Grid item marginTop={"20px"}>
        <Grid container justifyContent={"center"}>
          <Grid item sx={{ m: 1 }} md={5.7} xs={12}>
            <Button
              variant="contained"
              component="label"
              style={{ marginBottom: "8px" }}
            >
              Upload ppt
              <input
                hidden
                ref={startPptRef}
                accept=".pptx,.ppt"
                type="file"
                onChange={(e) => pptHandler(slideI, e)}
              />
            </Button>
            {
              sTData.slidePptName && (
                <Tooltip placement="bottom" title="Delete ">
                  <button
                    className="action-button delete-btn"
                    style={{ marginLeft: "15px" }}
                    onClick={() => pptDeleteHandler(slideI)}
                  >
                    <RiDeleteBinLine />
                  </button>
                </Tooltip>
              )
            }
            {sTData.slidePptName && (
              <h5 style={{ marginTop: "10px" }}>{sTData.slidePptName.name}</h5>
            )}
          </Grid>

          <Grid item sx={{ m: 1 }} md={5.7} xs={12}>
            <Button
              variant="contained"
              component="label"
              style={{ marginBottom: "8px" }}
            >
              upload audio File
              <input
                hidden
                accept=".mp3,.wav,.ogg,.mp4"
                type="file"
                ref={startAudioRef}
                onChange={(e) => audioUploadHandler(slideI, e)}
              />
            </Button>
            {
              sTData?.slideAudioName && (
                <Tooltip placement="bottom" title="Delete ">
                  <button
                    className="action-button delete-btn"
                    style={{ marginLeft: "15px" }}
                    onClick={() => audioDeleteHandler(slideI)}
                  >
                    <RiDeleteBinLine />
                  </button>
                </Tooltip>
              )
            }
            {sTData?.slideAudioName && (
              <h5 style={{ marginTop: "10px" }}>{sTData?.slideAudioName}</h5>
            )}
            {sTData?.slideAudioName && (
              <UploadAudio
                audioFile={audioFile}
                keyName={`${sTData?.slideAudioName}`}
              />
            )}
          </Grid>
        </Grid>
      </Grid>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </Box>
  );
}

export default ShortTest;
