import React from "react";
import DrafQuestionList from "./DrafQuestionList";
import { Box } from "@mui/material";

function DraftQuestion() {
  return (
    <Box>
      <DrafQuestionList />
    </Box>
  );
}

export default DraftQuestion;
