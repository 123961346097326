import { Box } from "@mui/system";
import React from "react";
import StudentList from "./StudentList";

const Student = () => {
  return (
    <Box >
      <StudentList />
    </Box>
  );
};
export default Student;
