import React, { useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Main from "./Main";

const Layout = ({ children }) => {
  const [showNavbar, setShowNavbar] = useState(false);

  return (
    <div>
      {/* <Header /> */}
      <Main />
      {/* <Footer /> */}
    </div>
  );
};

export default Layout;
