import { Box, Stack, Typography, DialogContent, Divider } from "@mui/material";
import Logo from "../../../assets/Images/Logo.png";
import Controls from "../../controls/Controls";
import { GiCheckMark } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { trailAnsHandler } from "@/state/actions/TrailAction";
import { EventEmitter } from "@/utils/helper";

const Slide2 = ({ handleClose, modalOpen }) => {
  const navigate = useNavigate();
  const isAuthenticated = useSelector((store) => store.auth.isAuthenticated);

  const userAdmin = useSelector((store) => store?.auth?.user);

  if (!isAuthenticated) {
    navigate("/");
  }

  const agreeHandler = async () => {
    localStorage.setItem("trailStatus", "AGREE");
    const payload = {
      studentId : userAdmin.id,
      trial:1
    }
    const response = await trailAnsHandler(payload);
    EventEmitter.dispatch('trailStatus',true);
  };

  const disAgreeHandler = async () => {
    localStorage.setItem("trailStatus","NOTAGREE");
    const payload = {
      studentId : userAdmin.id,
      trial:1
    }
    const response = await trailAnsHandler(payload);
    console.log("response",response);
    handleClose();
  };

  return (
    <>
      <DialogContent>
        <Box height={"100%"}>
          <Stack height={"100%"} justifyContent={"space-between"} gap={2}>
            <Stack>
              {/* <Typography fontSize={'20px'}>Did you agree to be part of this trial?</Typography> */}
              <Divider
                sx={{
                  borderColor: "#ff751f",
                  borderWidth: "1px",
                  opacity: "1",
                  marginTop: "5px",
                }}
              />
            </Stack>
            <Stack>
              <Typography fontSize={"12px"}>
                When you do this work some data will be collected.
              </Typography>
              <Typography fontSize={"12px"}>
                The table shows what your teacher will see and what the
                researcher will see
              </Typography>
            </Stack>
            <Stack>
              <div className="table-responsive">
                <table className="table table-bordered slide2_table">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">The teacher can see</th>
                      <th scope="col">The researcher can see</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Your final test score</td>
                      <td style={{ textAlign: "center" }}>
                        {" "}
                        <GiCheckMark
                          style={{
                            color: "#000",
                            fontSize: "20px",
                            width: "100%",
                          }}
                        />
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <GiCheckMark
                          style={{
                            color: "#000",
                            fontSize: "20px",
                            width: "100%",
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>The number of slides you look at</td>
                      <td style={{ textAlign: "center" }}>
                        <GiCheckMark
                          style={{
                            color: "#000",
                            fontSize: "20px",
                            width: "100%",
                          }}
                        />
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <GiCheckMark
                          style={{
                            color: "#000",
                            fontSize: "20px",
                            width: "100%",
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Your name</td>
                      <td style={{ textAlign: "center" }}>
                        <GiCheckMark
                          style={{
                            color: "#000",
                            fontSize: "20px",
                            width: "100%",
                          }}
                        />
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {" "}
                        <AiOutlineClose
                          style={{
                            color: "#000",
                            fontSize: "20px",
                            width: "100%",
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Stack>
            <Stack
              flexDirection={"row"}
              gap={1}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography fontSize={"12px"}>
                Do you agree to this data being collected?
              </Typography>
              <Controls.Button
                style={{ backgroundColor: "#ff751f", color: "#fff" }}
                text="Yes"
                onClick={() => agreeHandler()}
              />
              <Controls.Button
                style={{ backgroundColor: "#ff751f", color: "#fff" }}
                text="No"
                onClick={() => disAgreeHandler()}
              />
            </Stack>
            <Stack flexDirection={"row"} gap={1} alignItems={"flex-end"}>
              <Divider
                sx={{
                  width: "390px",
                  borderColor: "#ff751f",
                  borderWidth: "1px",
                  opacity: "1",
                  marginTop: "5px",
                }}
              />
              <img src={Logo} alt="logo" className="w-[60px]" />
            </Stack>
          </Stack>
        </Box>
      </DialogContent>
    </>
  );
};

export default Slide2;
