import { Box, TextField } from "@mui/material";
import React from "react";
import { memo, useEffect } from "react";
import { useDrag } from "react-dnd";
import { AiFillCloseCircle } from "../../../../assets/Icons/Icons";

export const ItemTypes = {
  BOX: "box",
};

function getStyles(left, top, isDragging, correctAnswer) {
  const transform = `translate3d(${parseInt(left)}px, ${parseInt(top)}px, 0)`;
  return {
    position: "absolute",
    transform,
    WebkitTransform: transform,
    // IE fallback: hide the real node using CSS when dragging
    // because IE will ignore our custom "empty image" drag preview.
    opacity: isDragging ? 0 : 1,
    height: isDragging ? 0 : "",
  };
}

export const TextBox = memo(function TextBox(props) {
  const { id, correctAnswer, left, top, data, dataHandler, view } = props;

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: ItemTypes.BOX,
      item: { id, left, top, correctAnswer },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [id, left, top, correctAnswer]
  );

  const texthandler = (e, id) => {
    const rowsInput = [...data];
    rowsInput[id]["correctAnswer"] = e.target.value;
    dataHandler(rowsInput);
  };

  const textDeleteHandler = (id) => {
    const rows = [...data];
    rows.splice(id, 1);
    dataHandler(rows);
  };

  return (
    <div
      className={`${
        left == "20" ? "position-relative" : ""
      } mobile_screen_input`}
      ref={view ? null : drag}
      style={getStyles(left, top, isDragging, correctAnswer)}
      role="DraggableBox"
    >
      <TextField
        size="small"
        sx={{ width: "70%" }}
        disabled={view ? true : false}
        value={correctAnswer}
        onChange={(e) => texthandler(e, id)}
      />
      {!view && (
        <AiFillCloseCircle
          className="position-absolute top_20 right-10 fs-4"
          onClick={() => textDeleteHandler(id)}
        />
      )}
    </div>
  );
});
