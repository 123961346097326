import React from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import Controls from "@/components/controls/Controls";
import UploadAudio from "../Admin/upload/UploadAudio";

function PptView({ Link, next, audioFile }) {
  const docs = [
    {
      uri: Link,
    },
  ];

  return (
    <div style={{ padding: "10px 3%" }}>
      {Link && (
        <DocViewer
          documents={docs}
          pluginRenderers={DocViewerRenderers}
          style={{ height: "550px" }}
        />
      )}

      {audioFile && (
        <UploadAudio audioFile={audioFile} keyName={`${audioFile}`} />
      )}

      <div style={{ padding: "10px 0", textAlign: "end" }}>
        <Controls.Button
          style={{ backgroundColor: "var(--primary)", color: "#fff" }}
          text="Next"
          onClick={() => next()}
        />
      </div>
    </div>
  );
}

export default PptView;
