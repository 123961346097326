import axiosRequest from "../axios/axios";

//get Session List
export const getQuestionsData = async (id, data) => {
  try {
    const response = await axiosRequest.post(`/getexams/${id}`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};


export const getQuestionsDataFinalTest = async (data) => {
  try {
    const response = await axiosRequest.post(`/getfinalExamTest`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};


export const pptCountHandler = async (data) =>{
  try{
    const response = await axiosRequest.post(`/pptOpenCount`,data);
    return response.data;
  }catch(error){
    return error;
  }
};