// docs https://github.com/azouaoui-med/react-pro-sidebar
import { useState } from "react";
import { Menu, Sidebar, MenuItem, SubMenu } from "react-pro-sidebar";
import { useProSidebar } from "react-pro-sidebar";
import { useSidebarContext } from "./sidebarContext";
import { Link, Navigate } from "react-router-dom";
import { tokens } from "../../../../theme";
import { useTheme, Box, Typography, IconButton } from "@mui/material";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SwitchRightOutlinedIcon from "@mui/icons-material/SwitchRightOutlined";
import SwitchLeftOutlinedIcon from "@mui/icons-material/SwitchLeftOutlined";
import GridViewIcon from "@mui/icons-material/GridView";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import BorderAllIcon from "@mui/icons-material/BorderAll";
import { useNavigate } from "react-router-dom";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import BusinessIcon from "@mui/icons-material/Business";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

import StorefrontIcon from "@mui/icons-material/Storefront";
import AddToHomeScreenIcon from "@mui/icons-material/AddToHomeScreen";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonIcon from "@mui/icons-material/Person";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import MapIcon from "@mui/icons-material/Map";
import PaymentIcon from "@mui/icons-material/Payment";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import PaidIcon from "@mui/icons-material/Paid";
import BikeScooterIcon from "@mui/icons-material/BikeScooter";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import AdUnitsIcon from "@mui/icons-material/AdUnits";
import { Tooltip } from "@mui/material";
import Logo from "../../../../assets/Logo.png";
import ClassOutlinedIcon from "@mui/icons-material/ClassOutlined";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import SchoolIcon from "@mui/icons-material/School";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import RuleFolderIcon from "@mui/icons-material/RuleFolder";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import { useDispatch, useSelector } from "react-redux";
const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const Navigate = useNavigate();

  return (
    <MenuItem
      active={selected === title}
      style={{ color: colors.grey[100] }}
      onClick={() => {
        setSelected(title);
        Navigate(to);
      }}
      icon={icon}
      routerLink={<Link to={to} />}
    >
      <Typography>{title}</Typography>
    </MenuItem>
  );
};

const MyProSidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selected, setSelected] = useState("Dashboard");
  const { sidebarRTL, setSidebarRTL, sidebarImage } = useSidebarContext();
  const { collapseSidebar, toggleSidebar, collapsed, broken } = useProSidebar();
  const { user } = useSelector((store) => store.auth);
  return (
    <Box
      sx={{
        position: "sticky",
        display: "flex",
        height: "100vh",
        top: 0,
        bottom: 0,
        zIndex: 998,
        "& .sidebar": {
          border: "none",
        },
        "& .menu-icon": {
          backgroundColor: "rgba(255, 255, 255, 0.1) !important",
        },
        "& .menu-item": {
          padding: "5px 10px 5px 10px !important",
          backgroundColor: "rgba(255, 255, 255, 0.1) !important",
        },
        "& .menu-anchor": {
          color: "inherit !important",
          backgroundColor: "rgba(255, 255, 255, 0.1) !important",
        },
        "& .menu-item:hover": {
          color: `#fff!important`,
          backgroundColor: "rgba(255, 255, 255, 0.1) !important",
        },
        "& .menu-item.active": {
          color: `#fff!important`,
          backgroundColor: "rgba(255, 255, 255, 0.1) !important",
        },
      }}
    >
      <Sidebar
        id="sidebar"
        breakPoint="md"
        rtl={sidebarRTL}
        backgroundColor={colors.primary[600]}
        image={sidebarImage}
      >
        <Menu iconshape="square">
          <MenuItem
            icon={
              collapsed ? (
                <MenuOutlinedIcon onClick={() => collapseSidebar()} />
              ) : sidebarRTL ? (
                <SwitchLeftOutlinedIcon
                  onClick={() => setSidebarRTL(!sidebarRTL)}
                />
              ) : (
                <SwitchRightOutlinedIcon
                  onClick={() => setSidebarRTL(!sidebarRTL)}
                />
              )
            }
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!collapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" sx={{ color: "#fff" }}>
                  <img src={Logo} alt="" style={{ width: "81px" }} />
                </Typography>
                <IconButton
                  onClick={
                    broken ? () => toggleSidebar() : () => collapseSidebar()
                  }
                >
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          <Box
            display={collapsed ? "none" : "block"}
            padding={collapsed ? undefined : "0% 3%"}
          >
            <Item
              title="Dashboard"
              to="/dashboard"
              icon={<GridViewIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <SubMenu
              label="Manage Users"
              sx={{ backgroundColor: "transparent" }}
              icon={<SwitchAccountIcon />}
            >
              {user.roleID == "0" ? (
                <MenuItem sx={{ backgroundColor: "transparent" }}>
                  <Item
                    title="Teacher"
                    to="/teacher"
                    icon={<PersonIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </MenuItem>
              ) : null}
              <MenuItem sx={{ backgroundColor: "transparent" }}>
                <Item
                  title="Student"
                  to="/student"
                  icon={<PersonIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem>
            </SubMenu>

            {/* <SubMenu
              label="Academics"
              sx={{ backgroundColor: "transparent" }}
              icon={<SchoolIcon />}
            >
             
             
              
            </SubMenu> */}

            <SubMenu
              label="Title Examination"
              sx={{ backgroundColor: "transparent" }}
              icon={<DriveFileRenameOutlineOutlinedIcon />}
            >
              <MenuItem sx={{ backgroundColor: "transparent" }}>
                <Item
                  title="Class"
                  to="/class"
                  icon={<ClassOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem>
              <MenuItem>
                <Item
                  title="Course"
                  to="/course"
                  icon={<MenuBookIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem>
              <MenuItem>
                <Item
                  title="Session"
                  to="/session"
                  icon={<CoPresentIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem>

              <MenuItem>
                <Item
                  title="Question"
                  to="/question"
                  icon={<QuestionAnswerOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem>
              <MenuItem>
                <Item
                  title="Draft Question"
                  to="/draft-question"
                  icon={<QuestionAnswerOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem>
              <MenuItem>
                <Item
                  title="Result"
                  to="/results"
                  icon={<RuleFolderIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem>
            </SubMenu>
          </Box>

          <Box
            display={collapsed ? "block" : "none"}
            padding={collapsed ? undefined : "0% 3%"}
          >
            <Tooltip
              style={{ zIndex: "20000 !important" }}
              placement="right"
              title="Dashboard"
              arrow
            >
              <div>
                <Item
                  to="/"
                  icon={<GridViewIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </div>
            </Tooltip>

            <Tooltip
              style={{ zIndex: "20000 !important" }}
              placement="right"
              title="Manage Users"
              arrow
            >
              <SubMenu
                label={collapsed ? "" : "Manage Users"}
                sx={{ backgroundColor: "transparent" }}
                icon={<SwitchAccountIcon />}
              >
                <MenuItem
                  className="collapse-menu-view"
                  sx={{ backgroundColor: "transparent" }}
                >
                  <Item
                    title="Teacher"
                    to="/teacher"
                    icon={<PersonIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </MenuItem>
                <MenuItem
                  className="collapse-menu-view"
                  sx={{ backgroundColor: "transparent" }}
                >
                  <Item
                    title="Student"
                    to="/student"
                    icon={<PersonIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </MenuItem>
              </SubMenu>
            </Tooltip>

            <Tooltip
              style={{ zIndex: "20000 !important" }}
              placement="right"
              title="Academics"
              arrow
            >
              <SubMenu
                label={collapsed ? "" : "Academics"}
                sx={{ backgroundColor: "transparent" }}
                icon={<SettingsIcon />}
              >
                <MenuItem
                  className="collapse-menu-view"
                  sx={{ backgroundColor: "transparent" }}
                >
                  <Item
                    title="Class"
                    to="/class"
                    icon={<PersonIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </MenuItem>
                <MenuItem
                  className="collapse-menu-view"
                  sx={{ backgroundColor: "transparent" }}
                >
                  <Item
                    title="Course"
                    to="/course"
                    icon={<PersonIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </MenuItem>
                <MenuItem
                  className="collapse-menu-view"
                  sx={{ backgroundColor: "transparent" }}
                >
                  <Item
                    title="Session"
                    to="/session"
                    icon={<PersonIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </MenuItem>
              </SubMenu>
            </Tooltip>

            <Tooltip
              style={{ zIndex: "20000 !important" }}
              placement="right"
              title="Examination"
              arrow
            >
              <SubMenu
                label={collapsed ? "" : "Examination"}
                sx={{ backgroundColor: "transparent" }}
                icon={<SettingsIcon />}
              >
                <MenuItem
                  className="collapse-menu-view"
                  sx={{ backgroundColor: "transparent" }}
                >
                  <Item
                    title="Question"
                    to="/question"
                    icon={<PersonIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </MenuItem>
              </SubMenu>
            </Tooltip>
          </Box>
        </Menu>
      </Sidebar>
    </Box>
  );
};

export default MyProSidebar;
