import { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import { Grid, IconButton } from "@mui/material";
import { Form, useForm } from "../../../utils/useForm";
import Controls from "../../../components/controls/Controls";
import { useSnackbar } from "notistack";
import Close from "@mui/icons-material/Close";
import { useNavigate } from "react-router";
import {
  addClass,
  updateClass,
} from "../../../state/actions/Admin/classAction";
import { ToastContainer, toast } from "react-toastify";

const initialValues = {
  className: "",
};

const AddSettings = (props) => {
  const { handleClose, open, refreshScreen, isEdit, parameterData } = props;
  const [fieldDisabled, setFieldDisabled] = useState(true);

  const navigate = useNavigate();

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("className" in fieldValues) {
      temp.className = fieldValues.className ? "" : "This field is required.";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const body = {
        className: values.className,
      };

      if (isEdit) {
        const res = await updateClass(parameterData?.id, body);
        if (res && res.status === true) {
          close();
          refreshScreen();
          resetForm();
          handleClose();
          toast.success(res.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error(res.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
      if (!isEdit) {
        const res = await addClass(body);
        if (res && res.status === true) {
          close();
          setFieldDisabled(false);
          refreshScreen();
          resetForm();

          toast.success(res.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error(res.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
    }
  };

  const reset = () => {
    resetForm();
  };

  const close = () => {
    handleClose();
    setFieldDisabled(false);
  };

  const [confirmDialog, setConfirmDialog] = useState(false);

  const openConfirmDialog = () => {
    setConfirmDialog(true);
  };
  const closeConfirmDialog = () => {
    setConfirmDialog(false);
  };

  useEffect(() => {
    if (isEdit) {
      setValues({
        ...values,
        className: parameterData.className,
      });
    } else {
      resetForm();
    }
  }, [isEdit, parameterData]);

  return (
    <div>
      {/* add new user modal */}
      <Dialog
        open={open}
        onClose={openConfirmDialog}
        fullWidth={true}
        maxWidth="lg"
        sx={{
          width: { xs: "100%", sm: "400px", md: "500px" },
          marginLeft: {
            xs: "0px",
            sm: "30%",
            md: "430px",
          },
          color: "black",
        }}
      >
        <DialogTitle
          sx={{
            // fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "20px",
            lineHeight: "30px",
            display: "flex",
            justifyContent: "space-between",
            color: "black",
            alignItems: "center",
          }}
        >
          {isEdit ? "Edit Class " : "Add New Class"}
          <Tooltip title="Close">
            <IconButton
              onClick={() => {
                close();
              }}
            >
              <Close sx={{ color: "black" }} />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <DialogContent>
          <Form onSubmit={handleSubmit} id="addNewBuyer">
            <Grid
              container
              spacing={2}
              style={{
                marginTop: "1px",
              }}
            >
              <Grid
                item
                md={9}
                sm={8}
                xs={12}
                container
                spacing={1}
                sx={{ display: "contents" }}
              >
                <Grid item xs={12}>
                  <Controls.Input
                    label="Class Name"
                    name="className"
                    onChange={handleInputChange}
                    value={values.className}
                    error={errors.className}
                    helperText={errors.className}
                    sx={{ marginTop: "0px !important" }}
                    inputProps={{ maxLength: 25 }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </DialogContent>
        <DialogActions sx={{ margin: "10px" }}>
          <Grid container justifyContent={"flex-end"}>
            <Grid item className="text-end">
              <Controls.Button
                style={{
                  backgroundColor: "#fff",
                  marginRight: "7px",
                  color: "#000",
                }}
                text="Cancel"
                onClick={() => {
                  close();
                  resetForm();
                }}
              />
            </Grid>
            <Grid item className="text-end">
              <Controls.Button
                style={{ marginRight: "7px" }}
                text="Save"
                onClick={handleSubmit}
                type="submit"
              />
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </div>
  );
};

export default AddSettings;
