import { FinalResultHandler } from "@/state/actions/Admin/resultAction";
import { Box, Typography, Stack } from "@mui/material";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import True from "../../assets/ClientPass.png";
import False from "../../assets/false.jpg";

function StudentFinalResult() {
  const [marks, setMarks] = useState(0);
  const { state } = useLocation();
  const [totalMarks, setTotalMarks] = useState(0);
  const [result, setResult] = useState();
  const [message, setMessage] = useState("");

  useEffect(() => {
    resultHandler(state.id);
  }, []);

  const resultHandler = async (id) => {
    const data = await FinalResultHandler(id);
    setMessage(data?.data?.message)
    setTotalMarks(data?.data?.totalMark);
    setMarks(data?.data?.gainMarks);
    setResult(data?.data?.result);
  };
  return (
    <Box
      padding={"50px 0"}
      sx={{
        height: "calc(100vh - 200px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: result == "Pass" ? "space-between" : "center",
        alignItems: "center",
      }}
    >
      <Typography
        fontSize={"30px"}
        fontWeight={700}
        borderBottom={"3px solid var(--primary)"}
      >
        {message}
      </Typography>
      {result == "Pass" && (
        <img src={result == "Pass" ? True : ""} width={200} alt="" />
      )}
      {result == "Pass" && (
        <Typography
          fontSize={"25px"}
          fontWeight={700}
          border={"3px solid var(--primary)"}
          padding={"2px 12px"}
        >
          {`${marks} / ${totalMarks}`}
        </Typography>
      )}

      {result != "Pass" && (
        <Stack flexDirection={'row'} alignItems={'center'} gap={3} height={'300px'}>
          <Typography
            fontSize={"25px"}
            fontWeight={700}
            border={"3px solid var(--primary)"}
            padding={"2px 12px"}
          >
            {`${marks} / ${totalMarks}`}
          </Typography>

          <Typography fontSize={"20px"} fontWeight={700} border={"3px solid var(--primary)"}
            padding={"2px 12px"} width={'300px'}>
            {`Ask your teacher for help with the difficult words next lesson.`}
          </Typography>
        </Stack>
      )}

    </Box>
  );
}

export default StudentFinalResult;
