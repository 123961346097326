import { COURSE_DATA, SESSION_DATA } from "../action-types/commanAction";

const initialState = {
  session: {},
  course:{}
};

export default function commanReducer(
  state = initialState,
  action: { type: any, payload: any }
) {
  switch (action.type) {
    case SESSION_DATA:
      return {
        ...state,
        session: action.payload,
      };
    case COURSE_DATA:
      return {
        ...state,
        course: action.payload,
      };
    default:
      return state;
  }
}
