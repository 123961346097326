import styled from "@emotion/styled";
import {
  Box,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import { Table } from "react-bootstrap";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f6f9fb",
    color: "#000",
    fontSize: 13,
    border: 0,
    fontWeight: 600,
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "25px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    color: "rgb(17, 24, 39)",
    border: 0,
    paddingLeft: "25px",

    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    backgroundColor: "#fff",
    ":hover": { backgroundColor: "rgb(248 248 248)!important" },

    "&:nth-of-type(odd)": {
      backgroundColor: "rgb(250 250 250)!important",
    },
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function TopStudentList(data) {
  const studentLists = data.data || [];
  return (
    <Box
      sx={{
        backgroundColor: "rgb(241, 245, 249)",
        width: "100%",
        margin: "auto",
      }}
    >
      <Box
        sx={{
          border: 1,
          borderColor: "#eaeaea",
        }}
      >
        <TableContainer component={Paper}>
          <Typography
            sx={{
              paddingTop: "25px",
              marginLeft: "20px",
              paddingBottom: "15px",
              fontSize: "24px",
              fontWeight: "600",
              color: "#000",
              cursor: "pointer",
            }}
          >
            <h3>Top Students List </h3>
          </Typography>
          <Table aria-label="customized table" sx={{ width: "100%" }}>
            <TableHead>
              <TableRow>
                <StyledTableCell> Name</StyledTableCell>
                <StyledTableCell>Class Name</StyledTableCell>
                <StyledTableCell>Session Name</StyledTableCell>
                <StyledTableCell> Percentage </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {studentLists.length > 0 &&
                studentLists?.map((res) => {
                  return (
                    <StyledTableRow sx={{ cursor: "pointer" }}>
                      <StyledTableCell component="th" scope="row">
                        {res.firstName} {res.lastName}
                      </StyledTableCell>

                      <StyledTableCell component="th" scope="row">
                        {res.className}
                      </StyledTableCell>

                      <StyledTableCell component="th" scope="row">
                        {res.sessionName} 
                      </StyledTableCell>

                      <StyledTableCell component="th" scope="row">
                        {res.percentage} %
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
          {studentLists && studentLists.length === 0 && (
            <div
              className="noDataFound"
              style={{
                display: "flex",
              }}
            >
              {" "}
              No Data Found
            </div>
          )}
        </TableContainer>
      </Box>
    </Box>
  );
}

export default TopStudentList;
