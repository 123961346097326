import axiosRequest from "@/state/axios/axios"

export const topStudentListHandler = async () =>{
    try{
        const result = await axiosRequest.get("/getdashboarddata");
        return {
            success : result.data.status,
            message : result.data.message,
            data : result.data.data
        }
    }catch(err){
        return {
            success :  false,
            message :  err || "Some thing went Wrong",
            data : []
        }
    }
}