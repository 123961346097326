import React, { useEffect, useState } from "react";
import { Avatar, InputBase, MenuItem, Pagination, Select } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../../theme";
import Typography from "@mui/material/Typography";
import { Box, Divider, Grid } from "@mui/material";
import Stack from "@mui/material/Stack";
import { Tooltip, Drawer, FormLabel, TextField } from "@mui/material";
import { useForm } from "../../../utils/useForm";
import { Space } from "antd";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Button from "@mui/material/Button";
import Controls from "../../../components/controls/Controls";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import AddIcon from "@mui/icons-material/Add";
import BackdropComponent from "../../../components/BackDrop/BackDrop";
import { RiDeleteBinLine, RiEditLine } from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router";
import { GrView } from "react-icons/gr";
import Modal from "@mui/material/Modal";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import AddSessionModal from "./AddSession";
import { useSnackbar } from "notistack";
import {
  DeleteSession,
  StatusUpdate,
  getSessionLists,
} from "../../../state/actions/Admin/sessionAction";
import {
  getClassDropdownList,
  getCourseDropdownList,
} from "../../../state/actions/Admin/courseAction";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { duplicateSessionHandler } from "@/state/actions/Admin/questionAction";

const initialValues = {
  name: "",
};

const RecordPerPage = [
  { id: "10", title: "10" },
  { id: "20", title: "20" },
  { id: "30", title: "30" },
  { id: "40", title: "40" },
  { id: "50", title: "50" },
];

const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", sm: "400px" },
  border: "0",
  outline: "0",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: 3,
};

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  marginTop: "20px",
  marginBottom: "5px",
}));

const WhiteBorderTextField = styled(TextField)`
  & .MuiInputBase-input {
    color: black;
  }
  & .MuiTextField-root {
    padding-top: 20px;
  }
  & .MuiInputLabel-root.Mui-error {
    color: #fc9494;
  }
  & .MuiOutlinedInput-input {
    padding: 10px 14px !important;
    border-radius: 7px;
  }
  & .MuiInputLabel-root {
    line-height: 2.7;
    overflow: visible;
  }
  & .MuiInputLabel-root {
    color: rgb(92 91 91 / 70%) !important;
    font-weight: 500 !important;
    font-size: 14px;
    height: 20px;
  }
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: rgba(107, 114, 128, 0.5);
  }
  & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #000;
  }
  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    color: #4f46e5 !important;
    border-color: #4f46e5;
  }

  & .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #fc9494 !important;
  }
  & .MuiSvgIcon-root {
    color: black;
  }
`;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#000",
    fontSize: 13,
    border: 0,
    fontWeight: 600,
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "25px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    color: "rgb(17, 24, 39)",
    border: 0,
    paddingLeft: "25px",

    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    backgroundColor: "#fff",
    ":hover": { backgroundColor: "rgb(248 248 248)!important" },

    "&:nth-of-type(odd)": {
      backgroundColor: "rgb(250 250 250)!important",
    },
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
function createData(State, Status, Action) {
  return { State, Status, Action };
}
const rows = [createData("Alashka")];

export default function BasicTabs() {
  const [value, setValue] = useState(0);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [vehicalList, setVehicalList] = useState([]);
  const [pageLimit, setPageLimit] = useState(10);

  const [totalRecords, setTotalRecords] = useState(3);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [switchId, setSwitchId] = useState(null);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [status, setStatus] = useState();
  const [openSwitchModel, setOpenSwitchModel] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [search, setSearch] = useState("");
  const [fieldName, setFieldName] = useState("");
  const [sortFlag, setSortFlag] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [filter, setFilter] = useState(false);
  const [id, setId] = useState("");
  const [checked, setChecked] = useState(true);
  const [stateData, setStateData] = useState([]);
  const [flagName, setflagName] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const refreshScreen = () => {
    setRefresh(!refresh);
  };

  const handleSwitchModel = () => setOpenSwitchModel(true);
  const handleCloseSwitch = () => setOpenSwitchModel(false);

  const handleCloseDelete = () => setOpenDeleteModel(false);
  const handleDeleteModel = () => setOpenDeleteModel(true);

  const vehicalLists = [
    { id: "1", title: "Active" },
    { id: "0", title: "Inactive" },
  ];

  const handleChangePage = (e, value) => {
    if (fieldName == "sessionName") {
      VehicalTypeList(value, fieldName, flagName);
    } else {
      VehicalTypeList(value, "", "");
    }
    setPage(value);
  };

  const handleChangeRow = (event) => {
    if (fieldName == "sessionName") {
      VehicalTypeList(page, fieldName, flagName, event);
    } else {
      VehicalTypeList(page, "", "", event);
    }
  };


  const sessionDuplicateHandler = async (id) =>{
    const response = await duplicateSessionHandler(id);
    console.log("response-res",response);
    if(response.status){
      toast.success(response.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      VehicalTypeList(page, "", "");
    }else{
      toast.error(response.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  useEffect(() => {
    VehicalTypeList(page, "", "");
  }, [refresh, filterData, search]);

  const VehicalTypeList = async (page, name = "", flag, limit) => {
    setOpenBreakdrop(true);
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }
    let recordLimit = limit ? limit : pageLimit;

    let queryString = `?page=${page}&search=${search}${string}&limit=${recordLimit}&sortby=${name}&sortFlag=${sortFlag}`;

    const res = await getSessionLists(queryString);
    if (res.status === true) {
      setVehicalList(res.data);
      setTotalRecords(res.total);
      setTotalPages(Math.ceil(res.total / recordLimit));
      setPageLimit(recordLimit);

      setSortFlag(!sortFlag);
      setOpenBreakdrop(false);
    } else {
      setVehicalList([]);
      setOpenBreakdrop(false);
      setTotalRecords(0);
      setTotalPages(0);
    }
    setFilter(false);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const [classDropdown, setClassDropdown] = useState([]);
  const [CourseDropdown, setCourseDropdown] = useState([]);
  const ClassDropdownList = async () => {
    const res = await getClassDropdownList();
    if (res.status === true) {
      setClassDropdown(res.data);
    } else {
      setClassDropdown([]);
    }
  };
  useEffect(() => {
    ClassDropdownList();
  }, []);

  const getDetails = async () => {
    if (values.classID) {
      const res = await getCourseDropdownList(values.classID);
      setCourseDropdown(res.data);
    }
  };

  useEffect(() => {
    if (values.classID) {
      getDetails(values.classID);
    }
  }, [values.classID]);

  //status Change
  const handleChangeStatus = async () => {
    setOpenBreakdrop(true);
    setOpenBreakdrop(!openBackdrop);

    const res = await StatusUpdate(switchId);

    if (res && res.status === true) {
      if (fieldName == "sessionName") {
        VehicalTypeList(page, fieldName, flagName);
      } else {
        VehicalTypeList(page, "");
      }
      handleCloseSwitch();
      setOpenBreakdrop(false);
      toast.success(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      setOpenBreakdrop(!openBackdrop);

      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  //delete
  const handleDelete = async () => {
    const res = await DeleteSession(id);
    setOpenBreakdrop(true);
    if (res && res.status === true) {
      if (fieldName == "sessionName") {
        VehicalTypeList(page, fieldName, flagName);
      } else {
        VehicalTypeList(page, "");
      }
      handleCloseDelete();
      toast.success(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      setOpenBreakdrop(!openBackdrop);

      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  //filter
  const handleSubmit = (e) => {
    e.preventDefault();

    setFilterData(!filterData);
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const navigate = useNavigate();

  const dataShorting = (name) => {
    if (name === "sessionName") {
      setflagName(!flagName);
      VehicalTypeList(page, name, !flagName);
    } else {
      setflagName(false);
      setSortFlag(!sortFlag);
    }
    setFieldName(name);
  };

  //add Vehicle
  const [AddVehicle, setAddVehicle] = useState(false);
  const openVehicleModal = () => {
    setAddVehicle(true);
  };
  const closeVehicleModal = () => {
    setAddVehicle(false);
    setIsEdit(false);
  };

  return (
    <>
      <Box>
        <BackdropComponent open={openBackdrop} />

        <Box
          sx={{
            padding: { xs: "0 10px", md: "0 15px" },
            backgroundColor: "rgb(241, 245, 249)",
          }}
        >
          <Grid
            container
            sx={{
              padding: { xs: "20px 0", md: "40px 0" },
              alignItems: "center",
            }}
          >
            <Grid item sm={4} xs={12}>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: "25px", sm: "32px" },
                }}
              >
                Session List
              </Typography>
            </Grid>
            <Grid item sm={8} xs={12}>
              <Box
                sx={{
                  display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
                  marginTop: { xs: "10px", sm: "0", md: "0", lg: "0" },
                }}
                justifyContent="flex-end"
              >
                <Box
                  border={1}
                  borderColor="rgba(0, 0, 0, .1)"
                  alignItems="center"
                  display="flex"
                  backgroundColor="#ffffffa3"
                  p={0.4}
                  borderRadius={10}
                >
                  <SearchIcon
                    style={{
                      color: colors.grey[300],
                    }}
                    sx={{ ml: 1, fontSize: "24px" }}
                  />
                  <InputBase
                    name="search"
                    sx={{ ml: 1, flex: 1, color: "#000", fontSize: "16px" }}
                    placeholder="Search"
                    onChange={(e) => {
                      setTimeout(() => {
                        setSearch(e.target.value);
                      }, 800);
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  sx={{ marginTop: { xs: "10px", sm: "0", md: "0", lg: "0" } }}
                >
                  <Controls.Button
                    startIcon={<AddIcon />}
                    text="Add Session"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      borderRadius: "50px",
                      border: "0",
                      color: "#fff",
                      ":hover": { bgcolor: "rgb(79, 70, 229)", border: "0" },
                      backgroundColor: "rgb(79, 70, 229)",
                      marginLeft: {
                        xs: "0px",
                        sm: "15px",
                        md: "15px",
                        lg: "15px",
                      },
                      textTransform: "none",
                    }}
                    onClick={() => {
                      openVehicleModal();
                      setIsEdit(false);
                    }}
                    variant="outlined"
                  />

                  <Button
                    variant="outlined"
                    startIcon={<FilterAltIcon />}
                    onClick={openFilterDrawer}
                    sx={{
                      marginLeft: "15px",
                      borderRadius: "50px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                      color: "#fff",
                      ":hover": { bgcolor: "rgb(30, 41, 59)" },
                      background: "rgb(30, 41, 59)",
                      fontSize: "16px",
                      fontWeight: "500",
                      textTransform: "none",
                    }}
                  >
                    Filter
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            backgroundColor: "rgb(241, 245, 249)",
            width: "98%",
            margin: "auto",
          }}
        >
          <Box
            sx={{
              border: 1,
              borderColor: "#eaeaea",
            }}
          >
            <TableContainer component={Paper}>
              <Table aria-label="customized table" sx={{ width: "100%" }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Id</StyledTableCell>
                    <StyledTableCell>Class Name</StyledTableCell>
                    <StyledTableCell>Course Name</StyledTableCell>
                    <StyledTableCell
                      onClick={() => dataShorting("sessionName")}
                    >
                      Session Name
                      {flagName ? (
                        <ArrowUpwardIcon
                          sx={{
                            color: "rgba(107, 114, 128, .5)",
                            fontSize: "17px",
                            marginLeft: "3px",
                          }}
                        />
                      ) : (
                        <ArrowDownwardIcon
                          sx={{
                            color: "rgba(107, 114, 128, .5)",
                            fontSize: "17px",
                            marginLeft: "3px",
                          }}
                        />
                      )}
                    </StyledTableCell>

                    <StyledTableCell>Sequence No</StyledTableCell>
                    <StyledTableCell>
                      <Button
                        variant="text"
                        sx={{
                          ":hover": { bgcolor: "transparent" },
                          paddingLeft: "0",
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "capitalize",
                          justifyContent: "left",
                        }}
                      >
                        Status
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Button
                        variant="text"
                        sx={{
                          ":hover": { bgcolor: "transparent" },
                          paddingLeft: "0",
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "capitalize",
                          justifyContent: "left",
                        }}
                      >
                        Action
                      </Button>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vehicalList.map((row, index) => (
                    <StyledTableRow key={index} sx={{ cursor: "pointer" }}>
                      <StyledTableCell component="th" scope="row">
                        {row.id}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.className}
                      </StyledTableCell>{" "}
                      <StyledTableCell component="th" scope="row">
                        {row.courseName}
                      </StyledTableCell>{" "}
                      <StyledTableCell component="th" scope="row">
                        {row.sessionName}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.sequenceNo}
                      </StyledTableCell>{" "}
                      <StyledTableCell>
                        <Controls.Switch
                          checked={row.isActive == 1 ? true : false}
                          onChange={(e) => {
                            handleSwitchModel();
                            setStatus(
                              e.target.checked == true ? "Active" : "InActive"
                            );
                            setSwitchId(row.id);
                            setChecked(e.target.checked);
                          }}
                          name="checkedB"
                          color="primary"
                          sx={{ marginLeft: "40px" }}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        {console.log("roww-", row)}

                        <Space size="middle">
                          {!row.parentId && (
                            <Tooltip
                              placement="bottom"
                              className="cursor-pointer"
                              title="revise Session"
                            >
                              <button
                                className="action-button revise"
                                onClick={() => {
                                  sessionDuplicateHandler(row.id)
                                  
                                  // openVehicleModal();
                                  // setStateData(row);
                                  // setIsEdit(true);
                                }}
                              >
                                <ContentCopyIcon fontSize="small" />
                              </button>
                            </Tooltip>
                          )}
                          <Tooltip
                            placement="bottom"
                            className="cursor-pointer"
                            title="Edit Session"
                          >
                            <button
                              className="action-button edit "
                              onClick={() => {
                                openVehicleModal();
                                setStateData(row);
                                setIsEdit(true);
                              }}
                            >
                              <RiEditLine />
                            </button>
                          </Tooltip>
                          <Tooltip placement="bottom" title="Delete Session">
                            <button
                              className="action-button delete-btn"
                              onClick={() => {
                                setId(row.id);
                                setDeleteId(row?.id);
                                handleDeleteModel();
                              }}
                            >
                              <RiDeleteBinLine />
                            </button>
                          </Tooltip>
                        </Space>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
              {vehicalList && vehicalList.length === 0 ? (
                <div
                  className="noDataFound"
                  style={{
                    display: "flex",
                  }}
                >
                  {" "}
                  No Data Found
                </div>
              ) : null}
            </TableContainer>
          </Box>

          <AddSessionModal
            open={AddVehicle}
            setOpen={setAddVehicle}
            handleClickOpen={openVehicleModal}
            handleClose={closeVehicleModal}
            stateData={stateData}
            refreshScreen={refreshScreen}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
          />
        </Box>

        <Box
          sx={{
            display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
            marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
            mt: 2,
            mb: 2,
          }}
        >
          <Box
            sx={{
              fontSize: "12px",
              marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
              gap: 2,
            }}
            display="flex"
            alignItems="center"
          >
            <Box>
              Showing {vehicalList && vehicalList.length} of {totalRecords}{" "}
              Results
            </Box>
            <Box display="flex" sx={{ gap: 1 }} alignItems="center">
              <Typography fontSize={"12px"}>Records per page</Typography>
              <Select
                className="recordLimit"
                sx={{
                  color: "#000",
                  height: "30px",
                  fontSize: "12px",
                  borderRadius: "5px",
                  border: 1,
                  borderColor: "#000",
                  ".MuiSvgIcon-root ": {
                    fill: "black !important",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                }}
                id="limites"
                name="limites"
                value={pageLimit}
                onChange={(e) => {
                  handleChangeRow(e.target.value);
                }}
              >
                {RecordPerPage.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name || item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </Box>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            sx={{
              "& .MuiButtonBase-root ": {
                color: "#000 !important",
              },
              "& .Mui-selected ": {
                background: "#4b4b4e70 !important",
              },
              "& .Mui-disabled ": {
                background: "#6c696966 !important",
                color: "#000000 !important",
                opacity: 1,
              },
            }}
          />
        </Box>

        <Modal
          keepMounted
          open={openSwitchModel}
          onClose={handleCloseSwitch}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={styles}>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "#000",
                marginBottom: "15px",
              }}
            >
              Status Change
            </Typography>
            <label className="fontLight">
              Are you sure change status to {status} ?
            </label>
            <Grid
              container
              style={{ marginTop: "2rem" }}
              justifyContent={"flex-end"}
            >
              <Grid item className="text-end">
                <Controls.Button
                  style={{
                    backgroundColor: "#fff",
                    color: "#000",
                    marginRight: "10px",
                  }}
                  text="Cancel"
                  onClick={handleCloseSwitch}
                />
              </Grid>
              <Grid item className="text-end">
                <Controls.Button
                  style={{ marginRight: "7px" }}
                  text="Change"
                  onClick={() => handleChangeStatus(switchId)}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Modal
          keepMounted
          open={openDeleteModel}
          onClose={handleCloseDelete}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={styles}>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "#000",
                marginBottom: "15px",
              }}
            >
              Delete Record
            </Typography>
            <label className="fontLight">
              Are you sure to want to delete this record?
            </label>
            <Grid
              container
              style={{ marginTop: "2rem" }}
              justifyContent={"flex-end"}
            >
              <Grid item className="text-end">
                <Controls.Button
                  style={{
                    backgroundColor: "#fff",
                    color: "#000",
                    marginRight: "10px",
                  }}
                  text="Cancel"
                  onClick={handleCloseDelete}
                />
              </Grid>
              <Grid item className="text-end">
                <Controls.Button
                  style={{ marginRight: "7px" }}
                  text="Delete"
                  onClick={() => handleDelete(deleteId)}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form
            onSubmit={handleSubmit}
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              backgroundColor: "#fff",
            }}
          >
            <Box
              sx={{
                Width: { xs: "100%", sm: "300px", md: "300px", lg: "300px" },
                padding: "10px 20px",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  sx={{ fontWeight: "bold", color: "#000", fontSize: "30px" }}
                >
                  Filter
                </Typography>

                <AiOutlineCloseCircle
                  className="close-button"
                  onClick={closeFilterDrawer1}
                />
              </Stack>

              <Box
                sx={{
                  marginTop: "5px",
                }}
              >
                <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
                <StyledFormLabel htmlFor="name">Session Name</StyledFormLabel>
                <WhiteBorderTextField
                  className="input"
                  fullWidth
                  id="sessionName"
                  name="sessionName"
                  value={values.sessionName}
                  onChange={handleInputChange}
                  sx={{ mt: 0 }}
                />
              </Box>
              <Box>
                <StyledFormLabel htmlFor="hsnCode">Class Name</StyledFormLabel>
                <Controls.SelectBox
                  className="input select_box"
                  fullWidth
                  id="classID"
                  name="classID"
                  value={values.classID}
                  onChange={handleInputChange}
                  options={classDropdown}
                />
              </Box>
              <Box>
                <StyledFormLabel htmlFor="hsnCode">Course Name</StyledFormLabel>
                <Controls.SelectBox
                  className="input select_box"
                  fullWidth
                  id="courseID"
                  name="courseID"
                  value={values.courseID}
                  onChange={handleInputChange}
                  options={CourseDropdown}
                  error={errors.courseID}
                  helperText={errors.courseID}
                />
              </Box>

              <Box>
                <StyledFormLabel htmlFor="hsnCode">Status</StyledFormLabel>
                <Controls.SelectBox
                  className="input select_box"
                  fullWidth
                  id="status"
                  name="isActive"
                  value={values.isActive}
                  onChange={handleInputChange}
                  options={vehicalLists}
                />
              </Box>
            </Box>
            <Stack sx={{ margin: "5px 20px 20px 20px" }} flexDirection="row">
              <button className="cancel-filter" onClick={closeFilterDrawer}>
                Reset
              </button>
              <button
                className="order-filter-button"
                type="submit"
                style={{
                  cursor: "pointer",
                }}
              >
                Filter
              </button>
            </Stack>
          </form>
        </Drawer>
      </Box>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </>
  );
}
