import axiosRequest from "../axios/axios";


export const submitTest = async (payload) => {
    try {
        const response = await axiosRequest.post(`/result`, payload);
        return response.data;
    } catch (error) {
        return error;
    }
}