import { SESSION_DATA } from "../action-types/commanAction";
import axiosRequest from "../axios/axios";

//get Session List
export const getSessionData = async (courseId) => {
  try {
    const response = await axiosRequest.get(`/getSessionData/${courseId}`);
    return response.data;
  } catch (error) {
    return error;
  }
};




export const sessionDataHandler = (data) => (dispatch)=>{
  dispatch({type:SESSION_DATA,payload:data})
}


