import axiosRequest from "../../axios/axios";

//get setting List
export const getSessionLists = async (queryString) => {
  try {
    const response = await axiosRequest.get(`sessionList` + queryString);
    return response.data;
  } catch (error) {
    return error;
  }
};

//add setting

export const AddSession = async (payload) => {
  try {
    const response = await axiosRequest.post(`sessionAdd`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//setting Delete
export const DeleteSession = async (id) => {
  try {
    const response = await axiosRequest.delete(`sessionDelete/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//setting Status Update

export const StatusUpdate = async (id) => {
  try {
    const response = await axiosRequest.put(`sessionStatusUpdate/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//setting update

export const updateSession = async (id, payload) => {
  try {
    const response = await axiosRequest.patch(`sessionUpdate/${id}`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getCourseDropdownList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`/getcourselist`);
    return response.data;
  } catch (error) {
    return error;
  }
};
