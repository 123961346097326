import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

const WhiteBorderTextField = styled(TextField)`
  & .MuiInputBase-input {
    color: black;
  }
  & .MuiInputLabel-root.Mui-error {
    color: #fc9494;
  }
  & .MuiInputBase-root {
    padding: 10.5px 14px !important;
    border-radius: 7px;
  }
  & .MuiInputLabel-root {
    line-height: 1.7;
    overflow: visible;
  }
  & .MuiInputLabel-root {
    color: rgb(92 91 91 / 100%) !important;
    font-weight: 500 !important;
    font-size: 14px;
    height: 20px;
  }
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: rgba(107, 114, 128);
  }
  & label.Mui-focused {
    color: #4f46e5 !important;
    border-color: black;
  }

  & label.Mui-error {
    color: #fc9494 !important;
  }
  & .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #fc9494 !important;
  }
  & .MuiSvgIcon-root {
    color: black;
  }
`;
const Input = (props) => { 
  const {
    name,
    type,
    value,
    onChange,
    disabled,
    maxRows = 1,
    error = null,
    label,
    style,
    onblur,
    autoFocus,
    ...other
  } = props;

  const [ClassName, setClassName] = useState("");

  useEffect(() => {
    if (other && other.className) {
      setClassName(ClassName + other.className);
    }
  }, []);

  return (
    <WhiteBorderTextField
      name={name}
      type="number"
      value={value}
      onChange={onChange}
      {...other}
      {...(error && { error: true, helperText: error })}
      multiline
      disabled={disabled}
      minRows={maxRows}
      className={ClassName}
      sx={{
        "& .MuiOutlinedInput-root:hover": {
          "& > fieldset": {
            borderColor: "black",
          },
        },
        "&.Mui-focused .MuiOutlinedInput-root": {
          borderColor: "#4f46e5!important",
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: "#4f46e5!important",
          },
        },

        mt: 1,
        color: "white",
        borderRadius: "12px",
      }}
      size="small"
      variant="outlined"
      fullWidth
      autoFocus={autoFocus}
      onBlur={onblur}
      label={label}
    />
  );
};

export default Input;
