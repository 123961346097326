import { Box } from "@mui/system";
import React from "react";
import CourseList from "./CourseList";

const Course = () => {
    return (
        <Box>
            <CourseList />
        </Box>
    );
};

export default Course;


