import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import True from "../../assets/ClientPass.png";
import Logo from "../../assets/Images/Logo.png";
import bottomRight from "../../assets/bg/bottomRight.png";
import topRight from "../../assets/bg/topRight.png";
import { useLocation, useNavigate } from "react-router-dom";
import { FinalResultHandler } from "@/state/actions/Admin/resultAction";

function NewResult() {
  const navigate = useNavigate();
  const [marks, setMarks] = useState(0);
  const { state } = useLocation();
  const [totalMarks, setTotalMarks] = useState(0);
  const [result, setResult] = useState();
  const [message, setMessage] = useState("");
  const [passData, setPassData] = useState([]);
  const [failData, setFailData] = useState([]);
  const [questionType, setQuestionType] = useState([]);

  useEffect(() => {
    resultHandler(state?.id);
  }, []);

  const resultHandler = async (id) => {
    const data = await FinalResultHandler(id);
    console.log("data", data);
    setMessage(data?.data?.message);
    setTotalMarks(data?.data?.totalMark);
    setMarks(data?.data?.gainMarks);
    setResult(data?.data?.result);
    setQuestionType(data?.data?.questionType);
    setPassData(data?.data?.passData || []);
    setFailData(data?.data?.failData || []);
    // setFailData(data?.data?.failData || []);
  };
  return (
    <>
      <div className="w-full lg:h-screen h-fit p-3 relative">
        <div className=" bg-[var(--primary)] cursor-pointer text-white px-3 py-2 rounded-3xl w-fit mb-2" onClick={() => { navigate('/') }}>Home</div>
        <div className="final_result_grid gap-3 sm:!gap-4 lg:!gap-10 h_web items-center">
          {
            passData && passData.length > 0 &&
            <div className="bg-[#32AD95] rounded-[30px] grid_item_one">
              <div className="text-xl bg-[#F5B816] rounded-[30px] px-4 py-3 font-semibold text-center">
                Words you know and can try to use next lesson:
              </div>
              <div className="p-3 grid gap-2">
                {passData.map((res, index) => (
                  <div className="bg-white px-3 py-2 text-lg rounded-3xl">
                    {questionType === 1
                      ? res.studentAns === "1"
                        ? "true"
                        : "false"
                      : res.studentAns}
                  </div>
                ))}
              </div>
            </div>
          }
          <div className="relative h-full flex items-center grid_item_two">
            <div className="border-8 border-[#c6e8e1] h-fit rounded-[50px] z-50 w-full">
              <Box
                padding={{ xs: "20px", md: "30px", lg: "50px" }}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: { xs: "10px", md: "15px", lg: "20px" },
                  width: "100%",
                }}
              >
                {/* <Typography
                  fontSize={{ xs: "16px", sm: "21px", md: "25px" }}
                  fontWeight={700}
                  width={"100%"}
                  textAlign={"center"}
                  borderBottom={"2px solid var(--primary)"}
                >
                  {message}
                </Typography> */}
                {/* { <img src={True} width={120} alt="" /> } */}
                {/* <Typography
                  fontSize={"25px"}
                  fontWeight={700}
                  border={"3px solid var(--primary)"}
                  padding={"2px 12px"}
                >
                  {`${marks} / ${totalMarks}`}
                </Typography> */}
                {/* <Stack flexDirection={"row"} gap={"20px"} width={"100%"}>
                  <Typography
                    className="modal_head"
                    width={"100%"}
                    fontSize={"30px"}
                    fontWeight={"700"}
                    sx={{ borderBottom: "2px solid var(--primary)" }}
                  ></Typography>
                  <img src={Logo} width={"100px"} />
                </Stack> */}
                <Typography
                  fontSize={{ xs: "16px", sm: "21px", md: "25px" }}
                  fontWeight={700}
                  textAlign={{ xs: 'center', sm: 'start' }}
                  borderBottom={"2px solid var(--primary)"}
                >
                  {message}
                </Typography>
                {result == "Pass" && (
                  <img src={result == "Pass" ? True : ""} width={200} alt="" />
                )}
                {result == "Pass" && (
                  <Typography
                    fontSize={"25px"}
                    fontWeight={700}
                    border={"3px solid var(--primary)"}
                    padding={"2px 12px"}
                  >
                    {`${marks ? marks : 0} / ${totalMarks ? totalMarks : 0}`}
                  </Typography>
                )}

                {result != "Pass" && (
                  <Stack
                    flexDirection={{ xs: 'column-reverse', sm: "row" }}
                    alignItems={"center"}
                    gap={3}
                    height={"300px"}
                    justifyContent={'center'}
                  >
                    <Typography
                      fontSize={"25px"}
                      fontWeight={700}
                      border={"3px solid var(--primary)"}
                      padding={"2px 12px"}
                    >
                      {`${marks ? marks : 0}  / ${totalMarks ? totalMarks : 0}`}
                    </Typography>

                    <Typography
                      fontSize={{ xs: "16px", sm: "18px", md: "20px" }}
                      fontWeight={700}
                      textAlign={{ xs: 'center', sm: 'start' }}
                      border={"3px solid var(--primary)"}
                      padding={"2px 12px"}
                      width={{ sm: "300px" }}
                    >
                      {`Ask your teacher for help with the difficult words next lesson.`}
                    </Typography>
                  </Stack>
                )}
              </Box>
            </div>
            <div className="bg-[#DF1E50] lg:block hidden sm:!px-3 px-2 sm:!py-2 py-1 rounded-lg absolute bottom-0 left-[50%] translate-x-[-50%] z-50">
              <a href="mailto:naomi.wineman.18@ucl.ac.uk" className="text-white text-xs sm:text-sm md:text-base">
                naomi.wineman.18@ucl.ac.uk
              </a>
            </div>
            <div className="absolute top-0 lg:block hidden left-12 z-10">
              <img
                src={topRight}
                alt=""
                className="w-[50px] sm:w-[80px] md:w-[120px]"
              />
            </div>
            <div className="absolute bottom-0 lg:block hidden right-[-10px] lg:right-[-10px] z-10">
              <img
                src={bottomRight}
                alt=""
                className="w-[80px] sm:w-[110px] md:w-[140px]"
              />
            </div>
          </div>
          {
            failData && failData.length > 0 &&
            <div className="bg-[#DF1E50] rounded-[30px] grid_item_three">
              <div className="text-xl bg-[#F5B816] rounded-[30px] px-4 py-3 font-semibold text-center">
                Words you could ask about next lesson
              </div>
              <div className="p-3 grid gap-2">
                {failData.map((res, index) => (
                  <div className="bg-white px-3 py-2 text-lg rounded-3xl">
                    {questionType === 1
                      ? res.studentAns === "1"
                        ? "true"
                        : "false"
                      : res.studentAns}
                  </div>
                ))}
              </div>
            </div>
          }
        </div>
      </div>
    </>
  );
}

export default NewResult;