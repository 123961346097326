import {
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
} from "../action-types/authActionTypes";
import axios from "axios";
import Cookies from "universal-cookie";
import axiosRequest from "../axios/axios";
const cookies = new Cookies();
const BASE_URL = process.env.REACT_APP_API_URL;

export const loadUserApi = () => async (dispatch) => {
  if (localStorage.token) {
    axiosRequest.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.token}`;
  }
  try {
    const res = await axiosRequest.get(`/detailsByToken`);

    if (res.data && res.data.status === true) {
      dispatch({
        type: USER_LOADED,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: AUTH_ERROR,
      });
    }
    return res.data;
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
    return { success: false, message: err.response?.data.message };
  }
};

export const loginUserApi = (payload, rememberMe) => async (dispatch) => {
  try {
    const res = await axiosRequest.post(`/adminLogin`, payload);
    if (res.data && res.data.status === true) {
      localStorage.setItem("authUser", JSON.stringify(res.data.data));
      
      if (rememberMe === true) {
        cookies.set("token", res.data.data.token, { path: "/" });
        cookies.set("email", payload.email, { path: "/" });
        cookies.set("password", payload.password, { path: "/" });
      }
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: LOGIN_FAIL,
      });
    }
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};

export const logoutApi = () => (dispatch) => {
  dispatch({ type: LOGOUT });
};

export const chnagePassword = async (payload) => {
  try {
    const response = await axiosRequest.post(`/changePassword`, payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
};


