import React from "react";
import { useDrag } from "react-dnd";

function ThirdAns({ answer ,disable}) {
  const [{ isDragging }, drag] = useDrag({
    type: "ANSWER",
    item: { answer },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  return (
  <div 
  ref={disable ? null: drag}
  style={{
    opacity: disable ? 0.5 : 1,
    cursor: 'move',
    marginBottom: '10px',
    padding: '5px',
    border: '1px solid #ccc',
    borderRadius: '5px',
  }}
  >
    {answer}
  </div>
  );
}

export default ThirdAns;
