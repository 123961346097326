import { useLocation, useNavigate } from "react-router-dom";
import TickMark from "../../assets/Images/resultTick.png";
import "./Result.css";
import { useEffect, useState } from "react";
import { ansHandler } from "@/state/actions/Admin/questionAction";
import { useDispatch } from "react-redux";

function Result() {
  const [resultData,setResultData] = useState("");
  window.scrollTo(0, 0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  useEffect(()=>{
    dispatch(ansHandler([]));
    if(state){
      setResultData(state);
    }
  },[])
  const windowHeight = window.innerHeight;
  const regularHeight = windowHeight > 750;

  const Homehandler = () => {
    navigate("/");
    dispatch(ansHandler([]));
  };


  return (
    <>
      <div className="h-full flex flex-col p-14 max-[750px]:p-7 max-[450px]:px-5">
        <div className="flex flex-col pb-2">
          <span className="primary-font">Test Result</span>
        </div>
        <div
          className="flex flex-col items-center justify-evenly"
        >
          <div className="rounded-[30px] w-full flex flex-col px-[59px] max-[1050px]:px-[40px] max-[830px]:px-[20px] max-[992px]: border-[3px] justify-between py-6 border-[##D3D3D3]">
            <div className="flex flex-col">
              <span className="primary-font">{resultData[0]?.courseName}</span>
              <div className="flex justify-between max-[420px]:flex-col max-[420px]:gap-y-2">
                <div className="text-[25px] font-normal max-[750px]:text-[20px]  max-[600px]:text-[15px]">
                  {resultData[0]?.sessionName}
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center">
              <div className="bg-[--primary] min-[600px]:p-5 max-[600px]:p-3 rounded-full">
                <img src={TickMark} alt="../tick" className="w-[50px] h-auto" />
              </div>
              <div className="font-semib-36">Congratulations!!</div>
              <div className="font-semib-24">You have passed this test!</div>
              <div className="result-container">
                <div className="flex flex-col items-center divider">
                  <span className="font-semib-24">Total Question</span>
                  <span className="text-[--primary] font-semib-30">{resultData[0]?.total}</span>
                </div>
                <div className="flex flex-col items-center divider">
                  <span className="font-semib-24">Correct</span>
                  <span className="text-[--primary] font-semib-30">{resultData[0]?.correct}</span>
                </div>
                <div className="flex flex-col items-center divider">
                  <span className="font-semib-24">Incorrect</span>
                  <span className="text-[--primary] font-semib-30">{resultData[0]?.inCorrect}</span>
                </div>
                <div className="flex flex-col items-center divider">
                  <span className="font-semib-24">Percentage</span>
                  <span className="text-[--primary] font-semib-30">{`${resultData[0]?.percentage} %`}</span>
                </div>
              </div>
              <div className="mt-8 mb-3">
                <span
                  onClick={() => Homehandler()}
                  className="cursor-pointer bg-[--primary] font-archia rounded-full px-8 py-3 max-[750px]:px-6 text-white max-[450px]:px-3 text-[18px] max-[992px]:text-[17px] max-[750px]:text-[14px] font-semibold"
                >
                  Back to Home
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Result;
