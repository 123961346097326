import { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import { Grid, IconButton } from "@mui/material";
import { Form, useForm } from "../../../utils/useForm";
import Controls from "../../../components/controls/Controls";
import Close from "@mui/icons-material/Close";
import { ToastContainer, toast } from "react-toastify";
import { getClassDropdownList, getClassCourseDropdownList, cloneClass } from "../../../state/actions/Admin/classAction";

const initialValues = {
  classID: "",
  classID1: "",
};

const CloneModal = (props) => {
  const { handleClose, open, change, classDropdownWithoutSession, classDropdown, refreshScreen, isEdit, parameterData } = props;

  const [disable, setDisable] = useState(false);


  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("classID" in fieldValues) {
      temp.classID = fieldValues.classID ? "" : "This field is required.";
    }
    if ("classID1" in fieldValues) {
      temp.classID1 = fieldValues.classID1 ? "" : "This field is required.";
    }
    console.log("this is for twst", fieldValues.classID, fieldValues.classID1);
    if (fieldValues.classID == fieldValues.classID1 && fieldValues.classID !== "" && fieldValues.classID1 !== "") {
      temp.classID1 = "Please select different class name. same class name not allowed.";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const body = {
        oldClassId: values.classID,
        newClassId: values.classID1,
      };

      const res = await cloneClass(body);
      if (res && res.status) {
        close();
        refreshScreen();
        resetForm();
        setDisable(false);

        toast.success(res.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        setDisable(false);
        toast.error(res.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }

    }
  };

  const close = () => {
    handleClose();
  };

  const [confirmDialog, setConfirmDialog] = useState(false);

  const openConfirmDialog = () => {
    setConfirmDialog(true);
  };
  const closeConfirmDialog = () => {
    setConfirmDialog(false);
  };

  useEffect(() => {
    if (isEdit) {
      setValues({
        ...values,
        className: parameterData.className,
      });
    } else {
      resetForm();
    }
  }, [isEdit, parameterData]);





  return (
    <div>
      {/* add new user modal */}
      <Dialog
        open={open}
        onClose={openConfirmDialog}
        fullWidth={true}
        maxWidth="lg"
        sx={{
          width: { xs: "100%", sm: "400px", md: "500px" },
          marginLeft: {
            xs: "0px",
            sm: "30%",
            md: "430px",
          },
          color: "black",
        }}
      >
        <DialogTitle
          sx={{
            // fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "20px",
            lineHeight: "30px",
            display: "flex",
            justifyContent: "space-between",
            color: "black",
            alignItems: 'center'
          }}
        >
          Clone Class
          <Tooltip title="Close">
            <IconButton
              onClick={() => {
                close();
              }}
            >
              <Close sx={{ color: "black" }} />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <DialogContent>
          <Form onSubmit={handleSubmit} id="addNewBuyer">
            <Grid
              container
              spacing={2}
              style={{
                marginTop: "1px",
              }}
            >
              <Grid
                item
                md={9}
                sm={8}
                xs={12}
                container
                spacing={1}
                sx={{ display: "contents" }}
              >
                <Grid item xs={12}>
                  <Controls.SelectBox
                    className="input select_box"
                    label="Select Class Name"
                    fullWidth
                    id="classID"
                    name="classID"
                    value={values.classID}
                    onChange={handleInputChange}
                    options={classDropdown}

                  />
                  {Boolean(errors.classID) ? (
                    <p
                      style={{
                        color: "rgb(255 0 0)",
                        marginTop: "4px",
                        marginBottom: "4px",
                        fontSize: "14px",
                      }}
                    >
                      {errors.classID}
                    </p>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Controls.SelectBox
                    className="input select_box"
                    label="Select New Class Name"
                    fullWidth
                    id="classID1"
                    name="classID1"
                    value={values.classID1}
                    onChange={handleInputChange}
                    options={classDropdownWithoutSession}
                  />
                  {Boolean(errors.classID1) ? (
                    <p
                      style={{
                        color: "rgb(255 0 0)",
                        marginTop: "4px",
                        marginBottom: "4px",
                        fontSize: "14px",
                      }}
                    >
                      {errors.classID1}
                    </p>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </DialogContent>
        <DialogActions sx={{ margin: "10px" }}>
          <Grid container justifyContent={'flex-end'}>
            <Grid item className="text-end">
              <Controls.Button
                style={{
                  backgroundColor: "#fff",
                  marginRight: "7px",
                  color: "#000",
                }}
                text="Cancel"
                onClick={() => {
                  close();
                  resetForm();
                }}
              />
            </Grid>
            <Grid item className="text-end">
              <Controls.Button
                style={{ marginRight: "7px" }}
                text="Save"
                disabled={disable}
                onClick={(e) => {
                  handleSubmit(e)
                  setDisable(true)
                }}
                type="submit"
              />
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </div>
  );
};

export default CloneModal;
