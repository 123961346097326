import Logo from "../../assets/Images/Logo.png";
import React, { useState, useEffect } from "react";
import { useForm, Form } from "../../utils/useForm";
import { loginUserApi } from "../../state/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import { store } from "../../state/store";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { EventEmitter } from "@/utils/helper";
const initialValues = { email: "", password: "" };

function Login() {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);


  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const cookies = new Cookies();
  const isAuthenticated = useSelector((store) => store.auth.isAuthenticated);
  const userAdmin = useSelector((store) => store?.auth?.user);
  if (isAuthenticated && userAdmin.roleID === 2) {
    Navigate("/");
  }
  useEffect(() => {

    const userEmail = cookies.get("email");
    const userPassword = cookies.get("password");
    if (userEmail !== "" && userPassword !== "") {
      setValues({
        ...values,
        email: userEmail,
        password: userPassword,
      });
    }
  }, []);
  useEffect(() => { }, [loadingButton]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("email" in fieldValues) {
      if (fieldValues.email !== "") {
        if (!/$^|.+@.+..+/.test(fieldValues.email)) {
          temp.email = "Invalid Email";
        } else {
          temp.email = "";
        }
      } else {
        temp.email = "This field is required.";
      }
    }
    if ("password" in fieldValues) {
      if (fieldValues.password !== "") {
        temp.password = "";
      } else {
        temp.password = "This field is required.";
      }
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      setLoadingButton(!loadingButton);
      const LoginResponse = await dispatch(
        loginUserApi(
          {
            email: values.email,
            password: values.password,
            type: 2,
          },
          rememberMe
        )
      );
      console.log("LoginResponse",LoginResponse);
      if (LoginResponse && LoginResponse.status === true) {
        setLoadingButton(!loadingButton);
        localStorage.setItem("trailOpen",true);
        localStorage.setItem("trailStatus","ASKTRAIL");
        EventEmitter.dispatch('trailUpdate',true);
        toast.success(LoginResponse.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        setLoadingButton(false);
        toast.error(LoginResponse.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };
  const [activeSpan, setActiveSpan] = useState("home");
  const handleSpanClick = (span) => {
    setActiveSpan(span);
    Navigate(`/${span}`);
  };
  return (
    <>
      <div className="bg-[--primaryLight] h-full">
        <div className="flex px-[86px] py-2 max-[1100px]:px-[65px]">
          <div>
            <img
              src={Logo} onClick={() => handleSpanClick("")}
              alt="logo"
              className="h-[92px] w-[139px] max-[700px]:hidden"
            />
          </div>
        </div>
        <Form onSubmit={handleSubmit}>
          <div
            className="flex flex-col justify-center items-center gap-y-10 h-[calc(100vh-110px)] max-[700px]:h-[calc(100vh-16px)]"
          >
            <div>
              <div className="primary-font">
                Log In
              </div>
              <div className="py-[2px] mt-2 relative top-[-13px] w-[35px] bg-[--primary] max-[480px]:w-[25px]"></div>
            </div>

            <div className="flex flex-col font-nunito w-[27%] max-[1200px]:w-[32%] max-[992px]:w-[40%] max-[600px]:w-[50%] max-[480px]:w-[60%] max-[400px]:w-[70%]">
              <label className="font-nunito-20">Email</label>
              <input maxLength={100} type="email" onChange={handleInputChange} name="email" value={values.email}
                className="border-[3px] input_Placeholder bg-[--primaryLight] font-nunito-20 border-black rounded-full py-3 px-5"
                placeholder="Enter Your Email"
              />
              {Boolean(errors.email) ? (
                <p
                  style={{
                    color: "#fc9494",
                    marginTop: "4px",
                    fontSize: "14px",
                  }}
                >
                  {errors.email}
                </p>
              ) : (
                ""
              )}

            </div>
            <div className="flex flex-col font-nunito w-[27%] max-[1200px]:w-[32%] max-[992px]:w-[40%] max-[600px]:w-[50%] max-[480px]:w-[60%] max-[400px]:w-[70%]">
              <label className="font-nunito-20">Password</label>
              <input maxLength={12} type="password" name="password" onChange={handleInputChange} value={values.password}
                className="border-[3px] border-black rounded-full py-3 px-5 font-nunito-20 bg-[--primaryLight]"
                placeholder="Enter Your Password"
              />
              {Boolean(errors.password) ? (
                <p
                  style={{
                    color: "#fc9494",
                    marginTop: "4px",
                    fontSize: "14px",
                  }}
                >
                  {errors.password}
                </p>
              ) : (
                ""
              )}
              {/* <label className="font-normal mt-4  text-[18px] ml-auto">
                Forgot Password?
              </label> */}
            </div>

            <div
              className="flex flex-col font-nunito w-[27%] max-[1200px]:w-[32%] max-[992px]:w-[40%] max-[600px]:w-[50%] max-[480px]:w-[60%] max-[400px]:w-[70%]"

            >
              <button type="submit" className="border-0 rounded-full py-3 px-5 text-[18px] text-white font-semibold bg-[--primary]">
                Log In
              </button>
            </div>
          </div>
        </Form>
        {/* <div className="flex h-[108px]"></div> */}
      </div>


      <ToastContainer
        position="top-right"
        autoClose={2000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />

    </>
  );
}

export default Login;
