import React, { useState, useCallback, useEffect } from "react";
import update from "immutability-helper";
import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  tableCellClasses,
  Button,
  Grid,
  Typography
} from "@mui/material";
import ThirdBox from "./ThirdBox";
import { useDrag, useDrop } from "react-dnd";
import { styled } from "@mui/material/styles";
import { snapToGrid as doSnapToGrid } from "../../components/CustomDragLayout/snapToGrid";

import ThirdAns from "./ThirdAns";
import { useDispatch, useSelector } from "react-redux";
import { ansHandler } from "@/state/actions/Admin/questionAction";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    backgroundColor: "#fff",
    ":hover": { backgroundColor: "rgb(248 248 248)!important" },

    "&:nth-of-type(odd)": {
      backgroundColor: "rgb(250 250 250)!important",
    },
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#000",
    fontSize: 13,
    border: 0,
    fontWeight: 600,
    paddingTop: "15px",
    paddingBottom: "15px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    color: "rgb(17, 24, 39)",
    border: 0,
    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));


function ThirdQue(props) {
  const [fakeData, setFakeData] = useState();
  const dispatch = useDispatch();
  const [textFieldValue, setTextFieldValue] = useState("");
  const [answer, setAnswer] = useState([]);
  const [imageToDisplay, setImageToDisplay] = useState("");
  const [reduxDataBefore, setReduxBefore] = useState("");

  const totalData = useSelector((data) => data.questionAnswer);

  const handleDrop = (responseanswer, id, res) => {

    if (res && res.studentAns == "") {

      const AnsDisableHandler = answer.map((res) =>
        res.ans == responseanswer ? { ...res, disable: true } : res
      );
      setAnswer(AnsDisableHandler);
      const existingAnswerIndex = totalData.answer.findIndex(
        (ans) =>
          ans.optionID == fakeData && fakeData[id] && fakeData[id].optionID &&
          ans.studentAns != ""
      );

      if (existingAnswerIndex === -1) {

        fakeData.map((field) => {
          if (field.Uid === id) {
            const singleOne = {
              questionID: field.questionID,
              optionID: field.optionID,
              answer: responseanswer
            }
            dispatch(ansHandler([...totalData.answer, singleOne]));
          }
        });
        const updatedText = fakeData.map((field) =>
          field.Uid === id ? { ...field, studentAns: responseanswer } : field
        );
        setFakeData(updatedText);
        props.returnData(updatedText);
      }
    }
  };


  const resultBeforeData = useSelector((data) => data.questionAnswer);

  useEffect(() => {
    setReduxBefore(resultBeforeData);
  }, [props.totalData[0]]);

  const ItemTypes = {
    BOX: "box",
  };

  function snapToGrid(x, y) {
    const snappedX = Math.round(x / 32) * 32;
    const snappedY = Math.round(y / 32) * 32;
    return [snappedX, snappedY];
  }

  const moveBox = useCallback(
    (id, left, top) => {
      setFakeData(
        update(fakeData, {
          [id]: {
            $merge: { left, top },
          },
        })
      );
      props.data(fakeData);
    },
    [fakeData]
  );

  let ansData = [];

  useEffect(() => {
    if (props && props.totalData && props.totalData[0]) {
      getDatahandler(props.totalData[0]);
    }
  }, [props.totalData[0]]);

  const getDatahandler = (data) => {
    setImageToDisplay(data?.optionData[0]?.imageUrl);
    getAnswerHandler(data.optionData);
    getQuestionHandler(data.optionData);
  };

  const getAnswerHandler = (dataResult) => {
    console.log("dataResult", dataResult);
    dataResult?.map((res) => {
      ansData.push({ ans: res.correctAnswer, disable: res.disable, id: res.id });
    });
    setAnswer(shuffle(ansData));
  };


  function shuffle(array) {
    let currentIndex = array.length, randomIndex;
    while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }

  const resetAllHandler = () => {
    getDatahandler(props.totalData[0]);
    dispatch(ansHandler([...reduxDataBefore.answer]));
    props.resetHandler();
  };

  const resetOneHandler = (index, id, ans) => {
    const resetOne = answer.map((res, i) =>
      res.id == id ? { ...res, disable: false } : res
    );
    setAnswer(resetOne);

    dispatch(ansHandler([...totalData.answer.filter((res, i) => res.optionID != id)]) || []);
    props.resetHandler();

    let newArray = [...fakeData];
    newArray.filter((res, i) => res.studentAns == ans)[0].studentAns = "";
    setFakeData(newArray);

  };





  const getQuestionHandler = (result) => {
    const newQuestions = result.map((res) => ({
      correctAnswer: res.correctAnswer,
      questionID: res.questionID,
      optionID: res.id,
      studentAns: "",
      Uid: res.Uid,
      top: res.top,
      left: res.left,
    }));

    setFakeData(newQuestions);
  };

  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.BOX,
      drop(item, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset();
        let left = Math.round(item.left + delta.x);
        let top = Math.round(item.top + delta.y);
        if (snapToGrid) {
          [left, top] = doSnapToGrid(left, top);
        }
        moveBox(item.id, left, top, item.correctAnswer);
        return undefined;
      },
    }),
    [moveBox]
  );

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: ItemTypes.BOX,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    []
  );

  const handleAnswerDrop = (answer) => {
    setTextFieldValue((prevValue) => prevValue + answer);
  };

  console.log("dasdsdsdsdsdvvvvv", answer);

  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", position: "relative" }}
      ref={drop}
      height={"unset"}
    >
      <Grid container justifyContent={"center"}>
        <div className="relative">
          <img
            className="image_question"
            src={imageToDisplay}
          />
        </div>
        <Grid item width={"200px"}>
          <Table className="table" sx={{
            marginLeft: { xs: '0', md: "100px" }
          }}>
            <TableHead>
              <TableRow>
                <Grid container justifyContent={'space-between'} alignItems={'center'}>
                  <Grid item><h2> Answer </h2></Grid>
                  <Grid item><Typography sx={{ fontSize: '12px', color: 'var(--primary)', background: '#f5e7de', padding: '2px 10px', borderRadius: '3px', cursor: 'pointer', fontWeight: 600 }} onClick={() => resetAllHandler()} > Reset all </Typography></Grid>
                </Grid>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell>
                  {answer?.map((answer, index) => (
                    <div className="flex items-center gap-1 ">
                      <div className="w-full">
                        <ThirdAns
                          key={index}
                          answer={answer.ans}
                          disable={answer.disable}
                          onDrop={(ans) => handleDrop(ans, index, answer)}
                        />

                      </div>

                      {answer.disable && <Typography sx={{ fontSize: '12px', color: 'var(--primary)', background: '#f5e7de', padding: '2px 10px', borderRadius: '3px', cursor: 'pointer', fontWeight: 600 }} onClick={() => resetOneHandler(index, answer.id, answer.ans)} > Reset </Typography>}
                    </div>
                  ))}

                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>

          {fakeData?.map((res) => {
            return (

              <ThirdBox
                onDrop={(ans) => handleDrop(ans, res.Uid, res)}
                key={res}
                id={res}
                value={res.correctAnswer}
                {...fakeData[res]}
                onlyView={false}
              />
            );
          })}


        </Grid>
      </Grid>
    </Box >
  );
}

export default ThirdQue;
