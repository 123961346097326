import axiosRequest from "../../axios/axios";

//get Vehical List
export const getCourseList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`courseList` + queryString);
    return response.data;
  } catch (error) {
    return error;
  }
};

//add Vehical

export const addCourse = async (payload) => {
  try {
    const response = await axiosRequest.post(`courseAdd`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//Vehical Delete
export const DeleteCourse = async (id) => {
  try {
    const response = await axiosRequest.delete(`courseDelete/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//Vehical Status Update

export const StatusUpdate = async (id) => {
  try {
    const response = await axiosRequest.put(`courseStatusUpdate/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//Vehical update

export const UpdateCourse = async (id, payload) => {
  try {
    const response = await axiosRequest.patch(`courseUpdate/${id}`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getClassDropdownList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`/getclassData`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getCourseDropdownList = async (id) => {
  try {
    const response = await axiosRequest.get(`/getclasslist/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
