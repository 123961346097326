import Typography from "@mui/material/Typography";
import {
  Box,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  tableCellClasses,
} from "@mui/material";
import Button from "@mui/material/Button";
import Controls from "../../../components/controls/Controls";
import AddIcon from "@mui/icons-material/Add";
import { useForm, Form } from "../../../utils/useForm";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { RiDeleteBinLine, RiEditLine } from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import { Space } from "antd";
import camera from "../../../assets/camera.svg";
import {
  AddQuestions,
  AddthirdQuestion,
  getSessioDropdownList,
  updateQuestions,
  updateThirdQuestion,
} from "../../../state/actions/Admin/questionAction";
import {
  getClassDropdownList,
  getCourseDropdownList,
} from "../../../state/actions/Admin/courseAction";
import ImageQuestion from "./ImageQuestion";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const Types = [
  { id: 1, title: "True / False" },
  { id: 2, title: "Drag & Drop" },
  { id: 3, title: "Image" },
];

const Answer = [
  { id: 1, name: "True" },
  { id: 2, name: "False" },
];

const StyledInput = styled("input")({
  display: "none",
});

const StyledImageUploadWrapper = styled(Box)(() => ({
  marginTop: "16px",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  height: "206px",
  borderRadius: "10px",
  textAlign: "center",
}));

const StyledRemoveButton = styled(Box)({
  color: "red",
  display: "inline",
  "&:hover": {
    cursor: "pointer",
  },
});

const StyledIconWrapper = styled(Box)(() => ({
  margin: "20px auto",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",

  img: {
    marginTop: "30px",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#000",
    fontSize: 13,
    border: 0,
    fontWeight: 600,
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "25px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    color: "rgb(17, 24, 39)",
    border: 0,
    paddingLeft: "25px",

    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    backgroundColor: "#fff",
    ":hover": { backgroundColor: "rgb(248 248 248)!important" },

    "&:nth-of-type(odd)": {
      backgroundColor: "rgb(250 250 250)!important",
    },
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const initialValues = {
  classID: "",
  sessionID: "",
  courseID: "",
  questionType: "",
  marks: "",
};

export default function BasicTabs() {
  const [thirdQuestionData, setThirdQuestionData] = useState([]);
  const [thirdQuestionEdit, setThirdQuestionEdit] = useState([]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("classID" in fieldValues) {
      temp.classID = fieldValues.classID ? "" : "This field is required.";
    }
    if ("sessionID" in fieldValues) {
      temp.sessionID = fieldValues.sessionID ? "" : "This field is required.";
    }

    if ("courseID" in fieldValues) {
      temp.courseID = fieldValues.courseID ? "" : "This field is required.";
    }

    if ("questionType" in fieldValues) {
      temp.questionType = fieldValues.questionType
        ? ""
        : "This field is required.";
    }
    if ("marks" in fieldValues) {
      var regex = /^[+-]?([0-9]*[.])?[0-9]+$/;
      if (fieldValues.marks) {
        if (!regex.test(fieldValues.marks)) {
          temp.marks = "Only numbers are allowed";
        } else {
          temp.marks = "";
        }
      } else {
        temp.marks = "This field is required.";
      }
    }

    if (values.questionType == "3") {
      if (!image && !imageApi && !thirdQuestionData.length > 0) {
        setErrorMessage(
          "You have to upload picture for third type of questiona and answer"
        );
      } else {
        setErrorMessage("");
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const thirdtypeQuestion = () => {
    let errorStatus = false;
    if (values.questionType == "3") {
      if (!imageApi && !image) {
        errorStatus = true;
      }
      if (!thirdQuestionData.length > 0) {
        errorStatus = true;
        setErrorMessage("You have to add answer field.");
      }

      if (thirdQuestionData.length > 0) {
        const dataValid = thirdQuestionData.some(
          (res) => res.correctAnswer == ""
        );

        if (dataValid) {
          errorStatus = true;
          setErrorMessage("You have to fill answer.");
        }
      }
    }
    return errorStatus;
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const [isEdit, setIsEdit] = useState(false);
  const [rowsTermsData, setRowsTermsData] = useState([]);
  const [Questions, setQuestions] = useState();
  const [rowsData, setRowsData] = useState([]);

  const [SessionDropdown, setSessionDropdown] = useState([]);
  const getDetail = async () => {
    if (values.courseID) {
      const res = await getSessioDropdownList(values.courseID);
      setSessionDropdown(res.data);
    }
  };

  const [image, setImage] = useState("");
  const [imageApi, setImageApi] = useState("");

  async function handleImageItem(e) {
    const selected = e.target.files[0];
    const ALLOWED_TYPES = ["image/png", "image/jpg", "image/jpeg"];
    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      const base64String = await getBase64(selected);
      setImage(base64String);
      setImageApi(selected);
    } else {
    }
  }

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  useEffect(() => {
    if (values.courseID) {
      getDetail(values.courseID);
    }
  }, [values.courseID]);

  const [classDropdown, setClassDropdown] = useState([]);
  const [CourseDropdown, setCourseDropdown] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const ClassDropdownList = async () => {
    const res = await getClassDropdownList();
    if (res.status === true) {
      setClassDropdown(res.data);
    } else {
      setClassDropdown([]);
    }
  };
  useEffect(() => {
    ClassDropdownList();
  }, []);

  const getDetails = async () => {
    if (values.classID) {
      const res = await getCourseDropdownList(values.classID);
      setCourseDropdown(res.data);
    }
  };
  useEffect(() => {
    if (values.classID) {
      getDetails(values.classID);
    }
  }, [values.classID]);

  const addTableRows = () => {
    const rowsInput = {
      questionText: "",
      correctAnswer: "",
    };

    if (rowsData.length == 0) {
      setRowsData([...rowsData, rowsInput]);
    }
  };

  //terms and condition

  const addTableRowsTerms = () => {
    const rowsInput = {
      questionText: "",
      correctAnswer: "",
    };
    setRowsTermsData([...rowsTermsData, rowsInput]);
  };

  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  };

  //terms and condition

  const deleteTableRowsTerms = (index) => {
    const rows = [...rowsTermsData];
    rows.splice(index, 1);
    setRowsTermsData(rows);
  };

  const handleChanges = (index, e) => {
    const { name, value } = e.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    setRowsData(rowsInput);
  };

  //terms and condition

  const handleChangesTerms = (index, e) => {
    const { name, value } = e.target;
    const rowsInput = [...rowsTermsData];
    rowsInput[index][name] = value;
    setRowsTermsData(rowsInput);
  };

  const navigate = useNavigate();
  const { state } = useLocation();

  const { enqueueSnackbar } = useSnackbar();

  //descrption for array
  const data = rowsData?.map((row) => row);

  //terms and condition
  const dataTerms = rowsTermsData?.map((row) => row);

  const thirdQuestionHandler = (data) => {
    setThirdQuestionData(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      if (!thirdtypeQuestion()) {
        let Questionsdata = "";
        if (values.questionType == 1) {
          Questionsdata = JSON.stringify(data);
        } else {
          Questionsdata = JSON.stringify(dataTerms);
        }
        const playload = {
          classID: values.classID,
          courseID: values.courseID,
          sessionID: values.sessionID,
          questionType: values.questionType,
          marks: values.marks,
          Questions: Questionsdata,
        };

        if (isEdit) {
          if (values.questionType == "3") {
            const formData = new FormData();
            formData.append("image", imageApi);
            formData.append("classID", values.classID);
            formData.append("sessionID", values.sessionID);
            formData.append("courseID", values.courseID);
            formData.append("questionType", values.questionType);
            formData.append("marks", values.marks);
            formData.append("question", JSON.stringify(thirdQuestionData));
            const response = await updateThirdQuestion(
              state?.question?.id,
              formData
            );
            if (response && response.status === true) {
              navigate("/question");
              resetForm();
              toast.success(response.message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            } else {
              toast.error(response.message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          } else {
            const res = await updateQuestions(state?.question?.id, playload);
            if (res && res.status === true) {
              navigate("/question");
              resetForm();
              toast.success(res.message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            } else {
              toast.error(res.message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          }
        }

        if (!isEdit) {
          if (values.questionType == "3") {
            const formData = new FormData();
            formData.append("image", imageApi);
            formData.append("classID", values.classID);
            formData.append("sessionID", values.sessionID);
            formData.append("courseID", values.courseID);
            formData.append("questionType", values.questionType);
            formData.append("marks", values.marks);
            formData.append("question", JSON.stringify(thirdQuestionData));
            const response = await AddthirdQuestion(formData);
            if (response && response.status === true) {
              navigate("/question");
              toast.success(response.message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            } else {
              toast.error(response.message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          } else {
            const res = await AddQuestions(playload);
            if (res && res.status === true) {
              navigate("/question");
              toast.success(res.message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            } else {
              toast.error(res.message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          }
        }
      }
    }
  };

  //details edit
  useEffect(() => {
    if (
      state?.question.id != "" &&
      state?.question.id != null &&
      state?.question.id != undefined
    ) {
      setIsEdit(true);
      if (state?.question?.questionType === 3) {
        editDataHandler();
      }
      setValues({
        ...values,
        classID: state?.question.classID,
        sessionID: state?.question.sessionID,
        courseID: state?.question.courseID,
        questionType: state?.question.questionType,
        marks: state?.question.marks,
      });
      setRowsData(state?.question?.Questions);
      setRowsTermsData(state?.question?.Questions);
    } else {
      resetForm();
    }
  }, [state?.question]);

  const editDataHandler = () => {
    setThirdQuestionData(state?.question?.Questions);
    setThirdQuestionEdit(state?.question?.Questions);
    setImage(state?.question?.image);
    setImageApi(state?.question?.image);
  };

  const resetImage = () => {
    setImage("");
    setImageApi("");
  };

  const removeImage = () => {
    resetImage();
    setValues((previousValues) => {
      var newValues = { ...previousValues };
      delete newValues["profile_image"];
      return newValues;
    });
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            px: 3,
            paddingBottom: "3px",
            backgroundColor: "rgb(241, 245, 249)",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Typography
              sx={{
                paddingTop: "25px",
                paddingBottom: "15px",
                fontSize: "15px",
                fontWeight: "600",
                color: "#000",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/question");
              }}
            >
              <KeyboardBackspaceIcon
                sx={{ verticalAlign: "top", marginRight: "5px" }}
              />
              Question
            </Typography>
            <div>
              <Controls.Button
                style={{
                  backgroundColor: "#fff",
                  color: "#000",
                  marginRight: "10px",
                }}
                text="Cancel"
                onClick={() => {
                  navigate("/question");
                  resetForm();
                }}
              />
              <Controls.Button
                style={{ marginRight: "7px" }}
                text={isEdit ? 'save' : "Submit"}
                onClick={(e) => handleSubmit(e)}
                type="submit"
              />
            </div>
          </Box>
        </Box>
        <Form onSubmit={handleSubmit}>
          <Box
            className="b1"
            sx={{
              backgroundColor: "white",
            }}
          >
            <Grid
              item
              md={12}
              justifyContent="center"
              sm={12}
              xs={12}
              container
            >
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.SelectBox
                  className="input select_box"
                  label="Select Class"
                  fullWidth
                  id="classID"
                  name="classID"
                  value={values.classID}
                  onChange={handleInputChange}
                  options={classDropdown}
                />
                {Boolean(errors.classID) ? (
                  <p
                    style={{
                      color: "rgb(255 0 0)",
                      marginTop: "4px",
                      marginBottom: "4px",
                      fontSize: "14px",
                    }}
                  >
                    {errors.classID}
                  </p>
                ) : (
                  ""
                )}
              </Grid>{" "}
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.SelectBox
                  className="input select_box"
                  label="Select Course"
                  fullWidth
                  id="courseID"
                  name="courseID"
                  value={values.courseID}
                  onChange={handleInputChange}
                  options={CourseDropdown}
                  error={errors.courseID}
                  helperText={errors.courseID}
                />
                {Boolean(errors.courseID) ? (
                  <p
                    style={{
                      color: "rgb(255 0 0)",
                      marginTop: "4px",
                      marginBottom: "4px",
                      fontSize: "14px",
                    }}
                  >
                    {errors.courseID}
                  </p>
                ) : (
                  ""
                )}
              </Grid>{" "}
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.SelectBox
                  className="input select_box"
                  label="Select Session"
                  fullWidth
                  id="sessionID"
                  name="sessionID"
                  value={values.sessionID}
                  onChange={handleInputChange}
                  options={SessionDropdown}
                />
                {Boolean(errors.sessionID) ? (
                  <p
                    style={{
                      color: "rgb(255 0 0)",
                      marginTop: "4px",
                      marginBottom: "4px",
                      fontSize: "14px",
                    }}
                  >
                    {errors.sessionID}
                  </p>
                ) : (
                  ""
                )}
              </Grid>{" "}
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Marks"
                  name="marks"
                  fullWidth
                  value={values.marks}
                  onChange={handleInputChange}
                  error={errors.marks}
                  helperText={errors.marks}
                />
              </Grid>
              <Grid item sx={{ m: 1 }} xs={12}>
                <Box display="flex" alignItems="center">
                  <Controls.RadioGroup
                    name="questionType"
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                    value={values.questionType}
                    items={Types}
                    isRowDir={true}
                  />
                  {Boolean(errors.questionType) ? (
                    <p
                      style={{
                        color: "rgb(255 0 0)",
                        marginTop: "4px",
                        marginBottom: "4px",
                        fontSize: "14px",
                      }}
                    >
                      {errors.questionType}
                    </p>
                  ) : (
                    ""
                  )}
                </Box>
              </Grid>
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}></Grid>{" "}
            </Grid>
            <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
            {values.questionType == "1" ? (
              <Grid
                item
                md={12}
                justifyContent="center"
                sm={12}
                xs={12}
                container
              >
                <Grid item sx={{ m: 1 }} md={12} xs={12}>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      marginLeft: "28px",
                      fontSize: "20px",
                    }}
                  >
                    Question
                  </Typography>
                </Grid>

                <Grid item sx={{ m: 1 }} md={12} xs={12}>
                  <Box
                    sx={{
                      backgroundColor: "rgb(241, 245, 249)",
                      width: "98%",
                      margin: "auto",
                    }}
                  >
                    <Box
                      sx={{
                        border: 1,
                        borderColor: "#eaeaea",
                      }}
                    >
                      <Table className="table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Question Text</StyledTableCell>
                            <StyledTableCell>Correct Answer</StyledTableCell>
                            {rowsData.length == 0 ? (
                              <StyledTableCell sx={{ width: "10px" }}>
                                <Button
                                  variant="contained"
                                  size="large"
                                  style={{
                                    backgroundColor: "blue",
                                    borderRadius: "10px",
                                    width: "0%",
                                  }}
                                  startIcon={
                                    <AddIcon sx={{ marginLeft: "9px" }} />
                                  }
                                  fullWidth
                                  onClick={addTableRows}
                                />
                              </StyledTableCell>
                            ) : null}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rowsData?.map((data, index) => {
                            const { questionText, correctAnswer } = data;

                            return (
                              <StyledTableRow
                                key={index}
                                sx={{ cursor: "pointer" }}
                              >
                                <StyledTableCell component="th" scope="row">
                                  <Controls.Input
                                    text="Add New"
                                    variant="outlined"
                                    color="primary"
                                    label="Question Text"
                                    name="questionText"
                                    value={questionText}
                                    onChange={(e) => {
                                      handleChanges(index, e);
                                      handleInputChange(e);
                                    }}
                                    inputProps={{
                                      maxLength: 255,
                                    }}
                                  />
                                </StyledTableCell>

                                <StyledTableCell component="th" scope="row">
                                  <Controls.SelectBox
                                    className="input"
                                    label="Select Answer"
                                    fullWidth
                                    id="correctAnswer"
                                    name="correctAnswer"
                                    value={correctAnswer}
                                    onChange={(e) => {
                                      handleChanges(index, e);
                                      handleInputChange(e);
                                    }}
                                    options={Answer}
                                  />
                                  {Boolean(errors.correctAnswer) ? (
                                    <p
                                      style={{
                                        color: "rgb(255 0 0)",
                                        marginTop: "4px",
                                        marginBottom: "4px",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {errors.correctAnswer}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>

                                {rowsData.length == 0 ? (
                                  <StyledTableCell
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <Space size="middle">
                                      <Tooltip
                                        placement="bottom"
                                        title="Delete "
                                      >
                                        <button
                                          className="action-button delete-btn"
                                          onClick={() => deleteTableRows(index)}
                                          style={{ marginLeft: "15px" }}
                                        >
                                          <RiDeleteBinLine />
                                        </button>
                                      </Tooltip>
                                    </Space>
                                  </StyledTableCell>
                                ) : null}
                              </StyledTableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            ) : null}
            <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
            {values.questionType == "2" ? (
              <Grid
                item
                md={12}
                justifyContent="center"
                sm={12}
                xs={12}
                container
              >
                <Grid item sx={{ m: 1 }} md={12} xs={12}>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      marginLeft: "28px",
                      fontSize: "20px",
                    }}
                  >
                    Question
                  </Typography>
                </Grid>

                <Grid item sx={{ m: 1 }} md={12} xs={12}>
                  <Box
                    sx={{
                      backgroundColor: "rgb(241, 245, 249)",
                      width: "98%",
                      margin: "auto",
                    }}
                  >
                    <Box
                      sx={{
                        border: 1,
                        borderColor: "#eaeaea",
                      }}
                    >
                      <Table className="table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Question Text</StyledTableCell>
                            <StyledTableCell>Correct Answer</StyledTableCell>
                            <StyledTableCell sx={{ width: "10px" }}>
                              <Button
                                variant="contained"
                                size="large"
                                style={{
                                  backgroundColor: "blue",
                                  borderRadius: "10px",
                                  width: "0%",
                                }}
                                startIcon={
                                  <AddIcon sx={{ marginLeft: "9px" }} />
                                }
                                fullWidth
                                onClick={addTableRowsTerms}
                              />
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rowsTermsData?.map((data, index) => {
                            const { questionText, correctAnswer } = data;

                            return (
                              <StyledTableRow
                                key={index}
                                sx={{ cursor: "pointer" }}
                              >
                                <StyledTableCell component="th" scope="row">
                                  <Controls.Input
                                    text="Add New"
                                    variant="outlined"
                                    color="primary"
                                    label="Question Text"
                                    value={questionText}
                                    onChange={(e) => {
                                      handleChangesTerms(index, e);
                                      handleInputChange(e);
                                    }}
                                    name="questionText"
                                    inputProps={{
                                      maxLength: 255,
                                    }}
                                  />
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                  <Controls.Input
                                    text="Add New"
                                    variant="outlined"
                                    color="primary"
                                    label="correct Answer"
                                    value={correctAnswer}
                                    onChange={(e) => {
                                      handleChangesTerms(index, e);
                                      handleInputChange(e);
                                    }}
                                    name="correctAnswer"
                                    inputProps={{
                                      maxLength: 255,
                                    }}
                                  />
                                </StyledTableCell>
                                <StyledTableCell
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <Space size="middle">
                                    <Tooltip placement="bottom" title="Delete ">
                                      <button
                                        className="action-button delete-btn"
                                        onClick={() =>
                                          deleteTableRowsTerms(index)
                                        }
                                        style={{ marginLeft: "15px" }}
                                      >
                                        <RiDeleteBinLine />
                                      </button>
                                    </Tooltip>
                                  </Space>
                                </StyledTableCell>
                              </StyledTableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            ) : null}
            <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
            
            {values.questionType == "3" ? (
              <>
                <Grid
                  item
                  md={12}
                  justifyContent="center"
                  sm={12}
                  xs={12}
                  container
                >
                  <Grid item sx={{ m: 1 }} md={12} xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "400",
                        marginLeft: "28px",
                        fontSize: "20px",
                      }}
                    >
                      Question Type Image
                    </Typography>
                    {errorMessage && (
                      <h5 style={{ color: "red" }}> {errorMessage}</h5>
                    )}
                    <Box>
                      <StyledImageUploadWrapper>
                        <label htmlFor="image-upload">
                          <StyledInput
                            accept="image/*"
                            id="image-upload"
                            type="file"
                            onChange={handleImageItem}
                            onClick={(event) => {
                              event.currentTarget.value = null;
                            }}
                          />
                          <StyledIconWrapper
                            sx={
                              image == ""
                                ? {
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }
                                : {
                                  backgroundImage: `url(${image})`,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "cover",
                                }
                            }
                          >
                            {image === "" && (
                              <img
                                src={camera}
                                alt="Camera"
                                style={{ margin: "0" }}
                              />
                            )}
                          </StyledIconWrapper>
                        </label>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "black",
                          }}
                        >
                          Add Images For Question
                        </Typography>
                        <Typography
                          sx={{
                            color: "rgba(15, 15, 15, 0.5)",
                            mx: 2,
                            fontSize: "12px",
                          }}
                        >
                          Allowed *.jpeg, *.jpg, *.png, max size of 3.1 MB
                        </Typography>
                        {image != "" && (
                          <StyledRemoveButton
                            onClick={(e) => {
                              e.preventDefault();
                              removeImage();
                            }}
                          >
                            Remove
                          </StyledRemoveButton>
                        )}
                      </StyledImageUploadWrapper>
                    </Box>
                  </Grid>
                </Grid>
                <DndProvider backend={HTML5Backend}>
                  <ImageQuestion
                    data={thirdQuestionHandler}
                    Image={image}
                    OutData={thirdQuestionEdit}
                  />
                </DndProvider>
              </>
            ) : null}
          </Box>
        </Form>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          limit={1}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <ToastContainer />
      </Box>
    </>
  );
}
