import axiosRequest from "../../axios/axios";

export const getStudentList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`studentsList/${queryString}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const addStudent = async (payload) => {
  try {
    const response = await axiosRequest.post(`studentsAdd`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//user Delete
export const DeleteStudent = async (id) => {
  try {
    const response = await axiosRequest.delete(`studentsDelete/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//user Status Update

export const StatusUpdate = async (id) => {
  try {
    const response = await axiosRequest.put(`studentsStatusUpdate/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateBanner = async (id, payload) => {
  try {
    const response = await axiosRequest.put(`updateBanner/${id}`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const updateStudent = async (id, payload) => {
  try {
    const response = await axiosRequest.patch(`studentsUpdate/${id}`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getStudentDetails = async (id) => {
  try {
    const response = await axiosRequest.get(`/studentsDetailes/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getClassDropdownList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`/getclassData`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getStudentName = async (queryString) => {
  try {
    const response = await axiosRequest.get(`/getStudentName`);
    return response.data;
  } catch (error) {
    return error;
  }
};
//edit Address

export const updateAddress = async (id, ids, payload) => {
  try {
    const response = await axiosRequest.put(
      `UserAddressUpdate/${id}/${ids}`,
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//delete address
export const DeleteAddress = async (id, ids) => {
  try {
    const response = await axiosRequest.delete(
      `UserAddressDelete/${id}/${ids}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const AddressDefault = async (id, ids) => {
  try {
    const response = await axiosRequest.put(`UserAddressDefault/${id}/${ids}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
