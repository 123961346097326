import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  tableCellClasses,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router";
import { styled } from "@mui/material/styles";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Controls from "@/components/controls/Controls";
import { useForm, Form } from "@/utils/useForm";
import { Space } from "antd";
import { ToastContainer, toast } from "react-toastify";
import {
  getClassDropdownList,
  getCourseDropdownList,
} from "@/state/actions/Admin/courseAction";
import AddIcon from "@mui/icons-material/Add";
import { RiDeleteBinLine, RiEditLine } from "react-icons/ri";
import {
  AddQuestions,
  AddthirdQuestion,
  getQuestionDetails,
  getSessioDropdownList,
  updateQuestions,
  updateThirdQuestion,
} from "@/state/actions/Admin/questionAction";
import { useLocation } from "react-router-dom";
import FinalTest from "./FinalTest";
import ShortTest from "./ShortTest";
import UploadAudio from "../../upload/UploadAudio";

const initialValues = {
  classID: "",
  sessionID: "",
  courseID: "",
  marks: "",
  startingPpt: "",
  feedbackPpt: "",
};

function SlidePhase() {
  const navigate = useNavigate();

  const [isEdit, setIsEdit] = useState(false);
  const [rowsTermsData, setRowsTermsData] = useState([]);
  const [image, setImage] = useState("");
  const [imageApi, setImageApi] = useState("");
  const [thirdQuestionData, setThirdQuestionData] = useState([]);
  const [thirdQuestionEdit, setThirdQuestionEdit] = useState([]);
  const [slidesId, setSlidesId] = useState("");
  const [editData, setEditData] = useState([]);
  const [finalTData, setFinalTData] = useState([]);
  const { state } = useLocation();
  const [audioFile, setAudioFile] = useState("");
  const [audioFileName, setAudioFileName] = useState("");

  const Types = [
    { id: 1, title: "True / False" },
    { id: 2, title: "Drag & Drop" },
    { id: 3, title: "Image" },
  ];

  const maxSize = 2048;

  const validateImageSize = (vehicleIcons) => {
    if (vehicleIcons) {
      const fsize = vehicleIcons.size / 1024;
      if (fsize > maxSize) {
        return false;
      } else {
        return true;
      }
    }
  };

  const finalTestData = (data) => {
    setFinalTData(data);
  };

  const StyledIconWrapper = styled(Box)(() => ({
    margin: "20px auto",
    border: "1px solid rgba(15, 15, 15, 0.15)",
    width: "80px",
    height: "80px",
    borderRadius: "10px",
    backgroundColor: "rgba(15, 15, 15, 0.05)",
    img: {
      marginTop: "30px",
    },
  }));

  const Answer = [
    { id: 1, name: "True" },
    { id: 2, name: "False" },
  ];

  const validatePptSize = (ppt) => {
    const MAX_FILE_SIZE = 2 * 1024 * 1024;
    const allowedExtensions = [".ppt", ".pptx"];
    const selectedFile = ppt;
    const fileExtension = selectedFile?.name.split(".").pop().toLowerCase();
    if (selectedFile) {
      if (allowedExtensions.includes(`.${fileExtension}`)) {
        if (selectedFile.size <= MAX_FILE_SIZE) {
          return true;
        } else {
          toast.error("Please Upload Ppt File Less Than 2 MB", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        toast.error("Please Upload Only Ppt File", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  const StyledRemoveButton = styled(Box)({
    color: "red",
    display: "inline",
    "&:hover": {
      cursor: "pointer",
    },
  });

  const resetImage = (slideIndex) => {
    const rowsData = [...slides];
    rowsData[slideIndex]["image"] = "";
    rowsData[slideIndex]["imageApi"] = "";
    setSlides(rowsData);
    setImage("");
    setImageApi("");
  };

  const backDataHandler = (data) => {
    setSlides(data);
  };

  const getSingleQuestionDetails = async (id) => {
    const res = await getQuestionDetails(id);
    if (res && res.status) {
      if (res.data) {
        setValues({
          ...values,
          classID: res.data.classID,
          sessionID: res.data.sessionID,
          courseID: res.data.courseID,
          marks: res.data.marks,
          startingPpt: res.data.startPpt,
          feedbackPpt: res.data.feedbackPpt,
        });
        setStartPptName(res.data.startPpt);
        setStartPpt(res.data.startPpt);
        setEndPpt(res.data.feedbackPpt);
        setEndPptName(res.data.feedbackPpt);
        setThirdQuestionEdit(res.data.pptData);
        const slidesData = res.data.pptData.map((result) => ({
          questionType: result.questionType,
          slidePpt: result.ppt,
          questionSet: result.optionData,
          imageApi: result.image,
          image: result.image,
        }));
        setSlides(slidesData);
      }
    } else {
      // put here notifaction if there is some issue while getting data from the api
    }
  };

  useEffect(() => {
    if (
      state?.question.id != "" &&
      state?.question.id != null &&
      state?.question.id != undefined
    ) {
      setIsEdit(true);
      getSingleQuestionDetails(state?.question.id);
    }
  }, []);

  const removeImage = (slideIndex) => {
    resetImage(slideIndex);
    setValues((previousValues) => {
      var newValues = { ...previousValues };
      delete newValues["profile_image"];
      return newValues;
    });
  };

  async function handleImageItem(index, e) {
    if (validateImageSize(e.target.files[0])) {
      const rowsData = [...slides];
      const selected = e.target.files[0];
      const ALLOWED_TYPES = ["image/png", "image/jpg", "image/jpeg"];
      if (selected && ALLOWED_TYPES.includes(selected.type)) {
        const base64String = await getBase64(selected);
        slides[index]["image"] = base64String;
        slides[index]["imageApi"] = base64String;
        setSlides(rowsData);
      } else {
      }
    } else {
      // need to clear slie wise image
    }
  }

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const addTableRowsTerms = (slideIndex, option) => {
    const rowsInput = {
      questionText: "",
      correctAnswer: "",
    };
    setRowsTermsData([...rowsTermsData, rowsInput]);
    slides[slideIndex]["questionSet"] = [...rowsTermsData, rowsInput];
    slides[slideIndex][option] = [...slides[slideIndex][option], rowsInput];
  };

  const StyledInput = styled("input")({
    display: "none",
  });

  const thirdQuestionHandler = (data) => {
    setThirdQuestionData(data);
  };

  const thirIndexHandler = (slideIndex, data) => {
    slides[slideIndex]["questionSet"] = data;
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: "#000",
      fontSize: 13,
      border: 0,
      fontWeight: 600,
      paddingTop: "15px",
      paddingBottom: "15px",
      paddingLeft: "25px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 13,
      color: "rgb(17, 24, 39)",
      border: 0,
      paddingLeft: "25px",

      paddingTop: "10px",
      paddingBottom: "10px",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&": {
      backgroundColor: "#fff",
      ":hover": { backgroundColor: "rgb(248 248 248)!important" },

      "&:nth-of-type(odd)": {
        backgroundColor: "rgb(250 250 250)!important",
      },
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("classID" in fieldValues) {
      temp.classID = fieldValues.classID ? "" : "This field is required.";
      if (!fieldValues.classID) {
        setCourseDropdown([]);
      }
    }
    if ("sessionID" in fieldValues) {
      temp.sessionID = fieldValues.sessionID ? "" : "This field is required.";
    }

    if ("courseID" in fieldValues) {
      temp.courseID = fieldValues.courseID ? "" : "This field is required.";
      if (!fieldValues.courseID) {
        setSessionDropdown([]);
      }
    }

    if ("marks" in fieldValues) {
      temp.marks = fieldValues.marks ? "" : "This field is required.";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const [classDropdown, setClassDropdown] = useState([]);
  const [CourseDropdown, setCourseDropdown] = useState([]);
  const [SessionDropdown, setSessionDropdown] = useState([]);
  const [startPpt, setStartPpt] = useState("");
  const [startPptName, setStartPptName] = useState("");
  const [endPpt, setEndPpt] = useState("");
  const [endPptName, setEndPptName] = useState("");
  const [ppt, setPpt] = useState("");
  const [pptName, setPptName] = useState("");
  const [slides, setSlides] = useState([]);
  const [rowsData, setRowsData] = useState([]);
  const [audioFileApi, setAudioFileApi] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [finalTest, setFinalTest] = useState("");

  // to upload File again Issue
  const startPptRef = useRef(null);
  const startAudioRef = useRef(null);

  const handleChangesTerms = (index, e, slideIndex, option) => {
    const { name, value } = e.target;
    const rowsInput = [...rowsTermsData];
    rowsInput[index][name] = value;
    slides[slideIndex]["questionSet"] = rowsInput;
    setRowsTermsData(rowsInput);
  };

  const questionTypeHandler = (index, e) => {
    const { name, value } = e.target;
    const rowsInput = [...slides];
    rowsInput[index]["questionType"] = value;
    setSlides(rowsInput);
    setRowsData([]);
    setRowsTermsData([]);
  };

  const ClassDropdownList = async () => {
    const res = await getClassDropdownList();
    if (res.status === true) {
      setClassDropdown(res.data);
    } else {
      setClassDropdown([]);
    }
  };

  useEffect(() => {
    ClassDropdownList();
  }, []);

  const deleteTableRowsTerms = (index, slideIndex) => {
    const rows = [...rowsTermsData];
    rows.splice(index, 1);
    slides[slideIndex]["questionSet"] = rows;
    setRowsTermsData(rows);
  };

  const deleteTableRows = (index, slideIndex) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    slides[slideIndex]["questionSet"] = rows;
    setRowsData(rows);
  };

  const StyledImageUploadWrapper = styled(Box)(() => ({
    marginTop: "16px",
    border: "1px solid rgba(15, 15, 15, 0.15)",
    height: "206px",
    borderRadius: "10px",
    textAlign: "center",
  }));

  const handleChanges = (index, e, slideIndex, option) => {
    const { name, value } = e.target;

    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    setRowsData(rowsInput);
    slides[slideIndex][option] = rowsInput;
  };

  const getDetails = async () => {
    if (values.classID) {
      const res = await getCourseDropdownList(values.classID);
      setCourseDropdown(res.data);
    }
  };

  useEffect(() => {
    if (values.classID) {
      getDetails(values.classID);
    }
  }, [values.classID]);

  const getSessionDetail = async () => {
    if (values.courseID) {
      const res = await getSessioDropdownList(values.courseID);
      setSessionDropdown(res.data);
    }
  };

  useEffect(() => {
    if (values.courseID) {
      getSessionDetail(values.courseID);
    }
  }, [values.courseID]);

  const startPpthandler = (event) => {
    if (validatePptSize(event.target.files[0])) {
      setStartPpt(event.target.files[0]);
      setStartPptName(event.target.files[0].name);
      startPptRef.current.value = "";
    } else {
      setStartPpt("");
      setStartPptName("");
    }
  };

  const startPptDeleteHandler = () => {
    setStartPpt("");
    setStartPptName("");
  };

  const startAudioDeleteHandler = () => {
    setAudioFile("");
    setAudioFileApi("");
    setAudioFileName("");
  };

  const validAudioHandler = (audioFile) => {
    const MAX_FILE_SIZE = 2 * 1024 * 1024;
    const allowedExtensions = [
      ".mp3",
      ".mp4",
      ".wav",
      ".ogg",
      ".flac",
      ".aac",
      ".m4a",
    ];

    if (audioFile) {
      const fileExtension = audioFile.name.split(".").pop().toLowerCase();
      if (allowedExtensions.includes(`.${fileExtension}`)) {
        if (audioFile.size <= MAX_FILE_SIZE) {
          return true;
        } else {
          toast.error("Please Upload Only audio File Less Than 2 MB", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        toast.error("Please Upload Only Audio File ", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
    return false;
  };

  const audioFileHandler = (e) => {
    if (validAudioHandler(e.target.files[0])) {
      setAudioFile(URL.createObjectURL(e.target.files[0]));
      setAudioFileApi(e.target.files[0]);
      setAudioFileName(e.target.files[0].name);
      startAudioRef.current.value = "";
    } else {
      setAudioFile("");
      setAudioFileApi("");
      setAudioFileName("");
    }
  };

  const pptHandler = async (index, event) => {
    if (validatePptSize(event.target.files[0])) {
      const rowsInput = [...slides];
      const basedData = await getBase64(event.target.files[0]);
      rowsInput[index]["slidePpt"] = basedData;
      rowsInput[index]["slidePptName"] = event.target.files[0];
      setSlides(rowsInput);
      setPpt(event.target.files[0]);
      setPptName(event.target.files[0].name);
    } else {
      setPpt("");
      setPptName("");
      toast.error("Please Upload PPT Less Than 10MB", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const endPpthandler = (event) => {
    if (validatePptSize(event.target.files[0])) {
      setEndPpt(event.target.files[0]);
      setEndPptName(event.target.files[0].name);
    } else {
      setEndPpt("");
      setEndPptName("");
      toast.error("Please Upload PPT Less Than 10MB", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleClick = () => {
    if (slides.length <= 4) {
      const addSlides = {
        questionType: "",
        slidePpt: "",
        questionSet: [],
        questionSet2: [],
        option1: [],
        option2: [],
        image1: "",
        imageApi1: "",
        image2: "",
        imageApi2: "",
      };
      setSlides([...slides, addSlides]);
    }
  };

  const addTableRows = (slideIndex, option) => {
    const rowsInput = {
      questionText: "",
      correctAnswer: "",
    };
    setRowsData([...rowsData, rowsInput]);
    slides[slideIndex]["questionSet"] = [...rowsData, rowsInput];
    slides[slideIndex][option] = [...slides[slideIndex][option], rowsInput];

    const handleChanges = (index, e) => {
      const { name, value } = e.target;
      const rowsInput = [...rowsData];
      rowsInput[index][name] = value;
      setRowsData(rowsInput);
    };
  };

  const dataValidation = (type, data, image) => {
    let errorStatement = false;
    if (type === "3") {
      const thirdvalid = data.some((obj) => obj.correctAnswer == "");
      if (thirdvalid) {
        errorStatement = true;
      }
      if (!image) {
        errorStatement = true;
      }
    } else {
      const question = data.some((obj) => obj.questionText == "");
      const ans = data.some((obj) => obj.correctAnswer == "");
      if (question || ans) {
        errorStatement = true;
      }
    }
    return errorStatement;
  };

  const slideSubmitHandler = async (questionType, index) => {
    const validate = customValidation(
      questionType,
      slides[index].questionSet,
      slides[index].slidePpt
    );
    if (!validate) {
      const datavalidate = dataValidation(
        questionType,
        slides[index].questionSet,
        slides[index].imageApi
      );
      if (!datavalidate) {
        const formData = new FormData();
        formData.append("question", JSON.stringify(slides[index].questionSet));
        formData.append("image", slides[index].imageApi);
        formData.append("classID", values.classID);
        formData.append("courseID", values.courseID);
        formData.append("marks", values.marks);
        formData.append("questionType", questionType);
        formData.append("sessionID", values.sessionID);
        formData.append("ppt", slides[index].slidePpt);
        formData.append("startPpt", startPpt);
        formData.append("feedbackPpt", endPpt);

        if (slidesId) {
          if (questionType === "3") {
            const response = await updateThirdQuestion(slidesId, formData);
          } else {
            const data = await updateQuestions(slidesId, formData);
          }
        } else {
          const data = await AddQuestions(formData);
          setEditData(data.data);
          setSlidesId(data.data.id);
        }
      }
    }
  };

  const slideValidation = (slideData) => {
    let isValid = true;
    for (const question of slideData) {
      if (!question.slidePpt) {
        isValid = false;
        return isValid;
      }
      if (question.questionType == "1" || question.questionType == "2") {
        if (question.option1.length == 0 || question.option2.length == 0) {
          isValid = false;
          return isValid;
        }

        for (let a = 0; a < question.option1.length; a++) {
          const option = question.option1[a];
          if (
            !option.questionText.toString().trim() ||
            !option.correctAnswer.toString().trim()
          ) {
            isValid = false;
            return isValid;
          }
        }

        for (let b = 0; b < question.option2.length; b++) {
          const option = question.option2[b];
          if (
            !option.questionText.toString().trim() ||
            !option.correctAnswer.toString().trim()
          ) {
            isValid = false;
            return isValid;
          }
        }
      }

      if (question.questionType == "3") {
        if (
          !question.image1 ||
          !question.image2 ||
          question.questionSet.length == 0 ||
          question.questionSet2.length == 0
        ) {
          isValid = false;
          return isValid;
        }

        for (let j = 0; j < question.questionSet.length; j++) {
          const questionSet = question.questionSet[j];
          if (!questionSet.correctAnswer) {
            isValid = false;
            return isValid;
          }
        }

        for (let k = 0; k < question.questionSet2.length; k++) {
          const questionSet2 = question.questionSet2[k];
          if (!questionSet2.correctAnswer) {
            isValid = false;
            return isValid;
          }
        }
      }
    }
    return isValid;
  };

  const finalTestValidation = (finalData) => {
    let isValid = true;
    for (const question of finalData) {
      if (question.questionType == "1" || question.questionType == "2") {
        if (question.questionSet.length == 0) {
          isValid = false;
          return isValid;
        }

        for (let a = 0; a < question.questionSet.length; a++) {
          const option = question.questionSet[a];
          if (
            !option.questionText.toString().trim() ||
            !option.correctAnswer.toString().trim()
          ) {
            isValid = false;
            return isValid;
          }
        }
      }

      if (question.questionType == "3") {
        if (!question.image || question.questionSet.length == 0) {
          isValid = false;
          return isValid;
        }

        for (let j = 0; j < question.questionSet.length; j++) {
          const questionSet = question.questionSet[j];
          if (!questionSet.correctAnswer) {
            isValid = false;
            return isValid;
          }
        }
      }
    }
    return isValid;
  };

  const customValidation = () => {
    let error = false;

    if (!values.classID) {
      error = true;
    }

    if (!values.courseID) {
      error = true;
    }

    if (!values.sessionID) {
      error = true;
    }

    if (!slides.length > 0) {
      error = true;
    } else {
      if (!slideValidation(slides)) {
        error = true;
      }
    }

    if (!finalTData.length > 0) {
      error = true;
    } else {
      if (!finalTestValidation(finalTData)) {
        error = true;
      }
    }

    return error;
  };

  const deleteSlideHandler = (index) => {
    const rows = [...slides];
    rows.splice(index, 1);
    setSlides(rows);
  };

  const setClearData = () => {
    setSlides([]);
    setFinalTest([]);
    setValues({
      ...values,
      classID: "",
      courseID: "",
      sessionID: "",
    });
    setStartPpt("");
  };

  const handleSubmit = async () => {
    const basicValidation = customValidation();
    if (!basicValidation) {
      const formData = new FormData();
      formData.append("classID", values.classID);
      formData.append("courseID", values.courseID);
      formData.append("sessionID", values.sessionID);
      formData.append("audioFile", audioFileApi ? audioFileApi : null);
      formData.append("startPpt", startPpt ? startPpt : null);

      const SlideData = slides.map((res) => ({
        questionType: res.questionType,
        ppt: res.slidePpt ? res.slidePpt : "",
        audio: res.slideAudio ? res.slideAudio : null,
        type: 1,
        question: (() => {
          if (res.questionType == "3") {
            return [
              {
                image: res.image1,
                option: res.questionSet.map((payload) => ({
                  questionText: payload.questionText
                    ? payload.questionText
                    : "",
                  correctAnswer: payload.correctAnswer
                    ? payload.correctAnswer
                    : "",
                  optionType: "1",
                  Uid: payload.Uid ? payload.Uid : "",
                  top: payload.top ? payload.top : 0,
                  left: payload.left ? payload.left : 0,
                })),
              },
              {
                image: res.image2,
                option: res.questionSet2.map((payload) => ({
                  questionText: payload.questionText
                    ? payload.questionText
                    : "",
                  correctAnswer: payload.correctAnswer
                    ? payload.correctAnswer
                    : "",
                  optionType: "2",
                  Uid: payload.Uid ? payload.Uid : "",
                  top: payload.top ? payload.top : 0,
                  left: payload.left ? payload.left : 0,
                })),
              },
            ];
          } else {
            return [
              {
                option: res.option1.map((payload) => ({
                  questionText: payload.questionText,
                  optionType: 1,
                  correctAnswer: payload.correctAnswer,
                })),
              },
              {
                option: res.option2.map((payload) => ({
                  questionText: payload.questionText,
                  optionType: 2,
                  correctAnswer: payload.correctAnswer,
                })),
              },
            ];
          }
        })(),
      }));
      const FinalSlide = finalTData.map((res) => ({
        questionType: res.questionType,
        ppt: res.ppt ? res.ppt : "",
        type: 2,
        question: (() => {
          if (res.questionType == "3") {
            return [
              {
                image: res.image,
                option: res.questionSet.map((payload) => ({
                  questionText: payload.questionText
                    ? payload.questionText
                    : "",
                  correctAnswer: payload.correctAnswer
                    ? payload.correctAnswer
                    : "",
                  optionType: "1",
                  Uid: payload.Uid ? payload.Uid : "",
                  top: payload.top ? payload.top : 0,
                  left: payload.left ? payload.left : 0,
                })),
              },
            ];
          } else {
            return [
              {
                option: res.questionSet.map((payload) => ({
                  questionText: payload.questionText,
                  optionType: 1,
                  correctAnswer: payload.correctAnswer,
                })),
              },
            ];
          }
        })(),
      }));

      formData.append("Questions", JSON.stringify(SlideData));
      formData.append("finalQuestion", JSON.stringify(FinalSlide));
      formData.append("feedbackPpt1", "");
      formData.append("feedbackPpt2", "");

      const data = await AddQuestions(formData);
      if (data && data.status === true) {
        navigate("/question");
        setClearData();
        toast.success(data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } else {
      toast.error("please Enter requierd fields", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <Box>
      <Box
        sx={{
          px: 3,
          paddingBottom: "3px",
          backgroundColor: "rgb(241, 245, 249)",
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography
            sx={{
              paddingTop: "25px",
              paddingBottom: "15px",
              fontSize: "15px",
              fontWeight: "600",
              color: "#000",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/question");
            }}
          >
            <KeyboardBackspaceIcon
              sx={{ verticalAlign: "top", marginRight: "5px" }}
            />
            Question
          </Typography>
          <Box>
            <Controls.Button
              style={{
                backgroundColor: "#fff",
                color: "#000",
                marginRight: "10px",
              }}
              text="Cancel"
              onClick={() => {
                navigate("/question");
              }}
            />
            <Controls.Button
              style={{ marginRight: "7px" }}
              text="save as draft"
              onClick={(e) => handleSubmit(e)}
              type="submit"
            />
          </Box>
        </Box>
      </Box>
      <Box
        className="b1"
        sx={{
          backgroundColor: "white",
        }}
      >
        <Grid item md={12} justifyContent="center" sm={12} xs={12} container>
          <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
            <Controls.SelectBox
              className="input"
              label="Select Class"
              fullWidth
              id="classID"
              name="classID"
              value={values.classID}
              onChange={handleInputChange}
              options={classDropdown}
            />
            {Boolean(errors.classID) ? (
              <p
                style={{
                  color: "rgb(255 0 0)",
                  marginTop: "4px",
                  marginBottom: "4px",
                  fontSize: "14px",
                }}
              >
                {errors.classID}
              </p>
            ) : (
              ""
            )}
          </Grid>{" "}
          <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
            <Controls.SelectBox
              className="input"
              label="Select Course"
              fullWidth
              id="courseID"
              name="courseID"
              value={values.courseID}
              onChange={handleInputChange}
              options={CourseDropdown}
              error={errors.courseID}
              helperText={errors.courseID}
            />
            {Boolean(errors.courseID) ? (
              <p
                style={{
                  color: "rgb(255 0 0)",
                  marginTop: "4px",
                  marginBottom: "4px",
                  fontSize: "14px",
                }}
              >
                {errors.courseID}
              </p>
            ) : (
              ""
            )}
          </Grid>{" "}
          <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
            <Controls.SelectBox
              className="input"
              label="Select Session"
              fullWidth
              id="sessionID"
              name="sessionID"
              value={values.sessionID}
              onChange={handleInputChange}
              options={SessionDropdown}
            />
            {Boolean(errors.sessionID) ? (
              <p
                style={{
                  color: "rgb(255 0 0)",
                  marginTop: "4px",
                  marginBottom: "4px",
                  fontSize: "14px",
                }}
              >
                {errors.sessionID}
              </p>
            ) : (
              ""
            )}
          </Grid>{" "}
          <Grid item sx={{ m: 1 }} md={5.5} xs={12}></Grid>{" "}
          <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
            <Grid>
              <Button
                variant="contained"
                component="label"
                style={{ marginBottom: "8px" }}
              >
                upload start ppt
                <input
                  hidden
                  ref={startPptRef}
                  accept=".pptx,.ppt"
                  type="file"
                  onChange={(e) => startPpthandler(e)}
                />
              </Button>
              {startPptName && (
                <Tooltip placement="bottom" title="Delete ">
                  <button
                    className="action-button delete-btn"
                    style={{ marginLeft: "15px" }}
                    onClick={() => startPptDeleteHandler()}
                  >
                    <RiDeleteBinLine />
                  </button>
                </Tooltip>
              )}
              {startPptName && (
                <h5 style={{ marginTop: "10px" }}> {startPptName} </h5>
              )}
            </Grid>
          </Grid>
          <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
            <Grid>
              <Button
                variant="contained"
                component="label"
                style={{ marginBottom: "8px" }}
              >
                upload audio File
                <input
                  hidden
                  ref={startAudioRef}
                  accept="audio/*"
                  type="file"
                  onChange={(e) => audioFileHandler(e)}
                />
              </Button>
              {audioFileName && (
                <Tooltip placement="bottom" title="Delete ">
                  <button
                    className="action-button delete-btn"
                    style={{ marginLeft: "15px" }}
                    onClick={() => startAudioDeleteHandler()}
                  >
                    <RiDeleteBinLine />
                  </button>
                </Tooltip>
              )}
              {audioFileName && (
                <h5 style={{ marginTop: "10px" }}> {audioFileName} </h5>
              )}
              <div key={`${audioFile}`}>
                {audioFile && (
                  <UploadAudio audioFile={audioFile} keyName={`${audioFile}`} />
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box
        className="b1"
        sx={{
          backgroundColor: "white",
          marginTop: "20px",
          padding: "20px !important",
        }}
      >
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid item>
            <Typography variant="h6">Add knowledge check </Typography>
          </Grid>

          {slides.length <= 4 && (
            <Grid item sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                size="large"
                style={{
                  backgroundColor: "blue",
                  borderRadius: "10px",
                  width: "0%",
                }}
                startIcon={<AddIcon sx={{ marginLeft: "9px" }} />}
                fullWidth
                onClick={handleClick}
              />
            </Grid>
          )}
        </Grid>

        {/* multiple slides start Here  */}
        {slides?.map((res, index) => {
          let slideIndex = index;
          return (
            <ShortTest
              key={`ShorTest${index}`}
              slideI={index}
              sTData={res}
              slides={slides}
              backData={backDataHandler}
            />
          );
        })}
      </Box>

      <FinalTest data={finalTestData} />

      <ToastContainer
        position="top-right"
        autoClose={2000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </Box>
  );
}

export default SlidePhase;
