import { Route, Routes, useLocation } from "react-router-dom";
import PrivateRoute from "./routes/PrivateRoute";
import Login from "./pages/Login";
import AdminLogin from "./pages/Admin/Login/Login";
import Exam from "./pages/Exam";
import Questions from "./pages/Questions";
import Layout from "./components/Layout/Layout";
import AdminLayout from "./components/Layout/AdminLayout";
import { loadUserApi } from "./state/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import Result from "./pages/Result";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Main from "./components/CustomDragLayout/Main";
import SmallDND from "./pages/Questions/SmallDND";
import DataNotFound from "./pages/DataNotFound";
import UploadAudio from "./pages/Admin/upload/UploadAudio";
import './App.css'

export default function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname.substr(1);

  const path = window.location.pathname;
  const slug = path.split("/").filter((segment) => segment !== "")[0];

  window.onload = async () => {
    if (pathname !== "login" || pathname !== "adminlogin") {
      await dispatch(loadUserApi());
    }
  };
  const { user } = useSelector((store) => store.auth);
  let routeElement = null;

  if (user) {
    routeElement = user && user.roleID != 2 && (
      <PrivateRoute>
        <AdminLayout />
      </PrivateRoute>
    );
  }

  return (
    <>
      <Routes>
        <Route path="/login" element={<Login page="login" />} />
        <Route path="/adminlogin" element={<AdminLogin page="adminlogin" />} />
        <Route path="/exam" element={<Exam page="exam" />} />
        <Route path="/result" element={<Result page="result" />} />
        <Route path="/questions" element={<Questions page="questions" />} />
        <Route path="/audioTest" element={<UploadAudio />} />
        <Route
          path="/small-dnd"
          element={
            <DndProvider backend={HTML5Backend}>
              <SmallDND />
            </DndProvider>
          }
        />
        <Route exact path="/dataNotFound" element={<DataNotFound />} />
        <Route
          path="/customDragable"
          element={<Main page="CustomDragLayout" />}
        />
        {(slug == undefined ||
          slug == "home" ||
          slug == "about-me" ||
          slug == "courses" ||
          slug == "exam" ||
          slug == "questions" ||
          slug == "preview" ||
          slug == "finalResult" ||
          slug == "feedBack" ||
          slug == "finalResult1" ||
          slug == "finalRightAns" ||
          slug == "pptPreview") && <Route path="*" element={<Layout />} />}
        <Route path="*" element={routeElement} />
      </Routes>
    </>
  );
}
