import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { store } from "../../state/store";
import StartLogo from "../../assets/Images/centerLogo.png";
import { getSessionData, sessionDataHandler } from "../../state/actions/sessionActions";
function Exam() {
  window.scrollTo(0, 0);
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [sessionTest, setSessionTest] = useState([]);
  
  const getDetails = async (courseId) => {
    const res = await getSessionData(courseId);
    if (res && res.status === true) {
      setSessionTest(res?.data);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getDetails(state);
    }, 800);
  }, [state]);

 
  return (
    <>

      <div className="flex flex-col items-center+ bg-[--primaryLight] py-[100px]">
        <div className="flex justify-center items-center">
          <img src={StartLogo} alt="logo" className="w-[26%] h-[38%] flex justify-center items-center max-[600px]:h-auto max-[600px]:w-[40%]" />
        </div>
        {sessionTest?.length === 0 ? (
          <>
            <div className="flex flex-col items-center justify-between max-[600px]:gap-y-10 max-[600px]:justify-start font-nunito max-[]:">
              <div className="flex flex-col items-center max-[600px]:mt-10 ">
                <div className="text-[75px] font-normal max-[1100px]:text-[60px] max-[600px]:text-[40px] max-[380px]:text-[35px] font-archia">
                  No session available
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {sessionTest?.map((session, index) => {
              return (
                <div className="flex flex-col items-center justify-between max-[600px]:gap-y-10 max-[600px]:justify-start font-nunito max-[]:">
                  <div className="flex flex-col items-center max-[600px]:mt-10 ">
                    <div className="text-[75px] font-normal max-[1100px]:text-[60px] max-[600px]:text-[40px] max-[380px]:text-[35px] font-archia">
                      Topic: {session.courseName}
                    </div>
                    <div className="py-1 w-[100px] bg-[--primary]"></div>
                  </div>
                  <div className="text-[40px] mt-4 max-[1100px]:text-[30px] max-[600px]:text-[25px] max-[380px]:text-[22px]">
                    Session {index + 1}: {session.sessionName}
                  </div>
                  <div className="cursor-pointer border mt-4 rounded-full bg-[--primary] text-white text-[20px] px-14 py-3"
                    onClick={() => {
                      dispatch(sessionDataHandler(session));
                      navigate("/questions", {
                        state: session.id,
                      });
                    }}
                  >
                    Start
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>

    </>
  );
}

export default Exam;
