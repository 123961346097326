import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide1 from './modals/Slide1';
import Slide2 from './modals/Slide2';
import Slide3 from './modals/Slide3';


export default function CommonModal({ handleClose, modalOpen }) {

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="Common_modal"
            open={modalOpen.open}
        >
            {modalOpen.currentComponent === 'slide1' && <Slide1 handleClose={handleClose} modalOpen={modalOpen}/>}
            {modalOpen.currentComponent === 'slide2' && <Slide2 handleClose={handleClose} modalOpen={modalOpen}/>}
            {modalOpen.currentComponent === 'slide3' && <Slide3 handleClose={handleClose} modalOpen={modalOpen}/>}
        </Dialog>
    );
}
