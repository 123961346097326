import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  tableCellClasses,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import RadioGroup from "@mui/material/RadioGroup";
import ImageQuestion from "../ImageQuestion";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { styled } from "@mui/material/styles";
import Controls from "@/components/controls/Controls";
import camera from "../../../../assets/camera.svg";

function FinalTestView(props) {
  const Answer = [
    { id: 1, name: "True" },
    { id: 2, name: "False" },
  ];
  const [finaTestData, setFinalTestData] = useState([]);
  const [thirdQuestionEdit, setThirdQuestionEdit] = useState([]);


  useEffect(()=>{
    setFinalTestData(props.incomeData);
    setThirdQuestionEdit(props.incomeData[0]?.optionData);
  },[props]);


 

  

  const thirdQuestionHandler = (data) => {
    // setThirdQuestionData(data);
  };

  const thirIndexHandler=(slideIndex,data)=>{
    // finaTestData[0]["questionSet"] = data;
  }


  const StyledRemoveButton = styled(Box)({
    color: "red",
    display: "inline",
    "&:hover": {
      cursor: "pointer",
    },
  });

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: "#000",
      fontSize: 13,
      border: 0,
      fontWeight: 600,
      paddingTop: "15px",
      paddingBottom: "15px",
      paddingLeft: "25px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 13,
      color: "rgb(17, 24, 39)",
      border: 0,
      paddingLeft: "25px",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
  }));

  const StyledInput = styled("input")({
    display: "none",
  });

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&": {
      backgroundColor: "#fff",
      ":hover": { backgroundColor: "rgb(248 248 248)!important" },

      "&:nth-of-type(odd)": {
        backgroundColor: "rgb(250 250 250)!important",
      },
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const StyledImageUploadWrapper = styled(Box)(() => ({
    marginTop: "16px",
    border: "1px solid rgba(15, 15, 15, 0.15)",
    height: "206px",
    borderRadius: "10px",
    textAlign: "center",
  }));

  const StyledIconWrapper = styled(Box)(() => ({
    margin: "20px auto",
    border: "1px solid rgba(15, 15, 15, 0.15)",
    width: "80px",
    height: "80px",
    borderRadius: "10px",
    backgroundColor: "rgba(15, 15, 15, 0.05)",
    img: {
      marginTop: "30px",
    },
  }));

  

  return (
    <Box
      className="b1"
      sx={{
        backgroundColor: "white",
        marginTop: "20px",
        padding: "20px !important",
      }}
    >
      <Grid container justifyContent={"space-between"} alignItems={"center"}>
        <Grid item>
          <Typography variant="h6"> Final Test</Typography>
        </Grid>
      </Grid>
      {finaTestData.map((res, index) => {
        return (
          <>
            <Box
              key={`slides1 ${index}`}
              sx={{
                padding: "10px !important",
                border: "1px solid #8d8c8c",
                marginTop: "10px",
                borderRadius: "10px",
              }}
            >
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Box display="flex" alignItems="center">
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Question Type
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={res.questionType}
                    >
                      <FormControlLabel
                        value="1"
                        control={
                          <Radio/>
                        }
                        label="True / False"
                      />
                      <FormControlLabel
                        value="2"
                        control={
                          <Radio />
                        }
                        label="Drag & Drop"
                      />
                      <FormControlLabel
                        value="3"
                        control={
                          <Radio />
                        }
                        label="Image"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
              <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />

              {res.questionType == "1" && (
                <Grid
                  item
                  md={12}
                  justifyContent="center"
                  sm={12}
                  xs={12}
                  container
                >
                  <Grid item sx={{ m: 1 }} md={12} xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "400",
                        marginLeft: "28px",
                        fontSize: "20px",
                      }}
                    >
                      Question
                    </Typography>
                  </Grid>

                  <Grid item sx={{ m: 1 }} md={12} xs={12}>
                    <Box
                      sx={{
                        backgroundColor: "rgb(241, 245, 249)",
                        width: "98%",
                        margin: "auto",
                      }}
                    >
                      <Box
                        sx={{
                          border: 1,
                          borderColor: "#eaeaea",
                        }}
                      >
                        <Table className="table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Question Text</StyledTableCell>
                              <StyledTableCell>Correct Answer</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {res.optionData.map((data, index) => {
                              const { questionText, correctAnswer } = data;
                              return (
                                <StyledTableRow
                                  key={index}
                                  sx={{ cursor: "pointer" }}
                                >
                                  <StyledTableCell component="th" scope="row">
                                    <Controls.Input
                                      text="Add New"
                                      variant="outlined"
                                      color="primary"
                                      disabled
                                      fullWidth
                                      label="Question Text"
                                      name="questionText"
                                      value={questionText}
                                      inputProps={{
                                        maxLength: 255,
                                      }}
                                    />
                                  </StyledTableCell>

                                  <StyledTableCell component="th" scope="row">
                                    <Controls.SelectBox
                                      className="input"
                                      label="Select Answer"
                                      fullWidth
                                      disabled
                                      id="correctAnswer"
                                      name="correctAnswer"
                                      value={correctAnswer}
                                      options={Answer}
                                    />
                                  </StyledTableCell>
                                </StyledTableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              )}
              <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
             
              {res.questionType == "2" && (
                <Grid
                  item
                  md={12}
                  justifyContent="center"
                  sm={12}
                  xs={12}
                  container
                >
                  <Grid item sx={{ m: 1 }} md={12} xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "400",
                        marginLeft: "28px",
                        fontSize: "20px",
                      }}
                    >
                      question
                    </Typography>
                  </Grid>

                  <Grid item sx={{ m: 1 }} md={12} xs={12}>
                    <Box
                      sx={{
                        backgroundColor: "rgb(241, 245, 249)",
                        width: "98%",
                        margin: "auto",
                      }}
                    >
                      <Box
                        sx={{
                          border: 1,
                          borderColor: "#eaeaea",
                        }}
                      >
                        <Table className="table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Question Text</StyledTableCell>
                              <StyledTableCell>Correct Answer</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {/* rowsTermsData */}
                            {res.optionData?.map((data, index) => {
                              const { questionText, correctAnswer } = data;

                              return (
                                <StyledTableRow
                                  key={index}
                                  sx={{ cursor: "pointer" }}
                                >
                                  <StyledTableCell component="th" scope="row">
                                    <Controls.Input
                                      text="Add New"
                                      variant="outlined"
                                      color="primary"
                                      disabled
                                      fullWidth
                                      label="Question Text"
                                      value={questionText}
                                      name="questionText"
                                      inputProps={{
                                        maxLength: 255,
                                      }}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell component="th" scope="row">
                                    <Controls.Input
                                      text="Add New"
                                      variant="outlined"
                                      color="primary"
                                      disabled
                                      label="correct Answer"
                                      value={correctAnswer}
                                      name="correctAnswer"
                                      inputProps={{
                                        maxLength: 255,
                                      }}
                                    />
                                  </StyledTableCell>
                                </StyledTableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              )}
              <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
              {res.questionType == "3" && (
                <>
                <Grid
                  item
                  md={12}
                  justifyContent="center"
                  sm={12}
                  xs={12}
                  container
                >
                  <Grid item sx={{ m: 1 }} md={12} xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "400",
                        marginLeft: "28px",
                        fontSize: "20px",
                      }}
                    >
                      Question Type Image
                    </Typography>
                    <Box key={`FInal-Index-1`}>
                      <StyledImageUploadWrapper>
                        <label htmlFor="image-upload-Final">
                          <StyledInput />
                          <StyledIconWrapper
                            sx={
                              res.image == ""
                                ? {
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }
                                : {
                                    backgroundImage: `url(${res.image})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                  }
                            }
                          >
                            {res.image === "" && (
                              <img
                                src={camera}
                                alt="Camera"
                                style={{ margin: "0" }}
                              />
                            )}
                          </StyledIconWrapper>
                        </label>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "black",
                          }}
                        >
                          Add Images For Question
                        </Typography>
                        <Typography
                          sx={{
                            color: "rgba(15, 15, 15, 0.5)",
                            mx: 2,
                            fontSize: "12px",
                          }}
                        >
                          Allowed *.jpeg, *.jpg, *.png, max size of 3.1 MB
                        </Typography>
                      </StyledImageUploadWrapper>
                    </Box>
                  </Grid>
                </Grid>
                
                <DndProvider backend={HTML5Backend}>
                    <ImageQuestion
                      data={thirdQuestionHandler}
                      indexHandler={thirIndexHandler}
                      View={true}
                      Image={res.image && res.image}
                      OutData={thirdQuestionEdit}
                    />
                  </DndProvider>
                </>
              )}
            </Box>
          </>
        );
      })}
    </Box>
  );
}

export default FinalTestView;
