import { Box } from "@mui/material";
import React from "react";
import ClassList from "./ClassList";

const Class = () => {
  return (
    <Box>
      <ClassList />
    </Box>
  );
};

export default Class;
