import React, { useEffect, useState } from "react";
import { Space } from "antd";
import { useForm, Form } from "@/utils/useForm";
import debounce from "lodash.debounce";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  tableCellClasses,
} from "@mui/material";
import Controls from "@/components/controls/Controls";
import Typography from "@mui/material/Typography";
import ImageQuestion from "../ImageQuestion";
import { styled } from "@mui/material/styles";
import camera from "../../../../assets/camera.svg";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import UploadAudio from "../../upload/UploadAudio";

function ShortTestView({ slideI, sTData, slides, backData }) {
  const [errorMessage, setErrorMessage] = useState("");
  const [shorTestData, setShortTestData] = useState(slides);
  const [tFo1Data, setTFO1eData] = useState([]);
  const [tFo2Data, setTFO2eData] = useState([]);
  const [dD1Data, setDD1Data] = useState([]);
  const [dD2Data, setDD2Data] = useState([]);
  const [imageOne, setImageOne] = useState([]);
  const [imageTwo, setImageTwo] = useState([]);
  const [imageOneData, setImageOneData] = useState([]);
  const [imageTwoData, setImageTwoData] = useState([]);

  const AttemptHandler = (data) => {
    if (data.questionType == "1") {
      const optionType1Data = data.optionData.filter(
        (item) => item.optionType === 1
      );
      const optionType2Data = data.optionData.filter(
        (item) => item.optionType === 2
      );
      setTFO1eData(optionType1Data);
      setTFO2eData(optionType2Data);
    }
    if (data.questionType == "2") {
      const optionType1Data = data.optionData.filter(
        (item) => item.optionType === 1
      );
      const optionType2Data = data.optionData.filter(
        (item) => item.optionType === 2
      );
      setDD1Data(optionType1Data);
      setDD2Data(optionType2Data);
    }
    if (data.questionType == "3") {
      const optionType1Data = data.optionData.filter(
        (item) => item.optionType === 1
      );
      const optionType2Data = data.optionData.filter(
        (item) => item.optionType === 2
      );
      setImageOneData(optionType1Data);
      setImageOne(optionType1Data[0]?.image);
      setImageTwoData(optionType2Data);
      setImageTwo(optionType2Data[0]?.image);
    }
  };

  useEffect(() => {
    AttemptHandler(slides[slideI]);
  }, []);

  const StyledInput = styled("input")({
    display: "none",
  });

  const thirdQuestionHandler = () => { };

  const thirIndexHandler = () => { };

  const Types = [
    { id: 1, title: "True / False" },
    { id: 2, title: "Drag & Drop" },
    { id: 3, title: "Image" },
  ];

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(true);

  const StyledImageUploadWrapper = styled(Box)(() => ({
    marginTop: "16px",
    border: "1px solid rgba(15, 15, 15, 0.15)",
    height: "206px",
    borderRadius: "10px",
    textAlign: "center",
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: "#000",
      fontSize: 13,
      border: 0,
      fontWeight: 600,
      paddingTop: "15px",
      paddingBottom: "15px",
      paddingLeft: "25px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 13,
      color: "rgb(17, 24, 39)",
      border: 0,
      paddingLeft: "25px",

      paddingTop: "10px",
      paddingBottom: "10px",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&": {
      backgroundColor: "#fff",
      ":hover": { backgroundColor: "rgb(248 248 248)!important" },

      "&:nth-of-type(odd)": {
        backgroundColor: "rgb(250 250 250)!important",
      },
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const StyledRemoveButton = styled(Box)({
    color: "red",
    display: "inline",
    "&:hover": {
      cursor: "pointer",
    },
  });

  const StyledIconWrapper = styled(Box)(() => ({
    margin: "20px auto",
    border: "1px solid rgba(15, 15, 15, 0.15)",
    width: "80px",
    height: "80px",
    borderRadius: "10px",
    backgroundColor: "rgba(15, 15, 15, 0.05)",
    img: {
      marginTop: "30px",
    },
  }));

  const Answer = [
    { id: 1, name: "True" },
    { id: 2, name: "False" },
  ];

  return (
    <Box
      key={`shortTest${slideI}`}
      sx={{
        padding: "10px !important",
        border: "1px solid #8d8c8c",
        marginTop: "12px",
        borderRadius: "10px",
        position: "relative"
      }}
    >
      {/* Question Type */}

      <div className="absolute top-[-12px] left-[40px] bg-white text-lg font-semibold px-[10px] w-fit">{`Unit ${slideI + 1}`}</div>

      <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
        <Box display="flex" alignItems="center">
          <Controls.RadioGroup
            name="questionType"
            disabled
            value={values.questionType || shorTestData[slideI].questionType}
            items={Types}
            isRowDir={true}
          />
          {Boolean(errors.questionType) ? (
            <p
              style={{
                color: "rgb(255 0 0)",
                marginTop: "4px",
                marginBottom: "4px",
                fontSize: "14px",
              }}
            >
              {errors.questionType}
            </p>
          ) : (
            ""
          )}
        </Box>
      </Grid>
      <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
      {shorTestData[slideI]?.questionType == "1" && (
        <Grid item md={12} justifyContent="center" sm={12} xs={12} container>
          <Grid item sx={{ m: 1 }} md={12} xs={12}>
            <Typography
              sx={{
                fontWeight: "400",
                marginLeft: "28px",
                fontSize: "20px",
              }}
            >
              Attempt - 1
            </Typography>
          </Grid>

          <Grid item sx={{ m: 1 }} md={12} xs={12}>
            <Box
              sx={{
                backgroundColor: "rgb(241, 245, 249)",
                width: "98%",
                margin: "auto",
              }}
            >
              <Box
                sx={{
                  border: 1,
                  borderColor: "#eaeaea",
                }}
              >
                <Table className="table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Question Text</StyledTableCell>
                      <StyledTableCell>Correct Answer</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tFo1Data.map((data, index) => {
                      const { questionText, correctAnswer } = data;
                      return (
                        <StyledTableRow key={index} sx={{ cursor: "pointer" }}>
                          <StyledTableCell component="th" scope="row">
                            <Controls.Input
                              text="Add New"
                              variant="outlined"
                              color="primary"
                              disabled
                              fullWidth
                              label="Question Text"
                              name="questionText"
                              value={questionText}
                              inputProps={{
                                maxLength: 255,
                              }}
                            />
                          </StyledTableCell>

                          <StyledTableCell component="th" scope="row">
                            <Controls.SelectBox
                              className="input"
                              label="Select Answer"
                              fullWidth
                              id="correctAnswer"
                              disabled
                              name="correctAnswer"
                              value={correctAnswer}
                              options={Answer}
                            />
                            {Boolean(errors.correctAnswer) ? (
                              <p
                                style={{
                                  color: "rgb(255 0 0)",
                                  marginTop: "4px",
                                  marginBottom: "4px",
                                  fontSize: "14px",
                                }}
                              >
                                {errors.correctAnswer}
                              </p>
                            ) : (
                              ""
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </Grid>

          <Grid item sx={{ m: 1 }} md={12} xs={12}>
            <Typography
              sx={{
                fontWeight: "400",
                marginLeft: "28px",
                fontSize: "20px",
              }}
            >
              Attempt - 2
            </Typography>
          </Grid>

          <Grid item sx={{ m: 1 }} md={12} xs={12}>
            <Box
              sx={{
                backgroundColor: "rgb(241, 245, 249)",
                width: "98%",
                margin: "auto",
              }}
            >
              <Box
                sx={{
                  border: 1,
                  borderColor: "#eaeaea",
                }}
              >
                <Table className="table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Question Text</StyledTableCell>
                      <StyledTableCell>Correct Answer</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tFo2Data.map((data, index) => {
                      const { questionText, correctAnswer } = data;
                      return (
                        <StyledTableRow key={index} sx={{ cursor: "pointer" }}>
                          <StyledTableCell component="th" scope="row">
                            <Controls.Input
                              text="Add New"
                              variant="outlined"
                              color="primary"
                              label="Question Text"
                              name="questionText"
                              value={questionText}
                              disabled
                              fullWidth
                              inputProps={{
                                maxLength: 255,
                              }}
                            />
                          </StyledTableCell>

                          <StyledTableCell component="th" scope="row">
                            <Controls.SelectBox
                              className="input"
                              label="Select Answer"
                              fullWidth
                              id="correctAnswer"
                              name="correctAnswer"
                              value={correctAnswer}
                              options={Answer}
                            />
                            {Boolean(errors.correctAnswer) ? (
                              <p
                                style={{
                                  color: "rgb(255 0 0)",
                                  marginTop: "4px",
                                  marginBottom: "4px",
                                  fontSize: "14px",
                                }}
                              >
                                {errors.correctAnswer}
                              </p>
                            ) : (
                              ""
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
      <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />

      {/* question type 2 */}
      {shorTestData[slideI]?.questionType == "2" ? (
        <Grid item md={12} justifyContent="center" sm={12} xs={12} container>
          <Grid item sx={{ m: 1 }} md={12} xs={12}>
            <Typography
              sx={{
                fontWeight: "400",
                marginLeft: "28px",
                fontSize: "20px",
              }}
            >
              Attempt -1
            </Typography>
          </Grid>

          <Grid item sx={{ m: 1 }} md={12} xs={12}>
            <Box
              sx={{
                backgroundColor: "rgb(241, 245, 249)",
                width: "98%",
                margin: "auto",
              }}
            >
              <Box
                sx={{
                  border: 1,
                  borderColor: "#eaeaea",
                }}
              >
                <Table className="table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Question Text</StyledTableCell>
                      <StyledTableCell>Correct Answer</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dD1Data?.map((data, index) => {
                      const { questionText, correctAnswer } = data;
                      return (
                        <StyledTableRow key={index} sx={{ cursor: "pointer" }}>
                          <StyledTableCell component="th" scope="row">
                            <Controls.Input
                              text="Add New"
                              variant="outlined"
                              color="primary"
                              disabled
                              fullWidth
                              label="Question Text"
                              value={questionText}
                              name="questionText"
                              inputProps={{
                                maxLength: 255,
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            <Controls.Input
                              text="Add New"
                              variant="outlined"
                              color="primary"
                              disabled
                              label="correct Answer"
                              value={correctAnswer}
                              name="correctAnswer"
                              inputProps={{
                                maxLength: 255,
                              }}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </Grid>

          <Grid item sx={{ m: 1 }} md={12} xs={12}>
            <Typography
              sx={{
                fontWeight: "400",
                marginLeft: "28px",
                fontSize: "20px",
              }}
            >
              Attempt -2
            </Typography>
          </Grid>

          <Grid item sx={{ m: 1 }} md={12} xs={12}>
            <Box
              sx={{
                backgroundColor: "rgb(241, 245, 249)",
                width: "98%",
                margin: "auto",
              }}
            >
              <Box
                sx={{
                  border: 1,
                  borderColor: "#eaeaea",
                }}
              >
                <Table className="table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Question Text</StyledTableCell>
                      <StyledTableCell>Correct Answer</StyledTableCell>
                      {/* <StyledTableCell sx={{ width: "10px" }}>
                        <Button
                          variant="contained"
                          size="large"
                          style={{
                            backgroundColor: "blue",
                            borderRadius: "10px",
                            width: "0%",
                          }}
                          startIcon={<AddIcon sx={{ marginLeft: "9px" }} />}
                          fullWidth
                          onClick={() => addTableRowsTerms(slideI, "option2")}
                        />
                      </StyledTableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* rowsTermsData */}
                    {dD2Data.map((data, index) => {
                      const { questionText, correctAnswer } = data;

                      return (
                        <StyledTableRow key={index} sx={{ cursor: "pointer" }}>
                          <StyledTableCell component="th" scope="row">
                            <Controls.Input
                              text="Add New"
                              variant="outlined"
                              color="primary"
                              label="Question Text"
                              value={questionText}
                              disabled
                              fullWidth
                              name="questionText"
                              inputProps={{
                                maxLength: 255,
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            <Controls.Input
                              text="Add New"
                              variant="outlined"
                              color="primary"
                              label="correct Answer"
                              value={correctAnswer}
                              disabled
                              name="correctAnswer"
                              inputProps={{
                                maxLength: 255,
                              }}
                            />
                          </StyledTableCell>
                          {/* <StyledTableCell
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Space size="middle">
                              <Tooltip placement="bottom" title="Delete ">
                                <button
                                  className="action-button delete-btn"
                                  onClick={() =>
                                    deleteTableRowsTerms(
                                      index,
                                      slideI,
                                      "option2"
                                    )
                                  }
                                  style={{ marginLeft: "15px" }}
                                >
                                  <RiDeleteBinLine />
                                </button>
                              </Tooltip>
                            </Space>
                          </StyledTableCell> */}
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </Grid>
        </Grid>
      ) : null}
      <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />

      {/* question type 3 */}

      {shorTestData[slideI]?.questionType == "3" ? (
        <>
          {/* Attempt - 1 */}
          <Grid
            item
            md={12}
            mt={2}
            justifyContent="center"
            sm={12}
            xs={12}
            container
          >
            <Grid item sx={{ m: 1 }} md={12} xs={12}>
              <Typography
                sx={{
                  fontWeight: "400",
                  marginLeft: "28px",
                  fontSize: "20px",
                }}
              >
                Attempt - 1
              </Typography>
              {errorMessage && (
                <h5 style={{ color: "red" }}> {errorMessage}</h5>
              )}
              <Box key={`Index-1`}>
                <StyledImageUploadWrapper>
                  <label htmlFor={`image-upload-Attempt-1_${slideI}`}>
                    <StyledIconWrapper
                      sx={
                        imageOne == ""
                          ? {
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }
                          : {
                            backgroundImage: `url(${imageOne})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                          }
                      }
                    >
                      {imageOne === "" && (
                        <img
                          src={camera}
                          alt="Camera"
                          style={{ margin: "0" }}
                        />
                      )}
                    </StyledIconWrapper>
                  </label>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "black",
                    }}
                  >
                    Add Images For Question
                  </Typography>
                  <Typography
                    sx={{
                      color: "rgba(15, 15, 15, 0.5)",
                      mx: 2,
                      fontSize: "12px",
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, max size of 3.1 MB
                  </Typography>
                </StyledImageUploadWrapper>
              </Box>
            </Grid>
          </Grid>
          <DndProvider backend={HTML5Backend} key={`option-1`}>
            <ImageQuestion
              data={thirdQuestionHandler}
              slideIndex={slideI}
              option="option1"
              indexHandler={thirIndexHandler}
              View={true}
              Image={imageOne && imageOne}
              OutData={imageOneData}
            />
          </DndProvider>
          <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />

          {/* Attempt - 2 */}

          <Grid item md={12} justifyContent="center" sm={12} xs={12} container>
            <Grid item sx={{ m: 1 }} md={12} xs={12}>
              <Typography
                sx={{
                  fontWeight: "400",
                  marginLeft: "28px",
                  fontSize: "20px",
                }}
              >
                Attempt - 2
              </Typography>
              {errorMessage && (
                <h5 style={{ color: "red" }}> {errorMessage}</h5>
              )}
              <Box key={`Index-2`}>
                <StyledImageUploadWrapper>
                  <label htmlFor={`image-upload-Attempt-2_${slideI}`}>
                    <StyledInput
                      accept="image/*"
                      id={`image-upload-Attempt-2_${slideI}`}
                      type="file"
                    />
                    <StyledIconWrapper
                      sx={
                        imageTwo == ""
                          ? {
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }
                          : {
                            backgroundImage: `url(${imageTwo})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                          }
                      }
                    >
                      {imageTwo === "" && (
                        <img
                          src={camera}
                          alt="Camera"
                          style={{ margin: "0" }}
                        />
                      )}
                    </StyledIconWrapper>
                  </label>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "black",
                    }}
                  >
                    Add Images For Question
                  </Typography>
                  <Typography
                    sx={{
                      color: "rgba(15, 15, 15, 0.5)",
                      mx: 2,
                      fontSize: "12px",
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, max size of 3.1 MB
                  </Typography>
                </StyledImageUploadWrapper>
              </Box>
            </Grid>
          </Grid>
          <DndProvider backend={HTML5Backend} key={`option-1`}>
            <ImageQuestion
              data={thirdQuestionHandler}
              slideIndex={slideI}
              option="option2"
              indexHandler={thirIndexHandler}
              Image={imageTwo && imageTwo}
              View={true}
              OutData={imageTwoData}
            />
          </DndProvider>
          <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
        </>
      ) : null}
      <Grid item marginTop={"20px"}>
        <Grid container justifyContent={"center"}>
          <Grid item sx={{ m: 1 }} md={5.7} xs={12}>
            <Button variant="contained" component="label">
              ppt
              <input hidden />
            </Button>
            {shorTestData[slideI].ppt && <h5 style={{ marginTop: "10px", overflowWrap: 'anywhere' }}>{shorTestData[slideI].ppt}</h5>}
          </Grid>
          <Grid item sx={{ m: 1 }} md={5.7} xs={12}>
            <Button variant="contained" component="label">
              audio
              <input hidden />
            </Button>
            {
              shorTestData[slideI].audio && <h5 style={{ marginTop: "10px", overflowWrap: 'anywhere' }}>{shorTestData[slideI].audio}</h5>
            }
            {shorTestData[slideI].audio && (
              <UploadAudio
                audioFile={shorTestData[slideI].audio}
                keyName={`${shorTestData[slideI].audio}`}
              />
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        sx={{
          display: "flex",
          marginTop: "20px",
          justifyContent: "flex-end",
        }}
      ></Grid>
    </Box>
  );
}

export default ShortTestView;
