import React, { useEffect, useReducer, useRef, useState } from "react";
import { Space } from "antd";
import { useForm, Form } from "@/utils/useForm";
import { ToastContainer, toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  tableCellClasses,
} from "@mui/material";
import Controls from "@/components/controls/Controls";
import Typography from "@mui/material/Typography";
import ImageQuestion from "../ImageQuestion";
import { styled } from "@mui/material/styles";
import camera from "../../../../assets/camera.svg";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { RiDeleteBinLine, RiEditLine } from "react-icons/ri";
import {
  deleteAudiobyShortTest,
  deletePptByShortTest,
  deleteSlideByPptId,
  editApiHandler,
} from "@/state/actions/Admin/questionAction";
import { useNavigate } from "react-router";
import UploadAudio from "../../upload/UploadAudio";

const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "0",
  outline: "0",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: 3,
};

function ShortTestEdit({
  slideI,
  SlideId,
  sTData,
  slides,
  backData,
  questionId,
}) {
  const navigate = useNavigate();
  const [thirdQuestionData, setThirdQuestionData] = useState([]);
  const [thirdQuestionEdit, setThirdQuestionEdit] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [apiTotalData, setApiTotalData] = useState([]);
  const [apiTotalData2, setApiTotalData2] = useState([]);
  const [shorTestData, setShortTestData] = useState(slides);
  const [tFo1Data, setTFO1eData] = useState([]);
  const [tFo2Data, setTFO2eData] = useState([]);
  const [dD1Data, setDD1Data] = useState([]);
  const [dD2Data, setDD2Data] = useState([]);
  const [imageOne, setImageOne] = useState([]);
  const [imageTwo, setImageTwo] = useState([]);
  const [imageApi1, setImageApi1] = useState("");
  const [imageApi2, setImageApi2] = useState("");
  const [deleteIds, setDeleteIds] = useState([]);
  const [imageOneData, setImageOneData] = useState([]);
  const [imageTwoData, setImageTwoData] = useState([]);
  const [qTypeChanged, setQuestionTypeChnaged] = useState(false);
  const [i1, setI1] = useState("");
  const [iApi1, setApi1] = useState("");
  const [ppt, setPpt] = useState("");
  const [audio, setAudio] = useState("");
  const [audioApi, setAudioApi] = useState("");
  const [audioName, setAudioName] = useState("");
  const [pptName, setPptName] = useState("");

  const truFalse = useRef([]);
  const trueFalseAttempet2 = useRef([]);
  const dragAtt1 = useRef([]);
  const dragAtt2 = useRef([]);
  const dragAnsAtt1 = useRef([]);
  const dragAnsAtt2 = useRef([]);

  // Attempet -1
  const [t1index, setT1Index] = useState();
  const [d1Index, setD1Index] = useState();
  const [d1AIndex, setD1AIndex] = useState();

  // Attempet-2
  const [t2index, setT2Index] = useState();
  const [d2Index, setD2Index] = useState();
  const [d2AIndex, setD2AIndex] = useState();

  const [deletebyFlag, setDeleteByFlag] = useState(false);
  const [deleteAudioByFlag, setDeleteAudioByFlag] = useState(false);

  const trueFalseIndexHandler = () => {
    const lastFieldIndex = truFalse.current.length - 1;
    setT1Index(lastFieldIndex);
  };

  // to upload File again Issue
  const startPptRef = useRef(null);
  const startAudioRef = useRef(null);

  // below useState and Funcation is  For True False Attempt-1
  const [cursorPositions, setCursorPositions] = useState({});

  const handleCursorChange = (index, position) => {
    setCursorPositions((prevState) => ({
      ...prevState,
      [index]: position,
    }));
  };

  // below useState and Funcation For True False Attempt-2
  const [cursorPositions2, setCursorPositions2] = useState({});

  const handleCursorChange2 = (index, position) => {
    setCursorPositions2((prevState) => ({
      ...prevState,
      [index]: position,
    }));
  };

  // below useState and Funcation For Drag and Drop Attempt-1
  const [cPDD1, setCPDD1] = useState({});
  const handlecPDD1 = (index, position) => {
    setCPDD1((prevState) => ({
      ...prevState,
      [index]: position,
    }));
  };

  // below useState and Funcation For Drag and Drop Attempt-2
  const [cPDD2, setCPDD2] = useState({});
  const handlecPDD2 = (index, position) => {
    setCPDD2((prevState) => ({
      ...prevState,
      [index]: position,
    }));
  };

  // below useState and Funcation For Drag and Drop (Answer) Attempt-1
  const [cPDDA1, setCPDDA1] = useState({});
  const handlecPDDA1 = (index, position) => {
    setCPDDA1((prevState) => ({
      ...prevState,
      [index]: position,
    }));
  };

  // below useState and Funcation For Drag and Drop (Answer) Attempt-2
  const [cPDDA2, setCPDDA2] = useState({});
  const handlecPDDA2 = (index, position) => {
    setCPDDA2((prevState) => ({
      ...prevState,
      [index]: position,
    }));
  };

  const deletePptHandler = async (data, Index) => {
    if (data && !deletebyFlag) {
      const deletePptBySt = await deletePptByShortTest(data);
      if (deletePptBySt.status) {
        handleCloseDelete();
        toast.success(deletePptBySt.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        handleCloseDelete();
        toast.error(deletePptBySt.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
    shorTestData[Index]["slidePpt"] = "";
    shorTestData[Index]["slidePptName"] = "";
    setPpt("");
    setPptName("");
    handleCloseDelete();
  };

  const deleteAudioHandler = async (data, Index) => {
    if (data && !deleteAudioByFlag) {
      const deleteAudioBySt = await deleteAudiobyShortTest(data);
      if (deleteAudioBySt.status) {
        toast.success(deleteAudioBySt.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(deleteAudioBySt.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
    setAudio("");
    setAudioApi("");
    const rowsInput = [...slides];
    rowsInput[Index]["audio"] = "";
    setAudioName("");
  }

  const AttemptHandler = (data) => {
    setPpt(data.ppt);
    setAudio(data.audio);
    setAudioApi(data.audio);
    setPptName(data.ppt);
    setAudioName(data.audio);
    if (data.questionType == "1") {
      const optionType1Data = data?.optionData?.filter(
        (item) => item.optionType === 1
      );
      const optionType2Data = data?.optionData?.filter(
        (item) => item.optionType === 2
      );
      setTFO1eData(optionType1Data);
      setApiTotalData(optionType1Data);
      setApiTotalData2(optionType2Data);
      shorTestData[slideI]["option1"] = optionType1Data;
      shorTestData[slideI]["option2"] = optionType2Data;
      setTFO2eData(optionType2Data);
    }
    if (data.questionType == "2") {
      setApiTotalData(data.optionData);
      const optionType1Data = data.optionData.filter(
        (item) => item.optionType === 1
      );
      const optionType2Data = data.optionData.filter(
        (item) => item.optionType === 2
      );
      setDD1Data(optionType1Data);
      setApiTotalData(optionType1Data);
      setApiTotalData2(optionType2Data);
      shorTestData[slideI]["option1"] = optionType1Data;
      setDD2Data(optionType2Data);
      shorTestData[slideI]["option2"] = optionType2Data;
    }
    if (data.questionType == "3") {
      setApiTotalData(data.optionData);
      const optionType1Data = data.optionData.filter(
        (item) => item.optionType === 1
      );
      const optionType2Data = data.optionData.filter(
        (item) => item.optionType === 2
      );
      setImageOneData(optionType1Data);
      setImageOne(optionType1Data[0]?.image);
      setImageTwoData(optionType2Data);
      setImageTwo(optionType2Data[0]?.image);
      setApiTotalData(optionType1Data);
      setApiTotalData2(optionType2Data);
    }
  };

  useEffect(() => {
    AttemptHandler(slides[slideI]);
  }, []);

  const trueFalseAtt2Handler = () => {
    const lastFieldIndex = trueFalseAttempet2.current.length - 1;
    setT2Index(lastFieldIndex);
  };

  const dragIndexHandler = () => {
    const lastFieldIndex = dragAtt1.current.length - 1;
    setD1Index(lastFieldIndex);
  };

  const dragIndexAtt2Handler = () => {
    const lastFieldIndex = dragAtt2.current.length - 1;
    setD2Index(lastFieldIndex);
  };

  const dragAnsIndexHandler = () => {
    const lastFieldIndex = dragAnsAtt1.current.length - 1;
    setD1AIndex(lastFieldIndex);
  };

  const dragAnsAtt2IndexHandler = () => {
    const lastFieldIndex = dragAnsAtt2.current.length - 1;
    setD2AIndex(lastFieldIndex);
  };

  const addTableRows = (index, option) => {
    const rowsInput = {
      questionText: "",
      correctAnswer: "",
    };
    if (option == "option1") {
      setTFO1eData([...tFo1Data, rowsInput]);
      shorTestData[index][option] = [...tFo1Data, rowsInput];
    } else {
      setTFO2eData([...tFo2Data, rowsInput]);
      shorTestData[index][option] = [...tFo2Data, rowsInput];
    }
  };

  const handleChanges = (index, e, slideIndex, option) => {
    const { name, value } = e.target;

    if (option == "option1") {
      const rowsInput = [...tFo1Data];
      rowsInput[index][name] = value;
      setTFO1eData(rowsInput);
      setT1Index(index);
      setT2Index(null);
    } else {
      setT2Index(index);
      setT1Index(null);
      const rowsInput = [...tFo2Data];
      rowsInput[index][name] = value;
      setTFO2eData(rowsInput);
    }
  };

  const addTableRowsTerms = (index, option) => {
    const rowsInput = {
      questionText: "",
      correctAnswer: "",
    };
    if (option == "option1") {
      setDD1Data([...dD1Data, rowsInput]);
      shorTestData[index][option] = [...dD1Data, rowsInput];
    } else {
      setDD2Data([...dD2Data, rowsInput]);
      shorTestData[index][option] = [...dD2Data, rowsInput];
    }
  };

  const StyledInput = styled("input")({
    display: "none",
  });

  const handleChangesTerms = (index, e, slideIndex, option) => {
    const { name, value } = e.target;
    if (option == "option1") {
      const rowsInput = [...dD1Data];
      rowsInput[index][name] = value;
      shorTestData[slideI]["option1"] = rowsInput;
      setDD1Data(rowsInput);
      if (name == "questionText") {
        setD1Index(index);
        setD1AIndex(null);
        setD2AIndex(null);
        setD2Index(null);
      }
      if (name == "correctAnswer") {
        setD1AIndex(index);
        setD1Index(null);
        setD2AIndex(null);
        setD2Index(null);
      }
    } else {
      const rowsInput = [...dD2Data];
      rowsInput[index][name] = value;
      setDD2Data(rowsInput);
      shorTestData[slideI]["option2"] = rowsInput;
      if (name == "questionText") {
        setD2Index(index);
        setD2AIndex(null);
        setD1Index(null);
        setD1AIndex(null);
      }
      if (name == "correctAnswer") {
        setD2AIndex(index);
        setD2Index(null);
        setD1Index(null);
        setD1AIndex(null);
      }
    }
  };

  const Types = [
    { id: 1, title: "True / False" },
    { id: 2, title: "Drag & Drop" },
    { id: 3, title: "Image" },
  ];

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(true);

  const maxSize = 2048;

  const validateImageSize = (vehicleIcons) => {
    if (vehicleIcons) {
      const fsize = vehicleIcons.size / 1024;
      if (fsize > maxSize) {
        return false;
      } else {
        return true;
      }
    }
  };

  const handleImageItem = async (slideIndex, e, option) => {
    if (validateImageSize(e.target.files[0])) {
      const selected = e.target.files[0];
      const ALLOWED_TYPES = ["image/png", "image/jpg", "image/jpeg"];
      if (selected && ALLOWED_TYPES.includes(selected.type)) {
        const base64String = await getBase64(selected);
        const rowsInput = [...slides];
        rowsInput[slideIndex]["image1"] = base64String;
        rowsInput[slideIndex]["imageApi1"] = base64String;
        setShortTestData(rowsInput);
        setImageOne(base64String);
        setImageApi1(base64String);
      }
    }
  };

  const customValidation = (data, QuestionId) => {
    let isValid = true;
    if (ppt) {
      if (!ppt.toString().trim()) {
        return (isValid = false);
      }
    } else {
      return (isValid = false);
    }
    if (QuestionId) {
      if (data.questionType.toString().trim()) {
        if (data.questionType == "3") {
          if (data.questionSet.length == 0) {
            isValid = false;
            return isValid;
          }

          if (data.questionSet2.length == 0) {
            isValid = false;
            return isValid;
          }

          for (let F = 0; F < data.questionSet.length; F++) {
            const option = data.questionSet[F];

            if (!option.correctAnswer) {
              isValid = false;
              return isValid;
            }

            if (!option.Image && !imageOne) {
              isValid = false;
              return isValid;
            }
          }

          for (let g = 0; g < data.questionSet2.length; g++) {
            const option = data.questionSet2[g];
            if (!option.correctAnswer) {
              isValid = false;
              return isValid;
            }
            if (!option.Image && !imageTwo) {
              isValid = false;
              return isValid;
            }
          }
        } else {
          if (data.option1.length == 0) {
            isValid = false;
            return isValid;
          }
          if (data.option2.length == 0) {
            isValid = false;
            return isValid;
          }

          for (let t = 0; t < data.option1.length; t++) {
            const option = data.option1[t];

            if (!option.correctAnswer) {
              isValid = false;
              return isValid;
            }
            if (!option.questionText) {
              isValid = false;
              return isValid;
            }
          }

          for (let v = 0; v < data.option2.length; v++) {
            const option = data.option2[v];
            if (!option.correctAnswer) {
              isValid = false;
              return isValid;
            }
            if (!option.questionText) {
              isValid = false;
              return isValid;
            }
          }
          return isValid;
        }
      }
    } else {
      if (data.questionType.toString().trim()) {
        if (data.questionType == "1" || data.questionType == "2") {
          if (data?.option1.length == 0) {
            isValid = false;
            return isValid;
          }
          if (data?.option2.length == 0) {
            isValid = false;
            return isValid;
          }
          for (let a = 0; a < data.option1.length; a++) {
            const option = data.option1[a];
            if (
              !option.questionText.toString().trim() ||
              !option.correctAnswer.toString().trim()
            ) {
              isValid = false;
              return isValid;
            }
          }

          for (let b = 0; b < data.option2.length; b++) {
            const option = data.option2[b];
            if (
              !option.questionText.toString().trim() ||
              !option.correctAnswer.toString().trim()
            ) {
              isValid = false;
              return isValid;
            }
          }
        }
        if (data.questionType == "3") {
          if (!data.image1) {
            isValid = false;
            return isValid;
          }
          if (!data.image2) {
            isValid = false;
            return isValid;
          }
          if (data.questionSet.length == 0) {
            isValid = false;
            return isValid;
          }

          if (data.questionSet2.length == 0) {
            isValid = false;
            return isValid;
          }

          for (let F = 0; F < data.questionSet.length; F++) {
            const option = data.questionSet[F];
            if (!option.correctAnswer) {
              isValid = false;
              return isValid;
            }
          }

          for (let g = 0; g < data.questionSet2.length; g++) {
            const option = data.questionSet2[g];
            if (!option.correctAnswer) {
              isValid = false;
              return isValid;
            }
          }
        }
      } else {
        return (isValid = false);
      }
      if (!data.slidePpt) {
        return (isValid = false);
      }
    }
    return isValid;
  };

  const submitDatahandler = async (index) => {
    let deleteId = [];
    let delteId2 = [];
    if (shorTestData[slideI].questionType == "3") {
      const oldIds = apiTotalData.map((item) => item.id);
      const oldId2s = apiTotalData2.map((item) => item.id);
      const updatedIds = shorTestData[index].questionSet.map(
        (item) => item.beid
      );
      const updateId2s = shorTestData[index].questionSet2.map(
        (item) => item.beid
      );

      deleteId = oldIds.filter((id) => !updatedIds.includes(id));
      delteId2 = oldId2s.filter((id) => !updateId2s.includes(id));
      setDeleteIds([...deleteId, ...delteId2]);
    } else {
      const oldIds = apiTotalData.map((item) => item.id);
      const oldId2s = apiTotalData2.map((item) => item.id);
      const updatedIds = shorTestData[index].option1.map((item) => item.id);
      const updatedId2s = shorTestData[index].option2.map((item) => item.id);

      deleteId = oldIds.filter((id) => !updatedIds.includes(id));
      delteId2 = oldId2s.filter((id) => !updatedId2s.includes(id));
      setDeleteIds([...deleteId, ...delteId2]);
    }

    if (customValidation(shorTestData[index], shorTestData[index].questionID)) {
      const formData = new FormData();
      formData.append("questionType", shorTestData[index].questionType);
      if (!shorTestData[index].questionID) {
        formData.append("ppt", ppt);
        formData.append("audio", audioApi);
      } else {
        formData.append("ppt", ppt);
        formData.append("audio", audioApi);
      }
      if (qTypeChanged) {
        formData.append("type", 1);
        formData.append(
          "questionsId",
          shorTestData[index].questionID || questionId
        );
      }
      formData.append(
        "questionID",
        shorTestData[index].questionID || questionId
      );
      formData.append(
        "deleteOption",
        JSON.stringify([...deleteId, ...delteId2])
      );
      formData.append(
        "question",
        JSON.stringify(
          (() => {
            if (shorTestData[index].questionType == "3") {
              if (qTypeChanged) {
                return [
                  {
                    option: shorTestData[index].questionSet.map((payload) => ({
                      image: shorTestData[index].image1,
                      questionText: payload.questionText
                        ? payload.questionText
                        : "",
                      correctAnswer: payload.correctAnswer
                        ? payload.correctAnswer
                        : "",
                      pptID: shorTestData[index].id
                        ? shorTestData[index].id
                        : "",
                      optionType: "1",
                      questionID: questionId,
                      Uid: payload.Uid ? payload.Uid : "",
                      top: payload.top ? payload.top : 20,
                      left: payload.left ? payload.left : 20,
                    })),
                  },
                  {
                    option: shorTestData[index].questionSet2.map((payload) => ({
                      image: shorTestData[index].image2,
                      questionText: payload.questionText
                        ? payload.questionText
                        : "",
                      pptID: shorTestData[index].id
                        ? shorTestData[index].id
                        : "",
                      correctAnswer: payload.correctAnswer
                        ? payload.correctAnswer
                        : "",
                      questionID: questionId,
                      optionType: "2",
                      Uid: payload.Uid ? payload.Uid : "",
                      top: payload.top ? payload.top : 20,
                      left: payload.left ? payload.left : 20,
                    })),
                  },
                ];
              } else {
                return [
                  {
                    option: shorTestData[index].questionSet.map((payload) => ({
                      questionID: questionId,
                      pptID: payload.pptId,
                      id: payload.beid ? payload.beid : "",
                      imageId: payload.imageId,
                      image: imageApi1,
                      questionType: payload.questionType,
                      questionText: payload.questionText
                        ? payload.questionText
                        : "",
                      correctAnswer: payload.correctAnswer
                        ? payload.correctAnswer
                        : "",
                      optionType: payload.optionType || 1,
                      Uid: payload.Uid ? payload.Uid : "",
                      top: payload.top ? payload.top : 0,
                      left: payload.left ? payload.left : 0,
                    })),
                  },
                  {
                    option: shorTestData[index].questionSet2.map((payload) => ({
                      questionID: questionId,
                      pptID: payload.pptId,
                      id: payload.beid ? payload.beid : "",
                      imageId: payload.imageId,
                      image: imageApi2,
                      questionType: payload.questionType,
                      questionText: payload.questionText
                        ? payload.questionText
                        : "",
                      correctAnswer: payload.correctAnswer
                        ? payload.correctAnswer
                        : "",
                      optionType: payload.optionType || 2,
                      Uid: payload.Uid ? payload.Uid : "",
                      top: payload.top ? payload.top : 0,
                      left: payload.left ? payload.left : 0,
                    })),
                  },
                ];
              }
            } else {
              if (qTypeChanged) {
                return [
                  {
                    option: shorTestData[index].option1.map((payload) => ({
                      questionText: payload.questionText,
                      optionType: 1,
                      pptID: shorTestData[index].id
                        ? shorTestData[index].id
                        : "",
                      correctAnswer: payload.correctAnswer,
                    })),
                  },
                  {
                    option: shorTestData[index].option2.map((payload) => ({
                      questionText: payload.questionText,
                      optionType: 2,
                      pptID: shorTestData[index].id
                        ? shorTestData[index].id
                        : "",
                      correctAnswer: payload.correctAnswer,
                    })),
                  },
                ];
              } else {
                return [
                  {
                    option: shorTestData[index].option1.map((payload) => ({
                      id: payload.id ? payload.id : "",
                      questionText: payload.questionText,
                      questionID: questionId,
                      pptID: payload.pptId,
                      questionType: payload.questionType,
                      optionType: payload.optionType || 1,
                      correctAnswer: payload.correctAnswer,
                    })),
                  },
                  {
                    option: shorTestData[index].option2.map((payload) => ({
                      id: payload.id,
                      questionText: payload.questionText,
                      questionID: payload.questionID,
                      pptID: payload.pptID,
                      questionType: payload.questionType,
                      optionType: payload.optionType || 2,
                      correctAnswer: payload.correctAnswer,
                    })),
                  },
                ];
              }
            }
          })()
        )
      );

      const dataId = shorTestData[index].id ? shorTestData[index].id : 0;
      const editDataHandler = await editApiHandler(dataId, formData);

      if (editDataHandler && editDataHandler.status === true) {
        navigate("/question");
        toast.success(editDataHandler.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(editDataHandler.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } else {
      toast.error("please Enter Mandatory Field", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleImageTwoItem = async (slideIndex, e) => {
    if (validateImageSize(e.target.files[0])) {
      const selected = e.target.files[0];
      const ALLOWED_TYPES = ["image/png", "image/jpg", "image/jpeg"];
      if (selected && ALLOWED_TYPES.includes(selected.type)) {
        const base64String = await getBase64(selected);
        const rowsInput = [...slides];
        rowsInput[slideIndex]["image2"] = base64String;
        rowsInput[slideIndex]["imageApi2"] = base64String;
        setShortTestData(rowsInput);
        setImageTwo(base64String);
        setImageApi2(base64String);
      }
    }
  };

  const StyledImageUploadWrapper = styled(Box)(() => ({
    marginTop: "16px",
    border: "1px solid rgba(15, 15, 15, 0.15)",
    height: "206px",
    borderRadius: "10px",
    textAlign: "center",
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: "#000",
      fontSize: 13,
      border: 0,
      fontWeight: 600,
      paddingTop: "15px",
      paddingBottom: "15px",
      paddingLeft: "25px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 13,
      color: "rgb(17, 24, 39)",
      border: 0,
      paddingLeft: "25px",

      paddingTop: "10px",
      paddingBottom: "10px",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&": {
      backgroundColor: "#fff",
      ":hover": { backgroundColor: "rgb(248 248 248)!important" },

      "&:nth-of-type(odd)": {
        backgroundColor: "rgb(250 250 250)!important",
      },
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const deleteTableRowsTerms = (index, slideIndex, option) => {
    if (option == "option1") {
      const rows = [...dD1Data];
      rows.splice(index, 1);
      shorTestData[slideIndex][option] = rows;
      setDD1Data(rows);
    } else {
      const rows = [...dD2Data];
      rows.splice(index, 1);
      shorTestData[slideIndex][option] = rows;
      setDD2Data(rows);
    }
  };

  const deleteTableRows = (index, slideIndex, option) => {
    if (option == "option1") {
      const rows = [...tFo1Data];
      rows.splice(index, 1);
      shorTestData[slideIndex][option] = rows;
      setTFO1eData(rows);
    } else {
      const rows = [...tFo2Data];
      rows.splice(index, 1);
      shorTestData[slideIndex][option] = rows;
      setTFO2eData(rows);
    }
  };

  const thirIndexHandler = (slideIndex, data, option) => {
    if (option == "option1") {
      shorTestData[slideIndex]["questionSet"] = data;
    } else {
      shorTestData[slideIndex]["questionSet2"] = data;
    }
  };

  const removeImage = (index, option) => {
    if (option == "option1") {
      const shortestData = [...slides];
      shortestData[index]["image1"] = "";
      shorTestData[index]["imageApi1"] = "";
      setShortTestData(shortestData);
      setImageOne("");
    } else {
      const shortestData = [...slides];
      shortestData[index]["image2"] = "";
      shorTestData[index]["imageApi2"] = "";
      setShortTestData(shortestData);
      setImageTwo("");
    }
  };

  const StyledRemoveButton = styled(Box)({
    color: "red",
    display: "inline",
    "&:hover": {
      cursor: "pointer",
    },
  });

  const validatePptSize = (ppt) => {
    const MAX_FILE_SIZE = 2 * 1024 * 1024;
    const allowedExtensions = [".ppt", ".pptx"];
    const selectedFile = ppt;
    const fileExtension = selectedFile?.name.split(".").pop().toLowerCase();

    if (selectedFile) {
      if (allowedExtensions.includes(`.${fileExtension}`)) {
        if (selectedFile.size <= MAX_FILE_SIZE) {
          return true;
        } else {
          toast.error("Please Upload Only PPT File Less Than 2 MB", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        toast.error("Please Upload Only PPT File", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const pptHandler = (index, event) => {
    setDeleteByFlag(true);
    if (validatePptSize(event.target.files[0])) {
      const rowsInput = [...slides];
      shorTestData[index]["slidePpt"] = event.target.files[0];
      shorTestData[index]["slidePptName"] = event.target.files[0];
      setPpt(event.target.files[0]);
      setPptName(event.target.files[0].name);
      startPptRef.current.value = "";
    } else {
      setPpt("");
      setPptName("");
    }
  };

  const validateAudiohandler = (audioFile) => {
    const MAX_FILE_SIZE = 2 * 1024 * 1024;
    const allowedExtensions = [".mp3", ".wav", ".ogg"];
    if (audioFile) {
      const fileExtension = audioFile.name.split(".").pop().toLowerCase();
      if (allowedExtensions.includes(`.${fileExtension}`)) {
        if (audioFile.size <= MAX_FILE_SIZE) {
          return true;
        } else {
          toast.error("Please Upload Only audio File Less Than 2 MB", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        toast.error("Please Upload Only Audio File ", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  const audioFilehanlder = async (index, event) => {
    setDeleteAudioByFlag(true);
    if (validateAudiohandler(event.target.files[0])) {
      const rowsInput = [...slides];
      rowsInput[index]["audio"] = event.target.files[0];
      setShortTestData(rowsInput);
      setAudio(URL.createObjectURL(event.target.files[0]));
      setAudioApi(event.target.files[0]);
      setAudioName(URL.createObjectURL(event.target.files[0]));
      startAudioRef.current.value = "";
    }
  };

  const thirdQuestionHandler = (data) => { };

  const deleteSlideHandler = async (qTypeChanged, index, slideI) => {
    if (index) {
      const deletePpt = await deleteSlideByPptId(index);
      if (deletePpt.status) {
        navigate("/question");
        toast.success(deletePpt.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } else {
      const slideData = [...slides];
      slideData.splice(slideI, 1);
      setShortTestData(slideData);
      navigate("/question");
    }
  };

  const StyledIconWrapper = styled(Box)(() => ({
    margin: "20px auto",
    border: "1px solid rgba(15, 15, 15, 0.15)",
    width: "80px",
    height: "80px",
    borderRadius: "10px",
    backgroundColor: "rgba(15, 15, 15, 0.05)",
    img: {
      marginTop: "30px",
    },
  }));

  const Answer = [
    { id: 1, name: "True" },
    { id: 2, name: "False" },
  ];

  const questionTypeHandler = (index, e) => {
    const { name, value } = e.target;
    const rowsInput = [...slides];
    setQuestionTypeChnaged(true);
    rowsInput[index]["questionType"] = value;
    rowsInput[index]["optionData"] = [];
    rowsInput[index]["questionSet"] = [];
    rowsInput[index]["questionSet2"] = [];
    rowsInput[index]["imageApi1"] = [];
    rowsInput[index]["imageApi2"] = [];
    setImageOne("");
    setImageTwo("");
    setShortTestData(rowsInput);
    setDD1Data([]);
    setDD2Data([]);
    setTFO1eData([]);
    setTFO2eData([]);
  };

  const handleCloseDelete = () => setOpenDeleteModel(false);
  const handleDeleteModel = () => setOpenDeleteModel(true);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);

  return (
    <Box
      key={`shortTest${slideI}`}
      sx={{
        padding: "10px !important",
        border: "1px solid #8d8c8c",
        marginTop: "11px",
        borderRadius: "10px",
        position: "relative"
      }}
    >
      {/* Question Type */}
      <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
        <div className="absolute top-[-12px] left-[40px] bg-white text-lg font-semibold px-[10px] w-fit">{`Unit ${slideI + 1}`}</div>
        <Box display="flex" alignItems="center">
          <Controls.RadioGroup
            name="questionType"
            onChange={(e) => {
              questionTypeHandler(slideI, e);
            }}
            value={shorTestData[slideI]?.questionType}
            items={Types}
            isRowDir={true}
          />
          {Boolean(errors.questionType) ? (
            <p
              style={{
                color: "rgb(255 0 0)",
                marginTop: "4px",
                marginBottom: "4px",
                fontSize: "14px",
              }}
            >
              {errors.questionType}
            </p>
          ) : (
            ""
          )}
        </Box>
      </Grid>
      <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />

      {/* question type 1 */}

      {shorTestData[slideI]?.questionType == "1" && (
        <Grid item md={12} justifyContent="center" sm={12} xs={12} container>
          <Grid item sx={{ m: 1 }} md={12} xs={12}>
            <Typography
              sx={{
                fontWeight: "400",
                marginLeft: "28px",
                fontSize: "20px",
              }}
            >
              Attempt - 1
            </Typography>
          </Grid>

          <Grid item sx={{ m: 1 }} md={12} xs={12}>
            <Box
              sx={{
                backgroundColor: "rgb(241, 245, 249)",
                width: "98%",
                margin: "auto",
              }}
            >
              <Box
                sx={{
                  border: 1,
                  borderColor: "#eaeaea",
                }}
              >
                <Table className="table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Question Text</StyledTableCell>
                      <StyledTableCell>Correct Answer</StyledTableCell>
                      {/* {rowsData.length == 0 ? ( */}
                      <StyledTableCell sx={{ width: "10px" }}>
                        <Button
                          variant="contained"
                          size="large"
                          style={{
                            backgroundColor: "blue",
                            borderRadius: "10px",
                            width: "0%",
                          }}
                          startIcon={<AddIcon sx={{ marginLeft: "9px" }} />}
                          fullWidth
                          onClick={() => addTableRows(slideI, "option1")}
                        />
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tFo1Data?.map((data, index) => {
                      const { questionText, correctAnswer } = data;
                      return (
                        <StyledTableRow key={index} sx={{ cursor: "pointer" }}>
                          <StyledTableCell component="th" scope="row">
                            <TextField
                              fullWidth
                              text="Add New"
                              variant="outlined"
                              color="primary"
                              autoFocus={t1index == index}
                              label="Question Text"
                              name="questionText"
                              inputRef={(input) => {
                                if (input && cursorPositions[index] !== undefined) {
                                  input.selectionStart = cursorPositions[index];
                                  input.selectionEnd = cursorPositions[index];
                                }
                              }}
                              value={questionText}
                              onChange={(e) => {
                                handleChanges(index, e, slideI, "option1");
                                handleCursorChange(index, e.target.selectionStart);
                              }}
                              inputProps={{
                                maxLength: 255,
                              }}
                            />
                          </StyledTableCell>

                          <StyledTableCell component="th" scope="row">
                            <Controls.SelectBox
                              className="input"
                              label="Select Answer"
                              fullWidth
                              id="correctAnswer"
                              name="correctAnswer"
                              value={correctAnswer}
                              onChange={(e) =>
                                handleChanges(index, e, slideI, "option1")
                              }
                              options={Answer}
                            />
                            {Boolean(errors.correctAnswer) ? (
                              <p
                                style={{
                                  color: "rgb(255 0 0)",
                                  marginTop: "4px",
                                  marginBottom: "4px",
                                  fontSize: "14px",
                                }}
                              >
                                {errors.correctAnswer}
                              </p>
                            ) : (
                              ""
                            )}
                          </StyledTableCell>

                          {/* {rowsData.length == 0 ? ( */}

                          <StyledTableCell
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Space size="middle">
                              <Tooltip placement="bottom" title="Delete ">
                                <button
                                  className="action-button delete-btn"
                                  onClick={() =>
                                    deleteTableRows(index, slideI, "option1")
                                  }
                                  style={{ marginLeft: "15px" }}
                                >
                                  <RiDeleteBinLine />
                                </button>
                              </Tooltip>
                            </Space>
                          </StyledTableCell>
                          {/* <Divider
                            sx={{ bgcolor: "rgba(107, 114, 128, .4)" }}
                          /> */}
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </Grid>

          <Grid item sx={{ m: 1 }} md={12} xs={12}>
            <Typography
              sx={{
                fontWeight: "400",
                marginLeft: "28px",
                fontSize: "20px",
              }}
            >
              Attempt - 2
            </Typography>
          </Grid>

          <Grid item sx={{ m: 1 }} md={12} xs={12}>
            <Box
              sx={{
                backgroundColor: "rgb(241, 245, 249)",
                width: "98%",
                margin: "auto",
              }}
            >
              <Box
                sx={{
                  border: 1,
                  borderColor: "#eaeaea",
                }}
              >
                <Table className="table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Question Text</StyledTableCell>
                      <StyledTableCell>Correct Answer</StyledTableCell>
                      {/* {rowsData.length == 0 ? ( */}
                      <StyledTableCell sx={{ width: "10px" }}>
                        <Button
                          variant="contained"
                          size="large"
                          style={{
                            backgroundColor: "blue",
                            borderRadius: "10px",
                            width: "0%",
                          }}
                          startIcon={<AddIcon sx={{ marginLeft: "9px" }} />}
                          fullWidth
                          onClick={() => addTableRows(slideI, "option2")}
                        />
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tFo2Data?.map((data, index) => {
                      const { questionText, correctAnswer } = data;
                      return (
                        <StyledTableRow key={index} sx={{ cursor: "pointer" }}>
                          <StyledTableCell component="th" scope="row">
                            <TextField
                              text="Add New"
                              variant="outlined"
                              color="primary"
                              fullWidth
                              label="Question Text"
                              name="questionText"
                              autoFocus={t2index == index}
                              value={questionText}
                              inputRef={(input) => {
                                if (input && cursorPositions2[index] !== undefined) {
                                  input.selectionStart =
                                    cursorPositions2[index];
                                  input.selectionEnd = cursorPositions2[index];
                                }
                              }}
                              onChange={(e) => {
                                handleChanges(index, e, slideI, "option2");
                                handleCursorChange2(index,
                                  e.target.selectionStart);
                              }}
                              inputProps={{
                                maxLength: 255,
                              }}
                            />
                          </StyledTableCell>

                          <StyledTableCell component="th" scope="row">
                            <Controls.SelectBox
                              className="input"
                              label="Select Answer"
                              fullWidth
                              id="correctAnswer"
                              name="correctAnswer"
                              value={correctAnswer}
                              onChange={(e) => {
                                handleChanges(index, e, slideI, "option2");
                                handleInputChange(e);
                              }}
                              options={Answer}
                            />
                            {Boolean(errors.correctAnswer) ? (
                              <p
                                style={{
                                  color: "rgb(255 0 0)",
                                  marginTop: "4px",
                                  marginBottom: "4px",
                                  fontSize: "14px",
                                }}
                              >
                                {errors.correctAnswer}
                              </p>
                            ) : (
                              ""
                            )}
                          </StyledTableCell>

                          {/* {rowsData.length == 0 ? ( */}

                          <StyledTableCell
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Space size="middle">
                              <Tooltip placement="bottom" title="Delete ">
                                <button
                                  className="action-button delete-btn"
                                  onClick={() =>
                                    deleteTableRows(index, slideI, "option2")
                                  }
                                  style={{ marginLeft: "15px" }}
                                >
                                  <RiDeleteBinLine />
                                </button>
                              </Tooltip>
                            </Space>
                          </StyledTableCell>
                          {/* <Divider
                            sx={{ bgcolor: "rgba(107, 114, 128, .4)" }}
                          /> */}
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
      <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />

      {/* question type 2 */}
      {shorTestData[slideI]?.questionType == "2" ? (
        <Grid item md={12} justifyContent="center" sm={12} xs={12} container>
          <Grid item sx={{ m: 1 }} md={12} xs={12}>
            <Typography
              sx={{
                fontWeight: "400",
                marginLeft: "28px",
                fontSize: "20px",
              }}
            >
              Attempt - 1
            </Typography>
          </Grid>

          <Grid item sx={{ m: 1 }} md={12} xs={12}>
            <Box
              sx={{
                backgroundColor: "rgb(241, 245, 249)",
                width: "98%",
                margin: "auto",
              }}
            >
              <Box
                sx={{
                  border: 1,
                  borderColor: "#eaeaea",
                }}
              >
                <Table className="table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Question Text</StyledTableCell>
                      <StyledTableCell>Correct Answer</StyledTableCell>
                      <StyledTableCell sx={{ width: "10px" }}>
                        <Button
                          variant="contained"
                          size="large"
                          style={{
                            backgroundColor: "blue",
                            borderRadius: "10px",
                            width: "0%",
                          }}
                          startIcon={<AddIcon sx={{ marginLeft: "9px" }} />}
                          fullWidth
                          onClick={() => addTableRowsTerms(slideI, "option1")}
                        />
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* rowsTermsData */}
                    {dD1Data?.map((data, index) => {
                      const { questionText, correctAnswer } = data;

                      return (
                        <StyledTableRow
                          key={`${questionText}${index}`}
                          sx={{ cursor: "pointer" }}
                        >
                          <StyledTableCell component="th" scope="row">
                            <TextField
                              text="Add New"
                              variant="outlined"
                              color="primary"
                              fullWidth
                              inputRef={(input) => {
                                if (input && cPDD1[index] !== undefined) {
                                  input.selectionStart = cPDD1[index];
                                  input.selectionEnd = cPDD1[index];
                                }
                              }}
                              label="Question Text"
                              autoFocus={d1Index == index}
                              value={questionText}
                              onChange={(e) => {
                                handleChangesTerms(index, e, slideI, "option1");
                                handlecPDD1(index, e.target.selectionStart);
                              }}
                              name="questionText"
                              inputProps={{
                                maxLength: 255,
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            <TextField
                              text="Add New"
                              variant="outlined"
                              color="primary"
                              fullWidth
                              inputRef={(input1) => {
                                if (input1 && cPDDA1[index] !== undefined) {
                                  input1.selectionStart = cPDDA1[index];
                                  input1.selectionEnd = cPDDA1[index];
                                }
                              }}
                              label="correct Answer"
                              autoFocus={d1AIndex == index}
                              value={correctAnswer}
                              onChange={(e) => {
                                handleChangesTerms(index, e, slideI, "option1");
                                handlecPDDA1(index, e.target.selectionStart);
                              }}
                              name="correctAnswer"
                              inputProps={{
                                maxLength: 255,
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Space size="middle">
                              <Tooltip placement="bottom" title="Delete ">
                                <button
                                  className="action-button delete-btn"
                                  onClick={() =>
                                    deleteTableRowsTerms(
                                      index,
                                      slideI,
                                      "option1"
                                    )
                                  }
                                  style={{ marginLeft: "15px" }}
                                >
                                  <RiDeleteBinLine />
                                </button>
                              </Tooltip>
                            </Space>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </Grid>

          <Grid item sx={{ m: 1 }} md={12} xs={12}>
            <Typography
              sx={{
                fontWeight: "400",
                marginLeft: "28px",
                fontSize: "20px",
              }}
            >
              Attempt - 2
            </Typography>
          </Grid>

          <Grid item sx={{ m: 1 }} md={12} xs={12}>
            <Box
              sx={{
                backgroundColor: "rgb(241, 245, 249)",
                width: "98%",
                margin: "auto",
              }}
            >
              <Box
                sx={{
                  border: 1,
                  borderColor: "#eaeaea",
                }}
              >
                <Table className="table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Question Text</StyledTableCell>
                      <StyledTableCell>Correct Answer</StyledTableCell>
                      <StyledTableCell sx={{ width: "10px" }}>
                        <Button
                          variant="contained"
                          size="large"
                          style={{
                            backgroundColor: "blue",
                            borderRadius: "10px",
                            width: "0%",
                          }}
                          startIcon={<AddIcon sx={{ marginLeft: "9px" }} />}
                          fullWidth
                          onClick={() => addTableRowsTerms(slideI, "option2")}
                        />
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* rowsTermsData */}
                    {dD2Data.map((data, index) => {
                      const { questionText, correctAnswer } = data;

                      return (
                        <StyledTableRow
                          key={`${index} ${questionText}`}
                          sx={{ cursor: "pointer" }}
                        >
                          <StyledTableCell component="th" scope="row">
                            <TextField
                              text="Add New"
                              variant="outlined"
                              color="primary"
                              fullWidth
                              inputRef={(input) => {
                                if (input && cPDD2[index] !== undefined) {
                                  input.selectionStart = cPDD2[index];
                                  input.selectionEnd = cPDD2[index];
                                }
                              }}
                              label="Question Text"
                              autoFocus={d2Index == index}
                              value={questionText}
                              onChange={(e) => {
                                handleChangesTerms(index, e, slideI, "option2");
                                handlecPDD2(index, e.target.selectionStart);
                              }}
                              name="questionText"
                              inputProps={{
                                maxLength: 255,
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            <TextField
                              text="Add New"
                              variant="outlined"
                              color="primary"
                              fullWidth
                              inputRef={(input) => {
                                if (input && cPDD2[index] !== undefined) {
                                  input.selectionStart = cPDD2[index];
                                  input.selectionEnd = cPDD2[index];
                                }
                              }}
                              label="correct Answer"
                              autoFocus={d2AIndex == index}
                              value={correctAnswer}
                              onChange={(e) => {
                                handleChangesTerms(index, e, slideI, "option2");
                                handlecPDD2(index, e.target.selectionStart);
                              }}
                              name="correctAnswer"
                              inputProps={{
                                maxLength: 255,
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Space size="middle">
                              <Tooltip placement="bottom" title="Delete ">
                                <button
                                  className="action-button delete-btn"
                                  onClick={() =>
                                    deleteTableRowsTerms(
                                      index,
                                      slideI,
                                      "option2"
                                    )
                                  }
                                  style={{ marginLeft: "15px" }}
                                >
                                  <RiDeleteBinLine />
                                </button>
                              </Tooltip>
                            </Space>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </Grid>
        </Grid>
      ) : null}
      <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />

      {shorTestData[slideI]?.questionType == "3" ? (
        <>
          {/* Attempt - 1 */}
          <Grid
            item
            md={12}
            mt={2}
            justifyContent="center"
            sm={12}
            xs={12}
            container
          >
            <Grid item sx={{ m: 1 }} md={12} xs={12}>
              <Typography
                sx={{
                  fontWeight: "400",
                  marginLeft: "28px",
                  fontSize: "20px",
                }}
              >
                Attempt - 1
              </Typography>
              {errorMessage && (
                <h5 style={{ color: "red" }}> {errorMessage}</h5>
              )}
              <Box key={`Index-1`}>
                <StyledImageUploadWrapper>
                  <label htmlFor={`image-upload-Attempt-1_${slideI}`}>
                    <StyledInput
                      accept="image/*"
                      id={`image-upload-Attempt-1_${slideI}`}
                      type="file"
                      onChange={(e) => handleImageItem(slideI, e)}
                      onClick={(event) => {
                        event.currentTarget.value = null;
                      }}
                    />
                    <StyledIconWrapper
                      sx={
                        imageOne == ""
                          ? {
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }
                          : {
                            backgroundImage: `url(${imageOne})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                          }
                      }
                    >
                      {imageOne === "" && (
                        <img
                          src={camera}
                          alt="Camera"
                          style={{ margin: "0" }}
                        />
                      )}
                    </StyledIconWrapper>
                  </label>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "black",
                    }}
                  >
                    Add Images For Question
                  </Typography>
                  <Typography
                    sx={{
                      color: "rgba(15, 15, 15, 0.5)",
                      mx: 2,
                      fontSize: "12px",
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, max size of 3.1 MB
                  </Typography>
                  {/* slides[slideIndex].image != "" &&  */}
                  {imageOne != "" && (
                    <StyledRemoveButton
                      onClick={(e) => {
                        e.preventDefault();
                        removeImage(slideI, "option1");
                      }}
                    >
                      Remove
                    </StyledRemoveButton>
                  )}
                </StyledImageUploadWrapper>
              </Box>
            </Grid>
          </Grid>
          {/* backend={TouchBackend} */}
          <DndProvider backend={HTML5Backend}>
            <ImageQuestion
              data={thirdQuestionHandler}
              slideIndex={slideI}
              option="option1"
              indexHandler={thirIndexHandler}
              View={false}
              edit={true}
              Image={imageOne && imageOne}
              OutData={imageOneData}
            />
          </DndProvider>
          <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />

          {/* Attempt - 2 */}

          <Grid item md={12} justifyContent="center" sm={12} xs={12} container>
            <Grid item sx={{ m: 1 }} md={12} xs={12}>
              <Typography
                sx={{
                  fontWeight: "400",
                  marginLeft: "28px",
                  fontSize: "20px",
                }}
              >
                Attempt - 2
              </Typography>
              {errorMessage && (
                <h5 style={{ color: "red" }}> {errorMessage}</h5>
              )}
              <Box>
                <StyledImageUploadWrapper>
                  <label htmlFor={`image-upload-Attempt-2_${slideI}`}>
                    <StyledInput
                      accept="image/*"
                      id={`image-upload-Attempt-2_${slideI}`}
                      type="file"
                      View={false}
                      onChange={(e) => handleImageTwoItem(slideI, e)}
                      onClick={(event) => {
                        event.currentTarget.value = null;
                      }}
                    />
                    <StyledIconWrapper
                      sx={
                        imageTwo == ""
                          ? {
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }
                          : {
                            backgroundImage: `url(${imageTwo})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                          }
                      }
                    >
                      {imageTwo === "" && (
                        <img
                          src={camera}
                          alt="Camera"
                          style={{ margin: "0" }}
                        />
                      )}
                    </StyledIconWrapper>
                  </label>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "black",
                    }}
                  >
                    Add Images For Question
                  </Typography>
                  <Typography
                    sx={{
                      color: "rgba(15, 15, 15, 0.5)",
                      mx: 2,
                      fontSize: "12px",
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, max size of 3.1 MB
                  </Typography>
                  {imageTwo != "" && (
                    <StyledRemoveButton
                      onClick={(e) => {
                        e.preventDefault();
                        removeImage(slideI, "option2");
                      }}
                    >
                      Remove
                    </StyledRemoveButton>
                  )}
                </StyledImageUploadWrapper>
              </Box>
            </Grid>
          </Grid>
          <DndProvider backend={HTML5Backend}>
            <ImageQuestion
              data={thirdQuestionHandler}
              slideIndex={slideI}
              option="option2"
              indexHandler={thirIndexHandler}
              edit={true}
              Image={imageTwo && imageTwo}
              OutData={imageTwoData}
            />
          </DndProvider>
          <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
        </>
      ) : null}

      {/* <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
        <Button variant="contained" component="label">
          Upload ppt
          <input
            hidden
            accept=".pptx,application"
            type="file"
            onChange={(e) => pptHandler(slideI, e)}
          />
        </Button>
        {pptName && <h5>{pptName}</h5>}

        <Button variant="contained" component="label">
           Audio 
          <input
            hidden
            accept=".mp3,.wav,.ogg,.mp4"
            type="file"
            onChange={(e) => audioFilehanlder(slideI, e)}
          />
        </Button>
        {audio && <UploadAudio audioFile={audio} keyName={`${audio}`}/>}
        {audioName && <h5>{audioName}</h5>}

      </Grid> */}

      <Grid item marginTop={"20px"}>
        <Grid container justifyContent={"center"}>
          <Grid item sx={{ m: 1 }} md={5.7} xs={12}>
            <Button
              variant="contained"
              component="label"
              style={{ marginBottom: "8px" }}
            >
              Upload ppt
              <input
                hidden
                ref={startPptRef}
                accept=".pptx,application"
                type="file"
                onChange={(e) => pptHandler(slideI, e)}
              />
            </Button>
            {pptName && (
              <Tooltip placement="bottom" title="Delete ">
                <button
                  className="action-button delete-btn"
                  style={{ marginLeft: "15px" }}
                  onClick={() => handleDeleteModel()}
                >
                  <RiDeleteBinLine />
                </button>
              </Tooltip>
            )}
            {pptName && <h5 style={{ marginTop: "10px", overflowWrap: 'anywhere' }}>{pptName}</h5>}
          </Grid>

          <Grid item sx={{ m: 1 }} md={5.7} xs={12}>
            <Button
              variant="contained"
              component="label"
              style={{ marginBottom: "8px" }}
            >
              upload audio File
              <input
                hidden
                accept=".mp3,.wav,.ogg,.mp4"
                type="file"
                ref={startAudioRef}
                onChange={(e) => audioFilehanlder(slideI, e)}
              />
            </Button>
            {audioName && (
              <Tooltip placement="bottom" title="Delete ">
                <button
                  className="action-button delete-btn"
                  style={{ marginLeft: "15px" }}
                  onClick={() => deleteAudioHandler(shorTestData[slideI].id, slideI)}
                >
                  <RiDeleteBinLine />
                </button>
              </Tooltip>
            )}
            {audioName && <h5 style={{ marginTop: "10px", overflowWrap: 'anywhere' }}>{audioName}</h5>}
            {audio && <UploadAudio audioFile={audio} keyName={`${audio}`} />}
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        sx={{
          display: "flex",
          marginTop: "20px",
          justifyContent: "flex-end",
        }}
      >
        <Button variant="contained" onClick={() => submitDatahandler(slideI)} sx={{ marginRight: '10px' }}>
          {" "}
          Submit{" "}
        </Button>

        <Button
          variant="contained"
          onClick={() => deleteSlideHandler(qTypeChanged, SlideId, slideI)}
        >
          delete
        </Button>
      </Grid>

      <Modal
        keepMounted
        open={openDeleteModel}
        onClose={handleCloseDelete}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={styles}>
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#000",
              marginBottom: "15px",
            }}
          >
            Delete Record
          </Typography>
          <label className="fontLight">
            Are you sure to want to delete this record ?
          </label>
          <Grid container style={{ marginTop: "2rem" }}>
            <Grid item xs={9} className="text-end">
              <Controls.Button
                style={{ backgroundColor: "#fff", color: "#000", marginRight: '10px' }}
                text="Cancel"
                onClick={handleCloseDelete}
              />
            </Grid>
            <Grid item xs={3} className="text-end">
              <Controls.Button
                style={{ marginRight: "7px" }}
                text="Delete"
                onClick={() => deletePptHandler(shorTestData[slideI].id, slideI)}
                type="submit"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
}

export default ShortTestEdit;
