import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useProSidebar } from "react-pro-sidebar";
import { Grid, Box, IconButton } from "@mui/material";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { Form, useForm } from "../../../utils/useForm";
import Controls from "../../../components/controls/Controls";
import { useLocation, useNavigate } from "react-router";
import { addCourse, getClassDropdownList, UpdateCourse } from "../../../state/actions/Admin/courseAction";
import "./AddCourse.css";
import { ToastContainer, toast } from 'react-toastify';

const StyledIconWrapper = styled(Box)(() => ({
  margin: "20px auto",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",

  img: {
    marginTop: "30px",
  },
}));

const StyledInput = styled("input")({
  display: "none",
});
const StyledRequired = styled("span")({
  color: "red",
  marginLeft: "2px",
  fontSize: "20px",
});
const StyledRemoveButton = styled(Box)({
  color: "red",
  display: "inline",
  "&:hover": {
    cursor: "pointer",
  },
});

const initialValues = {
  courseName: "",
  sequenceNo: "",
  classID: "",
};

const AddVehicle = (props) => {
  const {
    open,
    handleClose,
    stateData,
    refreshScreen,
    isEdit,

  } = props;

  const [classDropdown, setClassDropdown] = useState([]);

  const ClassDropdownList = async () => {

    const res = await getClassDropdownList();
    if (res.status === true) {
      setClassDropdown(res.data);
    } else {
      setClassDropdown([]);
    }
  };
  useEffect(() => {
    ClassDropdownList();
  }, []);



  const { collapsed, broken } = useProSidebar();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();


  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("courseName" in fieldValues) {

      temp.courseName = fieldValues.courseName ? "" : "This field is required.";
    }
    if ("classID" in fieldValues) {
      temp.classID = fieldValues.classID ? "" : "This field is required.";
    }

    if ("sequenceNo" in fieldValues) {
      //only numbers allow regex
      var regex = /^[+-]?([0-9]*[.])?[0-9]+$/;
      if (!regex.test(fieldValues.sequenceNo)) {
        temp.sequenceNo = " Only numbers are allowed";
      } else {
        temp.sequenceNo = "";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const playload = {
        courseName: values.courseName,
        classID: values.classID,
        sequenceNo: values.sequenceNo,
      }

      if (isEdit) {
        const res = await UpdateCourse(stateData.id, playload);
        if (res && res.status === true) {
          close();
          refreshScreen();
          toast.success(res.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error(res.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }

      if (!isEdit) {

        const res = await addCourse(playload);
        if (res && res.status === true) {
          close();
          refreshScreen();
          resetForm();
          toast.success(res.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error(res.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }

      }
    }
  };


  const close = () => {
    handleClose();
  };

  const [confirmDialog, setConfirmDialog] = useState(false);

  const openConfirmDialog = () => {
    setConfirmDialog(true);
  };
  const closeConfirmDialog = () => {
    setConfirmDialog(false);
  };


  useEffect(() => {
    if (isEdit) {
      setValues({
        ...values,
        courseName: stateData.courseName,
        classID: stateData.classID,
        sequenceNo: stateData.sequenceNo,

      });
    } else {
      resetForm();
    }
  }, [isEdit, stateData]);



  return (
    <div>
      {/* add new user modal */}
      <Dialog
        open={open}
        onClose={openConfirmDialog}
        fullWidth={true}
        maxWidth="lg"
        sx={{
          width: { xs: "100%", sm: "70%", md: "50%" },
          marginLeft: {
            xs: "0px",
            sm: "30%",
            md: "430px",
            lg: "430px",
            xl: "430px",
          },
          color: "black",
        }}
      >
        <Form onSubmit={handleSubmit}>
          <DialogTitle
            sx={{
              // fontFamily: "Work Sans",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "20px",
              lineHeight: "30px",
              display: "flex",
              justifyContent: "space-between",
              color: "black",
              alignItems: 'center'
            }}
          >
            {isEdit ? "Edit Course" : "Add New Course"}
            <Tooltip title="Close">
              <IconButton
                onClick={() => {
                  close();
                }}
              >
                <Close sx={{ color: "black" }} />
              </IconButton>
            </Tooltip>
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>

                <Controls.SelectBox
                  className="input select_box"
                  label="Select Class Name"
                  fullWidth
                  id="classID"
                  name="classID"
                  value={values.classID}
                  onChange={handleInputChange}
                  options={classDropdown}

                />
                {Boolean(errors.classID) ? (
                  <p
                    style={{
                      color: "rgb(255 0 0)",
                      marginTop: "4px",
                      marginBottom: "4px",
                      fontSize: "14px",
                    }}
                  >
                    {errors.classID}
                  </p>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12}>
                <Controls.Input
                  label="Course Name"
                  name="courseName"
                  value={values.courseName}
                  onChange={handleInputChange}
                  error={errors.courseName}
                  helperText={errors.courseName}
                  sx={{ marginTop: "0px !important" }}
                  fullWidth
                  inputProps={{
                    maxLength: 100,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Controls.Input
                  type="number"
                  variant="outlined"
                  color="primary"
                  label="Sequence No"
                  name="sequenceNo"
                  value={values.sequenceNo}
                  onChange={handleInputChange}
                  error={errors.sequenceNo}
                  helperText={errors.sequenceNo}
                  inputProps={{
                    maxLength: 5,
                  }}
                  fullWidth

                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ margin: "10px" }}>
            <Grid container justifyContent={'flex-end'}>
              <Grid item className="text-end">
                <Controls.Button
                  style={{
                    backgroundColor: "#fff",
                    marginRight: "7px",
                    color: "#000",
                  }}
                  text="Cancel"
                  onClick={() => {
                    close();
                    resetForm();
                  }}
                />
              </Grid>
              <Grid item className="text-end">
                <Controls.Button
                  style={{ marginRight: "7px" }}
                  text="Save"
                  onClick={(e) => handleSubmit(e)}
                  type="submit"
                />
              </Grid>
            </Grid>
          </DialogActions>
        </Form>
      </Dialog>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </div>
  );
};

export default AddVehicle;
