import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../../pages/Login";
import Home from "../../pages/Home";
import AboutUs from "../../pages/AboutUs";
import Course from "../../pages/Course";
import Exam from "../../pages/Exam";
import Questions from "../../pages/Questions";
import PptxPreview from "../../pages/Ppt";
import StudentFinalResult from "@/pages/StudentFinalResult";
import StudentRightAns from "@/pages/StudentRightAns";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import PptView from "@/pages/Ppt/PptView";
import ShortTestFeedBack from "@/pages/StudentRightAns/ShortTestFeedBack";
import NewHome from "@/pages/Home/NewHome";
import NewResult from "@/pages/StudentFinalResult/NewResult";


const Main = (props) => {
  const { showNavbar, setShowNavbar } = props;
  return (
    <Routes>
      <Route exact path="/login" element={<Login />} />
      {/* <Route exact path="/" element={<Home />} /> */}
      <Route exact path="/" element={<NewHome />} />
      {/* <Route exact path="/home" element={<Home />} /> */}
      <Route exact path="/about-me" element={<AboutUs />} />
      <Route exact path="/courses" element={<Course />} />
      <Route exact path="/exam" element={<Exam />} />
      <Route exact path="/questions" element={<Questions />} />
      <Route exact path="/preview" element={<PptxPreview />} />
      <Route exact path="/pptPreview" element={<PptView />} />
      {/* <Route exact path="/finalResult" element={<StudentFinalResult />} /> */}
      <Route exact path="/finalResult" element={<NewResult />} />
      <Route exact path="/finalRightAns" element={
        <DndProvider backend={HTML5Backend}>
          <StudentRightAns />
        </DndProvider>
      } />
      <Route exact path="/feedBack" element={
        <DndProvider backend={HTML5Backend}>
          <ShortTestFeedBack />
        </DndProvider>
      } />
    </Routes>
  );
};

export default Main;
