import Typography from "@mui/material/Typography";
import {
  Box,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  tableCellClasses,
} from "@mui/material";
import Button from "@mui/material/Button";
import Controls from "../../../components/controls/Controls";
import AddIcon from "@mui/icons-material/Add";
import { useForm, Form } from "../../../utils/useForm";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { RiDeleteBinLine, RiEditLine } from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import { Space } from "antd";
import {
  AddQuestions,
  getSessioDropdownList,
  updateQuestions,
} from "../../../state/actions/Admin/questionAction";
import {
  getClassDropdownList,
  getCourseDropdownList,
} from "../../../state/actions/Admin/courseAction";
import { Padding } from "@mui/icons-material";
import "./Question.css";
const Types = [
  { id: 1, title: "True / False" },
  { id: 2, title: "Drag & Drop" },
  { id: 3, title: "Image" },
];

const Answer = [
  { id: 1, name: "True" },
  { id: 2, name: "False" },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#000",
    fontSize: 13,
    border: 0,
    fontWeight: 600,
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "25px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    color: "rgb(17, 24, 39)",
    border: 0,
    paddingLeft: "25px",

    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    backgroundColor: "#fff",
    ":hover": { backgroundColor: "rgb(248 248 248)!important" },

    "&:nth-of-type(odd)": {
      backgroundColor: "rgb(250 250 250)!important",
    },
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const initialValues = {
  classID: "",
  sessionID: "",
  courseID: "",
  questionType: "",
  marks: "",
};

export default function DynamicAddQuestion(props) {
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("classID" in fieldValues) {
      temp.classID = fieldValues.classID ? "" : "This field is required.";
    }
    if ("sessionID" in fieldValues) {
      temp.sessionID = fieldValues.sessionID ? "" : "This field is required.";
    }

    if ("courseID" in fieldValues) {
      temp.courseID = fieldValues.courseID ? "" : "This field is required.";
    }

    if ("questionType" in fieldValues) {
      temp.questionType = fieldValues.questionType
        ? ""
        : "This field is required.";
    }
    if ("marks" in fieldValues) {
      temp.marks = fieldValues.marks ? "" : "This field is required.";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const [isEdit, setIsEdit] = useState(false);
  const [rowsTermsData, setRowsTermsData] = useState([]);
  const [Questions, setQuestions] = useState();
  const [rowsData, setRowsData] = useState([]);

  const [SessionDropdown, setSessionDropdown] = useState([]);

  const getDetail = async () => {
    if (values.courseID) {
      const res = await getSessioDropdownList(values.courseID);
      setSessionDropdown(res.data);
    }
  };
  useEffect(() => {
    if (values.courseID) {
      getDetail(values.courseID);
    }
  }, [values.courseID]);

  const [classDropdown, setClassDropdown] = useState([]);
  const [CourseDropdown, setCourseDropdown] = useState([]);
  const [showData, setShowData] = useState(false);
  const [slides, setSlides] = useState([]);

  const handleClick = () => {
    setShowData(true);
    const addSlides = {
      questionType: "1",
    };
    setSlides([...slides, addSlides]);
  };

  const ClassDropdownList = async () => {
    const res = await getClassDropdownList();
    if (res.status === true) {
      setClassDropdown(res.data);
    } else {
      setClassDropdown([]);
    }
  };

  useEffect(() => {
    ClassDropdownList();
  }, []);

  const getDetails = async () => {
    if (values.classID) {
      const res = await getCourseDropdownList(values.classID);
      setCourseDropdown(res.data);
    }
  };

  useEffect(() => {
    if (values.classID) {
      getDetails(values.classID);
    }
  }, [values.classID]);

  const addTableRows = () => {
    const rowsInput = {
      questionText: "",
      correctAnswer: "",
    };

    if (rowsData.length == 0) {
      setRowsData([...rowsData, rowsInput]);
    }
  };

  //terms and condition

  const addTableRowsTerms = () => {
    const rowsInput = {
      questionText: "",
      correctAnswer: "",
    };
    setRowsTermsData([...rowsTermsData, rowsInput]);
  };

  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  };

  //terms and condition

  const deleteTableRowsTerms = (index) => {
    const rows = [...rowsTermsData];
    rows.splice(index, 1);
    setRowsTermsData(rows);
  };

  const handleChanges = (index, e) => {
    const { name, value } = e.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    setRowsData(rowsInput);
  };

  //terms and condition

  const handleChangesTerms = (index, e) => {
    const { name, value } = e.target;
    const rowsInput = [...rowsTermsData];
    rowsInput[index][name] = value;
    setRowsTermsData(rowsInput);
  };

  const navigate = useNavigate();
  const { state } = useLocation();

  const { enqueueSnackbar } = useSnackbar();

  //descrption for array
  const data = rowsData?.map((row) => row);

  //terms and condition
  const dataTerms = rowsTermsData?.map((row) => row);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      let Questionsdata = "";
      if (values.questionType == 1) {
        Questionsdata = JSON.stringify(data);
      } else {
        Questionsdata = JSON.stringify(dataTerms);
      }
      const playload = {
        classID: values.classID,
        courseID: values.courseID,
        sessionID: values.sessionID,
        questionType: values.questionType,
        marks: values.marks,
        Questions: Questionsdata,
      };

      if (isEdit) {
        const res = await updateQuestions(state?.question?.id, playload);
        if (res && res.status === true) {
          navigate("/question");
          resetForm();
          toast.success(res.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error(res.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }

      if (!isEdit) {
        const res = await AddQuestions(playload);

        if (res && res.status === true) {
          navigate("/question");
          toast.success(res.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error(res.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
    }
  };

  //details edit
  useEffect(() => {
    if (
      state?.question.id != "" &&
      state?.question.id != null &&
      state?.question.id != undefined
    ) {
      setIsEdit(true);
      setValues({
        ...values,
        classID: state?.question.classID,
        sessionID: state?.question.sessionID,
        courseID: state?.question.courseID,
        questionType: state?.question.questionType,
        marks: state?.question.marks,
      });
      setRowsData(state?.question?.Questions);
      setRowsTermsData(state?.question?.Questions);
    } else {
      resetForm();
    }
  }, [state?.question]);

  return (
    <>
      <Box>
        <Box
          sx={{
            px: 3,
            paddingBottom: "3px",
            backgroundColor: "rgb(241, 245, 249)",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Typography
              sx={{
                paddingTop: "25px",
                paddingBottom: "15px",
                fontSize: "15px",
                fontWeight: "600",
                color: "#000",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/question");
              }}
            >
              <KeyboardBackspaceIcon
                sx={{ verticalAlign: "top", marginRight: "5px" }}
              />
              Question
            </Typography>
            <div>
              <Controls.Button
                style={{
                  backgroundColor: "#fff",
                  color: "#000",
                  marginRight: "10px",
                }}
                text="Cancel"
                onClick={() => {
                  navigate("/question");
                  resetForm();
                }}
              />
              <Controls.Button
                style={{ marginRight: "7px" }}
                text="Submit"
                onClick={(e) => handleSubmit(e)}
                type="submit"
              />
            </div>
          </Box>
        </Box>
        <Form onSubmit={handleSubmit}>
          <Box
            className="b1"
            sx={{
              backgroundColor: "white",
            }}
          >
            <Grid
              item
              md={12}
              justifyContent="center"
              sm={12}
              xs={12}
              container
            >
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.SelectBox
                  className="input select_box"
                  label="Select Class"
                  fullWidth
                  id="classID"
                  name="classID"
                  value={values.classID}
                  onChange={handleInputChange}
                  options={classDropdown}
                />
                {Boolean(errors.classID) ? (
                  <p
                    style={{
                      color: "rgb(255 0 0)",
                      marginTop: "4px",
                      marginBottom: "4px",
                      fontSize: "14px",
                    }}
                  >
                    {errors.classID}
                  </p>
                ) : (
                  ""
                )}
              </Grid>{" "}

              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.SelectBox
                  className="input select_box"
                  label="Select Course"
                  fullWidth
                  id="courseID"
                  name="courseID"
                  value={values.courseID}
                  onChange={handleInputChange}
                  options={CourseDropdown}
                  error={errors.courseID}
                  helperText={errors.courseID}
                />
                {Boolean(errors.courseID) ? (
                  <p
                    style={{
                      color: "rgb(255 0 0)",
                      marginTop: "4px",
                      marginBottom: "4px",
                      fontSize: "14px",
                    }}
                  >
                    {errors.courseID}
                  </p>
                ) : (
                  ""
                )}
              </Grid>{" "}

              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.SelectBox
                  className="input select_box"
                  label="Select Session"
                  fullWidth
                  id="sessionID"
                  name="sessionID"
                  value={values.sessionID}
                  onChange={handleInputChange}
                  options={SessionDropdown}
                />
                {Boolean(errors.sessionID) ? (
                  <p
                    style={{
                      color: "rgb(255 0 0)",
                      marginTop: "4px",
                      marginBottom: "4px",
                      fontSize: "14px",
                    }}
                  >
                    {errors.sessionID}
                  </p>
                ) : (
                  ""
                )}
              </Grid>{" "}
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Marks"
                  name="marks"
                  fullWidth
                  value={values.marks}
                  onChange={handleInputChange}
                  error={errors.marks}
                  helperText={errors.marks}
                />
              </Grid>
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Button variant="contained" component="label">
                  Upload starting ppt
                  <input hidden accept="ppt/*" multiple type="file" />
                </Button>
              </Grid>
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Button variant="contained" component="label">
                  Upload feed back ppt
                  <input hidden accept="ppt/*" multiple type="file" />
                </Button>
              </Grid>
              {/* <Grid item sx={{ m: 1 }} md={5.5} xs={12}></Grid>{" "} */}
            </Grid>
          </Box>

          <Box
            className="b1"
            sx={{
              backgroundColor: "white",
              marginTop: "20px",
              padding: "20px !important",
            }}
          >
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid item>
                <Typography variant="h6">Add Slides</Typography>
              </Grid>
              <Grid item sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  size="large"
                  style={{
                    backgroundColor: "blue",
                    borderRadius: "10px",
                    width: "0%",
                  }}
                  startIcon={<AddIcon sx={{ marginLeft: "9px" }} />}
                  fullWidth
                  onClick={handleClick}
                />
              </Grid>
            </Grid>
            {showData ? (
              <Box
                sx={{
                  padding: "10px !important",
                  border: "1px solid #8d8c8c",
                  marginTop: "10px",
                  borderRadius: "10px",
                }}
              >
                <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                  <Box display="flex" alignItems="center">
                    <Controls.RadioGroup
                      name="questionType"
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                      value={values.questionType}
                      items={Types}
                      // defaultChecked={values.type}
                      isRowDir={true}
                    />{" "}
                    {Boolean(errors.questionType) ? (
                      <p
                        style={{
                          color: "rgb(255 0 0)",
                          marginTop: "4px",
                          marginBottom: "4px",
                          fontSize: "14px",
                        }}
                      >
                        {errors.questionType}
                      </p>
                    ) : (
                      ""
                    )}
                  </Box>
                </Grid>
                <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
                {values.questionType == "1" ? (
                  <Grid
                    item
                    md={12}
                    justifyContent="center"
                    sm={12}
                    xs={12}
                    container
                  >
                    <Grid item sx={{ m: 1 }} md={12} xs={12}>
                      <Typography
                        sx={{
                          fontWeight: "400",
                          marginLeft: "28px",
                          fontSize: "20px",
                        }}
                      >
                        Question
                      </Typography>
                    </Grid>

                    <Grid item sx={{ m: 1 }} md={12} xs={12}>
                      <Box
                        sx={{
                          backgroundColor: "rgb(241, 245, 249)",
                          width: "98%",
                          margin: "auto",
                        }}
                      >
                        <Box
                          sx={{
                            border: 1,
                            borderColor: "#eaeaea",
                          }}
                        >
                          <Table className="table">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Question Text</StyledTableCell>
                                <StyledTableCell>
                                  Correct Answer
                                </StyledTableCell>
                                {rowsData.length == 0 ? (
                                  <StyledTableCell sx={{ width: "10px" }}>
                                    <Button
                                      variant="contained"
                                      size="large"
                                      style={{
                                        backgroundColor: "blue",
                                        borderRadius: "10px",
                                        width: "0%",
                                      }}
                                      startIcon={
                                        <AddIcon sx={{ marginLeft: "9px" }} />
                                      }
                                      fullWidth
                                      onClick={addTableRows}
                                    />
                                  </StyledTableCell>
                                ) : null}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {rowsData?.map((data, index) => {
                                const { questionText, correctAnswer } = data;

                                return (
                                  <StyledTableRow
                                    key={index}
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <StyledTableCell component="th" scope="row">
                                      <Controls.Input
                                        text="Add New"
                                        variant="outlined"
                                        color="primary"
                                        label="Question Text"
                                        name="questionText"
                                        value={questionText}
                                        onChange={(e) => {
                                          handleChanges(index, e);
                                          handleInputChange(e);
                                        }}
                                        inputProps={{
                                          maxLength: 255,
                                        }}
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                      <Controls.SelectBox
                                        className="input"
                                        label="Select Answer"
                                        fullWidth
                                        id="correctAnswer"
                                        name="correctAnswer"
                                        value={correctAnswer}
                                        onChange={(e) => {
                                          handleChanges(index, e);
                                          handleInputChange(e);
                                        }}
                                        options={Answer}
                                      />
                                      {Boolean(errors.correctAnswer) ? (
                                        <p
                                          style={{
                                            color: "rgb(255 0 0)",
                                            marginTop: "4px",
                                            marginBottom: "4px",
                                            fontSize: "14px",
                                          }}
                                        >
                                          {errors.correctAnswer}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </StyledTableCell>

                                    {rowsData.length == 0 ? (
                                      <StyledTableCell
                                        onClick={(e) => {
                                          e.stopPropagation();
                                        }}
                                      >
                                        <Space size="middle">
                                          <Tooltip
                                            placement="bottom"
                                            title="Delete "
                                          >
                                            <button
                                              className="action-button delete-btn"
                                              onClick={() =>
                                                deleteTableRows(index)
                                              }
                                              style={{ marginLeft: "15px" }}
                                            >
                                              <RiDeleteBinLine />
                                            </button>
                                          </Tooltip>
                                        </Space>
                                      </StyledTableCell>
                                    ) : null}
                                  </StyledTableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      sx={{
                        textAlign: "center",
                        position: "relative",
                        padding: "10px",
                      }}
                      md={12}
                      xs={12}
                    >
                      <input
                        type="file"
                        id="myFile"
                        name="filename"
                        className="input_upload"
                      />
                      <Typography
                        sx={{
                          fontSize: "15px",
                          color: "blue",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        Please Upload PPT
                      </Typography>
                    </Grid>
                  </Grid>
                ) : null}
                <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
                {values.questionType == "2" ? (
                  <Grid
                    item
                    md={12}
                    justifyContent="center"
                    sm={12}
                    xs={12}
                    container
                  >
                    <Grid item sx={{ m: 1 }} md={12} xs={12}>
                      <Typography
                        sx={{
                          fontWeight: "400",
                          marginLeft: "28px",
                          fontSize: "20px",
                        }}
                      >
                        Question
                      </Typography>
                    </Grid>

                    <Grid item sx={{ m: 1 }} md={12} xs={12}>
                      <Box
                        sx={{
                          backgroundColor: "rgb(241, 245, 249)",
                          width: "98%",
                          margin: "auto",
                        }}
                      >
                        <Box
                          sx={{
                            border: 1,
                            borderColor: "#eaeaea",
                          }}
                        >
                          <Table className="table">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Question Text</StyledTableCell>
                                <StyledTableCell>
                                  Correct Answer
                                </StyledTableCell>
                                <StyledTableCell sx={{ width: "10px" }}>
                                  <Button
                                    variant="contained"
                                    size="large"
                                    style={{
                                      backgroundColor: "blue",
                                      borderRadius: "10px",
                                      width: "0%",
                                    }}
                                    startIcon={
                                      <AddIcon sx={{ marginLeft: "9px" }} />
                                    }
                                    fullWidth
                                    onClick={addTableRowsTerms}
                                  />
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {rowsTermsData?.map((data, index) => {
                                const { questionText, correctAnswer } = data;

                                return (
                                  <StyledTableRow
                                    key={index}
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <StyledTableCell component="th" scope="row">
                                      <Controls.Input
                                        text="Add New"
                                        variant="outlined"
                                        color="primary"
                                        label="Question Text"
                                        value={questionText}
                                        onChange={(e) => {
                                          handleChangesTerms(index, e);
                                          handleInputChange(e);
                                        }}
                                        name="questionText"
                                        inputProps={{
                                          maxLength: 255,
                                        }}
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                      <Controls.Input
                                        text="Add New"
                                        variant="outlined"
                                        color="primary"
                                        label="correct Answer"
                                        value={correctAnswer}
                                        onChange={(e) => {
                                          handleChangesTerms(index, e);
                                          handleInputChange(e);
                                        }}
                                        name="correctAnswer"
                                        inputProps={{
                                          maxLength: 255,
                                        }}
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <Space size="middle">
                                        <Tooltip
                                          placement="bottom"
                                          title="Delete "
                                        >
                                          <button
                                            className="action-button delete-btn"
                                            onClick={() =>
                                              deleteTableRowsTerms(index)
                                            }
                                            style={{ marginLeft: "15px" }}
                                          >
                                            <RiDeleteBinLine />
                                          </button>
                                        </Tooltip>
                                      </Space>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      sx={{
                        textAlign: "center",
                        position: "relative",
                        padding: "10px",
                      }}
                      md={12}
                      xs={12}
                    >
                      <input
                        type="file"
                        id="myFile"
                        name="filename"
                        className="input_upload"
                      />
                      <Typography
                        sx={{
                          fontSize: "15px",
                          color: "blue",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        Please Upload PPT
                      </Typography>
                    </Grid>
                  </Grid>
                ) : null}
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Form>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          limit={1}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <ToastContainer />
      </Box>
    </>
  );
}
