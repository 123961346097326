import { TextField } from "@mui/material";
import React from "react";
import { useDrag, useDrop } from "react-dnd";
import "../Questions/textfield.css";

export const ItemTypes = {
  BOX: "box",
};

function getStyles(left, top, right, bottom, isDragging, correctAnswer) {
  const isMobile = window.innerWidth <= 987;
  const leftOffset = isMobile ? 0 : 70;
  const transform = `translate3d(${parseInt(left)}px, ${parseInt(top)}px, 0)`;

  return {
    position: "absolute",
    transform,
    WebkitTransform: transform,
    opacity: isDragging ? 0 : 1,
    height: isDragging ? 0 : "",
  };
}

function DndBox(props) {
  const {
    id,
    correctAnswer,
    studentAns,
    left,
    top,
    data,
    dataHandler,
    right,
    bottom,
  } = props.id;

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: ItemTypes.BOX,
      item: { id, left, top, correctAnswer, right, bottom },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [id, left, top, correctAnswer, right, bottom]
  );

  const [{ isOver }, drop] = useDrop({
    accept: "ANSWER",
    drop: (item) => props.onDrop(item.answer),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div className="relative">
      <div
        className="top-[80px] left-[140px] w-full relative"
        style={getStyles(left, top, isDragging, correctAnswer)}
        role="DraggableBox"
      >
        <TextField
          size="small"
          ref={drop}
          sx={{ width: "100%" ,mixBlendMode:'difference'}}
          value={props.onlyView ? correctAnswer : studentAns}
        />
      </div>
    </div>
  );
}

export default DndBox;
